<h1>Insurance Groups</h1>
<nav mat-tab-nav-bar [tabPanel]="tabs">
    <a mat-tab-link *ngFor="let link of links" (click)="gotoTab(link.link)"
       [active]="activeLink == link">{{link.title}}</a>
</nav>
<app-vendor-groups-products *ngIf="activeLink?.title === 'Products'"></app-vendor-groups-products>
<app-vendor-groups-plans *ngIf="activeLink?.title === 'Plans'"></app-vendor-groups-plans>
<app-vendor-groups-rates *ngIf="activeLink?.title === 'Rates'"></app-vendor-groups-rates>
<app-vendor-groups-questions *ngIf="activeLink?.title === 'Questions'"></app-vendor-groups-questions>
<mat-tab-nav-panel #tabs></mat-tab-nav-panel>
