import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-media-content',
  templateUrl: './media-content.component.html',
  styleUrls: ['./media-content.component.scss']
})
export class MediaContentComponent implements OnInit {

  public config: TableConfig = {
    title: 'Media - Web Content',
    dbTableName: 'media',
    filter: {type: 'content'},
    expand: [],
    columns: [
      {name: 'media_id', title: '', isImage: true, imageType: 'content'},
      {name: 'name', title: 'Content Name'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ],
    excludedFields: ['type', 'mimetype'],
    preWrite: (fields) => { fields.type = 'content'; delete fields.media_id; return true; },
  };

  constructor() { }

  ngOnInit(): void {
  }

}
