<ng-container *ngIf="enabled">
    <ng-container *ngIf="vendorProducts.data.length === 0">
        <app-inlinemessage class="info" level="warning" message="There are no products assigned to this broker"></app-inlinemessage>
    </ng-container>
    <table mat-table matSort class="table" *ngIf="vendorProducts.data.length > 0" [dataSource]="vendorProducts">
        <ng-container matColumnDef="vendorName">
            <th mat-header-cell *matHeaderCellDef>Vendor Name</th>
            <td mat-cell *matCellDef="let item">{{item.vendorName}}</td>
        </ng-container>
        <ng-container matColumnDef="vendorSlug">
            <th mat-header-cell *matHeaderCellDef>Vendor Tag</th>
            <td mat-cell *matCellDef="let item">{{item.vendorSlug}}</td>
        </ng-container>
        <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef>Product Name</th>
            <td mat-cell *matCellDef="let item">{{item.productName}}</td>
        </ng-container>
        <ng-container matColumnDef="productSlug">
            <th mat-header-cell *matHeaderCellDef>Product Tag</th>
            <td mat-cell *matCellDef="let item">{{item.productSlug}}</td>
        </ng-container>
        <ng-container matColumnDef="actionmenu" stickyEnd>
            <th mat-header-cell matTooltip="Agency Actions" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item"><button mat-button class="cell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button></td>
        </ng-container>
        <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-item="item">
                <button mat-menu-item (click)="assignToAgencies(item)">Assign to Agencies</button>
                <button mat-menu-item (click)="removeFromAgencies(item)">Remove from Agencies</button>
            </ng-template>
        </mat-menu>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
    </table>
</ng-container>
