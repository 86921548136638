import {Component, Inject, OnInit} from '@angular/core';
import {TeamsService} from '../../../services/teams/teams.service';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {StripeService} from '../../../services/stripe/stripe.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import { FeeService } from 'src/app/services/fee/fee.service';

@Component({
  selector: 'app-member-reward-dialog',
  templateUrl: './memberrewarddialog.component.html',
  styleUrls: ['./memberrewarddialog.component.scss'],
})
export class MemberRewardDialogComponent implements OnInit {

  public team: any;
  public teamMember;
  public form;
  public isP2p: boolean;
  public title = '';
  public sending = false;

  serviceFee = 0;

  constructor(
      private route: ActivatedRoute,
      private teamsService: TeamsService,
      private formBuilder: UntypedFormBuilder,
      private stripeService: StripeService,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      public dialogRef: MatDialogRef<MemberRewardDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: MemberRewardDialogModel,
      private spinnerService: SpinnerService,
      private feeService: FeeService,
  ) {
    this.teamMember = data.member;
    this.isP2p = data.isP2p;
    this.title = this.isP2p ? 'Top Up Peer Recognition Balance' : 'Send One Time Payment to Member';
    this.form = this.formBuilder.group({
      amount: new UntypedFormControl('', Validators.required),
      message: new UntypedFormControl('', Validators.required),
      note: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      this.team = wellspace;
    });
  }

  private getFormField(name: string): any {
    const field = this.form.get(name);
    return field ? field.value : undefined;
  }

  async sendClicked(): Promise<void> {
    const amount = this.getFormField('amount');

    this.serviceFee = await this.feeService.getPaymentMethodFee(this.team);

    const amountPlusFee = amount * (1 + this.serviceFee);
    const paymentMethodDescription = 'to your default payment method';
    this.uiAlertService.presentAlertConfirm(`This will initiate a one time ${this.team.invoiceEmail ? 'invoice' : 'charge'} of $${amountPlusFee.toFixed(2)} ${paymentMethodDescription}. ${this.serviceFee > 0 ? `This includes a service fee of $${(amountPlusFee - amount).toFixed(2)}.` : ''}`).then(async confirm => {
      if (confirm) {
        this.sending = true;
        this.spinnerService.show();
        this.teamsService.getCustomerId(this.team).then(async customerId => {
          const type = this.isP2p ? 'P2P' : 'ONETIME-REWARD';
          this.stripeService.makeOneTimePayment(customerId, `${this.team.name}: one time payment to ${this.teamMember.email}`,
              amount, [this.teamMember.email], this.getFormField('message'), this.getFormField('note'), await this.teamsService.getPaymentMethod(this.team), this.team.id, type, this.team.id, this.form.get('destination')?.value).then(_ => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `One time payment made.`});
            this.spinnerService.hide();
            this.dialogRef.close(amount);
          }).catch(error => {
            this.spinnerService.hide();
            const info = error.error;
            this.snackBar.openFromComponent(SnackBarComponent, {data: `${info.raw.message}: ${info.raw.decline_code ? info.raw.decline_code.split('_').join(' ') : ''}`});
            this.dialogRef.close(0);
          });
        });
       }
    });
  }

  onDismiss(): void {
    this.dialogRef.close(0);
  }

}

export class MemberRewardDialogModel {
  constructor(public member: any, public isP2p: boolean) {
  }
}
