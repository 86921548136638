import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TeamCompanyUsersService} from '../../../services/teamcompanyusers/teamcompanyusers.service';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {MatDialog} from '@angular/material/dialog';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-agency-producers',
  templateUrl: './producers.component.html',
  styleUrls: ['./producers.component.scss']
})
export class ProducersComponent implements OnInit {

  @Input() enabled = false;
  @Input() companyId = '';
  @Output() countEvent = new EventEmitter<number>();

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public columnsToDisplay = ['name', 'clientName', 'actionmenu'];
  public producers = new MatTableDataSource<any>();

  constructor(
      private teamCompanyUsersService: TeamCompanyUsersService,
      private teamCompaniesService: TeamCompaniesService,
      private dialog: MatDialog,
      private uiAlertService: UiAlertService,
      private snackBar: MatSnackBar
  ){
  }

  ngOnInit(): void {
    this.loadItems();
    this.producers.sort = this.sort;
  }

  private loadItems(): void {
    this.teamCompaniesService.getCompanies(this.teamCompanyUsersService.roles.broker ? 'broker' : 'agency').then(companies => {
      const clients = companies.filter((company: { parentId: string; }) => company.parentId === this.companyId);
      this.teamCompanyUsersService.getCompanyUsers(this.companyId).then((companyUsers: any[]) => {
        this.producers.data = [];
        companyUsers.forEach(companyUser => {
          companyUser.metadata?.clients?.forEach((client: any) => {
            const foundClient = clients.find((thisClient: { id: any; }) => thisClient.id === client);
            this.producers.data.push({
              id: foundClient.id,
              clientName: foundClient.name,
              name: companyUser.name,
              metadata: companyUser.metadata,
              userId: companyUser.id
            });
          });
        });
        this.producers.data = this.producers.data.slice();
        this.countEvent.emit(this.producers.data.length);
      });
    });
  }

  removeProducer(producer: any): void {
    this.uiAlertService.presentAlertConfirm(`Do you really want to remove producer ${producer.name} from ${producer.clientName}?`).then(ok => {
      if (ok) {
        this.teamCompanyUsersService.modifyCompanyUser(producer.userId, {metadata: {clients: producer.metadata.clients.filter((client: any) => client !== producer.id)}}).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Producer ${producer.name} removed`});
          this.loadItems();
        });
      }
    });
  }

}
