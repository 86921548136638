<form [formGroup]="form">
    <div class="controls">
        <mat-form-field>
            <mat-label>Team Member</mat-label>
            <mat-select formControlName="member" name="member">
                <mat-option *ngFor="let member of members; let i = index" [value]="i">{{member.firstName}} {{member.lastName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="search">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput type="search" placeholder="Search receipts..." class="search-input" (input)="filterItems($event)">
        </mat-form-field>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: fileName})"><mat-icon>file_download</mat-icon>Export to CSV</button>
        <button *ngIf="filter === 'Reimbursement approved' && items.data.length > 0" class="exportbutton" mat-raised-button color="accent" (click)="makePayments()"><mat-icon>attach_money</mat-icon>Make Payments</button>
    </div>
</form>
<table class="table" mat-table matSort matTableExporter [hiddenColumns]="[8]" #exporter="matTableExporter" [dataSource]="items">
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>Receipt</th>
      <td class="cell" mat-cell *matCellDef="let item"><img (click)="showReceipt(item)" class="receiptimage" [src]="item.receiptUrl"></td>
    </ng-container>
    <ng-container matColumnDef="purchaseDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Purchase Date</th>
      <td class="cell" mat-cell *matCellDef="let item">{{(item.purchaseDate ?? item.transactionDate) | date: 'mediumDate' : 'UTC'}}</td>
    </ng-container>
    <ng-container matColumnDef="reimbursedToWallit" *ngIf="columnsToDisplay.includes('reimbursedToWallit')">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Reimbursed To Wallit</th>
        <td class="cell" mat-cell *matCellDef="let item">{{(item.answeredAt | date: 'mediumDate' : 'UTC') || '---'}}</td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Member</th>
      <td class="cell" mat-cell *matCellDef="let item">{{(!item.firstName && !item.lastName) ? '---' : (item.firstName + ' ' + item.lastName)}}</td>
    </ng-container>
    <ng-container matColumnDef="employeeNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Employee ID</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.employeeNumber ?? '---'}}</td>
    </ng-container>
    <ng-container matColumnDef="merchant">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Merchant</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.merchant ?? item.name}}</td>
    </ng-container>
    <ng-container matColumnDef="purpose">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Purpose</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.purpose ?? '---'}}</td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Project</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.project || '---'}}</td>
    </ng-container>
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Category</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.category || item.categories || '---'}}</td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Amount</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.amount | currency}}</td>
    </ng-container>
    <ng-container matColumnDef="billable">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Billable</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.billable ? 'Yes' : 'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="taxable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Taxable</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.taxable ? 'Yes' : 'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Status</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item | getState :this}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="cell" mat-cell *matCellDef="let item">
            <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="transactionMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
        </td>
    </ng-container>
    <mat-menu #transactionMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button [disabled]="(item | getState :this) === 'Reimbursed'" mat-menu-item (click)="reimbursePurchase(item)">Approve Reimbursement</button>
            <button [disabled]="(item | getState :this) === 'Reimbursed'" mat-menu-item (click)="denyPurchase(item)">Deny Reimbursement</button>
        </ng-template>
    </mat-menu>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
<span *ngIf="items.data.length === 0">No {{filter ? filter : 'Awaiting Approval'}} purchases</span>
