import {AfterViewInit, Component, EventEmitter, Output, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {Category, TeamsService} from '../../services/teams/teams.service';
import {Plan, StripeService} from '../../services/stripe/stripe.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {FeeService} from 'src/app/services/fee/fee.service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../modal/confirmdialog/confirmdialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-team-set-benefits',
  templateUrl: './team-set-benefits.page.html',
  styleUrls: ['./team-set-benefits.page.scss'],
})
export class TeamSetBenefitsPageComponent implements AfterViewInit {

  @ViewChild('range') rangeRef: any;
  @Output() emitHasUnsavedChanges: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() emitChangesWereSaved: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public categories: Array<any> = [];
  public atLeastOneSelected = false;
  public team: any;
  public allowances = [ 25, 50, 75, 0 ];
  public selectedAllowance = 0;
  public saving = false;
  public isSaveButtonDisabled = true;
  private initialAllowance = 0;
  private initialCategories = [];
  private currentSelectedCategories: Array<any> = [];
  serviceFee = 0;

  constructor(
      private teamsService: TeamsService,
      private stripeService: StripeService,
      private snackBar: MatSnackBar,
      private spinnerService: SpinnerService,
      private feeService: FeeService,
      private dialog: MatDialog
  ) { }

  ngAfterViewInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any): void => {
      if (!this.team) {
        this.team = JSON.parse(localStorage.getItem('selected-wellspace') as string);
      } else {
        this.team = wellspace;
      }
      this.setupBenefitsFields();
    });
  }

  private setInitialCategories(): void {
    this.initialCategories = JSON.parse(JSON.stringify(this.team.categories));
  }

  private setOneSelected(): void {
    this.atLeastOneSelected = this.categories.find(category => category.selected);
  }

  rangeChanged(event: any): void {
    this.allowances[this.allowances.length - 1] = this.selectedAllowance = Math.min(event.target.value, 1000);
    this.allowances = [...this.allowances];
    if (this.allowances.slice(0, -1).find((value: number): boolean => value === this.selectedAllowance)) {
      this.allowances[this.allowances.length - 1] = 0;
    }
    this.verifyIfCanSave();
  }

  allowanceClicked(allowance: number): void {
    this.selectedAllowance = allowance;
    this.allowances[this.allowances.length - 1] = 0;
    this.allowances = [...this.allowances];
    this.verifyIfCanSave();
  }

  categoryClicked(clickedCategory: any): void {
    const selectedCount: number = this.categories.filter(category => category.selected).length;
    if (this.currentSelectedCategories.filter(category => category === clickedCategory.category).length > 0) {
      this.currentSelectedCategories = this.currentSelectedCategories.filter(category => category !== clickedCategory.category);
    } else {
      this.currentSelectedCategories.push(clickedCategory.category);
    }
    if (selectedCount >= this.stripeService.STANDARD_CATEGORIES && this.team.plan !== Plan.Plus && this.team.plan !== Plan.PlusAnnual && !clickedCategory.selected) {
      this.snackBar.openFromComponent(SnackBarComponent, { data: `You can only have ${this.stripeService.STANDARD_CATEGORIES} categories on the standard plan. Upgrade to Plus for more categories and other features.`});
    } else {
      clickedCategory.selected = !clickedCategory.selected;
      this.setOneSelected();
    }
    this.verifyIfCanSave();
  }

  setBenefitsClicked(): void {
    if (this.team.amount !== 0 && this.team.amount !== this.selectedAllowance) {
      const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        data: new ConfirmDialogModel('New allowance amount', `You are changing the monthly allowance to $${this.selectedAllowance} per member. <br /> This change will take effect next month. <br /> Any available credit balance will be applied first before charging the remaining amount. <br /> Please confirm.`)
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.saveNewBenefits();
        } else {
          dialogRef.close(false);
        }
      });
    } else {
      this.saveNewBenefits();
    }
  }

  private verifyIfCanSave(): void {
    const isCategoriesEqual: boolean = this.checkIfEqualArrays(this.initialCategories,  this.currentSelectedCategories);
    const isAmountEqual: boolean = this.checkAmount();
    this.isSaveButtonDisabled = isCategoriesEqual && isAmountEqual;
    this.emitHasUnsavedChanges.emit(this.isSaveButtonDisabled)
  }

  private checkAmount(): boolean {
    if (this.team.amount === 0) {
      return true;
    }
    return this.team.amount !== 0 && this.team.amount === this.selectedAllowance;
  }

  private saveNewBenefits(): void {
    this.spinnerService.show('Updating benefits...');
    this.saving = true;
    this.testValueChanged((): void => {
      this.teamsService.getTeamInfo(this.team.id).then((wellspace: any): void => {
        this.teamsService.setWellspace(wellspace);
        this.saving = false;
        this.spinnerService.hide();
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'Your benefits changes have been saved.'});
        this.emitChangesWereSaved.emit(true)
      }).catch((): void => {
        this.teamsService.setWellspace(undefined);
        this.saving = false;
        this.spinnerService.hide();
      });
    }).catch(err => {
      console.error('Error in save new benefits in test value', err);
    });
  }

  private setupBenefitsFields(): void {
    this.spinnerService.show('Loading benefits...');
    this.categories = [];
    this.teamsService.getCategories().then((categories: Category[]): void => {
      this.categories = categories.map((category: any) => ({ taxable: !!category.taxable, toolTip: category.toolTip, category: category.name, selected: false }));
      this.selectedAllowance = this.rangeRef.value = this.initialAllowance =  this.team.amount;
      this.team.categories.forEach((savedCategory: string): void => {
        this.categories.find(category => category.category === savedCategory).selected = true;
      });
      this.setInitialCategories();
      this.setOneSelected();
      this.currentSelectedCategories = this.team.categories;
      this.spinnerService.hide();
    }).catch(err => {
      console.error('Error in get categories', err);
      this.categories = [];
      this.currentSelectedCategories = [];
      this.spinnerService.hide();
    });
  }

  private checkIfEqualArrays(a: Array<any>, b: Array<any>): boolean {
    if (a === b) { return true; }
    if (a == null || b == null) { return false; }
    if (a.length !== b.length) { return false; }
    for (let index = 0; index < a.length; index++) {
      if (a[index] !== b[index]) { return false; }
    }
    return true;
  }

  async testValueChanged(doneFunc: () => void): Promise<void> {
    const categoriesChanged: boolean = JSON.stringify(this.initialCategories) !== JSON.stringify(this.categories.filter(category => category.selected).map(cat => cat.category));
    if (this.initialAllowance !== this.selectedAllowance || categoriesChanged) {
      this.team.amount = this.selectedAllowance;
      this.team.categories = this.categories.filter(category => category.selected).map(category => category.category);
      const paymentMethod: string = await this.teamsService.getPaymentMethod(this.team);
      try {
        await this.teamsService.modifyTeam(this.team.id, {amount: this.team.amount, categories: this.team.categories});
        if (this.initialAllowance !== this.selectedAllowance && paymentMethod) {
          this.serviceFee = await this.feeService.getPaymentMethodFee(this.team);
          let members: any = await this.teamsService.getTeamMembers(this.team.id);
          members = members.filter((member: { status: string; }): boolean => member.status === 'active');
          const customerId: any = await this.teamsService.getCustomerId(this.team);
          await this.stripeService.changeAllowance(customerId, this.selectedAllowance, members.length, this.serviceFee.toString(), this.team.id, paymentMethod);
        }
        doneFunc();
      } catch (error) {
        this.spinnerService.hide();
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'Your benefits changes failed. Please try again later.'});
        return;
      }
    } else {
      doneFunc();
    }
  }

  checkAllowanceColorButton(allowance: number): boolean {
    if (this.selectedAllowance === 0 || !this.allowances.includes(this.selectedAllowance)) {
      return allowance === 0 || allowance === this.selectedAllowance;
    }
    return allowance === this.selectedAllowance;
  }

}

@Pipe({
  name: 'taxableFilter',
  pure: true
})
export class TaxableFilterPipe implements PipeTransform {

  transform(categories: Array<any>, taxable: boolean): Array<any> {
    return categories.filter(category => category.taxable === taxable);
  }

}


