<h1 mat-dialog-title>
    {{ title }}
</h1>
<div mat-dialog-content>
    <span class="info">The amount you specify will be immediately sent to the {{ isP2p ? 'peer recognition' : 'allowance' }}
        balance for the team members you select.
        {{ isP2p ? 'Make sure that this is what you intended to do rather than sending a one time reward to the allowance balance.' : 'You may choose to either send the payment to their allowance balance towards purchases or directly to their Wallit balance where they can withdraw the funds to a connected bank account. You will be able to approve the payment before it is sent.' }}</span>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
        <mat-form-field *ngIf="!isP2p" formFieldName="destination">
            <mat-label>Payment Destination</mat-label>
            <mat-select formControlName="destination">
                <mat-option [value]="false">Allowance Balance</mat-option>
                <mat-option [value]="true">Wallit Balance</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field formFieldName="amount">
            <mat-label>Amount to send ($)</mat-label>
            <input type="number" matInput placeholder="" formControlName="amount">
        </mat-form-field>
        <mat-form-field formFieldName="message">
            <mat-label>Message</mat-label>
            <textarea class="message" rows="4" matInput formControlName="message"></textarea>
        </mat-form-field>
        <mat-form-field formFieldName="note">
            <mat-label>Note (not sent)</mat-label>
            <textarea class="message" rows="4" matInput formControlName="note"></textarea>
        </mat-form-field>
    </form>
    <app-team-member-selector [team]="toTeam" class="teamlist"
                              (membersSelectedEvent)="membersChanged($event)"></app-team-member-selector>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="members.length === 0 || !form.valid || uploading" class="donebutton"
            color="primary" (click)="sendRewards()">{{ sendButtonLabel }}
    </button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
