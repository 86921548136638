<form [formGroup]="form">
    <div class="controls">
    </div>
</form>
<div class="header" *ngIf="info">
    <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'Stripe-Reconciliation-' + info.team?.name})"><mat-icon>file_download</mat-icon>Export to CSV</button>
    <h2 class="total">Total {{total / 100 | currency}}</h2>
    <h2 class="teamname">{{info.team?.name}}</h2>
</div>
<table class="table" mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="items">
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Transaction Date</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.created * 1000 | date: 'mediumDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Members</th>
        <td class="cell" mat-cell *matCellDef="let item">{{getMembers(item)}}</td>
    </ng-container>
    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Message</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.charges.data[0].invoice?.metadata?.message}}</td>
    </ng-container>
    <ng-container matColumnDef="sender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Sender</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.sender}}</td>
    </ng-container>
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Amount</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.amount_received / 100 | currency}}</td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Type</th>
        <td class="cell" mat-cell *matCellDef="let item">{{getType(item)}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Status</th>
        <td class="cell" mat-cell *matCellDef="let item">{{getStatus(item) | desnake}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
<span *ngIf="items.data?.length === 0">No Stripe transactions</span>
