<form class="form" [formGroup]="form">
    <mat-form-field>
        <mat-label>Gender</mat-label>
        <mat-select formControlName="gender">
            <mat-option value="M">M</mat-option>
            <mat-option value="F">F</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Tobacco Use End Date</mat-label>
        <div class="input">
            <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="tobaccoUseDate">
            <mat-icon *ngIf="form.get('tobaccoUseDate')?.value" class="close" (click)="removeTobaccoDate()">close</mat-icon>
        </div>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</form>
<div class="buttons">
    <button mat-raised-button color="primary" class="primarybutton" shape="" [disabled]="!form.valid || !form.dirty" (click)="saveClicked()">Save</button>
    <button mat-raised-button color="primary" class="primarybutton" shape="" [disabled]="!form.dirty" (click)="resetClicked()">Reset</button>
</div>
