import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-infoband',
  templateUrl: './infoband.component.html',
  styleUrls: ['./infoband.component.scss']
})
export class InfobandComponent {

  @Input() items: any = [];

}
