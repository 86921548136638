import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TeamsService} from '../../services/teams/teams.service';
import {SessionService} from '../../services/session/session.service';
import {SpinnerService} from '../../services/spinner/spinner.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit, AfterViewInit {

  public links = [
    { title: 'All Teams Overview', link: '/analytics/overview'},
    { title: 'Current Team Overview', link: '/analytics/team-overview'},
    { title: 'Members', link: '/analytics/agency-members'},
    { title: 'Categories', link: '/analytics/categories'},
    { title: 'Reimbursements', link: '/analytics/reimbursements'},
    { title: 'All Teams Allowances', link: '/analytics/allowances'},
    { title: 'Current Team Allowances', link: '/analytics/team-allowances'},
    { title: 'Peer To Peer', link: '/analytics/peer-to-peer' }
  ];
  public activeLink: any;
  public analytics: any;

  constructor(
      private router: Router,
      private teamsService: TeamsService,
      private sessionService: SessionService,
      private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 2;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      if (wellspace) {
        this.spinnerService.show();
        // @ts-ignore
        this.teamsService.getTeamAnalytics(this.sessionService.getUserId(), wellspace.id).then(analytics => {
          this.spinnerService.hide();
          this.analytics = analytics;
        });
      }
    });
  }

  ngAfterViewInit(): void {
  }

}

