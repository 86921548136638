import { Component, OnInit } from '@angular/core';
import {StripeService} from '../../services/stripe/stripe.service';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {UtilsService} from '../../services/utils/utils.service';
import {DomSanitizer} from '@angular/platform-browser';
import {UsersService} from '../../services/users/users.service';

interface SelectItem {
  name: string;
  value: string;
}

@Component({
  selector: 'app-products-purchases',
  templateUrl: './products-purchases.component.html',
  styleUrls: ['./products-purchases.component.scss']
})
export class ProductsPurchasesComponent implements OnInit {

  public form;
  public subscriptions = [];
  public companies: Array<SelectItem> = [];
  public columnsToDisplay = ['image', 'name', 'user', 'pricing', 'status', 'created'];
  public fileName = '';
  public statuses: Array<SelectItem> = [
    {name: 'All', value: ''},
    {name: 'Active', value: 'active'},
    {name: 'Past Due', value: 'past_due'},
    {name: 'Unpaid', value: 'unpaid'},
    {name: 'Canceled', value: 'canceled'},
    {name: 'Incomplete', value: 'incomplete'},
    {name: 'Incomplete Expired', value: 'incomplete_expired'},
    {name: 'Trialing', value: 'trialing'},
    {name: 'Paused', value: 'paused'},
  ];

  constructor(
      private stripeService: StripeService,
      private formBuilder: UntypedFormBuilder,
      private utilsService: UtilsService,
      private usersService: UsersService,
      private sanitizer: DomSanitizer
  ) {
    this.form = this.formBuilder.group({
          startDate: new UntypedFormControl(),
          endDate: new UntypedFormControl(),
          status: new UntypedFormControl(''),
          company: new UntypedFormControl('')
        }
    );
    this.form.valueChanges.subscribe(change => {
      this.loadItems();
    });
  }

  ngOnInit(): void {
    this.loadItems();
    this.usersService.getBrands().then(brands => {
      this.companies = [{name: 'All', value: ''}];
      // @ts-ignore
      this.companies.push(...brands.filter(brand => brand.configjson?.integration?.productBrand).map(brand => ({name: brand.configjson.integration.productBrand, value: brand.configjson.integration.productBrand})));
    });
  }

  private loadItems(): void {
    const values = this.utilsService.getSimpleFormFields(this.form);
    this.fileName = `subscriptions-${values.startDate}-${values.endDate}-${values.status}`;
    this.stripeService.searchSubscriptions('USAA', values).then(result => {
      this.subscriptions = result.data;
      this.subscriptions.forEach(item => {
        // @ts-ignore
        item.plan.product.images[0] = this.sanitizer.bypassSecurityTrustUrl(item.plan.product.images[0]);
      });
    });
  }

}
