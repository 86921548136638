import {Component, Inject} from '@angular/core';
import {TeamsService} from '../../../services/teams/teams.service';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {StripeService} from '../../../services/stripe/stripe.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import { FeeService } from 'src/app/services/fee/fee.service';

@Component({
  selector: 'app-refund-allowance-dialog',
  templateUrl: './refundallowancedialog.component.html',
  styleUrls: ['./refundallowancedialog.component.scss'],
})
export class RefundAllowanceDialogComponent {

  public form;
  public member;
  balance;

  constructor(
      private route: ActivatedRoute,
      private teamsService: TeamsService,
      private formBuilder: UntypedFormBuilder,
      private stripeService: StripeService,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      public dialogRef: MatDialogRef<RefundAllowanceDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: RefundAllowanceDialogModel,
      private spinnerService: SpinnerService,
      private feeService: FeeService,
  ) {
    this.member = data.member;
    this.balance = Number(data.member.balance).toFixed(2)
    this.form = this.formBuilder.group({
      amount: new UntypedFormControl(this.member.balance, Validators.required),
    });
  }

  async refundClicked(): Promise<void> {
    this.dialogRef.close(this.form.get('amount')?.value);
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }

}

export class RefundAllowanceDialogModel {
  constructor(public member: any) {
  }
}
