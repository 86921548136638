import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {SessionService} from '../session/session.service';
import {BehaviorSubject} from 'rxjs';
import {SendMode} from '../../components/modal/sendfundsdialog/send-funds-dialog.page';
// import {LiquidCache} from 'ngx-liquid-cache';
import {Dictionary} from 'highcharts';

export interface Category {
  taxable: boolean;
  name: string;
  toolTip: string;
  category: string;
}

export enum PaymentTargets {
  ALLOWANCES = 'Allowances',
  PLANS = 'Plans',
  REWARDS = 'Rewards'
}

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  public wellspaceChangedObservable = new BehaviorSubject(undefined);

  constructor(
      private restangular: Restangular,
      private sessionService: SessionService,
  ) {
  }

  setWellspace(wellspace: any): void {
    if (wellspace) {
      setTimeout(() => {
        this.getTeamInfo(wellspace.id).then((updatedWellspace: any) => {
          this.updateWellspace(updatedWellspace);
        }).catch((err: any): void => {
          console.error('Error: ', err);
          this.updateWellspace(wellspace);
        });
      }, 100);
    }
  }

  updateWellspace(wellspace: any): void {
    localStorage.setItem('selected-wellspace', JSON.stringify(wellspace));
    this.wellspaceChangedObservable.next(wellspace);
  }

  getUserMemberTeams(userId: string): Promise<any> {
    return this.restangular.one('teams').one('user', userId).one('member').getList().toPromise();
  }

  getUserTeamsInfo(userId: string): Promise<any> {
    return this.restangular.one('teams').one('user', userId).one('all').get().toPromise();
  }

  getUserTeamInfo(userId: string): Promise<any> {
    return this.restangular.one('teams').one('user', userId).get().toPromise();
  }

  getTeamInfo(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).get().toPromise();
  }

  getTeamReconciliation(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('reconciliation').get().toPromise();
  }

  getAllTeams(): Promise<any> {
    return this.restangular.one('teams').get().toPromise();
  }

  getCategories(): Promise<[Category]> {
    return this.restangular.one('teams').one('categories').get().toPromise();
  }

  getTeamMembers(teamId: string, wallitBalance?: boolean): Promise<any> {
    return this.restangular.one('teams', teamId).one(wallitBalance ? 'members?wallitBalance=1' : 'members').getList().toPromise();
  }

  getTeamAnalytics(userId: string, teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('user', userId).one('analytics').get().toPromise();
  }

  getMemberAnalytics(userId: string): Promise<any> {
    return this.restangular.one('teams').one('user', userId).one('memberanalytics').get().toPromise();
  }

  getAllTeamsAnalytics(userId: string): Promise<any> {
    return this.restangular.one('teams').one('user', userId).one('analytics').get().toPromise();
  }

  addTeamMember(teamId: string, userEmail: string, userId = null): Promise<any> {
    return this.restangular.one('teams', teamId).one('member').customPOST({
      userId,
      userEmail,
      balance: 0
    }).toPromise();

  }

  removeTeamMember(id: string, teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('member', id).remove().toPromise();
  }

  createTeam(userId: string, name: string): Promise<any> {
    return this.restangular.one('teams', userId).customPOST({
      name,
      categories: [],
      frequency: 'monthly',
      amount: 0,
      plan: 'Free',
    }).toPromise();
  }

  modifyTeam(teamId: string, fields: any): Promise<any> {
    return this.restangular.one('teams', teamId).customPUT({
      ...fields
    }).toPromise();
  }

  modifyTeamMember(teamId: string, memberId: string, fields: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('member', memberId).customPUT({
      ...fields
    }).toPromise();
  }

  deleteTeam(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).remove().toPromise();
  }

  resendEmailToTeamMember(memberId: string, teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('member', memberId).one('resend').customPUT().toPromise();
  }

  uploadMemberList(teamId: string, data: any): Promise<any> {
      return this.restangular.one('teams', teamId).one('uploadmembers').customPUT(data, undefined, undefined, { 'content-type': 'text/html' }).toPromise();
  }

  checkTeamPendingCharges(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('pendingcharges').get().toPromise();
  }

  setBankAccount(publicToken: string, teamId: string, accountId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('setbank', accountId).customPUT({publicToken}). toPromise();
  }

  buildMemberList(members: Array<any>, team: any): Array<any> {
      const newMembers: Array<any> = [];
      members.forEach((member: any, index: number) => {
        member.email = member.userEmail;
        member.isQr = true;
        if (!member.userId || !member.status) {
          if (member.status === 'active' || team.amount === 0) {
            member.message = team.amount === 0 ? 'Pending Invitation' : 'Pending Signup';
          } else {
            if (member.canReceiveAllowance === 0) {
              member.message = 'Active';
            } else {
              member.message = member.userId ? 'Awaiting Payment' : 'Awaiting Payment & Signup';
            }
            newMembers.push(member.email);
          }
        }
        if (member.userId) {
          member.message = member.status === 'active'  || team.amount === 0 || member.canReceiveAllowance === 0 ? 'Active' : 'Awaiting Payment';
          let me = false;
          if (member.userId === this.sessionService.getUserId()) {
            if (!member.lastName) {
              member.lastName = '';
            }
            if (member.userId === team.ownerUserId) {
              member.lastName += ' (Admin)';
            }
            member.lastName += ' (Me)';
            me = true;
          }
          if (me) {
            const thisMember = members[index];
            members.splice(index, 1);
            members.unshift(thisMember);
          }
        } else {
          member.avatar = 'no_avatar.png';
        }
      });
      return newMembers;
  }

  makeWallitTransfer(teamId: string, amount: number, members: any, message: string, sendMode: SendMode): Promise<any> {
    return this.restangular.one('teams', teamId).one('makewallittransfer').customPOST({amount, members, message, sendMode}).toPromise();
  }

  /*
  reimburseReceipt(teamId: string, amount: number, fee: number, member: Dictionary<any>, info: any, message: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('reimbursereceipt').customPOST({amount, fee, member, info, message}).toPromise();
  }
   */

  getPaymentMethod(team: any, paymentTarget?: PaymentTargets): Promise<string> {
    return this.getPaymentMethods(team.id).then(methods => {
      // see if there are any invoice methods available
      const invoiceMethod = methods.find((method: any) => method.bankInfo?.bankName === 'invoice' && method.paymentTargets.indexOf(paymentTarget) >= 0);
      return invoiceMethod || team.defaultPaymentMethod;
    });
  }

  getCustomerId(team: any): Promise<string> {
    return this.getPaymentMethods(team.id).then(methods => {
      return methods.find((method: { stripePaymentMethod: any; }) => team.defaultPaymentMethod === method.stripePaymentMethod)?.stripeCustomerId;
    });
  }

  getPaymentMethods(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('paymentmethods').getList().toPromise();
  }

  getAvailablePaymentMethods(teamId: string, userId: string): Promise<any> {
    return this.restangular
      .one('teams', teamId)
      .one('users', userId)
      .one('availablePaymentMethodsToAttach')
      .getList().toPromise();
  }

  addInvoiceMethod(teamId: string, paymentTarget: PaymentTargets): Promise<any> {
    return this.restangular.one('teams', teamId).one('paymentmethods').customPOST({paymentTarget}).toPromise();
  }

  async removeAvailablePaymentFromTeam(teamId: string, userId: string, paymentMethodId: string): Promise<any> {
    return this.restangular
      .one('teams', teamId)
      .one('users', userId)
      .one('availablePaymentMethodsToAttach', paymentMethodId)
      .customDELETE().toPromise();
  }

  async attachPaymentToTeam(teamId: string, userId: string, paymentMethodId: string): Promise<any> {
    return this.restangular
      .one('teams', teamId)
      .one('users', userId)
      .one('paymentMethods', paymentMethodId)
      .one('userAcceptedPaymentMethod', 'true')
      .customPUT().toPromise();
  }

  getUserSubscriptionsDate(teamId: string, customerId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('users').one(customerId).one('stripe').one('subscriptions').one('dates').get().toPromise();
  }
}
