
<div class="analytics-peer-to-peer-container">

    <div class="analytics-peer-to-peer-title">
        <h1>Peer to Peer</h1>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'peer-to-peer-transactions'})"><mat-icon>file_download</mat-icon>Export to CSV</button>
    </div>

    <table class="table" mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="transactionList">
        <ng-container matColumnDef="transactionDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Transaction Date</th>
            <td class="cell" mat-cell *matCellDef="let item">{{(item.transactionDate | date: 'mediumDate') || '---'}}</td>
        </ng-container>
        <ng-container matColumnDef="recipient">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Recipient</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.recipient || '---'}}</td>
        </ng-container>
        <ng-container matColumnDef="sender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Sender</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.sender || '---'}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Description/Message</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.description || '---'}}</td>
        </ng-container>
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Amount</th>
            <td class="cell" mat-cell *matCellDef="let item">{{(item.amount | currency) || '---'}}</td>
        </ng-container>
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Category</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.category && item.category !== "[]" ? item.category : '---'}}</td>
        </ng-container>
        <ng-container matColumnDef="taxable">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Taxable</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.taxable ? 'Yes' : 'No'}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Status</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.status || '---'}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
    </table>
    <span *ngIf="transactionList.data.length === 0">No peer to peer transactions</span>
</div>
