
<div class="rewards-report-container">

    <div class="rewards-report-title">
        <h1>Rewards Report</h1>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'rewards-report'})">
            <mat-icon>file_download</mat-icon>Export to CSV
        </button>
    </div>

    <div class="rewards-report-table-container">
        <div class="rewards-report-chart">
            <canvas id='chart'>{{chart}}</canvas>
        </div>

        <mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="rewardsList" class="custom-table mat-elevation-z4">

            <ng-container matColumnDef="columnName">
                <mat-cell *matCellDef="let element"> <strong>{{element.columnName ?? '---'}}</strong> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="columnValue">
                <mat-cell *matCellDef="let element"> {{(element.columnValue | currency) ?? 0}} </mat-cell>
            </ng-container>


            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>

        </mat-table>

        <p class="no-payment-text" *ngIf="rewardsList.data.length <= 0">
            No Rewards Report found!
        </p>

    </div>

</div>
