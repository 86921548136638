import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class TeamRewardSchedulesService {

  constructor(
      private restangular: Restangular,
  ) { }

  getRewardSchedules(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('rewardschedules').getList().toPromise();
  }

  deleteRewardSchedule(teamId: string, scheduleId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('rewardschedules', scheduleId).remove().toPromise();
  }

  deleteAllRewardSchedules(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('rewardschedules').remove().toPromise();
  }

  modifyRewardSchedule(teamId: string, scheduleId: string, fields: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('rewardschedules', scheduleId).customPUT({
      ...fields
    }).toPromise();
  }

  uploadRewardList(teamId: string, data: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('uploadrewards').customPUT(data, undefined, undefined, { 'content-type': 'text/html' }).toPromise();
  }

}
