import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {StripeService} from '../../services/stripe/stripe.service';
import {Location} from '@angular/common';
import {PaymentTargets, TeamsService} from '../../services/teams/teams.service';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../services/users/users.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppSettings} from '../../app.settings';

@Component({
  selector: 'app-stripecreditcard',
  templateUrl: './stripecreditcard.page.html',
  styleUrls: ['./stripecreditcard.page.scss'],
})
export class StripecreditcardPageComponent implements OnInit, AfterViewInit {

  @ViewChild('cardelement') cardElement: any;
  @ViewChild('carderrors') cardErrors: any;
  @ViewChild('cardsuccess') cardSuccess: any;

  public formValid = false;
  public team: any;
  public validating: any;
  private card: any;
  private elements: any;

  constructor(
      private stripeService: StripeService,
      private location: Location,
      private teamsService: TeamsService,
      private route: ActivatedRoute,
      private usersService: UsersService,
      private snackBar: MatSnackBar,
      private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
        this.team = wellspace || JSON.parse(localStorage.getItem('selected-wellspace') as string)
    });
  }

  ngAfterViewInit(): void {
    this.setupStripeCardElement();
  }

  private setupStripeCardElement(): void {
    this.elements = this.stripeService.getElements();
    this.card = this.elements.create('payment');
    this.card.mount('#card-element');
    this.card.on('change', (info: any) => {
      if (!info.complete) {
        this.formValid = false;
        this.setMessageToAlert(this.cardSuccess, '');
        this.setMessageToAlert(this.cardErrors, info.error ? info.error.message : '');
        if (!info.empty) {
          this.setMessageToAlert(this.cardErrors, 'You need to fill in all the information before adding your payment method.');
        }
      } else {
        this.formValid = true;
        this.setMessageToAlert(this.cardErrors, '');
        this.setMessageToAlert(this.cardSuccess, 'Do not forget to confirm the addition in the button below');
      }
    });
  }

  setMessageToAlert(element: ElementRef, message: string): any  {
    if (element) {
      element.nativeElement.style.display = message ? 'block' : 'none';
      element.nativeElement.textContent = message;
    }
  }

  testTeamUpdate(message: string, doingRemove = false): void {
    setTimeout(() => {
      this.teamsService.getPaymentMethods(this.team.id).then(methods => {
        if ((methods[0].stripeLast4 && methods[0].stripePaymentMethod && !doingRemove) || (!methods[0].stripePaymentMethod && doingRemove)) {
          this.teamsService.setWellspace(this.team);
          this.formValid = true;
          this.validating = false;
          this.snackBar.openFromComponent(SnackBarComponent, {data: message});
        } else {
          this.testTeamUpdate(message, doingRemove);
        }
      });
    }, 1000);
  }

  validateCard(): void {
    this.validating = true;
    this.spinnerService.show('Adding payment method...');
    this.elements.submit().then((submitResult: any) => {
      this.stripeService.getSetupIntent(this.team.id, this.usersService.getCurrentEmail(), Object.values(PaymentTargets)).then(intent => {
        this.stripeService.confirmSetup({
          elements: this.elements,
          clientSecret: intent.client_secret,
          confirmParams: {
            return_url: `${window.location.origin}/billingreturn/${this.team.id}${AppSettings.apiParam ? '?api=' + AppSettings.apiParam : ''}`
          }
        }).then(result => {
          this.spinnerService.hide();
          this.validating = false;
          if (result.error) {
            this.formValid = false;
            this.cardErrors.nativeElement.textContent = result.error.message;
            this.cardErrors.nativeElement.style.display = 'block';
          } else {
            this.card.clear();
            this.testTeamUpdate('Payment method successfully added to account');
          }
        }).catch(error => {
          console.error('CONFIRM ERROR', error);
          this.spinnerService.hide();
        });
      }).catch(error => {
        console.error('GETSETUPINTENTERROR', error);
        this.spinnerService.hide();
      });
    }).catch((error: any) => {
      console.error('Elements submit', error);
      this.spinnerService.hide();
    });
  }

}
