<form [formGroup]="formGroup">
    <mat-form-field appearance="fill" floatLabel="always" [ngStyle]="{'margin-left': field.level * LEVEL_OFFSET + 'px',  width: 'calc(100% - ' + field.level * LEVEL_OFFSET + 'px)'}">
        <mat-label *ngIf="inputType !== 'boolean'">{{field.label | desnake}} <span class="required">{{field.required ? '*' : ''}}</span></mat-label>
        <input *ngIf="!field.values && inputType !== 'array' && inputType !== 'html' && inputType !== 'boolean' && !field.foreignKeyTable" [type]="inputType" matInput placeholder="" [formControlName]="field.name">
        <textarea *ngIf="inputType === 'html'" matInput [formControlName]="field.name"></textarea>
        <div *ngIf="inputType === 'html'" class="htmlview" [innerHtml]="fieldValue"></div>
        <app-tags *ngIf="inputType === 'array'" [tags]="initialFieldValue" (changeEvent)="changed($event)"></app-tags>
        <mat-select *ngIf="!field.values && inputType !== 'boolean' && field.foreignKeyTable" [formControlName]="field.name" [placeholder]="foreignKeyValues.length > 0 ? '' : 'You must first add one or more ' + field.foreignKeyTable">
            <mat-option>
                <ngx-mat-select-search [formControlName]="searchFieldName" noEntriesFoundLabel="No matches" placeholderLabel="Find..."></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of currentValues" [value]="item.value">{{item.name}}</mat-option>
        </mat-select>
        <mat-select *ngIf="field.values && inputType !== 'boolean'" [formControlName]="field.name">
            <mat-option *ngFor="let value of field.values" [value]="value">{{value}}</mat-option>
        </mat-select>
        <div class="toggle" *ngIf="inputType === 'boolean'">
            <span class="togglelabel">{{field.label | desnake}}</span>
            <mat-slide-toggle [checked]="formGroup.get(field.name)?.value" (change)="formGroup.get(field.name)?.setValue($event.checked)" labelPosition="before"></mat-slide-toggle>
        </div>
        <input *ngIf="inputType === 'color'" class="colorpicker" matInput [name]="field.name" autocomplete="off" (colorPickerChange)="changed($event)" [colorPicker]="formGroup.get(field.name)?.value" [style.background]="formGroup.get(field.name)?.value" [cpOKButton]="true" [cpCancelButton]="true"/>
        <textarea *ngIf="inputType === 'boolean' || inputType === 'array'" matInput hidden></textarea>
    </mat-form-field>
</form>
