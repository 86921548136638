import {Component, OnInit, Inject, ElementRef, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-date-dialog',
  templateUrl: './editdatedialog.component.html',
  styleUrls: ['./editdatedialog.component.scss']
})
export class EditDateDialogComponent {

  title: string;
  label: string;
  value: string;

  public form: UntypedFormGroup;

  constructor(
      private formBuilder: UntypedFormBuilder,
      public dialogRef: MatDialogRef<EditDateDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: EditDateDialogModel
  ) {
    this.title = data.title;
    this.label = data.label;
    this.value = data.value;
    this.form = this.formBuilder.group({
          input: new UntypedFormControl(this.value, [Validators.required]),
        }
    );
  }

  onConfirm(): void {
    this.dialogRef.close(this.form.get('input')?.value);
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }
}

export class EditDateDialogModel {
  constructor(public title: string, public label: string, public value: string) {
  }

}
