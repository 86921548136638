import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MetaService} from '../../../services/meta/meta.service';
import * as _ from 'lodash';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AddEditDialogComponent, AddEditDialogModel} from '../addedit/addedit.component';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {Dictionary} from 'highcharts';
import {UtilsService} from '../../../services/utils/utils.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatTableExporterDirective} from 'mat-table-exporter';
import * as moment from 'moment';
import useCsvPlugin from '@usecsv/js';
import {SessionService} from '../../../services/session/session.service';
import {ImageService} from '../../../services/meta/meta.image.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
// import {TooltipOptions} from 'ng2-tooltip-directive';
import {unflatten} from 'flat';

export interface ColumnConfig {
  isImage?: boolean;
  imageUrl?: SafeUrl;
  name: string;
  name2?: string;
  value?: string;
  title: string;
  dateFormat?: string;
  imageType?: string;
}

export interface MenuItem {
  icon: string;
  label: string;
  click: (item: any) => boolean;
}

export interface TableConfig {
  title: string;
  dbTableName: string;
  readOnly?: boolean;
  filter?: Dictionary<any>;
  expand?: Array<string>;
  columns: Array<ColumnConfig>;
  menuItems: Array<MenuItem>;
  excludedFields?: Array<string>;
  preWrite?: (data: Dictionary<any>) => boolean;
  hiddenExportColumnNumbers?: Array<number>;
  importKey?: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() config: TableConfig = { title: '', dbTableName: '', columns: [], menuItems: []};
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;
  // @ts-ignore
  @ViewChild('exporter') exporter: MatTableExporterDirective;

  public items = new MatTableDataSource<any>();
  public columnsToDisplay: Array<string> = [];
  public _ = _;
  private isMedia = false;
//  public imageTooltipOptions: TooltipOptions = {
//    placement: 'right',
//    tooltipClass: 'imageTipClass'
//  };

  public itemCount = 0;

  constructor(
      private metaService: MetaService,
      private dialog: MatDialog,
      private snackbar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private utilsService: UtilsService,
      private sessionService: SessionService,
      private imageService: ImageService,
      private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.isMedia = this.config.dbTableName === 'media';
    this.columnsToDisplay = this.config.columns.map(column => column.name);
    this.columnsToDisplay.push('actionmenu');
    this.loadItems();
  }

  private loadItems(): void {
    this.metaService.read(this.config.dbTableName, this.config.filter, this.config.expand).then(data => {
      this.items.sortingDataAccessor = (item, property) => _.get(item, property);
      this.items.data = data.items;
      this.itemCount = data.cursors.total_count;
      this.items.sort = this.sort;
      this.items.data.forEach(item => {
        this.config.columns.forEach(column => {
          const mediaId = _.get(item, column.name);
          if (column.isImage && mediaId) {
            this.imageService.get(mediaId).then(file => {
              item.imageUrl = this.sanitizer.bypassSecurityTrustUrl(file.url);
            });
          }
        });
      });
    });
  }

  add(): void {
    const dialogRef = this.dialog.open(AddEditDialogComponent, {
      data: new AddEditDialogModel(this.config, this, this.addHelper)
    });
  }

  addHelper(dialogResult: Dictionary<any>): Promise<boolean> {
    if (this.config.preWrite) {
      if (!this.config.preWrite(dialogResult)) {
        return Promise.resolve(false);
      }
    }
    const file = dialogResult.file;
    delete dialogResult.file;
    return (this.isMedia ? this.imageService.create(dialogResult) : this.metaService.create(this.config.dbTableName, dialogResult)).then(result => {
      const promises: Array<Promise<any>> = [];
      if (!this.isMedia && file) {
        this.config.columns.forEach(column => {
          if (column.isImage) {
            promises.push(this.imageService.create({
              name: `logo-${dialogResult.name}`,
              type: column.imageType,
              mimetype: file.type,
              file
            }).then(mediaInfo => {
              return this.metaService.patch(this.config.dbTableName, result.id, unflatten({[column.name]: mediaInfo.id}));
            }));
          }
        });
      }
      return Promise.all(promises).then(() => {
        this.snackbar.openFromComponent(SnackBarComponent, {data: `New ${this.config.title.slice(0, -1)} added`});
        this.loadItems();
        return true;
      });
    }).catch(error => {
      this.utilsService.showError(error);
      return false;
    });
  }

  duplicate(item: any): void {
    const dialogRef = this.dialog.open(AddEditDialogComponent, {
      data: new AddEditDialogModel(this.config, this, this.duplicateHelper, Object.assign(item, {name: `Copy of ${item.name}`}))
    });
  }

  duplicateHelper(dialogResult: Dictionary<any>): Promise<boolean> {
    if (this.config.preWrite) {
      if (!this.config.preWrite(dialogResult)) {
        return Promise.resolve(false);
      }
    }
    return (this.isMedia ? this.imageService.create(dialogResult) : this.metaService.create(this.config.dbTableName, dialogResult)).then(() => {
      this.snackbar.openFromComponent(SnackBarComponent, {data: `New copy of ${this.config.title.slice(0, -1)} added`});
      this.loadItems();
      return true;
    }).catch(error => {
      this.utilsService.showError(error);
      return false;
    });
  }

  edit(item: any): void {
    const dialogRef = this.dialog.open(AddEditDialogComponent, {
      data: new AddEditDialogModel(this.config, this, this.editHelper, item)
    });
  }

  editHelper(dialogResult: Dictionary<any>, item: any): Promise<boolean> {
    if (this.config.preWrite) {
      if (!this.config.preWrite(dialogResult)) {
        return Promise.resolve(false);
      }
    }
    const file = dialogResult.file;
    delete dialogResult.file;
    return (this.isMedia ? this.imageService.patch(item.metadata.id, dialogResult) : this.metaService.update(this.config.dbTableName, item.metadata.id, dialogResult)).then(result => {
      const promises: Array<Promise<any>> = [];
      if (!this.isMedia) {
        this.config.columns.forEach(column => {
          if (column.isImage) {
            const mediaId = _.get(dialogResult, column.name);
            if (file) {
              if (mediaId) {
                promises.push(this.imageService.patch( mediaId, {
                  name: `logo-${dialogResult.name}`,
                  type: column.imageType,
                  mimetype: file.type,
                  file
                }));
              } else {
                promises.push(this.imageService.create({
                  name: `logo-${dialogResult.name}`,
                  type: column.imageType,
                  mimetype: file.type,
                  file
                }).then(mediaInfo => {
                  return this.metaService.patch(this.config.dbTableName, item.metadata.id, unflatten({[column.name]: mediaInfo.id}));
                }));
              }
            } else {
              if (!mediaId && _.get(item, column.name)) {
                promises.push(this.imageService.delete(_.get(item, column.name)));
                promises.push(this.metaService.patch(this.config.dbTableName, item.metadata.id, unflatten({[column.name]: null})));
              }
            }
          }
        });
      }
      return Promise.all(promises).then(() => {
        this.snackbar.openFromComponent(SnackBarComponent, {data: `${this.config.title.slice(0, -1)} updated`});
        this.loadItems();
        return true;
      });
    }).catch(error => {
      this.utilsService.showError(error);
      return false;
    });
  }

  delete(item: any): void {
    this.uiAlertService.presentAlertConfirm(`Do you really want to delete ${item.name}?`).then(async (confirm: any) => {
      if (confirm) {
        (this.isMedia ? this.imageService.delete(item.metadata.id) : this.metaService.delete(this.config.dbTableName, item.metadata.id)).then (() => {
          this.snackbar.openFromComponent(SnackBarComponent, {data: `Item ${item.name} deleted`});
          this.loadItems();
        }).catch(error => {
          this.utilsService.showError(error);
        });
      }
    });
  }

  export(): void {
    this.exporter.exportTable('csv', {fileName: `${this.config.dbTableName}-${moment().toString()}`});
  }

  import(): void {
    if (this.config.importKey) {
      useCsvPlugin({
        importerKey: this.config.importKey,
        user: {userId: this.sessionService.getUserId()},
        onData: (data) => console.log({data}),
        onClose: () => console.log('Importer is closed')
      });
    } else {
      this.snackbar.openFromComponent(SnackBarComponent, {data: `No data import available for ${this.config.title}`});
    }
  }

}
