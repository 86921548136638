<h1 mat-dialog-title>
    {{ title }}
</h1>
<div mat-dialog-content>
    <span class="teammembername">{{ teamMember.email }}</span>
    <span class="info">The amount you specify will be {{ teamMember.status !== 'active' ? 'immediately' : '' }} sent to this team member{{ teamMember.status === 'active' ? '' : ' as soon as they sign up' }}
        .<br>You will be able to approve the payment before it is sent.</span>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
        <mat-form-field *ngIf="!isP2p" formControlName="destination">
            <mat-label>Payment Destination</mat-label>
            <mat-select formControlName="destination">
                <mat-option [value]="false">Allowance Balance</mat-option>
                <mat-option [value]="true">Wallit Balance</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Amount to send ($)</mat-label>
            <input type="number" matInput placeholder="" formControlName="amount">
        </mat-form-field>
        <div class="textfield">
            <span class="textfieldtitle">Message</span>
            <textarea formControlName="message"></textarea>
        </div>
        <div class="textfield">
            <span class="textfieldtitle">Note (not sent)</span>
            <textarea formControlName="note"></textarea>
        </div>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!form.valid || sending" class="donebutton" color="primary"
            (click)="sendClicked()">Send
    </button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
