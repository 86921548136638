<div class="nav-container">
    <h1>Enrollment</h1>
    <div class="progress-bar-wrapper">
        <div class="progress-bar"></div>
    </div>
    <nav class="nav-menu">
        <div class="nav-item active" data-section="about-you" data-step="1" (click)="updateView('about-you', 1)">
            <span class="step-label">About You</span>
            <div class="nav-dropdown">
                <div class="dropdown-item" data-target="personal-info">
                    <div class="dropdown-title">Profile Information</div>
                    <div class="dropdown-description">Enter your basic information</div>
                </div>
                <div class="dropdown-item" data-target="employment-info">
                    <div class="dropdown-title">Employment Information</div>
                    <div class="dropdown-description">Provide your employment details</div>
                </div>
                <div class="dropdown-item" data-target="family-info">
                    <div class="dropdown-title">Family</div>
                    <div class="dropdown-description">Add dependents</div>
                </div>
            </div>
        </div>
        <div class="nav-item" data-section="your-coverage" data-step="2" (click)="updateView('your-coverage', 2)">
            <span class="step-label">Your Coverage</span>
            <div class="nav-dropdown">
                <div class="dropdown-item" data-target="coverage-info">
                    <div class="dropdown-title">Insurance Plans</div>
                    <div class="dropdown-description">Choose your coverage</div>
                </div>
            </div>
        </div>
        <div class="nav-item" data-section="complete-signup" data-step="3" (click)="updateView('complete-signup', 3)">
            <span class="step-label">Review</span>
            <div class="nav-dropdown">
                <div class="dropdown-item" data-target="finalize-enrollment">
                    <div class="dropdown-title">Review and Submit</div>
                    <div class="dropdown-description">Review and complete your enrollment</div>
                </div>
            </div>
        </div>
    </nav>
</div>
<div class="content-section active" id="about-you">
    <div class="section-subsection active" id="personal-info">
        <div class="form-container">
            <form id="personalInfoForm">
                <section>
                    <h3>Personal Information</h3>
                    <label for="firstName" class="form-label">First Name</label>
                    <input type="text" id="firstName" value="David">
                    <label for="lastName" class="form-label">Last Name</label>
                    <input type="text" id="lastName" value="Olivieri">
                    <label for="ssn" class="form-label">SSN <span class="required">*</span></label>
                    <input type="text" id="ssn" value="008-22-0000">
                    <label for="birthdate" class="form-label">Birthdate</label>
                    <input type="date" id="birthdate" value="1981-06-17">
                    <label for="maritalStatus" class="form-label">Marital Status <span class="required">*</span></label>
                    <input type="text" id="maritalStatus" value="Married">
                    <label for="biologicalSex" class="form-label">Biological Gender</label>
                    <input type="text" id="biologicalSex" value="Male">
                    <label for="tobaccoUse" class="form-label">Tobacco Use</label>
                    <input type="checkbox" id="tobaccoUse">
                    <span id="tobaccoLabel" class="tobacco-label">Uses Tobacco</span>
                </section>
                <section>
                    <h3>Contact</h3>
                    <label for="email" class="form-label">Email <span class="required">*</span></label>
                    <input type="email" id="email" value="7fdhgsdgfvv56ddva06@yopmail.com">
                    <label for="phone" class="form-label">Phone <span class="required">*</span></label>
                    <input type="tel" id="phone" value="(656) 333-8191">
                </section>
                <section>
                    <h3>Residential Address</h3>
                    <label for="address" class="form-label">Address <span class="required">*</span></label>
                    <input type="text" id="address" value="2 Plunkett Street">
                    <label for="address2" class="form-label">Address 2</label>
                    <input type="text" id="address2">
                    <label for="city" class="form-label">City <span class="required">*</span></label>
                    <input type="text" id="city" value="Lenox">
                    <label for="zipCode" class="form-label">Zip Code</label>
                    <input type="text" id="zipCode" value="01240">
                    <label for="state" class="form-label">State</label>
                    <input type="text" id="state" value="MA">
                    <label for="county" class="form-label">County</label>
                    <select id="county">
                        <option value="06067" selected>Berkshire County</option>
                        <!-- Add more options as needed -->
                    </select>
                </section>
                <section>
                    <label class="terms">
                        <input type="checkbox" id="termsConditions" required>
                        <span>I Accept the <a href="#">Terms and Conditions</a></span>
                    </label>
                </section>
                <button type="button" class="button-next" (click)="nextSubSection('employment-info')">Next</button>
            </form>
        </div>
    </div>
    <div class="section-subsection" id="employment-info">
        <h3>Employment Information</h3>
        <div class="form-container">
            <form>
                <div>
                    <label for="employer" class="form-label">Employer <span class="required">*</span></label>
                    <input type="text" id="employer" name="employer" required>
                </div>
                <div>
                    <label for="job-title" class="form-label">Job Title</label>
                    <input type="text" id="job-title" name="job-title">
                </div>
                <div>
                    <label for="employment-status" class="form-label">Employment Status <span class="required">*</span></label>
                    <select id="employment-status" name="employment-status" required>
                        <option value="">Select Status</option>
                        <option value="full-time">Full-time</option>
                        <option value="part-time">Part-time</option>
                        <option value="self-employed">Self-employed</option>
                    </select>
                </div>
                <button type="button" class="button-next" (click)="nextSubSection('family-info')">Next</button>
            </form>
        </div>
    </div>
    <div class="section-subsection" id="family-info">
        <h3>Family</h3>
        <div class="form-container">
            <div class="button-group">
                <button type="button" (click)="addSpouseForm()">Add Spouse</button>
                <button type="button" (click)="addChildForm()">Add Child</button>
            </div>
            <div id="spouse-forms">
                <!-- Spouse forms will be dynamically added here -->
            </div>
            <div id="child-forms">
                <!-- Child forms will be dynamically added here -->
            </div>
            <button type="button" class="button-next" (click)="nextSection('your-coverage')">Next</button>
        </div>
        <div id="toast" class="toast">Saved successfully!</div>
    </div>
</div>
<div class="content-section" id="your-coverage">
    <div class="section-subsection" id="coverage-info">
        <ng-container *ngFor="let category of policies.categories">
        <!-- Begin Active Enrollment Screen-->
        <div class="info-section">
            <h2 style="font-size: 40px; margin-bottom: 15px;" [innerHtml]="category.title"></h2>
            <div class="info-container" [innerHtml]="category.details"></div>
        </div>
        <div class="form-container">
            <form>
                <!--<div>
                    <h3>Insurance Plans</h3>
                    <label for="coverage-plan" class="form-label">Coverage Plan <span class="required">*</span></label>
                    <select id="coverage-plan" name="coverage-plan">
                        <option value="">Select Plan</option>
                        <option value="accident">Accident</option>
                        <option value="life">Life</option>
                        <option value="pet">Pet</option>
                        <option value="legal">Legal</option>
                    </select>
                </div>-->
                <div class="container">
                    <div class="card" *ngFor="let plan of category.plans; index as i">
                        <div class="header">
                            <input type="checkbox" class="checkbox" [id]="'selectCoverage' + i">
                            <label [for]="'selectCoverage' + i" class="selectCoverage">SELECT</label>
                            <div class="title" [innerHtml]="plan.title"></div>
                            <div class="logo">
                                <img [src]="plan.logo">
                            </div>
                            <button class="button" (click)="openFile(plan.detailsUrl)">Plan Details</button>
                        </div>
                        <div class="details">
                            <div class="detail-box left">
                                <h4 class="section-child-header" [innerHtml]="plan.tiles[0].header"></h4>
                                <p class="amount" [innerHtml]="plan.tiles[0].price"></p>
                                <p class="after-contribution" [innerHtml]="plan.tiles[0].priceFooter"></p>
                                <p class="employer-contribution" [innerHtml]="plan.tiles[0].content">
                            </div>
                            <ng-template #genericcard let-item="item">
                                <h4 [innerHtml]="item.line1"></h4>
                                <p class="default-amount" [innerHtml]="item.line2"></p>
                                <p class="asterisk-amount" [innerHtml]="item.line3"></p>
                            </ng-template>
                            <ng-container *ngIf="plan.tiles.length < 5">
                                <div class="detail-box middle">
                                    <ng-container *ngTemplateOutlet="genericcard; context: {item: plan.tiles[1]} "></ng-container>
                                </div>
                                <div class="stacked">
                                    <div class="detail-box">
                                        <ng-container *ngTemplateOutlet="genericcard; context: {item: plan.tiles[2]} "></ng-container>
                                    </div>
                                    <div class="detail-box">
                                        <ng-container *ngTemplateOutlet="genericcard; context: {item: plan.tiles[3]} "></ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="plan.tiles.length === 5">
                                <div class="stacked">
                                    <div class="detail-box">
                                        <ng-container *ngTemplateOutlet="genericcard; context: {item: plan.tiles[1]} "></ng-container>
                                    </div>
                                    <div class="detail-box">
                                        <ng-container *ngTemplateOutlet="genericcard; context: {item: plan.tiles[2]} "></ng-container>
                                    </div>
                                </div>
                                <div class="stacked">
                                    <div class="detail-box">
                                        <ng-container *ngTemplateOutlet="genericcard; context: {item: plan.tiles[3]} "></ng-container>
                                    </div>
                                    <div class="detail-box">
                                        <ng-container *ngTemplateOutlet="genericcard; context: {item: plan.tiles[4]} "></ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- End Active Enrollment Screen-->
        <button type="button" class="button-next" (click)="nextSection('complete-signup')">Next</button>
        </ng-container>
    </div>
</div>
<div class="content-section" id="complete-signup">
    <div class="section-subsection" id="finalize-enrollment">
        <h3>Review and Submit</h3>
        <div class="form-container">
            <form>
                <div class="terms">
                    <input type="checkbox" id="terms" name="terms" required>
                    <label for="terms">I agree to the <a href="#">terms and conditions</a></label>
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    </div>
</div>

