import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Table} from '../member-manager/member-manager.component';
import {FieldType} from '../member-addedit/member-addedit.component';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-client-census',
  templateUrl: './client-census.component.html',
  styleUrls: ['./client-census.component.scss']
})
export class ClientCensusComponent implements AfterViewInit{

  @Input() clientId = '';
  @ViewChild('search') search: ElementRef | undefined;

  public reload = false;
  public itemCount = 0;

  public table: Table = {
    title: 'Census Member',
    addEditFields: [
      {name: 'Given Name', label: 'First Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Family Name', label: 'Last Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 1', label: 'Address 1', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 2', label: 'Address 2', fieldType: FieldType.F_STRING},
      {name: 'City', label: 'City', fieldType: FieldType.F_STRING, required: true},
      {name: 'State', label: 'State', fieldType: FieldType.F_STRING, required: true},
      {name: 'ZIP', label: 'ZIP Code', fieldType: FieldType.F_STRING, required: true},
      {name: 'Email', label: 'Email Address', fieldType: FieldType.F_EMAIL, required: true},
      {name: 'Phone', label: 'Phone Number', fieldType: FieldType.F_PHONE, required: false},
      {name: 'Date of Birth', label: 'Date of Birth', fieldType: FieldType.F_PASTDATE, required: true},
      {name: 'Gender', label: 'Gender', fieldType: FieldType.F_DROPDOWN, values: ['Male', 'Female'], required: true},
      {name: 'SSN', label: 'Social Security Number', fieldType: FieldType.F_SSN, required: true},
      {name: 'Smoking', label: 'Smoking', fieldType: FieldType.F_DROPDOWN, values: ['Yes', 'No'], required: true}
    ],
    columnsToDisplay: [
      {
        data: (row: { [x: string]: any; }) => row['Given Name'],
        label: 'First Name',
      },
      {
        data: (row: { [x: string]: any; }) => row['Family Name'],
        label: 'Last Name'
      },
      {
        data: (row: { [x: string]: any; }) => row.SSN,
        label: 'SSN'
      },
      {
        data: (row: { [x: string]: any; }) => row.state,
        label: 'State'
      }
    ],
    menuItems: [
      {name: 'Make Eligible', disabled: this.eligibleDisabled.bind(this), click: this.makeEligible.bind(this)}
    ],
    templateUrl: '/assets/files/Wallit-census.xlsx',
    where: {
      limit: 50,
      offset: 0,
      sort: '',
      filter: {}
    }
  };

  constructor(
      private usersInsuranceService: UsersInsuranceService,
      private snackBar: MatSnackBar
  ) {
    this.usersInsuranceService.insuranceStateCountObservable.subscribe(result => {
      this.itemCount = result.census;
    });
  }

  ngAfterViewInit(): void {
    fromEvent(this.search?.nativeElement, 'keyup').pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap((): void => {
          Object.assign(this.table.where.filter, {search: this.search?.nativeElement.value.trim().toLowerCase()});
          this.reload = !this.reload;
        })).subscribe();
  }

  eligibleDisabled(item: any): boolean {
    return item.state !== 'census';
  }

  makeEligible(item: any): Promise<boolean> {
    return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'eligible'}).then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member set to eligible' });
      return true;
    });
  }

  setStateFilter(event: MatSelectChange): void {
    Object.assign(this.table.where.filter, {state: event.value});
    this.reload = !this.reload;
  }

  tableUpdated(count: number): void {
    this.itemCount = count;
  }

  pageEvent(event: any): void {
    this.table.where.limit = event.pageSize;
    this.table.where.offset = event.pageIndex * event.pageSize;
    this.reload = !this.reload;
  }

}
