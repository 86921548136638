import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-insurance-users',
  templateUrl: './insurance-users.component.html',
  styleUrls: ['./insurance-users.component.scss']
})
export class InsuranceUsersComponent implements OnInit {

  public config: TableConfig = {
    title: 'Users',
    dbTableName: 'users',
    expand: ['company_id'],
    columns: [
      {name: 'contact.name', title: 'Name', name2: 'contact.email'},
      {name: 'company_id.name', title: 'Company'},
      {name: 'company_id.company_type', title: 'Type'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
