<h1 mat-dialog-title>{{ client ? 'Edit' : 'New' }} Client</h1>

<div mat-dialog-content >
    <form class="form" [formGroup]="clientForm">
        <mat-form-field>
            <mat-label>Client Name</mat-label>
            <input id="name" type="text" matInput formControlName="name"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Client Email</mat-label>
            <input id="email" type="email" matInput formControlName="email"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>SIC Code</mat-label>
            <input id="sic" type="number" matInput formControlName="sic">
        </mat-form-field>
        <mat-form-field>
            <mat-label>EIN</mat-label>
            <input id="ein" type="number" matInput formControlName="ein">
        </mat-form-field>
    </form>
</div>

<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!clientForm.valid" class="done-button" color="primary" (click)="saveOrEdit()">OK</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
