import {Component, Input, OnInit} from '@angular/core';
import {Field} from '../addedit.component';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MetaService} from '../../../../services/meta/meta.service';
import {Dictionary} from 'highcharts';

@Component({
  selector: 'app-addeditfield',
  templateUrl: './addeditfield.component.html',
  styleUrls: ['./addeditfield.component.scss']
})
export class AddEditFieldComponent implements OnInit {

  public LEVEL_OFFSET = 40;
  public foreignKeyValues: Array<Dictionary<any>> = [];
  public currentValues: Array<Dictionary<any>> = [];
  public searchFieldName = '';
  public fieldValue = '';
  public initialFieldValue = [];

  @Input() field!: Field;
  @Input() inputType!: string;
  @Input() formGroup!: UntypedFormGroup;

  constructor(
      private metaService: MetaService
  ) {
  }

  ngOnInit(): void {
    this.searchFieldName = `_${this.field.name}`;
    this.formGroup.addControl(this.searchFieldName, new UntypedFormControl());
    this.fieldValue = this.formGroup.get(this.field.name)?.value;
    this.initialFieldValue = this.formGroup.get(this.field.name)?.value;
    this.formGroup.get(this.field.name)?.valueChanges.subscribe(value => {
      this.fieldValue = value;
    });
    if (this.field.foreignKeyTable) {
      this.metaService.read(this.field.foreignKeyTable, {}).then(result => {
        this.foreignKeyValues = this.currentValues = result.items.map((item: any) => ({name: item.name, value: item.metadata.id}));
      });
      this.formGroup.get(this.searchFieldName)?.valueChanges.subscribe(value => {
        if (value) {
          this.currentValues = this.foreignKeyValues.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        } else {
          this.currentValues = this.foreignKeyValues;
        }
      });
    }
  }

  changed(event: string): void {
    console.log('CHANGED', event);
    this.formGroup.get(this.field.name)?.setValue(event);
  }

}
