<ng-container *ngIf="enabled">
    <div class="actions">
        <button [disabled]="!companyId" mat-raised-button color="primary" (click)="addMember()">
            <mat-icon>add</mat-icon>
            Add new member
        </button>
    </div>
    <ng-container *ngIf="members.data.length === 0">
        <app-inlinemessage class="info" level="warning" message="There are no members assigned to this agency"></app-inlinemessage>
    </ng-container>
    <table mat-table matSort class="table" *ngIf="members.data.length > 0" [dataSource]="members">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Member Name</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let item">{{item.email}}</td>
        </ng-container>
        <ng-container matColumnDef="actionmenu" stickyEnd>
            <th mat-header-cell matTooltip="Agency Actions" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item"><button mat-button class="cell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button></td>
        </ng-container>
        <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-item="item">
                <button mat-menu-item (click)="removeMember(item)">Remove Member</button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="setClients(item)">Set Clients for Member</button>
            </ng-template>
        </mat-menu>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
    </table>
</ng-container>
