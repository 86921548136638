import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Approval, TeamApprovalsService} from '../../../services/teamapprovals/teamapprovals.service';
import {TeamsService} from '../../../services/teams/teams.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../../snackbar/snackbar.component';

@Component({
  selector: 'app-analytics-configure',
  templateUrl: './analytics-configure.component.html',
  styleUrls: ['./analytics-configure.component.scss']
})
export class AnalyticsConfigureComponent implements OnInit {

  @ViewChild('table') table: MatTable<Approval> | undefined;

  public columnsToDisplay = [ 'position', 'name', 'email', 'amount', 'delete' ];
  public items = new MatTableDataSource<Approval>();
  private team: any;

  constructor(
      private teamApprovalsService: TeamApprovalsService,
      private teamsService: TeamsService,
      private uiAlertService: UiAlertService,
      private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      if (wellspace) {
        this.team = wellspace;
        this.loadItems();
      }
    });
  }

  private loadItems(): void {
    this.teamApprovalsService.getApprovals(this.team.id).then(result => {
      this.items.data = result;
    });
  }

  drop(event: any): void {
    const previousIndex = this.items.data.findIndex(row => row === event.item.data);
    moveItemInArray(this.items.data, previousIndex, event.currentIndex);
    this.items.data = this.items.data.slice();
    let sequence = 1;
    this.items.data.forEach(item => {
      item.sequence = sequence;
      this.teamApprovalsService.modifyApproval(this.team.id, item.id, { sequence});
      sequence++;
    });
    this.table?.renderRows();
  }

  deleteItem(item: any): void {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this approver?').then(ok => {
      if (ok) {
        this.teamApprovalsService.deleteApproval(this.team.id, item.id).then(_ => {
          this.snackbar.openFromComponent(SnackBarComponent, {data: `Approver ${item.email} removed`});
          this.loadItems();
        });
      }
    });
  }

  addApprover(): void {
    this.uiAlertService.editTeamApprover({}).then(result => {
      if (result) {
        this.teamApprovalsService.createApproval(this.team.id, '', result.amount, result.email, result.name, this.items.data.length + 1).then(_ => {
          this.snackbar.openFromComponent(SnackBarComponent, {data: `New approver ${result.email} added`});
          this.loadItems();
        });
      }
    });
  }

  editItem(item: any): void {
    this.uiAlertService.editTeamApprover(item).then(result => {
      if (result) {
        this.teamApprovalsService.modifyApproval(this.team.id, item.id, { amount: result.amount, email: result.email, name: result.name}).then(_ => {
          this.snackbar.openFromComponent(SnackBarComponent, {data: `Approver ${result.email} updated`});
          this.loadItems();
        });
      }
    });
  }
}
