<h1>Analytics</h1>
<nav mat-tab-nav-bar #tabs>
<a mat-tab-link *ngFor="let link of links" [routerLink]="link.link"
     (click)="activeLink = link"
     [active]="activeLink == link">{{link.title}}</a>
</nav>
<app-analytics-overview *ngIf="activeLink.title === 'All Teams Overview'" [analytics]="analytics"></app-analytics-overview>
<app-analytics-team-overview *ngIf="activeLink.title === 'Current Team Overview'" [analytics]="analytics"></app-analytics-team-overview>
<app-analytics-members *ngIf="activeLink.title === 'Members'" [analytics]="analytics"></app-analytics-members>
<app-analytics-categories *ngIf="activeLink.title === 'Categories'" [analytics]="analytics"></app-analytics-categories>
<app-analytics-reimbursements *ngIf="activeLink.title === 'Reimbursements'" [analytics]="analytics"></app-analytics-reimbursements>
<app-analytics-allowances *ngIf="activeLink.title === 'All Teams Allowances'" [analytics]="analytics"></app-analytics-allowances>
<app-analytics-team-allowances *ngIf="activeLink.title === 'Current Team Allowances'" [analytics]="analytics"></app-analytics-team-allowances>
<app-analytics-peer-to-peer *ngIf="activeLink.title === 'Peer To Peer'"></app-analytics-peer-to-peer>
