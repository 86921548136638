<div *ngIf="isCustomerSupport || isBrokerAdmin" class="wholepage">
    <div class="itemgroup">
        <div class="item" (click)="helpClicked()">
            <mat-icon>help_center</mat-icon>
            <div class="itemright">
                <span class="header">Help Center</span>
                <span class="description">Consult the online help, chat with a support specialist, or report a problem.</span>
            </div>
        </div>
    </div>
    <!--
    <div class="itemgroup">
        <div class="item">
            <mat-icon>movie</mat-icon>
            <div class="itemright">
                <span class="header">Getting Started</span>
                <span *ngIf="!videoUrl" class="description" id="video-player"></span>
                <video *ngIf="videoUrl" class="video" [poster]="posterUrl" preload="auto" controls >
                    <source [src]="videoUrl">
                </video>
            </div>
        </div>
    </div>-->
</div>
<div *ngIf="!isCustomerSupport && !isBrokerAdmin" class="wholepage">
    <div *ngIf="showPendingAchMsg" class="warningbox">
        <span class="description">
            <strong>Pending ACH: </strong> A transfer is in progress at the moment. To ensure the integrity of the operation,
            it is not possible to make further changes at this time. Please wait until the transfer is completed to proceed with your actions.
        </span>
    </div>
    <div class="itemgroup">
        <div class="item" (click)="settingsClicked()">
            <mat-icon>person</mat-icon>
            <div class="itemright">
                <span class="header">Account Profile</span>
                <span class="description">Edit your profile, update your username and password, and manage account settings.</span>
            </div>
        </div>
    </div>
    <div *ngIf="currentWellspace" class="itemgroup">
        <!--
        <div class="item"  (click)="manageAccountsClicked()">
            <mat-icon>manage_accounts</mat-icon>
            <div class="itemright">
                <span class="header">Settings & Permissions</span>
                <span class="description">Manage your wellspace settings, permissions and preferences.</span>
            </div>
        </div>
        -->
        <div class="item" (click)="manageWellspaceClicked()">
            <mat-icon>groups</mat-icon>
            <div class="itemright">
                <span class="header">Manage Your Wellspace</span>
                <span class="description">Invite new members, manage your benefit categories and your allowance amount.</span>
            </div>
        </div>
        <div class="item" (click)="analyticsClicked()">
            <mat-icon>insights</mat-icon>
            <div class="itemright">
                <span class="header">Analytics</span>
                <span class="description">View stats for your wellspace, including member activity and reimbursements.</span>
            </div>
        </div>
        <div *ngIf="!currentWellspace.role" class="item" (click)="billingClicked()">
            <mat-icon>credit_card</mat-icon>
            <div class="itemright">
                <span class="header">Billing</span>
                <div>
                    <span class="description">Your wellspace is on the</span>&nbsp;<span style="font-weight: bold" class="description">{{currentWellspace.plan}}</span>&nbsp;<span class="description">plan.</span>
                    <span  *ngIf="!currentWellspace.approved" class="description">Your wellspace is awaiting approval by Wallit.</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!currentWellspace" class="itemgroup">
        <div class="item" *ngFor="let team of teams" (click)="selectTeam(team)">
            <mat-icon>grid_view</mat-icon>
            <div class="itemright">
                <span class="header">{{team.name}}</span>
                <span class="description">Manage this team</span>
            </div>
        </div>
        <div class="item" (click)="addTeam()">
            <mat-icon>add</mat-icon>
            <div class="itemright">
                <span class="header">Add a Wellspace</span>
                <span class="description">Add a new wellspace and invite members.</span>
            </div>
        </div>
    </div>
</div>
