import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {ApiService} from '../../services/api/api.service';
import {SessionService} from '../../services/session/session.service';
import {TeamCompanyUsersService} from '../../services/teamcompanyusers/teamcompanyusers.service';

@Injectable({
    providedIn: 'root'
})
export class ManagerAdminGuard implements CanActivate {

    constructor(
        public apiService: ApiService,
        private sessionService: SessionService,
        private teamCompaniesUserService: TeamCompanyUsersService,
        public router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.apiService.isAuthenticated()) {
            this.sessionService.loginUrl = state.url;
            this.router.navigate(['login']);
            return false;
        }
        return this.teamCompaniesUserService.getManagers().then(result => {
            return this.sessionService.isSuperAdmin() || result.length > 0;
        });
    }

}
