import {Component, Inject} from '@angular/core';
import {ClientsInterface} from '../../clients/clients.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClientService} from '../../../services/client/client.service';

@Component({
  selector: 'app-new-clients-dialog',
  templateUrl: './new-client-dialog.component.html',
  styleUrls: ['./new-client-dialog.component.scss']
})
export class NewClientDialogComponent {
  public client: ClientsInterface | null;
  public clientForm: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<NewClientDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ClientDialogModel,
      private fb: FormBuilder,
      private snackBar: MatSnackBar,
      private clientService: ClientService
  ) {
    this.client = data.client;
    this.clientForm = this.fb.group({
      id: [this.client?.id ?? ''],
      name: [this.client?.name ?? '', [Validators.required]],
      email: [this.client?.email ?? '', [Validators.required]],
      sic: [this.client?.sic ?? '', [Validators.required]],
      ein: [this.client?.ein ?? '', [Validators.required]],
      createdAt: [this.client?.createdAt ?? ''],
      updatedAt: [this.client?.updatedAt ?? ''],
      stripeCustomerId: [this.client?.stripeCustomerId ?? ''],
    });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  saveOrEdit(): void {
    const client = this.clientForm.value;
    if (this.clientForm.invalid) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Invalid form'});
    } else {
      if (this.client) {
        this.handleClientServiceResponse(this.clientService.updateClient(client), 'Error in edit clients');
      } else {
        this.handleClientServiceResponse(this.clientService.createNewClient(client), 'Error in create clients');
      }
    }
  }

  handleClientServiceResponse(promise: Promise<void>, errorMessage: string): void {
    promise.then((): void => {
      this.dialogRef.close('ok');
    }).catch(err => {
      this.snackBar.openFromComponent(SnackBarComponent, { data: errorMessage });
      console.error(errorMessage, err);
    });
  }

}

export class ClientDialogModel {
  constructor(public client: ClientsInterface | null) {
  }
}
