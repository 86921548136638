import {Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmDialogComponent implements AfterViewInit {

  @ViewChild('message') messageParagraph: ElementRef | undefined;

  title: string;
  public message: string;
  okOnly: boolean;

  constructor(
      public dialogRef: MatDialogRef<ConfirmDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.okOnly = data.okOnly;
  }

  update(): void {
    this.ngAfterViewInit();
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this.messageParagraph.nativeElement.innerHTML = this.message;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(public title: string, public message: string, public okOnly = false) {
  }

}
