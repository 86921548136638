<ng-container *ngIf="enabled">
    <div class="actions">
        <button [disabled]="!companyId" mat-raised-button color="primary" (click)="addAgency()">
            <mat-icon>add</mat-icon>
            Add new agency
        </button>
    </div>
    <ng-container *ngIf="agencies.data.length === 0">
        <app-inlinemessage class="info" level="warning" message="There are no agencies assigned to this broker"></app-inlinemessage>
    </ng-container>
    <table mat-table matSort class="table" *ngIf="agencies.data.length > 0" [dataSource]="agencies">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Agency Name</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>City</th>
            <td mat-cell *matCellDef="let item">{{item.city}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let item">{{item.state}}</td>
        </ng-container>
        <ng-container matColumnDef="actionmenu" stickyEnd>
            <th mat-header-cell matTooltip="Agency Actions" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item"><button mat-button class="cell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button></td>
        </ng-container>
        <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-item="item">
                <button mat-menu-item (click)="editAgency(item)">Edit Agency</button>
                <button mat-menu-item (click)="removeAgency(item)">Remove Agency</button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="setProducts(item)">Set Products for Agency</button>
            </ng-template>
        </mat-menu>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
    </table>
</ng-container>
