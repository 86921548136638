import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TeamsService} from '../../services/teams/teams.service';
import {IntroDialogComponent} from '../modal/introdialog/introdialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SessionService} from '../../services/session/session.service';
import {ApiService} from '../../services/api/api.service';
import {AppSettings} from '../../app.settings';
import {UtilsService} from '../../services/utils/utils.service';
import {TeamCompanyUsersService} from '../../services/teamcompanyusers/teamcompanyusers.service';
import {StripeService} from '../../services/stripe/stripe.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {Dictionary} from 'highcharts';
import YouTubePlayer from 'youtube-player';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  public currentWellspace: any;
  private firstTime = true;
  public teams: any[] = [];
  private isIntegrationAdmin = AppSettings.isIntegration();
  public isCustomerSupport = AppSettings.isCustomerSupport();
  public isBrokerAdmin = AppSettings.isBroker();
  public showPendingAchMsg = false;
  private player: any;
  public videoUrl = '';
  public posterUrl = '';

  constructor(
    private router: Router,
    private teamsService: TeamsService,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private utilsService: UtilsService,
    private teamCompanyUsersService: TeamCompanyUsersService,
    private stipeService: StripeService,
    private uiAlertService: UiAlertService,
  ) { }

  ngOnInit(): void {
    this.getUserTeams();
    this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any): void => {
      this.currentWellspace = wellspace;
    });
    this.apiService.monitorLoginState(loggedIn => {
      if (loggedIn && !this.isIntegrationAdmin && !this.isCustomerSupport && !this.isBrokerAdmin) {
        this.teamCompanyUsersService.getManagers().then(result => {
          if (result.length > 0) {
            this.router.navigate(['products/companies']);
          }
        });
        if (!AppSettings.isBroker()) {
          setTimeout(() => {
            this.teamsService.getUserTeamsInfo(this.sessionService.getUserId()).then(teams => {
              if (teams.length === 0) {
                this.showIntro();
              }
            }).catch(error => {
              this.showIntro();
            });
          }, 100);
        }
      }
    });
    this.route.params.subscribe(params => {
      if (params.teamId) {
        this.teamsService.getTeamInfo(params.teamId).then(team => {
            setTimeout(() => {
              this.teamsService.setWellspace(team);
            }, 500);
            this.router.navigate(['billing/stripepaymentmethods'], {queryParams: {teamName: team.name}});
        });
      }
    });
    this.showPendingAchMessage();
  }

  ngAfterViewInit(): void {
    const videoId = UiAlertService.content.HOME_PAGE;
    if (videoId.startsWith('https:')) {
      this.videoUrl = videoId;
      this.posterUrl = UiAlertService.content.HOME_PAGE_POSTER;
    } else {
      this.player = YouTubePlayer('video-player');
      this.player.loadVideoById(videoId);
      this.player.stopVideo();
    }
  }

  getUserTeams(): void {
    if (this.isBrokerAdmin) {
      return;
    }
    this.teamsService.getUserTeamsInfo(this.sessionService.getUserId()).then(teams => {
      this.teams = teams;
      if (this.teams && this.teams.length > 0) {
        this.teams.forEach(wellspace => wellspace.role = wellspace.ownerUserId === this.sessionService.getUserId() ? '' : 'admin');
      }
    });
  }

  showPendingAchMessage(): void {
    this.stipeService.pendingACHCount.subscribe((res: number) => this.showPendingAchMsg = res > 0);
  }

  showIntro(): void {
    if (this.firstTime) {
      this.firstTime = false;
      this.dialog.open(IntroDialogComponent);
    }
  }

  analyticsClicked(): void {
    this.router.navigate(['analytics']);
  }

  manageWellspaceClicked(): void {
    this.router.navigate(['managewellspace/overview/current']);
  }

  billingClicked(): void {
    this.router.navigate(['billing']);
  }

  settingsClicked(): void {
    this.router.navigate(['profile']);
  }

  manageAccountsClicked(): void {
    this.router.navigate(['settingsandpermissions']);
  }

  selectTeam(team: any): void {
    this.teamsService.setWellspace(team);
    this.router.navigate(['managewellspace/overview/current']);
  }

  addTeam(): void {
    const checkAllTeamUserToSeeIfAdmin: boolean = this.teams.some(team => team && team.role && team.role === 'admin');
    if (checkAllTeamUserToSeeIfAdmin) {
      const msg = 'Are you sure you want to proceed? You will become the team owner, ' +
          'responsible for setting up payment methods. You might prefer to ask your team admin to create a new team with you as the delegated admin.';
      this.uiAlertService.presentAlertConfirm(msg).then((confirm: boolean): void => {
        if (confirm) {
          this.createNewWellspace();
        }
      });
    } else {
      this.createNewWellspace();
    }
  }

  createNewWellspace(): void {
    this.utilsService.addWellspace().then((): void => {
      this.getUserTeams();
    }).catch(err => {
      console.error('Error in createNewWellspace', err);
    });
  }

  helpClicked(): void {

  }

}
