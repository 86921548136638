<h1 mat-dialog-title>Select Clients for agency {{name}}</h1>
<div mat-dialog-content>
    <app-inlinemessage *ngIf="items.data.length === 0" level="warning" message="No clients available"></app-inlinemessage>
    <table class="table" mat-table matSort matTableExporter [dataSource]="items">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox #ref (change)="$event ? masterToggle(ref) : null"
                              [checked]="selection.hasValue() && isAllSelected() && isSomeSelected()"
                              [indeterminate]="selection.hasValue() && !isSomeSelected && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" >
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null;isSomeSelected()"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Client</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="allColumnLabels"></tr>
        <tr class="listitem" mat-row *matRowDef="let rows; columns: allColumnLabels"></tr>
    </table>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button class="donebutton" color="primary" (click)="okClicked()">Done</button>
    <button *ngIf="items.data.length > 0" mat-button (click)="onDismiss()">Cancel</button>
</div>
