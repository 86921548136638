<h1 mat-dialog-title>
  {{approval.email ? 'Edit' : 'Add New'}} Approval
</h1>
<div mat-dialog-content>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
            <mat-form-field>
                <mat-label>Approver Name</mat-label>
                <input type="text" matInput placeholder="" formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Approver Email</mat-label>
                <input type="email" matInput placeholder="" formControlName="email">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Approval threshold ($)</mat-label>
                <input type="number" matInput placeholder="" formControlName="amount">
            </mat-form-field>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!form.valid" class="donebutton" color="primary" (click)="okClicked()">OK</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
