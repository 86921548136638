<form [formGroup]="form">
    <div class="controls">
    </div>
</form>
<div class="header" *ngIf="info">
    <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'Team-Log-Reconciliation-' + info.team?.name})"><mat-icon>file_download</mat-icon>Export to CSV</button>
    <h2 class="teamname">{{info.team?.name}}</h2>
</div>
<table class="table" mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="items">
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Transaction Date</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.createdAt | date: 'mediumDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Recipient</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.email}}</td>
    </ng-container>
    <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Team</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.teamName}}</td>
    </ng-container>
    <ng-container matColumnDef="sender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Sender</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.sender}}</td>
    </ng-container>
    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Message</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.message}}</td>
    </ng-container>
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Amount</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.transactionAmount | currency}}</td>
    </ng-container>
    <ng-container matColumnDef="taxable">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Taxable</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.taxable}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Status</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.status}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
<span *ngIf="items.data?.length === 0">No API log transactions</span>
