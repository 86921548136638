import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {TransactionsService} from '../../../services/transactions/transactions.service';
import {TeamsService} from '../../../services/teams/teams.service';
import {MatTableDataSource} from '@angular/material/table';
import {ITransactionInterface} from './peer-to-peer-transaction.interface';

@Component({
  selector: 'app-analytics-peer-to-peer',
  templateUrl: './analytics-peer-to-peer.component.html',
  styleUrls: ['./analytics-peer-to-peer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyticsPeerToPeerComponent implements OnInit {
  @Input() analytics: any;
  @ViewChild(MatSort) sort!: MatSort;
  public columnsToDisplay: string[] = ['transactionDate', 'recipient', 'sender', 'description', 'amount', 'category', 'taxable', 'status'];
  private teamId = '';
  public userId = 'all';
  public transactionList: MatTableDataSource<ITransactionInterface> = new MatTableDataSource<ITransactionInterface>([]);

  constructor(
      private transactionService: TransactionsService,
      private teamService: TeamsService
  ) {}

  ngOnInit(): void {
    this.teamService.wellspaceChangedObservable.subscribe((team: any): void => {
      if (team && team.id) {
        this.teamId = team.id;
        this.getAllPeerToPeerTransactions();
      }
    });
  }

  getAllPeerToPeerTransactions(): void {
    if (this.teamId) {
      this.transactionList.data = [];
      this.transactionService.getTeamPeerToPeerTransactions(this.teamId, 'P2p').then(((transactions: ITransactionInterface[]): void => {
        this.transactionList.data = transactions;
        this.transactionList.sort = this.sort;
      })).catch(err => {
        console.error('Error in list Reimbursements in get all peer to peer transaction: ', err);
        this.transactionList.data = [];
      });
    }
  }


}
