import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-advertising-campaigns',
  templateUrl: './advertising-campaigns.component.html',
  styleUrls: ['./advertising-campaigns.component.scss']
})
export class AdvertisingCampaignsComponent implements OnInit {

  public config: TableConfig = {
    title: 'Campaigns',
    dbTableName: 'campaigns',
    expand: ['advertiser_id'],
    columns: [
      {name: 'name', title: 'Campaign Name'},
      {name: 'advertiser_id.name', title: 'Advertiser'},
      {name: 'active', title: 'Active'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ]
  };
  constructor() { }

  ngOnInit(): void {
  }

}
