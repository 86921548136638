import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import { TeamsService } from '../teams/teams.service';

@Injectable({
  providedIn: 'root'
})
export class FeeService {

  constructor(private restangular: Restangular,
              private teamsService: TeamsService) { }

  getFee(teamId: string): Promise<any> {
    return this.restangular.one('fees').one('team', teamId).get().toPromise();
  }

  async getPaymentMethodFee(team: any): Promise<number> {
    const defaultPaymentMethod = await this.teamsService.getPaymentMethod(team);

    const paymentMethods = await this.teamsService.getPaymentMethods(team.id);

    const fee = await this.getFee(team.id);
    return fee[paymentMethods[paymentMethods.findIndex((x: any) => x.stripePaymentMethod === defaultPaymentMethod)].bankInfo.type === 'us_bank_account' ? 'ach' : 'creditCard'].fee / 100;
  }

}
