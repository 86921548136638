<div class="allocation-report-container">

    <div class="allocation-report-title">
        <h1>Fund Allocation Overview</h1>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'fund-allocation-overview-report'})">
            <mat-icon>file_download</mat-icon>Export to CSV
        </button>
    </div>

    <div class="allocation-report-table-container">
        <div class="allocation-report-chart">
            <canvas id='chart'>{{chart}}</canvas>
        </div>

        <mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="allocationList" class="custom-table mat-elevation-z4">

            <ng-container matColumnDef="columnName">
                <mat-cell *matCellDef="let element"> <strong>{{element.columnName ?? '---'}}</strong> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="columnValue">
                <mat-cell *matCellDef="let element"> {{element.columnValue | currency}} </mat-cell>
            </ng-container>


            <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>

        </mat-table>

        <p class="no-payment-text" *ngIf="allocationList.data.length <= 0">
            No Allocation Report found!
        </p>

    </div>

</div>
