<h1 mat-dialog-title>
  Enter PIN sent to {{phone}}
</h1>
<div mat-dialog-content>
    <form class="example-form" [formGroup]="form">
        <mat-form-field class="example-full-width" >
            <mat-label>PIN</mat-label>
            <input matInput placeholder="" formControlName="input">
        </mat-form-field>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
  <button class="okbutton" mat-raised-button color="primary" (click)="onConfirm()" [disabled]="!form.valid">OK</button>
  <button mat-button (click)="onDismiss()">Cancel</button>
</div>
