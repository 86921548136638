<div class="wholepage">
    <div class="itemgroup">
        <div class="item">
            <mat-icon></mat-icon>
            <div class="itemright">
                <span class="header">Insurance</span>
                <span class="description">Create and manage insurance industry business entities and insurance products and policies.</span>
                <div class="graphic"><img class="image" src="assets/graphics/insurancediagram.png"></div>
            </div>
        </div>
    </div>
    <div class="itemgroup">
        <div class="item">
            <mat-icon></mat-icon>
            <div class="itemright">
                <span class="header">Advertising</span>
                <span class="description">Create and manage the placement of components in the user app that are connected to insurance products.</span>
                <div class="graphic"><img class="image" src="assets/graphics/servingdiagram.png"></div>
            </div>
        </div>
    </div>
</div>

