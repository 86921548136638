import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {UsersInsuranceService} from '../../services/usersinsurance/users.insurance.service';
import {UsersService} from '../../services/users/users.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment/moment';
import {MatTabGroup} from '@angular/material/tabs';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';

@Component({
  selector: 'app-insurance-dependents',
  templateUrl: './insurance-dependents.component.html',
  styleUrls: ['./insurance-dependents.component.scss']
})
export class InsuranceDependentsComponent implements OnInit {

  // @ts-ignore
  @ViewChild('tabs') tabGroup: MatTabGroup;

  public dependents: any;
  public maxDate = moment().toDate();
  public addTabName = 'Add Dependent';
  private counter = 1;
  public selectedTabIndex = 0;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private usersInsuranceService: UsersInsuranceService,
      private usersService: UsersService,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService
  ) {
  }

  buildForm(dependent: any): void {
    dependent.form = this.formBuilder.group({
      gender: new UntypedFormControl(dependent.gender),
      tobaccoUseDate: new UntypedFormControl(dependent.tobaccoUseDate),
      relationship: new UntypedFormControl(dependent.relationship, Validators.required),
      firstName: new UntypedFormControl(dependent.firstName),
      lastName: new UntypedFormControl(dependent.lastName),
      dob: new UntypedFormControl(dependent.dob),
    });
    dependent.form.get('firstName').valueChanges.subscribe((value: any) => {
      dependent.firstName = value;
      this.dependents = this.dependents.slice();
    });
  }

  ngOnInit(): void {
    this.usersInsuranceService.getInsuranceDependents(this.usersService.getCurrentUserId()).then(result => {
      this.dependents = result.concat({firstName: this.addTabName});
      this.dependents.forEach((dependent: any) => this.buildForm(dependent));
    });
  }

  onClick(dependent: any): void {
    if (dependent.firstName === this.addTabName) {
      this.dependents.unshift({firstName: `New Dependent ${this.counter++}`});
      this.buildForm(this.dependents[0]);
      this.tabGroup.selectedIndex = 1;
      setTimeout(() => {
        this.tabGroup.selectedIndex = 0;
      }, 50);
    }
  }

  resetClicked(dependent: any): void {
    dependent.form.reset();
  }

  saveClicked(dependent: any): void {
    const newInfo = {
      gender: dependent.form.get('gender')?.value,
      tobaccoUseDate: dependent.form.get('tobaccoUseDate')?.value,
      firstName: dependent.form.get('firstName')?.value,
      lastName: dependent.form.get('lastName')?.value,
      dob: dependent.form.get('dob')?.value,
      relationship: dependent.form.get('relationship')?.value
    };
    if (dependent.id) {
      this.usersInsuranceService.modifyInsuranceDependent(this.usersService.getCurrentUserId(), dependent.id, newInfo).then(_ => {
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'User insurance dependent info updated'});
        dependent.form.markAsPristine();
      });
    } else {
      this.usersInsuranceService.createInsuranceDependent(
          this.usersService.getCurrentUserId(),
          dependent.form.get('gender')?.value,
          {},
          dependent.form.get('tobaccoUseDate')?.value,
          dependent.form.get('firstName')?.value,
          dependent.form.get('lastName')?.value,
          dependent.form.get('dob')?.value,
          dependent.form.get('relationship')?.value
      ).then(_ => {
        dependent = newInfo;
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'User insurance dependent info created'});
        dependent.form.markAsPristine();
      });
    }
  }

  removeClicked(dependent: any): void {
    if (dependent.id) {
      this.uiAlertService.presentAlertConfirm('Do you really want to remove this dependent?').then(ok => {
        if (ok) {
          this.usersInsuranceService.deleteInsuranceDependent(this.usersService.getCurrentUserId(), dependent.id).then(_ => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'Dependent removed'});
            this.dependents.splice(this.dependents.findIndex((item: { id: string; }) => item.id === dependent.id), 1);
            this.dependents = this.dependents.slice();
          });
        }
      });
    } else {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Dependent removed'});
      this.dependents.splice(this.tabGroup.selectedIndex, 1);
      this.dependents = this.dependents.slice();
    }
  }

}
