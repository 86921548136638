import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {StripeService} from '../../../services/stripe/stripe.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {TeamsService} from '../../../services/teams/teams.service';
import {Location} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../../snackbar/snackbar.component';

export enum SendMode {
  TO_WALLIT_BALANCE,
  TO_ALLOWANCE_BALANCE,
  ALLOWANCE_TO_WALLIT
}

@Component({
  selector: 'app-send-funds-dialog',
  templateUrl: './send-funds-dialog.html',
  styleUrls: ['./send-funds-dialog.scss'],
})
export class SendFundsComponent implements OnInit {

  public SendMode = SendMode;

  public members: Array<any> = [];
  public form;
  public sendButtonLabel = '';
  public team: any;
  public uploading = false;
  public sendMode;

  public messages = {
    [SendMode.TO_WALLIT_BALANCE]: {
      title: '',
      info: 'The amount you specify will be immediately transferred from the Wallit master balance to the Wallit balance of the team agency-members you select.'
    },
    [SendMode.TO_ALLOWANCE_BALANCE]: {
      title: '',
      info: 'The amount you specify will be immediately transferred from the Wallit master balance to the allowance balance of the team agency-members you select.'
    },
    [SendMode.ALLOWANCE_TO_WALLIT]: {
      title: '',
      info: `The allowance balance of the team members you select will be immediately transferred to their Wallit balance.`
    }
  };

  constructor(
      private route: ActivatedRoute,
      private formBuilder: UntypedFormBuilder,
      private stripeService: StripeService,
      private uiAlertService: UiAlertService,
      private teamsService: TeamsService,
      private location: Location,
      private snackBar: MatSnackBar,
      public dialogRef: MatDialogRef<SendFundsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: SendFundsComponentModel
  ) {
    this.sendMode = data.sendMode;
    this.teamsService.getTeamInfo(data.teamId).then(team => {
      this.team = team;
      this.messages[SendMode.TO_WALLIT_BALANCE].title = `Send Wallit Funds to Wallit Balance of Members of Team ${team.name}`;
      this.messages[SendMode.TO_ALLOWANCE_BALANCE].title = `Send Wallit Funds to Allowance Balance of Members of Team ${team.name}`;
      this.messages[SendMode.ALLOWANCE_TO_WALLIT].title = `Move allowance balance to Wallit balance for Members of Team ${team.name}`;
    });
    this.form = this.formBuilder.group({
      amount: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  membersChanged(event: any): void {
    this.members = event;
    this.sendButtonLabel = `Send Reward to ${this.members.length} Members`;
  }

  setButtonCompletionMessage(count: number): void {
    this.uploading = true;
    this.sendButtonLabel = `${count} of ${this.members.length} Rewards Sent`;
  }

  sendRewards(): void {
    if (this.sendMode === SendMode.ALLOWANCE_TO_WALLIT) {
        this.uiAlertService.presentAlertConfirm(`This will initiate a transfer from members' allowance balance to their Wallit balance.`).then(confirm => {
          if (confirm) {
            this.members = this.members.filter(member => parseFloat(member.balance) > 0);
            this.teamsService.makeWallitTransfer(this.team.id, 0, this.members, this.form.get('message')?.value, this.sendMode).then(() => {
              this.snackBar.openFromComponent(SnackBarComponent, {data: 'Transfer(s) from allowance balance to Wallit balance completed'});
              this.dialogRef.close(true);
            }).catch(error => {
              const info = error.error;
              this.snackBar.openFromComponent(SnackBarComponent, {data: `${info.raw.message}: ${info.raw.decline_code.split('_').join(' ')}`});
              this.uploading = false;
            });
          }
        });
    } else {
      const amount = this.form.get('amount')?.value * this.members.length;
      this.uiAlertService.presentAlertConfirm(`This will initiate a one time transfer of $${amount.toFixed(2)} from the Wallit master account.`).then(confirm => {
        if (confirm) {
          const promises: any = [];
          let count = 0;
          this.setButtonCompletionMessage(0);
          this.members.forEach(member => {
            promises.push(this.teamsService.makeWallitTransfer(this.team.id, amount, this.members, this.form.get('message')?.value, this.sendMode).then(() => {
              this.setButtonCompletionMessage(++count);
            }));
          });
          Promise.all(promises).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'One time transfer from Wallit master account completed'});
            this.dialogRef.close(true);
          }).catch(error => {
            const info = error.error;
            this.snackBar.openFromComponent(SnackBarComponent, {data: `${info.raw.message}: ${info.raw.decline_code.split('_').join(' ')}`});
            this.uploading = false;
          });
        }
      });
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}

export class SendFundsComponentModel {
  constructor(public teamId: string, public sendMode: SendMode) {
  }
}
