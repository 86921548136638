<div>
    <span class="info-message">Select one or more transaction categories for employee reimbursement</span>

    <table>
        <tr>
            <td class="taxable-header">Taxable</td>
            <td>
                <div class="categories">
                    <button class="chip" mat-raised-button *ngFor="let category of (categories | taxableFilter :true)" (click)="categoryClicked(category)" [matTooltip]="category.toolTip" [color]="category.selected ? 'accent' : undefined">
                        {{category.category}}
                    </button>
                </div>
            </td>
        </tr>
        <tr><td>&nbsp;</td></tr>
        <tr>
            <td class="taxable-header">Non-Taxable</td>
            <td>
                <div class="categories">
                    <button class="chip" mat-raised-button *ngFor="let category of (categories | taxableFilter :false)" (click)="categoryClicked(category)" [matTooltip]="category.toolTip" [color]="category.selected ? 'accent' : undefined">
                        {{category.category}}
                    </button>
                </div>
            </td>
        </tr>
    </table>

    <span class="info-message">Select your monthly benefit allowance</span>

    <div class="category-wrapper">
        <div class="categories">
            <button class="chip" mat-raised-button *ngFor="let allowance of allowances" (click)="allowanceClicked(allowance)" [color]="checkAllowanceColorButton(allowance) ? 'accent' : undefined">
                ${{allowance ? allowance : 'Custom'}}
            </button>
        </div>
        <div class="custom">
            <span>Custom amount $</span>
            <input class="custom-amount" #range [(ngModel)]="selectedAllowance" type="number" min="0" max="1000" (input)="rangeChanged($event)">
        </div>
    </div>

    <div class="save-button">
        <button mat-raised-button color="primary" [disabled]="isSaveButtonDisabled || saving" class="button primary-button" (click)="setBenefitsClicked()">
            Save
        </button>
    </div>

</div>
