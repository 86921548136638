import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';
import {UseCsvService} from '../../services/usercsv/usecsv.service';

@Component({
  selector: 'app-insurance-employers',
  templateUrl: './insurance-employers.component.html',
  styleUrls: ['./insurance-employers.component.scss']
})
export class InsuranceEmployersComponent implements OnInit {

  public config: TableConfig = {
    title: 'Employers',
    dbTableName: 'companies',
    filter: {company_type: 'employer'},
    expand: ['parent_id'],
    columns: [
      {name: 'branding.logo', title: '', isImage: true, imageType: 'logo'},
      {name: 'name', title: 'Business Name'},
      {name: 'parent_id.name', title: 'Parent Business'},
      {name: 'contact.name', title: 'Contact'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ],
    excludedFields: ['company_type'],
    preWrite: (fields) => { fields.company_type = 'employer'; return true; },
    hiddenExportColumnNumbers: [4],
    importKey: this.useCsvService.importerKeys.IMPORTER_COMPANIES
  };

  constructor(
      private useCsvService: UseCsvService
  ) { }

  ngOnInit(): void {
  }

}
