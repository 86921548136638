import {RESTANGULAR_INTEGRATION_SERVER} from '../../app.module';
import {Restangular} from 'ngx-restangular';
import {Inject, Injectable} from '@angular/core';
import {Dictionary} from 'highcharts';
// import {LiquidCache, LiquidCacheService} from 'ngx-liquid-cache';

export interface MediaVersionInfo {
    version_id: string;
    last_modified: string;
}

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor(
        @Inject(RESTANGULAR_INTEGRATION_SERVER) private restangular: Restangular,
        // private cache: LiquidCacheService
    ) { }

    create(data: Dictionary<any>): Promise<any> {
        return this.restangular.all('media').post(this.buildFormData(data)).toPromise();
    }

    patch(mediaId: string, data: Dictionary<any>): Promise<any> {
        // this.cache.remove(`get${mediaId}`);
        return this.restangular.one('media').one(mediaId).patch(this.buildFormData(data)).toPromise();
    }

    // @LiquidCache('get{mediaId}')
    get(mediaId: string): Promise<{blob: Blob, url: string}> {
        return this.restangular.one('media').one(mediaId).one('content').withHttpConfig({responseType: 'blob'}).get().toPromise().then((response: any) => {
            const urlCreator = window.URL || window.webkitURL;
            return { blob: response, url: urlCreator.createObjectURL(response)};
        }).catch((error: any) => {
            console.log('GET IMAGE ERROR', error);
        });
    }

    getVersions(mediaId: string): Promise<Array<MediaVersionInfo>> {
        return this.restangular.one('media').one(mediaId).one('versions').get().toPromise();
    }

    setVersion(mediaId: string, versionId: string): Promise<MediaVersionInfo> {
        // this.cache.remove(`get${mediaId}`);
        return this.restangular.one('media').one(mediaId).one('version').one(versionId).patch().toPromise();
    }

    delete(id: string): Promise<string> {
        return this.restangular.one('media').one(id).remove().toPromise();
    }

    buildFormData(data: Dictionary<any>): FormData {
        const formData = new FormData();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                formData.append(key, data[key]);
            }
        }
        return formData;
    }

}
