import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-analytics-allowances',
  templateUrl: './analytics-allowances.component.html',
  styleUrls: ['./analytics-allowances.component.scss']
})
export class AnalyticsAllowancesComponent {
  @Input() analytics: any;
}
