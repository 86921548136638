import { Component, OnInit } from '@angular/core';
import {StripeService} from '../../services/stripe/stripe.service';
import {TeamsService} from '../../services/teams/teams.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {SessionService} from '../../services/session/session.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';

enum Plans {
  Free = 'Free',
  Plus = 'Plus',
  PlusAnnual = 'PlusAnnual'
}

@Component({
  selector: 'app-plan',
  templateUrl: './plan.page.html',
  styleUrls: ['./plan.page.scss'],
})

export class PlanComponent implements OnInit {

  public team: any;
  public members = [];
  public companyPlusPlan = TeamsService.companyPlusPlan;

  public plans = [
    {name: 'Free', rate: '', plan: Plans.Free },
    {name: 'Plus Monthly', rate: '$7.00/month', plan: Plans.Plus },
    {name: 'Plus Annual', rate: '$66.00/year ($5.50/month)', plan: Plans.PlusAnnual },
  ];

  isTeamApproved = false;

  constructor(
      private stripeService: StripeService,
      private teamsService: TeamsService,
      private spinnerService: SpinnerService,
      private sessionService: SessionService,
      private snackBar: MatSnackBar,
      private route: ActivatedRoute,
      private uiAlertService: UiAlertService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.teamId && params.teamId !== 'current') {
        this.teamsService.getTeamInfo(params.teamId).then(team => {
          this.team = team;
          this.teamsService.getTeamMembers(this.team.id).then(members => {
            this.members = members;
          });
        });
      } else {
        this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any) => {
          if (wellspace) {
            this.isTeamApproved = wellspace?.approved === 1;
            this.team = wellspace;
            this.teamsService.getTeamMembers(this.team.id).then(members => {
              this.members = members;
            });
          }
        });
      }
    });
  }

  selectPlan(plan: any): void {
    this.teamsService.getUserTeamsInfo(this.sessionService.getUserId()).then(teams => {
      if (teams.length > 1 && plan.plan === Plans.Free) {
        this.uiAlertService.presentAlertConfirm('You have more than one team, which requires all teams to be on the Plus plan or all teams to be on the Free plan. Do you wish to downgrade all teams to the free plan?').then(confirm => {
          if (confirm) {
            this.spinnerService.show(`Changing plans for ${teams.length} teams...`);
            const promises = Array<Promise<void>>();
            teams.forEach((team: any) => {
              promises.push(new Promise((resolve) => {
                this.teamsService.getTeamMembers(team.id).then(members => {
                  this.stripeService.changePlan(team, members, Plans.Free, () => {
                    resolve();
                  });
                });
              }));
            });
            Promise.all(promises).then(() => {
              this.plans = this.plans.slice();
              this.spinnerService.hide();
            });
          }
        });
      } else {
        this.spinnerService.show('Changing plan...');
        this.stripeService.changePlan(this.team, this.members, plan.plan, () => {
          this.plans = this.plans.slice();
          this.spinnerService.hide();
        });
      }
    });
  }

  buttonMessage(plan: any): string {
    if (!this.isTeamApproved) {
      return 'Your team is awaiting approval by Wallit';
    } else if (plan === this.plans[0]) {
      return 'Available';
    } else if (this.team.defaultPaymentMethod) {
      return 'Select';
    } else {
      return 'Payment Method Required';
    }
  }

}
