import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { AllocationReportInterface } from './allocation-report.interface';
import Chart from 'chart.js/auto';


@Component({
    selector: 'app-analytics-allocation-report',
    templateUrl: './analytics-allocation-report.component.html',
    styleUrls: ['./analytics-allocation-report.component.scss']
})
export class AnalyticsAllocationReportComponent {
    @ViewChild(MatSort) sort!: MatSort;
    private teamId = '';
    public allocationList: MatTableDataSource<{ columnName: string, columnValue: number }> = new MatTableDataSource<{ columnName: string, columnValue: number }>([]);
    public columnsToDisplay: string[] = ['columnName', 'columnValue'];
    public chart: any = [];

    constructor(
        private transactionService: TransactionsService,
        private teamService: TeamsService
    ) {
    }

    ngOnInit(): void {
        this.teamService.wellspaceChangedObservable.subscribe((team: any): void => {
            if (team && team.id) {
                this.teamId = team.id;
                this.getAllocationList();
            }
        });
    }

    getAllocationList(): void {
        if (this.teamId) {
            this.allocationList.data = [];
            this.transactionService.getAllocationReport(this.teamId).then((allocationReportData: AllocationReportInterface): void => {
                const data = [
                    {
                        columnName: 'Rewards Allocation ($)',
                        columnValue: allocationReportData.rewardsSent ?? 0
                    },
                    {
                        columnName: 'Allowance Allocation ($)',
                        columnValue: allocationReportData.allowanceSent ?? 0
                    },
                    {
                        columnName: 'Wallit Allocation ($)',
                        columnValue: allocationReportData.wallitSent ?? 0
                    }
                ];
                this.allocationList.data = data;
                this.setChart(allocationReportData);
            }).catch(err => {
                this.allocationList.data = [];
                console.error('Error in get allocation report', err);
            });
        }
    }

    setChart(allocationReportData: AllocationReportInterface): void {
        if (this.teamId) {
            this.chart = new Chart('chart', {
                type: 'pie',
                data: {
                    labels: ['Rewards Allocation ($)', 'Allowance Allocation ($)', 'Wallit Allocation ($)'],
                    datasets: [
                        {
                            label: '$',
                            data: [
                                allocationReportData.rewardsSent,
                                allocationReportData.allowanceSent,
                                allocationReportData.wallitSent
                            ],
                        },
                    ],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    return new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(tooltipItem.raw as number);
                                }
                            }
                        }
                    }
                }
            });
        }
    }
}
