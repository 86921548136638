import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import { faker } from '@faker-js/faker';
import {FormBuilder, UntypedFormControl} from '@angular/forms';
import {UsersService} from '../../../services/users/users.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
const rc = require('randomcolor');
import {Clipboard} from '@angular/cdk/clipboard';
import {PartnersService} from '../../../services/partners/partners.service';
import {Member, Team, TeamsService} from '../../../services/teams/teams.service';
import {CompanyInfo, TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import moment from 'moment';
import {LocalStorageService} from '../../../services/localstorage/localstorage.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Partner} from '../../../services/partners/partners.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {VendorProduct, VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';

@Component({
  selector: 'app-provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: ['./provisioning.component.scss'],
    animations: [
        trigger('expandCollapse', [
            state('expanded', style({ height: '*', opacity: 1 })),
            state('collapsed', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
            transition('expanded <=> collapsed', [
                animate('300ms ease-in-out')
            ])
        ])
    ]
})
export class ProvisioningComponent implements AfterViewInit {

    private LOG_STORAGE_KEY = 'LogStorage';

    @ViewChild('avatar') avatar?: ElementRef;

    protected brandingForm: any;
    protected companyForm: any;
    protected teamForm: any;
    protected memberForm: any;
    protected imageData = '';
    private parentCompanyId = '';
    protected brandingCount = 1;
    protected isExpanded = true;

    private brandName = '';
    private sandbox = false;
    protected logItems = Array<string>();

    protected currentPartner: Partner | undefined;
    protected currentCompany: CompanyInfo | undefined;
    protected currentTeam: Team | undefined;
    protected currentMember: Member | undefined;
    protected currentVendorProduct: VendorProduct | undefined;

    protected partnerItems = Array<Partner>();
    protected companyItems = Array<CompanyInfo>();
    protected teamItems = Array<Team>();
    protected memberItems = Array<Member>();
    protected vendorProducts = Array<VendorProduct>();

    protected vendorProductForm: any;

    constructor(
      private formBuilder: FormBuilder,
      private usersService: UsersService,
      private clipboard: Clipboard,
      private snackBar: MatSnackBar,
      private partnersService: PartnersService,
      private teamsService: TeamsService,
      private teamCompaniesService: TeamCompaniesService,
      private localStorage: LocalStorageService,
      private vendorProductsService: VendorProductsService
    ) {
        this.brandingSetFakes();
        this.companyShuffle();
        this.teamShuffle();
        this.memberShuffle();
        this.loadItems();
        this.teamCompaniesService.getCompanyByName('Sandbox').then(result => {
            this.parentCompanyId = result.id;
        });
        const savedLog = localStorage.getStorage(this.LOG_STORAGE_KEY);
        if (savedLog) {
            this.logItems = JSON.parse(savedLog);
        }
    }

    ngAfterViewInit(): void {
      const words = this.brandName.replace(/[-,']/g, '').split(' ');
      this.usersService.getAvatar({avatar: 'no_avatar.png', firstName: words[0], lastName: words[1]}, this.avatar?.nativeElement);
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }

    loadItems(): void {
        const sandboxFilter = (items: any) => {
            return items.filter((item: { configjson: { sandbox: any; }; }) => !this.sandbox || item.configjson.sandbox);
        };
        this.partnersService.getAllPartners().then(partners => {
            this.partnerItems = sandboxFilter(partners);
        });
        this.teamCompaniesService.getCompanies().then(companies => {
            this.companyItems = sandboxFilter(companies).filter((((company: { type: string; payrollConfig: { partners: { find: (arg0: string | undefined) => any; }; }; }) => company.type === 'employer' && (!this.currentCompany || company.payrollConfig.partners.find(this.currentPartner?.shortName.split('.')[0])))));
        });
        this.teamsService.getAllTeams().then(teams => {
            this.teamItems = sandboxFilter(teams).filter((team: { id: string; }) => !this.currentCompany || team.id === this.currentCompany.id);
        });
        this.vendorProductsService.getAllVendorProducts().then(vendorProducts => {
            // @ts-ignore
            vendorProducts.forEach(vendorProduct => vendorProduct.name = `${vendorProduct.vendorName} ${vendorProduct.productName}`);
            this.vendorProducts = vendorProducts;
        });
        if (this.currentTeam?.id) {
            this.teamsService.getTeamMembers(this.currentTeam.id).then(members => {
                this.memberItems = sandboxFilter(members).filter((member: { teamId: string | undefined; }) => !this.currentTeam || member.teamId === this.currentTeam.id);
            });
        }
    }

    addToLog(message: string): void {
      this.logItems.push(`${moment().format('YYYY-MM-DD HH:MM:SS')}: ${message}`);
      this.localStorage.setStorage(this.LOG_STORAGE_KEY, JSON.stringify(this.logItems));
    }

    clearLog(): void {
        this.logItems = [];
        this.localStorage.setStorage(this.LOG_STORAGE_KEY, JSON.stringify([]));
    }

    logCopyToClipboard(): void {
        let message = 'Wallit Broker Admin Provisioning Log\n\n';
        this.logItems.forEach(item => message += `${item}\n`);
        this.clipboard.copy(message);
        this.snackBar.openFromComponent(SnackBarComponent, {data: `Log copied to clipboard`});

    }

    sandBoxChanged(event: MatSlideToggleChange): void {
        this.sandbox = event.checked;
        this.loadItems();
    }

    setCurrentItem(type: string, item: Partner | CompanyInfo | Team | Member | VendorProduct): void {
        switch (type) {
            case 'partner':
                this.currentPartner = item as Partner;
                break;
            case 'company':
                this.currentCompany = item as CompanyInfo;
                break;
            case 'team':
                this.currentTeam = item as Team;
                break;
            case 'member':
                this.currentMember = item as Member;
                break;
            case 'vendorproduct':
                this.currentVendorProduct = item as VendorProduct;
                break;
        }
        this.loadItems();
    }

    viewItem(type: string, item: Partner | CompanyInfo | Team | Member): void {
        switch (type) {
            case 'partner':
                break;
            case 'company':
                break;
            case 'team':
                break;
            case 'member':
                break;
        }
    }

    // BRANDING

    brandingShuffle(): void {
        this.brandingSetFakes();
        this.ngAfterViewInit();
    }

    brandingSetFakes(): void {
        this.brandName = faker.company.name();
        const fgColor = rc({luminosity: 'bright'});
        const bgColor = rc({luminosity: 'light'});
        this.brandingForm = this.formBuilder.group(
            {
                logo: new UntypedFormControl(this.imageData),
                name: new UntypedFormControl(this.brandName),
                url: new UntypedFormControl(this.brandName.replace(/[-,']/g, '').split(' ').join('').toLowerCase()),
                fgColor: new UntypedFormControl(fgColor),
                bgColor: new UntypedFormControl(bgColor)
            }
        );
    }

    brandingDone(): void  {
        const logoUrl = '';
        const primaryColor = this.brandingForm.get('fgColor').value;
        this.partnersService.createPartner({
            name: this.brandingForm.get('name').value,
            shortName: this.brandingForm.get('url').value,
            logoUrl,
            horizontalLogoUrl: logoUrl,
            lightLogoUrl: logoUrl,
            horizontalLogoOnWhite: logoUrl,
            verticaLogoOnWhite: logoUrl,
            redirectUrl: logoUrl,
            configjson: { sandbox: this.sandbox},
            signupDisabled: false,
            tagline: '',
            ourlyPrimaryColor: primaryColor,
            ourlySecondaryColor: this.brandingForm.get('bgColor').value,
            wallitGradientNEColor: primaryColor,
            wallitGradientSWColor: primaryColor
        }).then(async partner => {
            this.currentPartner = partner;
            await this.partnersService.changePartnerLogo(partner.partnerId || '', this.imageData, 'avatar');
            await this.partnersService.changePartnerLogo(partner.partnerId || '', this.imageData, 'lightlogo');
            await this.partnersService.changePartnerLogo(partner.partnerId || '', this.imageData, 'horizontallightlogo');
            await this.partnersService.changePartnerLogo(partner.partnerId || '', this.imageData, 'horizontallogo');
            this.addToLog(`Brand ${this.brandingForm.get('name').value} created`);
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Partner ${this.brandingForm.get('name').value} created`});
        }).catch(error => {
            const errorMessage = `Error creating partner: ${error.message}`;
            this.snackBar.openFromComponent(SnackBarComponent, { data: errorMessage});
            this.addToLog(errorMessage);
        });
    }

    brandingClicked(partner: Partner): void {

    }

    imageUploaded(img: any): void {
        this.imageData = img;
    }

    onBrandingCountChange(event: Event): void {
        this.brandingCount = Number((event.target as HTMLInputElement).value);
    }
    // COMPANY

    companyShuffle(): void {
        const generateSSN = () => {
            const part1 = faker.number.int({ min: 100, max: 999 }).toString();
            const part2 = faker.number.int({ min: 10, max: 99 }).toString();
            const part3 = faker.number.int({ min: 1000, max: 9999 }).toString();
            return `${part1}${part2}${part3}`;
        };
        this.companyForm = this.formBuilder.group(
            {
                name: new UntypedFormControl(faker.company.name()),
                ssn: new UntypedFormControl(generateSSN()),
                sic: new UntypedFormControl(faker.number.int({ min: 1000, max: 9999 }).toString()),
                address1: new UntypedFormControl(faker.location.streetAddress()),
                city: new UntypedFormControl(faker.location.city()),
                state: new UntypedFormControl(faker.location.state()),
                zip: new UntypedFormControl(faker.location.zipCode()),
            }
        );
    }

    companyDone(): void {
        this.teamCompaniesService.createCompany(
            this.companyForm.get('name').value,
            { sandbox: this.sandbox },
            '',
            'employer',
            this.parentCompanyId,
            this.companyForm.get('ssn').value,
            this.companyForm.get('sic').value,
            this.companyForm.get('address1').value,
            '',
            this.companyForm.get('city').value,
            this.companyForm.get('state').value,
            this.companyForm.get('zip').value,
            {sandbox: this.sandbox}
            ).then(result => {
            this.currentCompany = result;
        }).then(result => {
            this.addToLog(`Company ${this.companyForm.get('name').value} created`);
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Company ${this.companyForm.get('name').value} created`});
        }).catch(error => {
            this.snackBar.openFromComponent(SnackBarComponent, { data: `Error creating company: ${error.message}`});
        });
    }

    // TEAM

    teamShuffle(): void {
        this.teamForm = this.formBuilder.group({
            name: new UntypedFormControl()
        });
    }

    teamDone(): void {
        this.teamsService.createTeam(
            '',
            '',
        ).then(result => {
            this.addToLog(`Team ${this.teamForm.get('name').value} created`);
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Team ${this.teamForm.get('name').value} created`});
        }).catch(error => {
            this.snackBar.openFromComponent(SnackBarComponent, { data: `Error creating team: ${error.message}`});
        });
    }

    // MEMBER

    memberShuffle(): void {
        this.memberForm = this.formBuilder.group({
            email: new UntypedFormControl()
        });
    }

    memberDone(): void {
        this.teamsService.addTeamMember(
            '',
            '',
            ''
        ).then(result => {
            this.addToLog(`Team member ${this.teamForm.get('email').value} added`);
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Member ${this.teamForm.get('email').value} added`});
        }).catch(error => {
            this.snackBar.openFromComponent(SnackBarComponent, { data: `Error adding team member: ${error.message}`});
        });
    }


}
