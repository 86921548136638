<button class="addbutton" mat-raised-button color="accent" (click)="addApprover()"><mat-icon>add</mat-icon>Add an Approver</button>
<mat-table #table class="table" matSort [dataSource]="items" cdkDropList [cdkDropListData]="items" (cdkDropListDropped)="drop($event)">
    <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef style="max-width: 72px;"></mat-header-cell>
        <td mat-cell *matCellDef="let item">
            <mat-icon cdkDragHandle>reorder</mat-icon>
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Name</mat-header-cell>
      <mat-cell class="cell" *matCellDef="let item">{{item.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Email</mat-header-cell>
      <mat-cell class="cell" *matCellDef="let item">{{item.email}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Amount</mat-header-cell>
      <mat-cell class="cell" *matCellDef="let item"> {{item.amount | currency}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell class="cell" *matCellDef="let item"><button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="itemMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
      </mat-cell>
    </ng-container>
    <mat-menu #itemMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="deleteItem(item)">Remove</button>
            <button mat-menu-item (click)="editItem(item)">Edit</button>
        </ng-template>
    </mat-menu>
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row class="listitem" *matRowDef="let rows; columns: columnsToDisplay" cdkDrag cdkDragLockAxis="y"></mat-row>
</mat-table>
<span class="emptyinfo" *ngIf="items.data.length === 0">No custom approvers have been configured. Approvals will be sent to the team leader.
 When you add custom approvers, you can set an amount below which approval is not required. The approval request is sent sequentially to each
 person in the list.
</span>
