import { Component, OnInit, ViewChild } from '@angular/core';
import { Category, TeamsService } from '../../services/teams/teams.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiAlertService } from '../../services/ui-alert/ui-alert.service';
import { Plan, StripeService } from '../../services/stripe/stripe.service';
import { Location } from '@angular/common';
import { SessionService } from '../../services/session/session.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { SnackBarComponent } from '../snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../services/users/users.service';
import { FeeService } from 'src/app/services/fee/fee.service';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-teamedit',
  templateUrl: './teamedit.component.html',
  styleUrls: ['./teamedit.component.scss']
})
export class TeameditComponent implements OnInit {

  public team: any;
  public members: Array<any> = [];
  public action = ''; // new or edit
  public newMembers: Array<any> = [];
  public freePlan = Plan.Free;
  public categories: any;
  public isAdmin = this.usersService.isAdmin();
  public paymentMethod: any;
  public monthlyAllowancesMemberCheck = false;
  showFundMembersWarningBox = false;
  serviceFee = 0;
  showReimbursementsSpinner = false;
  showMonthlyAllowanceSpinner = false;
  proRateParam = false;
  @ViewChild('reimbursementsToggle') reimbursementsToggle!: MatSlideToggle;
  @ViewChild('monthlyAllowanceToggle') monthlyAllowanceToggle!: MatSlideToggle;
  @ViewChild('proRateFundingToggle') proRateToggle!: MatSlideToggle;

  constructor(
      private teamsService: TeamsService,
      private sessionService: SessionService,
      private stripeService: StripeService,
      private route: ActivatedRoute,
      private location: Location,
      private uiAlertService: UiAlertService,
      private router: Router,
      private snackBar: MatSnackBar,
      private spinnerService: SpinnerService,
      private usersService: UsersService,
      private feeService: FeeService
  ) { }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any): void => {
      this.team = wellspace || JSON.parse(localStorage.getItem('selected-wellspace') as string);
      if (this.team) {
        this.proRateParam = this.team?.proRate !== 0;
        this.getMembers();
      }
      if (this.reimbursementsToggle && this.monthlyAllowanceToggle) {
        this.resetAllToggles();
      }
    });
  }

  getMembers(): void {
    this.showFundMembersWarningBox = false;
    this.teamsService.getPaymentMethod(this.team).then(method => {
      this.teamsService.getPaymentMethods(this.team.id).then(methods => {
        this.paymentMethod = methods.find((thisMethod: { stripePaymentMethod: string; }) => thisMethod.stripePaymentMethod === method);
      });
    });
    this.teamsService.getTeamMembers(this.team.id, true).then(members => {
      this.newMembers = this.teamsService.buildMemberList(members, this.team);
      this.members = members;
      this.members.forEach(member => {
        if (member.userId === this.team.ownerUserId) {
          member.role = 'team admin';
        }
      });
      this.checkIfAllNewMembersCanReceiveAllowance();
      this.getSubscriptionDate();
      this.teamsService.getCategories().then(categories => {
        this.categories = categories.map(category => ({ taxable: category.taxable, toolTip: category.toolTip, category: category.name, selected: false }));
        this.categories = this.categories.filter((category: Category) => this.team.categories.find((teamCategory: any) => teamCategory === category.category));
      });
    }).catch(err => {
      console.error('Error in get team agency-members', err);
      this.members = [];
      this.newMembers = [];
      this.monthlyAllowancesMemberCheck = true;
      this.showFundMembersWarningBox = true;
    });
  }

  async fundAllowancesClicked(): Promise<void> {
    this.serviceFee = await this.feeService.getPaymentMethodFee(this.team);

    const chargeAmount = this.newMembers.length * this.team.amount;
    let premiumAmount = 0;
    let premiumMessage = '';
    switch (this.team.plan) {
      case Plan.Free:
        premiumAmount =  0;
        premiumMessage = '';
        break;
      case Plan.Plus:
        premiumAmount = this.stripeService.PREMIUM_MONTHLY_FEE * this.newMembers.length;
        premiumMessage = `A Plus Monthly plan recurring monthly charge of $${premiumAmount.toFixed(2)} will also be initiated and pro-rated for the initial period.`;
        break;
      case Plan.PlusAnnual:
        premiumAmount = this.stripeService.PREMIUM_ANNUAL_FEE * this.newMembers.length;
        premiumMessage = `A Plus Annual plan recurring annual charge of $${premiumAmount.toFixed(2)} will also be initiated and pro-rated for the initial period.`;
        break;
    }
    const paymentMethodDescription = ' to your default payment method';
    this.uiAlertService.presentAlertConfirm(
      `${this.newMembers.length} new member${this.newMembers.length === 1 ? '' : 's'}.<br><br>This will initiate a monthly allowance recurring ${this.team.invoiceEmail ? 'invoice' : 'charge'} of $${(chargeAmount * (1 + this.serviceFee)).toFixed(2)}${paymentMethodDescription} on the first day of each month.
      ${this.serviceFee > 0 ? `This includes a monthly $${(chargeAmount * this.serviceFee).toFixed(2)} service fee. For the first month, the amount will be pro-rated and charged immediately.` : ''} ${premiumMessage} Any available credit balance will be applied first before charging the remaining amount.`
      ).then(async confirm => {
      if (confirm) {
        this.spinnerService.show();
        this.teamsService.getCustomerId(this.team).then(async customerId => {
          this.stripeService.makePayment(customerId, `${this.team.name}: ${this.newMembers.length} new members`, this.team.amount, this.newMembers, await this.teamsService.getPaymentMethod(this.team), this.serviceFee, this.team.id, this.team.plan, this.team.id, this.proRateParam).then(_ => {
            this.hideSpinnerAndShowMessage('Payment initiated. Subscription will be activated when the payment completes. Bank payments may take a few days.');
            this.teamsService.setWellspace(this.team);
            setTimeout(() => {
              this.getMembers();
            }, 3000);
          }).catch(err => {
            console.error('error in makePayment', err);
            this.hideSpinnerAndShowMessage('An error has occurred!');
          });
        }).catch(err => {
          console.error('error in getCustomerId', err);
          this.hideSpinnerAndShowMessage('An error has occurred!');
        });
      }
    });
  }

  hideSpinnerAndShowMessage(message: string): void {
    this.spinnerService.hide();
    this.snackBar.openFromComponent(SnackBarComponent, {data: message});
  }

  sendEmailsClicked(): void {

  }

  editName(): void {
    this.uiAlertService.presentFieldInput('Change Wellspace Name', 'Wellspace Name', this.team.name).then(result => {
      if (result) {
        this.teamsService.modifyTeam(this.team.id, {name: result}).then(_ => {
          this.team.name = result;
          this.teamsService.setWellspace(this.team);
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Wellspace name changed'});
        });
      }
    });
  }

  editCategories(): void {
    this.router.navigate(['managewellspace/categories', this.team.id]);
  }

  editMembers(): void {
    this.router.navigate(['managewellspace/agency-members', this.team.id]);

  }

  deleteClicked(): void {
    const remainingAllowances = this.members.length === 0 ? 0 : this.members.map(member => parseFloat(member.balance)).reduce((accumulator, currentValue) => accumulator + currentValue);
    const info = remainingAllowances > 0 ? `This team's members have a combined remaining allowance of $${remainingAllowances.toFixed(2)} which will be refunded to your default payment method.  ` : '';
    this.uiAlertService.presentAlertConfirm(`${info}Do you really want to remove this wellspace${this.members.length === 0 ? '' : ' and all its team agency-members'}?`).then(confirm => {
      if (confirm) {
        if (remainingAllowances) {
          this.teamsService.getCustomerId(this.team).then(customerId => {
            this.stripeService.issueRefund(customerId, remainingAllowances);
          });
        }
        this.spinnerService.show('removing team...');
        this.teamsService.deleteTeam(this.team.id).then((response: any) => {
              this.snackBar.openFromComponent(SnackBarComponent, {data: 'Team removed'});
              localStorage.removeItem('selected-wellspace');
              this.router.navigate(['']).then(() => window.location.reload());
            }
        ).catch((error: any) => {
          this.spinnerService.hide();
          this.snackBar.openFromComponent(SnackBarComponent, {data: error});
        });
      }
    });
  }

  memberDeleted(member: any, dontDelete: boolean): void {
    if (member.balance > 0) {
      this.teamsService.getCustomerId(this.team).then(customerId => {
        this.stripeService.issueRefund(customerId, member.balance).then(_ => {
          if (dontDelete) {
            this.teamsService.modifyTeamMember(this.team.id, member.memberId, {balance: 0}).then(__ => {
              this.getMembers();
            });
          } else {
            this.getMembers();
          }
        });
      });
      this.snackBar.openFromComponent(SnackBarComponent, {data: `This team member has a remaining balance of $${member.balance.toFixed(2)} which will be refunded to your default payment method.`});
    } else {
      this.getMembers();
    }
  }

  addPaymentMethod(): void {
    this.router.navigate(['/billing']);
  }

  changePaymentMethod(): void {
    this.router.navigate(['/billing']);
  }

  memberClicked(member: any): void {
    // this.router.navigate(['team-member-detail', this.team.id, member.id]);
  }

  changePlan(): void {
    this.router.navigate(['/managewellspace/plan', this.team.id]);
  }

  private updateMembers(): void {
    this.members = JSON.parse(JSON.stringify(this.members));
  }

  sendOnetimeClicked(): void {
    this.uiAlertService.teamOneTimeReward(false).then(_ => {
      this.updateMembers();
    });
  }

  sendOnetimeP2pClicked(): void {
    this.uiAlertService.teamOneTimeReward(true).then(_ => {
      this.updateMembers();
    });
  }

  uploadRewardsClicked(): void {
    this.uiAlertService.uploadRewards(this.team.id).then(_ => {
      this.updateMembers();
    });
  }

  resetAllToggles(): void {
    this.reimbursementsToggle.disabled = false;
    this.monthlyAllowanceToggle.disabled = false;
    this.showReimbursementsSpinner = false;
    this.showMonthlyAllowanceSpinner = false;
  }

  enabledChanged(event: MatSlideToggleChange): void {
    const modalMsg = {
      enable: 'Enabling the allowance reimbursement feature will allow your team agency-members to approve and receive reimbursements for their purchase transactions. Are you sure you want to enable this feature?',
      disable: 'Disabling the allowance reimbursement feature means your team agency-members can no longer request or approve reimbursements for their eligible purchases. Are you sure you want to proceed?'
    };
    this.uiAlertService.presentAlertConfirm(event.checked ? modalMsg.enable : modalMsg.disable).then((confirm: boolean): void => {
      if (confirm) {
        this.reimbursementsToggle.disabled = true;
        this.showReimbursementsSpinner = true;
        this.teamsService.modifyTeam(this.team.id, {disabled: !event.checked}).then(_ => {
          this.teamsService.setWellspace(this.team);
        }).catch(err => {
          console.error('error in modifyTeam: ', err);
          this.resetAndEnableTeamToggle(this.reimbursementsToggle, event);
          this.resetAllToggles();
        });
      } else {
        this.resetAndEnableTeamToggle(this.reimbursementsToggle, event);
      }
    });
  }

  topupEnabledChanged(event: any): void {
    const modalMsg = {
      enable: 'Enabling the monthly allowance feature will fund your team\'s Allowance Balances, and your selected payment method will be charged the monthly allowance amount. Are you sure you want to enable the monthly allowance?',
      disable: 'Disabling the monthly allowance feature means you will no longer contribute funds to your team agency-members, and you will not be charged for the monthly allowance. Are you sure you want to disable it? '
    };
    this.uiAlertService.presentAlertConfirm(event.checked ? modalMsg.enable : modalMsg.disable).then((confirm: boolean): void => {
      if (confirm) {
        this.monthlyAllowanceToggle.disabled = true;
        this.showMonthlyAllowanceSpinner = true;
        this.teamsService.modifyTeam(this.team.id, {topupDisabled: !event.checked}).then(_ => {
          this.teamsService.setWellspace(this.team);
        }).catch(err => {
          console.error('error in modifyTeam: ', err);
          this.resetAndEnableTeamToggle(this.monthlyAllowanceToggle, event);
          this.resetAllToggles();
        });
      } else {
        this.resetAndEnableTeamToggle(this.monthlyAllowanceToggle, event);
      }
    });
  }

  private resetAndEnableTeamToggle(toggle: MatSlideToggle, event: any): void {
    toggle.checked = !event.checked;
    toggle.disabled = false;
  }

  privacyChanged(event: any): void {
    this.teamsService.modifyTeam(this.team.id, {transactionPrivacy: event.checked}).then(_ => {
      this.teamsService.setWellspace(undefined);
    });
  }

  checkIfAllNewMembersCanReceiveAllowance(): void {
    const newMembersArray = this.members.filter(member => !member.status);
    this.monthlyAllowancesMemberCheck = newMembersArray.every(member => member.canReceiveAllowance === 0);
  }

  canReceiveAllowancesMemberCheck(subscriptionPeriodStart: any): void {
    const dataBShort = this.formatDateShort(subscriptionPeriodStart);
    this.members.map(member => {
      if (!member.status && member.canReceiveAllowance === 0) {
        this.removeItemObjectFromIndex(this.newMembers, member.userEmail);
      }
      if (!member.status && member.billingDate && subscriptionPeriodStart) {
        const dataAShort = this.formatDateShort(member.billingDate);
        if (dataAShort >= dataBShort) {
          this.removeItemObjectFromIndex(this.newMembers, member.userEmail);
        }
      }
    });
    this.showFundMembersWarningBox = this.newMembers.length > 0;
  }

  formatDateShort(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 10);
  }

  getSubscriptionDate(): void {
    this.teamsService.getCustomerId(this.team).then((customerId: string): void => {
      if (customerId) {
        this.teamsService.getUserSubscriptionsDate(this.team.id, customerId).then((subscriptionsDate: any): void => {
          this.canReceiveAllowancesMemberCheck(subscriptionsDate?.periodStart);
        }).catch(err => {
          this.showFundMembersWarningBox = true;
          console.error('Error in get user subscriptions date', err);
        });
      }
    }).catch(err => {
      this.showFundMembersWarningBox = true;
      console.error('Error in get customer id', err);
    });
  }

  removeItemObjectFromIndex(array: any[], value: any): void {
    const index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  loadTeamMembers(): void {
    console.log('Loading team members');
    this.teamsService.getTeamMembers(this.team.id, true).then(members => {
      console.log(members);
      this.newMembers = this.teamsService.buildMemberList(members, this.team);
      this.members = members;
    });
  }

  proRateToggleChange(event: MatSlideToggleChange): void {
    const modalMsg = {
      enable: 'Enable start funding at beginning of next month?',
      disable: 'Disable start funding at beginning of next month?'
    };
    this.uiAlertService.presentAlertConfirm(event.checked ? modalMsg.enable : modalMsg.disable).then((confirm: boolean): void => {
      if (confirm) {
        this.proRateParam = !event.checked;
      } else {
        this.proRateToggle.checked = !event.checked;
      }
    });
  }
 }
