import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss'],
})
export class FileuploadComponent implements OnInit {

  @Input() requiredFileType = '';
  @Output() doneEvent: EventEmitter<any> = new EventEmitter<any>();

  fileName = '';

  constructor(
  ) {}

  ngOnInit(): void {}

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        this.doneEvent.emit(reader.result);
      };
      reader.readAsArrayBuffer(file);
    }
  }

}
