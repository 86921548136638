<div>
    <form [formGroup]="form">
            <div class="addemail">
                <input class="emailinput" clearInput #email type="email" email="true" inputmode="email" formControlName="email" placeholder="Type an email address to invite">
                <button mat-raised-button color="primary" [disabled]="!form.valid || uploading" class="addbutton" (click)="addMember()">Add</button>
            </div>
    </form>
    <mat-divider></mat-divider>
    <div>
        <span class="info">The file you upload must be in plain text CSV format have one email address per line.</span>
        <app-fileupload class="uploadarea" (doneEvent)="uploadMembers($event)"></app-fileupload>
        <div class="error infosection" *ngIf="result && result.newCount === 0 && result.invalidEmail?.length > 0 && result.alreadyMember?.length === 0">
            <span class="infosection">Invalid File Format</span>
            <span><br><br>You can only upload a plain text file that has a single email address per line. For example, Excel spreadsheets and Word documents will not work.</span>
        </div>
        <div class="section" *ngIf="result && result.invalidEmail && (result.alreadyMember?.length > 0 || result.newCount > 0)">
            <span class="infosection">Invalid Email Address</span>
            <span *ngFor="let info of result.invalidEmail">Line {{info.line}}: {{info.email}}</span>
        </div>
        <div class="section" *ngIf="result && result.alreadyMember?.length > 0">
            <span class="infosection">Already a Member</span>
            <span *ngFor="let info of result.alreadyMember">Line {{info.line}}: {{info.email}}</span>
        </div>
    <div>
    <mat-divider></mat-divider>
    <div class="list">
        <div class="exportbuttons">
            <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: currentWellspace.name})"><mat-icon>file_download</mat-icon>Export to CSV</button>
            <!--
            <button class="exportbutton" mat-raised-button color="accent" (click)="exportCensus()"><mat-icon>file_download</mat-icon>Export Census</button>
            -->
        </div>
        <mat-table class="hiddentable" matTableExporter [dataSource]="members" #exporter="matTableExporter">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let item">{{(item.firstName || '') + ' ' + (item.lastName || '')}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let item">{{item.email}}</td>
            </ng-container>
            <ng-container matColumnDef="wallitbalance">
                <th mat-header-cell *matHeaderCellDef>Wallit Balance</th>
                <td mat-cell *matCellDef="let item">{{item.wallitBalance | currency}}</td>
            </ng-container>
            <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef>Allowance Balance</th>
                <td mat-cell *matCellDef="let item">{{item.balance | currency}}</td>
            </ng-container>
            <ng-container matColumnDef="p2pbalance">
                <th mat-header-cell *matHeaderCellDef>P2P Balance</th>
                <td mat-cell *matCellDef="let item">{{item.p2pBalance | currency}}</td>
            </ng-container>
            <ng-container matColumnDef="employmentDate">
                <th mat-header-cell *matHeaderCellDef>Employment Date</th>
                <td mat-cell *matCellDef="let item">{{item.employmentDate | date: 'mediumDate'}}</td>
            </ng-container>
            <ng-container matColumnDef="memberCreatedAt">
                <th mat-header-cell *matHeaderCellDef>Created</th>
                <td mat-cell *matCellDef="let item">{{item.memberCreatedAt | date: 'mediumDate'}}</td>
            </ng-container>
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let item">{{item.role}}</td>
            </ng-container>
            <ng-container matColumnDef="canReceiveAllowance">
                <th mat-header-cell *matHeaderCellDef>Allowance</th>
                <td mat-cell *matCellDef="let item">{{item.canReceiveAllowance === 1 ? 'Enabled' : 'Disabled'}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['name','email','wallitbalance','balance','p2pbalance','employmentDate','memberCreatedAt','role','canReceiveAllowance']"></tr>
            <tr mat-row *matRowDef="let rows; columns: ['name','email','wallitbalance','balance','p2pbalance','employmentDate','memberCreatedAt','role','canReceiveAllowance']"></tr>
        </mat-table>
        <div class="card" *ngFor="let member of members">
            <app-user-item (memberChangedEvent)="loadTeamMembers()" class="useritem"
                           [showHeader]="member === members[0]" [team]="currentWellspace" [wallitBalance]="isAdmin"
                           [member]="member" [detail]="true"></app-user-item>
        </div>
    </div>
</div>
