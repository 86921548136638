<mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex">
    <mat-tab *ngFor="let beneficiary of beneficiaries">
        <ng-template mat-tab-label>
            <span (click)="onClick(beneficiary)">{{beneficiary.firstName || 'No Name'}}</span>
        </ng-template>
        <form *ngIf="beneficiary.firstName !== addTabName" class="form" [formGroup]="beneficiary.form">
            <mat-form-field>
                <mat-label>Relationship</mat-label>
                <mat-select formControlName="relationship">
                    <mat-option value="Spouse">Spouse</mat-option>
                    <mat-option value="Child">Child</mat-option>
                    <mat-option value="Other">Other</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Type</mat-label>
                <mat-select formControlName="beneficiaryType">
                    <mat-option value="Primary">Primary</mat-option>
                    <mat-option value="Contingent">Contingent</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field hintLabel="Max 50 characters">
                <mat-label>First Name</mat-label>
                <input matInput #input maxlength="50" placeholder="" formControlName="firstName">
                <mat-hint align="end">{{input.value.length || 0}}/50</mat-hint>
            </mat-form-field>
            <mat-form-field hintLabel="Max 50 characters">
                <mat-label>Last Name</mat-label>
                <input matInput #input2 maxlength="50" placeholder="" formControlName="lastName">
                <mat-hint align="end">{{input2.value.length || 0}}/50</mat-hint>
            </mat-form-field>
            <mat-form-field>
                <mat-label>SSN</mat-label>
                <input matInput placeholder="" formControlName="ssn">
            </mat-form-field>
        </form>
        <div *ngIf="beneficiary.firstName !== addTabName" class="buttons">
            <button mat-raised-button color="primary" class="primarybutton" shape="" [disabled]="!beneficiary.form.valid || !beneficiary.form.dirty" (click)="saveClicked(beneficiary)">Save</button>
            <button mat-raised-button color="primary" class="primarybutton" shape="" [disabled]="!beneficiary.form.dirty" (click)="resetClicked(beneficiary)">Reset</button>
            <button mat-raised-button color="primary" class="primarybutton" shape="" (click)="removeClicked(beneficiary)">Remove</button>
        </div>
    </mat-tab>
</mat-tab-group>
