import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-inlinemessage',
  templateUrl: './inlinemessage.component.html',
  styleUrls: ['./inlinemessage.component.scss']
})
export class InlinemessageComponent {

  @Input() level = 'info';
  @Input() message = '';

}
