import {Inject, Injectable} from '@angular/core';
import {LocalStorageService} from '../localstorage/localstorage.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {Restangular} from 'ngx-restangular';
import {RESTANGULAR_INTEGRATION_SERVER} from '../../app.module';
import {AppSettings} from '../../app.settings';
import {debounceTime} from 'rxjs/operators';

interface Dictionary<T> {
  [Key: string]: T;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  static spinnerObservable = new BehaviorSubject(0);
  static spinnerLevel = 0;

  public apiName = '';

  private API_ENDPOINT = '';
  private loginChangedObservable = new BehaviorSubject(false);
  private headers: HttpHeaders;
  private isIntegrationAdmin = AppSettings.isIntegration();

  constructor(
      private localStorageService: LocalStorageService,
      private http: HttpClient,
      private restangular: Restangular,
      @Inject(RESTANGULAR_INTEGRATION_SERVER) private integrationRestangular: Restangular
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('content-type',
        'application/json; charset=utf-8');
  }

  static spinnerIncrement(): void {
    this.spinnerObservable.next(++this.spinnerLevel);
  }

  static spinnerDecrement(): void {
    if (this.spinnerLevel > 0) {
      this.spinnerObservable.next(--this.spinnerLevel);
    }
  }

  spinnerSubscribe(func: (arg0: any) => void): void {
    function syncFunc(val: any): void { setTimeout(() => {
      func(val);
    });
    }
    ApiService.spinnerObservable.pipe(debounceTime(300)).subscribe(syncFunc);
  }

  setBaseUrl(url: string, api: string): void {
    this.API_ENDPOINT = url;
    this.apiName = api;
  }

  isAuthenticated(): boolean {
    if (!this.headers.get('Authorization')) {
      const token = this.localStorageService.getStorage('JWTToken');
      if (token) {
        this.setToken(token);
      }
    }
    return this.headers.get('Authorization') ? true : false;
  }

  monitorLoginState(func: (value: boolean) => void): void {
    this.loginChangedObservable.subscribe(value => func(value));
    func(this.isAuthenticated());
  }

  public setToken(token: string): void {
    this.unsetToken();
    this.addHeader('Authorization', 'Bearer ' + token);
    if (this.isIntegrationAdmin) {
      // @ts-ignore
      this.integrationRestangular.setDefaultHeaders({Authorization: 'Bearer ' + token});
    } else {
      this.restangular.provider.setDefaultHeaders({Authorization: 'Bearer ' + token});
    }
    this.loginChangedObservable.next(true);
  }

  public unsetToken(): void {
    this.removeHeader('Authorization');
    this.restangular.provider.setDefaultHeaders({});

    this.loginChangedObservable.next(false);

  }

  private addHeader(key: string, value: any): void {
    this.headers = this.headers.set(key, value);
  }

  private removeHeader(key: string): void {
    this.headers = this.headers.delete(key);
  }

  public get(apiPath: string): Promise<any> {
    return this.http.get(this.API_ENDPOINT + apiPath, {headers: this.headers}).toPromise();
  }

  public post(apiPath: string, data: Dictionary<any>): Promise<any> {
    return this.http.post(this.API_ENDPOINT + apiPath, data, {headers: this.headers, withCredentials: true}).toPromise();
  }

  public put(apiPath: string, data: Dictionary<any>): Promise<any>  {
    return this.http.put(this.API_ENDPOINT + apiPath, data, {headers: this.headers}).toPromise();
  }

  public patch(apiPath: string, data: Dictionary<any>): Promise<any>  {
    return this.http.patch(this.API_ENDPOINT + apiPath, data, {headers: this.headers}).toPromise();
  }

  public delete(apiPath: string): Promise<any>  {
    return this.http.delete(this.API_ENDPOINT + apiPath, {headers: this.headers}).toPromise();
  }

}
