import {Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './imagedialog.component.html',
  styleUrls: ['./imagedialog.component.scss']
})
export class ImageDialogComponent {

  @ViewChild('message') messageParagraph: ElementRef | undefined;

  title: string;
  imageUrl: string;

  constructor(
      public dialogRef: MatDialogRef<ImageDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ImageDialogModel
  ) {
    this.title = data.title;
    this.imageUrl = data.imageUrl;
  }

  onConfirm(): void {
    this.dialogRef.close();
  }

}

export class ImageDialogModel {
  constructor(public title: string, public imageUrl: string) {
  }

}
