<h1 mat-dialog-title>
  Set Team Transaction Privacy
</h1>
<div mat-dialog-content>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
            <mat-form-field>
                <mat-slide-toggle [checked]="form.get('privacy').value" (change)="privacyChanged($event)">Transaction Privacy</mat-slide-toggle>
                <input matInput hidden>
            </mat-form-field>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="form && !form.valid" class="donebutton" color="primary" (click)="setPrivacy()">OK</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
