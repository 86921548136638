import { BrowserModule } from '@angular/platform-browser';
import {InjectionToken, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Angular Material Components
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {CdkScrollableModule} from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkStepperModule} from '@angular/cdk/stepper';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { HomeComponent } from './components/home/home.component';
import { ManageMembersComponent } from './components/managemembers/managemembers.component';
import { InvitationsComponent } from './components/invitations/invitations.component';
import { AdManagerComponent } from './components/admanager/admanager.component';
import { ProfileComponent } from './components/profile/edit-profile.page';
import { ManageWellspaceComponent } from './components/managewellspace/managewellspace.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { SettingsAndPermissionsComponent } from './components/settingsandpermissions/settingsandpermissions.component';
import { LoginComponent } from './components/login/login.component';
import { TeampaymentsComponent } from './components/teampayments/teampayments.component';
import { TeameditComponent } from './components/teamedit/teamedit.component';
import {ConfirmDialogComponent} from './components/modal/confirmdialog/confirmdialog.component';
import {UserItemComponent} from './components/user-item/user-item.component';
import {Restangular, RestangularModule} from 'ngx-restangular';
import {Router} from '@angular/router';
import {AppSettings} from './app.settings';
import {TaxableFilterPipe, TeamSetBenefitsPageComponent} from './components/team-set-benefits/team-set-benefits.page';
import {TeamAddMembersPageComponent} from './components/team-add-members/team-add-members.page';
import {StripecreditcardPageComponent} from './components/stripe-creditcard/stripecreditcard.page';
import {FieldInputDialogComponent} from './components/modal/fieldinputdialog/fieldinputdialog.component';
import { PlanComponent } from './components/plan/plan.page';
import {TeamOneTimeRewardComponent} from './components/modal/onetimerewarddialog/team-one-time-reward.page';
import {MemberRewardDialogComponent} from './components/modal/memberrewarddialog/memberrewarddialog.component';
import {TeamMemberSelectorComponent} from './components/team-member-selector/team-member-selector.component';
import {EnterPinDialogComponent} from './components/modal/enterpindialog/enterpindialog.component';

import { AnalyticsOverviewComponent } from './components/analytics/analytics-overview/analytics-overview.component';
import { AnalyticsCategoriesComponent } from './components/analytics/analytics-categories/analytics-categories.component';
import { AnalyticsMembersComponent } from './components/analytics/analytics-members/analytics-members.component';
import {FileuploadComponent} from './components/fileupload/fileupload.component';
import {MatTableExporterModule} from 'mat-table-exporter';
import { BillingComponent } from './components/billing/billing.component';
import { StripeChargehistoryComponent } from './components/stripe-chargehistory/stripe-chargehistory.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {SendFundsComponent} from './components/modal/sendfundsdialog/send-funds-dialog.page';
import {LoginMemberComponent} from './components/modal/loginmemberdialog/login-member.page';
import {ProgramComponent} from './components/program/program.page';
import { EditProgramDialogComponent } from './components/modal/editprogramdialog/editprogramdialog.component';
import {EditDateDialogComponent} from './components/modal/editdatedialog/editdatedialog.component';
import {
  AnalyticsReimbursementsComponent, GetSenderNameReimbursements,
  GetUserNameReimbursements
} from './components/analytics/analytics-reimbursements/analytics-reimbursements.component';
import {AnalyticsApproveComponent, GetStatePipe} from './components/analytics/analytics-approve/analytics-approve.component';
import {PurchasesComponent} from './components/purchases/purchases.component';
import {UploadRewardsComponent} from './components/modal/uploadrewardsdialog/uploadrewardsdialog.page';
import { IntroDialogComponent } from './components/modal/introdialog/introdialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SnackBarComponent} from './components/snackbar/snackbar.component';
import { AnalyticsConfigureComponent } from './components/analytics/analytics-configure/analytics-configure.component';
import {EditApproverDialogComponent} from './components/modal/editapproverdialog/editapproverdialog.component';
import {ImageDialogComponent} from './components/modal/imagedialog/imagedialog.component';
import { TeamCompaniesComponent } from './components/team-companies/team-companies.component';
import { EditCompanyDialogComponent } from './components/modal/editcompanydialog/editcompanydialog.component';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import { SetTeamCompanyDialogComponent } from './components/modal/setteamcompanydialog/setteamcompanydialog.component';
import { WebdatarocksPivotModule } from 'ng-webdatarocks';
import {AnalyticsToapproveComponent, GetStatusPipe} from './components/analytics/analytics-toapprove/analytics-toapprove.component';
import { IntegrationHomeComponent } from './components/integrationhome/integrationhome.component';
import { InsuranceUsersComponent } from './components/insurance-users/insurance-users.component';
import { InsuranceCarriersComponent } from './components/insurance-carriers/insurance-carriers.component';
import { InsuranceBrokersComponent } from './components/insurance-brokers/insurance-brokers.component';
import { InsuranceEmployersComponent } from './components/insurance-employers/insurance-employers.component';
import { AdvertisingAdvertisersComponent } from './components/advertising-advertisers/advertising-advertisers.component';
import { AdvertisingAdsComponent } from './components/advertising-ads/advertising-ads.component';
import { AdvertisingCreativesComponent } from './components/advertising-creatives/advertising-creatives.component';
import { AdvertisingFlightsComponent } from './components/advertising-flights/advertising-flights.component';
import { AdvertisingImpressionsComponent } from './components/advertising-impressions/advertising-impressions.component';
import { AdvertisingCampaignsComponent } from './components/advertising-campaigns/advertising-campaigns.component';
import { TableComponent } from './components/metaview/table/table.component';
import {AddEditDialogComponent, SnakeCasePipe} from './components/metaview/addedit/addedit.component';
import { AddEditFieldComponent } from './components/metaview/addedit/addeditfield/addeditfield.component';
import {SetTeamPrivacyDialogComponent} from './components/modal/setteamprivacydialog/setteamprivacydialog.component';
import { MediaImagesComponent } from './components/media-images/media-images.component';
import { MediaDocumentsComponent } from './components/media-documents/media-documents.component';
import { MediaContentComponent } from './components/media-content/media-content.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
// import {TooltipModule} from 'ng2-tooltip-directive';
// import {NgxLiquidCacheModule} from 'ngx-liquid-cache';
import { PaginatorComponent } from './components/paginator/paginator.component';
import {ApiService} from './services/api/api.service';
import {ColorPickerModule} from 'ngx-color-picker';
import { AdminActionsComponent } from './components/admin-actions/admin-actions.component';
import { TagsComponent } from './components/tags/tags.component';
import { ProductsCompaniesComponent } from './components/products-companies/products-companies.component';
import { ProductsPurchasesComponent } from './components/products-purchases/products-purchases.component';
import { AnalyticsAllowancesComponent } from './components/analytics/analytics-allowances/analytics-allowances.component';
import { ReconciliationComponent } from './components/reconciliation/reconciliation.component';
import { ReconciliationStripeComponent } from './components/reconciliation-stripe/reconciliation-stripe.component';
import { ReconciliationTeamlogComponent } from './components/reconciliation-teamlog/reconciliation-teamlog.component';
import { ReconciliationApilogComponent } from './components/reconciliation-apilog/reconciliation-apilog.component';
import { SetInvoicingDialogComponent } from './components/modal/setinvoicingdialog/setinvoicingdialog.component';
import { StripePaymentMethodsComponent } from './components/stripepaymentmethods/stripepaymentmethods.component';
import { BrokeradminComponent } from './components/externalbrokeradmin/brokeradmin.component';
import { EditCompanyManagersDialogComponent } from './components/modal/editcompanymanagersdialog/editcompanymanagersdialog.component';
import {MatTreeModule} from '@angular/material/tree';
import { ProductsPermissionsComponent } from './components/products-permissions/products-permissions.component';
import { InsuranceProfileComponent } from './components/insuranceprofile/insuranceprofile.component';
import { InsurancePrimaryComponent } from './components/insurance-primary/insurance-primary.component';
import { InsuranceDependentsComponent } from './components/insurance-dependents/insurance-dependents.component';
import { HighchartsContainerComponent } from './components/analytics/common/highcharts-container/highcharts-container.component';
import { AnalyticsTeamOverviewComponent } from './components/analytics/analytics-team-overview/analytics-team-overview.component';
import { MembersOverviewComponent } from './components/analytics/common/members-overview/members-overview.component';
import { EmailCodeComponent } from './components/email-code/email-code.component';
import { CodeInputModule } from 'angular-code-input';
import {
  StripeAvailablePaymentMethodsComponent
} from './components/stripe-available-payment-methods/stripe-available-payment-methods.component';
import { AllowancesHighchartsContainerComponent } from './components/analytics/common/allowances-highcharts-container/allowances-highcharts-container.component';
import { AnalyticsTeamAllowancesComponent } from './components/analytics/analytics-team-allowances/analytics-team-allowances.component';
import {RefundAllowanceDialogComponent} from './components/modal/refundallowancedialog/refundallowancedialog.component';
import {InsuranceBeneficiariesComponent} from './components/insurance-beneficiaries/insurance-beneficiaries.component';
import { ManageusersComponent } from './components/customersupport/manageusers/manageusers.component';
import { ManagesupportagentsComponent } from './components/customersupport/managesupportagents/managesupportagents.component';
import { AdduserdialogComponent } from './components/modal/adduserdialog/adduserdialog.component';
import {NgIdleModule} from '@ng-idle/core';
import {NgxMatIntlTelInputComponent} from 'ngx-mat-intl-tel-input';
import { LoginlistdialogComponent } from './components/modal/loginlistdialog/loginlistdialog.component';
import { CustomerLinkModalComponent } from './components/modal/customer-link-modal/customer-link-modal.component';
import { NewClientDialogComponent } from './components/modal/new-client-dialog/new-client-dialog.component';
import { AnalyticsPeerToPeerComponent } from './components/analytics/analytics-peer-to-peer/analytics-peer-to-peer.component';
import { MovieDialogComponent } from './components/modal/moviedialog/moviedialog.component';
import {MatBasicSpinnerDirective} from './directives/mat-basic-spinner.directive';
import { ReportsComponent } from './components/customersupport/reports/reports.component';
import { BrokerComponent } from './components/brokeragency/broker/broker.component';
import { AgencyComponent } from './components/brokeragency/agency/agency.component';
import { ClientsComponent } from './components/brokeragency/clients/clients.component';
import { MembersComponent } from './components/brokeragency/agency-members/members.component';
import { ProducersComponent } from './components/brokeragency/agency-producers/producers.component';
import { ClientCensusComponent } from './components/brokeragency/client-census/client-census.component';
import { ClientEligibilityComponent } from './components/brokeragency/client-eligibility/client-eligibility.component';
import { ClientEnrollmentComponent } from './components/brokeragency/client-enrollment/client-enrollment.component';
import { AgencyProfileComponent } from './components/brokeragency/agency-profile/agency-profile.component';
import { BrokerProfileComponent } from './components/brokeragency/broker-profile/broker-profile.component';
import { BrokerAgenciesComponent } from './components/brokeragency/broker-agencies/broker-agencies.component';
import { MemberManagerComponent } from './components/brokeragency/member-manager/member-manager.component';
import { ImageuploadComponent } from './components/imageupload/imageupload/imageupload.component';
import { BrokerProductsComponent } from './components/brokeragency/broker-products/broker-products.component';
import { MemberAddeditComponent } from './components/brokeragency/member-addedit/member-addedit.component';
import { CompanyProductsDialogComponent } from './components/modal/companyproductsdialog/companyproductsdialog.component';
import { AgencyClientsComponent } from './components/brokeragency/agency-clients/agency-clients.component';
import { InlinemessageComponent } from './components/inlinemessage/inlinemessage.component';
import { ProductDetailsComponent } from './components/brokeragency/product-details/product-details.component';
import { ClientsDialogComponent } from './components/modal/clientsdialog/clientsdialog.component';
import { InfobandComponent } from './components/infoband/infoband.component';
import { MemberEnrollComponent } from './components/brokeragency/member-enroll/member-enroll.component';
import { StateComponent } from './components/form-fields/state/state.component';
import { VendorGroupsComponent } from './components/brokeragency/vendor-groups/vendor-groups.component';
import { VendorGroupsRatesComponent } from './components/brokeragency/vendor-groups-rates/vendor-groups-rates.component';
import { VendorGroupsProductsComponent } from './components/brokeragency/vendor-groups-products/vendor-groups-products.component';
import { VendorGroupsPlansComponent } from './components/brokeragency/vendor-groups-plans/vendor-groups-plans.component';
import { JsonComponent } from './components/form-fields/json/json.component';
import {
  ChangePartnerDialogComponent,
} from './components/modal/changepartnerdialog/changepartnerdialog.component';
import { GetUserDialogComponent } from './components/modal/getuserdialog/getuserdialog.component';
import { ProvisioningComponent } from './components/brokeragency/provisioning/provisioning.component';
import { ProvisioningTileComponent } from './components/brokeragency/provisioning-tile/provisioning-tile.component';
import { AnalyticsPaymentReportComponent } from './components/analytics/reports/analytics-payment-report/analytics-payment-report.component';
import { AnalyticsAllowanceAllocationReportComponent } from './components/analytics/reports/analytics-allowance-allocation-report/analytics-allowance-allocation-report.component';
import { AnalyticsSpendingReportComponent } from './components/analytics/reports/analytics-spending-report/analytics-spending-report.component';
import { AnalyticsRewardsReportComponent } from './components/analytics/reports/analytics-rewards-report/analytics-rewards-report.component';
import { AnalyticsAllocationReportComponent } from './components/analytics/reports/analytics-allocation-report/analytics-allocation-report.component';
import { VendorGroupsQuestionsComponent } from './components/brokeragency/vendor-groups-questions/vendor-groups-questions.component';
import { StripeChargehistoryTableComponent } from './components/stripe-chargehistory/stripe-chargehistory-table/stripe-chargehistory-table.component';

function RestangularConfigFactory(RestangularProvider: any, router: any): void {

  RestangularProvider.setBaseUrl(AppSettings.getEndpoint());
  RestangularProvider.addResponseInterceptor((data: any, operation: any, what: any, url: any, response: any) => {
    ApiService.spinnerDecrement();
    return data;
  });

  RestangularProvider.setRequestInterceptor((elem: any, operation: any) => {
    ApiService.spinnerIncrement();
    return elem;
  });

  RestangularProvider.addErrorInterceptor(()  => {
    ApiService.spinnerDecrement();
  });

}

export const RESTANGULAR_INTEGRATION_SERVER = new InjectionToken<any>('RestangularIntegration');

export const RestangularIntegrationFactory = (restangular: Restangular) => restangular.withConfig((RestangularConfigurer: any) => {
  RestangularConfigurer.setBaseUrl(AppSettings.getIntegrationEndpoint());
  RestangularConfigurer.addResponseInterceptor((data: any, operation: any, what: any, url: any, response: any) => {
    ApiService.spinnerDecrement();
    return data;
  });
  RestangularConfigurer.setRequestInterceptor((elem: any, operation: any) => {
    ApiService.spinnerIncrement();
    return elem;
  });
  RestangularConfigurer.addErrorInterceptor((response: any, subject: any, responseHandler: any) => {
    ApiService.spinnerDecrement();
  });
});

const allModules = [
  MatToolbarModule,
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatSelectModule,
  MatCheckboxModule,
  MatIconModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatChipsModule,
  MatSidenavModule,
  MatListModule,
  MatProgressBarModule,
  MatBottomSheetModule,
  MatBadgeModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatGridListModule,
  MatNativeDateModule,
  MatRippleModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatMenuModule,
  MatPaginatorModule,
  MatDialogModule,
  MatTabsModule,
  MatTableModule,
  MatSortModule,
  DragDropModule,
  MatSliderModule,
  MatRadioModule,
  MatStepperModule,
  MatSlideToggleModule,
  CdkStepperModule,
  CdkScrollableModule,
  HttpClientModule,
  BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  ReactiveFormsModule,
  FormsModule,
  CommonModule,
  MatTableExporterModule,
  NgxMatSelectSearchModule,
  MatSnackBarModule,
  NgJsonEditorModule,
  WebdatarocksPivotModule,
  NgxDropzoneModule,
  // TooltipModule,
  ColorPickerModule,
  MatBasicSpinnerDirective,
];

@NgModule({
  declarations: [
    GetStatePipe,
    GetStatusPipe,
    GetUserNameReimbursements,
    GetSenderNameReimbursements,
    TaxableFilterPipe,
    SnakeCasePipe,
    AppComponent,
    AnalyticsComponent,
    HomeComponent,
    ManageMembersComponent,
    InvitationsComponent,
    AdManagerComponent,
    ProfileComponent,
    ManageWellspaceComponent,
    CategoriesComponent,
    SettingsAndPermissionsComponent,
    LoginComponent,
    TeampaymentsComponent,
    TeameditComponent,
    ConfirmDialogComponent,
    UserItemComponent,
    TeamSetBenefitsPageComponent,
    TeamAddMembersPageComponent,
    StripecreditcardPageComponent,
    FieldInputDialogComponent,
    PlanComponent,
    ProgramComponent,
    TeamOneTimeRewardComponent,
    MemberRewardDialogComponent,
    RefundAllowanceDialogComponent,
    TeamMemberSelectorComponent,
    EnterPinDialogComponent,
    AnalyticsOverviewComponent,
    AnalyticsCategoriesComponent,
    AnalyticsMembersComponent,
    AnalyticsToapproveComponent,
    FileuploadComponent,
    BillingComponent,
    StripeChargehistoryComponent,
    SendFundsComponent,
    UploadRewardsComponent,
    LoginMemberComponent,
    EditProgramDialogComponent,
    EditDateDialogComponent,
    AnalyticsReimbursementsComponent,
    AnalyticsApproveComponent,
    PurchasesComponent,
    IntroDialogComponent,
    SnackBarComponent,
    AnalyticsConfigureComponent,
    EditApproverDialogComponent,
    ChangePartnerDialogComponent,
    ImageDialogComponent,
    TeamCompaniesComponent,
    EditCompanyDialogComponent,
    SetTeamCompanyDialogComponent,
    SetTeamPrivacyDialogComponent,
    IntegrationHomeComponent,
    InsuranceUsersComponent,
    InsuranceCarriersComponent,
    InsuranceBrokersComponent,
    InsuranceEmployersComponent,
    AdvertisingAdvertisersComponent,
    AdvertisingAdsComponent,
    AdvertisingCreativesComponent,
    AdvertisingFlightsComponent,
    AdvertisingImpressionsComponent,
    AdvertisingCampaignsComponent,
    TableComponent,
    AddEditDialogComponent,
    AddEditFieldComponent,
    MediaImagesComponent,
    MediaDocumentsComponent,
    MediaContentComponent,
    PaginatorComponent,
    AdminActionsComponent,
    TagsComponent,
    ProductsCompaniesComponent,
    ProductsPurchasesComponent,
    AnalyticsAllowancesComponent,
    ReconciliationComponent,
    ReconciliationStripeComponent,
    ReconciliationTeamlogComponent,
    ReconciliationApilogComponent,
    SetInvoicingDialogComponent,
    StripePaymentMethodsComponent,
    BrokeradminComponent,
    EditCompanyManagersDialogComponent,
    ProductsPermissionsComponent,
    InsuranceProfileComponent,
    InsurancePrimaryComponent,
    InsuranceDependentsComponent,
    InsuranceBeneficiariesComponent,
    HighchartsContainerComponent,
    AnalyticsTeamOverviewComponent,
    MembersOverviewComponent,
    EmailCodeComponent,
    StripeAvailablePaymentMethodsComponent,
    AllowancesHighchartsContainerComponent,
    AnalyticsTeamAllowancesComponent,
    ManageusersComponent,
    ManagesupportagentsComponent,
    AdduserdialogComponent,
    LoginlistdialogComponent,
    CustomerLinkModalComponent,
    NewClientDialogComponent,
    AnalyticsPeerToPeerComponent,
    MovieDialogComponent,
    ReportsComponent,
    BrokerComponent,
    AgencyComponent,
    ClientsComponent,
    MembersComponent,
    ProducersComponent,
    ClientCensusComponent,
    ClientEligibilityComponent,
    ClientEnrollmentComponent,
    AgencyProfileComponent,
    BrokerProfileComponent,
    BrokerAgenciesComponent,
    MemberManagerComponent,
    ImageuploadComponent,
    BrokerProductsComponent,
    MemberAddeditComponent,
    CompanyProductsDialogComponent,
    AgencyClientsComponent,
    InlinemessageComponent,
    ProductDetailsComponent,
    ClientsDialogComponent,
    InfobandComponent,
    MemberEnrollComponent,
    StateComponent,
    VendorGroupsComponent,
    VendorGroupsRatesComponent,
    VendorGroupsProductsComponent,
    VendorGroupsPlansComponent,
    JsonComponent,
    GetUserDialogComponent,
    ProvisioningComponent,
    ProvisioningTileComponent,
    AnalyticsPaymentReportComponent,
    AnalyticsAllowanceAllocationReportComponent,
    AnalyticsSpendingReportComponent,
    AnalyticsRewardsReportComponent,
    AnalyticsAllocationReportComponent,
    VendorGroupsQuestionsComponent,
    StripeChargehistoryTableComponent,
  ],
    imports: [...allModules,
      RestangularModule.forRoot([Router], RestangularConfigFactory),
      // NgxLiquidCacheModule.forRoot({duration: 300}),
      MatTreeModule,
      NgxMatIntlTelInputComponent,
      CodeInputModule,
      NgIdleModule.forRoot()
    ],
  providers: [
    { provide: RESTANGULAR_INTEGRATION_SERVER, useFactory:  RestangularIntegrationFactory, deps: [Restangular] }
  ],
  exports: allModules,
  bootstrap: [AppComponent]
})
export class AppModule { }
