<form [formGroup]="form" class="form">
    <mat-form-field *ngIf="items && items.length > 1">
        <mat-label>Select your payment method</mat-label>
        <mat-select formControlName="method">
            <mat-option *ngFor="let item of items" [value]="item">{{item.bankInfo?.bankName || '---'}} ****{{item.stripeLast4}}</mat-option>
        </mat-select>
    </mat-form-field>
</form>
<span class="mandatetext" *ngIf="invoices && invoices.length === 0">No charges have been made yet to this payment method.</span>
<span class="mandatetext" *ngIf="!invoices">Please select a payment method.</span>
<span class="title" *ngIf="invoicesWithoutPaymentMethod && invoicesWithoutPaymentMethod.length > 0">Invoices without a payment method</span>
<div *ngIf="invoicesWithoutPaymentMethod && invoicesWithoutPaymentMethod.length > 0">
    <app-stripe-chargehistory-table [invoices]="invoicesWithoutPaymentMethod"></app-stripe-chargehistory-table>
</div>
<span class="title" *ngIf="invoices && invoices.length > 0">Invoices with a payment method</span>
<div *ngIf="invoices && invoices.length > 0">
    <app-stripe-chargehistory-table [invoices]="invoices"></app-stripe-chargehistory-table>
</div>
