import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-advertising-advertisers',
  templateUrl: './advertising-advertisers.component.html',
  styleUrls: ['./advertising-advertisers.component.scss']
})
export class AdvertisingAdvertisersComponent implements OnInit {

  public config: TableConfig = {
    title: 'Advertisers',
    dbTableName: 'bannerproviders',
    expand: ['company_id'],
    columns: [
      {name: 'name', title: 'Advertiser Name'},
      {name: 'company_id.name', title: 'Parent Company'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
