import {Component, OnInit, Inject, ElementRef, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-enter-pin-dialog',
  templateUrl: './enterpindialog.component.html',
  styleUrls: ['./enterpindialog.component.scss']
})
export class EnterPinDialogComponent {

  phone: string;

  public form: UntypedFormGroup;

  constructor(
      private formBuilder: UntypedFormBuilder,
      public dialogRef: MatDialogRef<EnterPinDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: EnterPinDialogModel
  ) {
    this.phone = data.phone;
    this.form = this.formBuilder.group({
          input: new UntypedFormControl('', [Validators.required]),
        }
    );
  }

  onConfirm(): void {
    this.dialogRef.close(this.form.get('input')?.value);
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }
}

export class EnterPinDialogModel {
  constructor(public phone: string) {
  }

}
