<form [formGroup]="form">
    <div class="controls">
        <mat-form-field>
            <mat-label>Team Member</mat-label>
            <mat-select formControlName="member" name="member">
                <mat-option *ngFor="let member of members; let i = index" [value]="i">{{member.firstName}} {{member.lastName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field >
            <mat-label>Status Filter</mat-label>
            <mat-select formControlName="status" name="status">
                <mat-option *ngFor="let status of statuses" [value]="status.number">{{status.message}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="search">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput type="search" placeholder="Search transactions..." class="search-input" (input)="filterItems($event)">
        </mat-form-field>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: fileName})"><mat-icon>file_download</mat-icon>Export to CSV</button>
    </div>
</form>
<table class="table" mat-table matSort matTableExporter [hiddenColumns]="[8]" #exporter="matTableExporter" [dataSource]="items">
    <ng-container matColumnDef="transactionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Transaction Date</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.date | date: 'mediumDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Recipient</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item | getUserNameReimbursements :this}}</td>
    </ng-container>
    <ng-container matColumnDef="employeeNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Employee ID</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.employeeNumber}}</td>
    </ng-container>
    <ng-container matColumnDef="sender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Sender</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.from || (item | getSenderNameReimbursements :this)}}</td>
    </ng-container>
    <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Description/Message</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.message || item.info || '---'}}</td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Amount</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.amount | currency}}</td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Category</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.category ?? '---'}}</td>
    </ng-container>
    <ng-container matColumnDef="taxable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Taxable</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.taxable ? 'Yes' : 'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Status</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.status === 'Paid' ? 'Reimbursement' : item.status}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="cell" mat-cell *matCellDef="let item">
            <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="transactionMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
        </td>
    </ng-container>
    <mat-menu #transactionMenu="matMenu">
        <ng-template matMenuContent let-item="item">
        </ng-template>
    </mat-menu>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
<span *ngIf="items.data.length === 0">No reimbursements</span>
