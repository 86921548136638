import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js';
import {SecretService} from '../secret/secret.service';
import {SecretsInterface} from '../../interfaces/secrets/secrets.interface';
import {AppSettings} from '../../app.settings';
import {HttpClient} from '@angular/common/http';
import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';
import {MatSnackBar} from "@angular/material/snack-bar";
import {LocalStorageService} from "../localstorage/localstorage.service";


export interface userCredentialsInterface {
    username: string;
    password: string;
}

@Injectable({
    providedIn: 'root'
})
export class CognitoMfaService {
    currentCognitoUser: any;
    private secrets?: SecretsInterface;

    constructor(
        private secretService: SecretService,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private localStorageService: LocalStorageService,
    ) {
        this.getAppSecrets();
    }

    getAppSecrets() {
        this.secretService.secrets.subscribe({
            next: (secrets: SecretsInterface | null): void => {
                if (secrets) {
                    this.secrets = secrets;
                }
            }
        });
    }

    async cognitoMFASignIn(userCredentials: userCredentialsInterface): Promise<CognitoUser | undefined> {
        try {
            this.currentCognitoUser = await Auth.signIn(userCredentials);
            return this.currentCognitoUser;
        } catch (error) {
            this.snackBar.open('Error while saving cognito information');
            return;
        }
    }

    async cognitoMFASignInWithoutPassword(email: string): Promise<CognitoUser | undefined> {
        try {
            this.currentCognitoUser = await Auth.signIn(email);
            return this.currentCognitoUser;
        } catch (error: any) {
            if (JSON.stringify(error).includes('UserLambdaValidationException')) {
                // Handle case where user does not exist in Cognito
                // BE will verify user in the db and create in Cognito
                return this.http
                    .post(`${AppSettings.getEndpoint()}auth/verify/login-without-pass`, { email })
                    .toPromise()
                    .then(async () => {
                        this.currentCognitoUser = await Auth.signIn(email);
                        return this.currentCognitoUser;
                    })
                    .catch((httpError) => {
                        const errorMsg = httpError.error?.errors ?? 'Could not authenticate.';
                        this.snackBar.open(errorMsg);
                        return;
                    })
            } else {
                this.snackBar.open(`${error}`);
                return;
            }
        }
    }

    async cognitoCustomChallengeAnswer(code: string): Promise<any> {
        const customChallenge = await Auth.sendCustomChallengeAnswer(this.currentCognitoUser, code);
        if (customChallenge && customChallenge.signInUserSession) {
            this.currentCognitoUser = customChallenge;
            return customChallenge;
        }
    }

    cognitoMFAConfirmSignIn(TOTPCode: string, mfaType: 'SOFTWARE_TOKEN_MFA'): Promise<any> {
        return Auth.confirmSignIn(this.currentCognitoUser, TOTPCode, mfaType);
    }

    async cognitoSetupTOTP(): Promise<string> {
        await this.cognitoUpdateCurrentUser();
        return Auth.setupTOTP(this.currentCognitoUser);
    }

    async cognitoVerifyTOTPToken(totpCode: string): Promise<CognitoUserSession> {
        await this.cognitoUpdateCurrentUser();
        return Auth.verifyTotpToken(this.currentCognitoUser, totpCode);
    }

    async cognitoSetPreferredMFA(mfaType: 'TOTP'): Promise<string> {
        await this.cognitoUpdateCurrentUser();
        return Auth.setPreferredMFA(this.currentCognitoUser, mfaType);
    }

    async cognitoRemoveMFA(): Promise<any> {
        await this.cognitoUpdateCurrentUser();
        await Auth.setPreferredMFA(this.currentCognitoUser, 'NOMFA');
        return Auth.forgetDevice();
    }

    async cognitoUpdateCurrentUser(): Promise<void> {
        await Auth.currentAuthenticatedUser().then((user: CognitoUser): void => {
            this.currentCognitoUser = user;
        });
    }

    async cognitoRegisterUserDevice(): Promise<any> {
        return await Auth.rememberDevice();
    }

    async getCurrentCognitoUser(): Promise<any> {
        await this.cognitoUpdateCurrentUser();
        return Auth.getPreferredMFA(this.currentCognitoUser);
    }

    async cognitoSignOut(): Promise<any> {
        return Auth.signOut();
    }

    async getAccessTokenUsingRefreshToken(refreshToken: string, deviceKey: string): Promise<{ idToken?: string, accessToken?: string } | null> {
        const REGION =this.secrets?.region; // Your AWS Region
        const CLIENT_ID = this.secrets?.userPoolWebClientId;

        const client = new CognitoIdentityProviderClient({ region: REGION });

        return new Promise<{ idToken?: string, accessToken?: string } | null>(async (resolve) => {
            try {
                const command = new InitiateAuthCommand({
                    AuthFlow: "REFRESH_TOKEN_AUTH",
                    ClientId: CLIENT_ID,
                    AuthParameters: {
                        REFRESH_TOKEN: refreshToken,
                        DEVICE_KEY: deviceKey,
                    },
                });

                const response = await client.send(command);
                console.log("Authentication successful:", response);
                resolve({idToken: response.AuthenticationResult?.IdToken, accessToken: response.AuthenticationResult?.AccessToken});
                // The response will contain new ID and access tokens
            } catch (error) {
                resolve(null)
                console.error("Error refreshing session:", error);
            }
        });

    }

    async saveCognitoRefreshTokenInformation(): Promise<void> {
        const userEmail = this.currentCognitoUser.attributes.email;
        const storage = this.currentCognitoUser.pool.storage;
        const storageKeys = Object.keys(storage);

        const refreshToken = storage[storageKeys.find(key => key.indexOf('refreshToken') !== -1) ?? ''];
        const deviceKey = storage[storageKeys.find(key => key.indexOf('deviceKey') !== -1) ?? ''];
        const body = {
            userEmail,
            refreshToken,
            deviceKey
        };
        const jwtToken = this.localStorageService.getStorage('JWTToken');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`
        };
        try {
            await this.http.post(
                `${AppSettings.getEndpoint()}auth/save-cognito-refresh-token`,
                body,
                {headers}
            ).toPromise();
        } catch (error) {
            console.log(`Error while saving refreshToken: ${error}`);
        }
    }

    generatePassword(): string {
        const minLength = 12;
        const numbers = '0123456789';
        const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?';
        const allChars = numbers + upperCase + lowerCase + specialChars;
        const requiredChars = [
            numbers[Math.floor(Math.random() * numbers.length)],
            upperCase[Math.floor(Math.random() * upperCase.length)],
            lowerCase[Math.floor(Math.random() * lowerCase.length)],
            specialChars[Math.floor(Math.random() * specialChars.length)],
        ];
        const remainingLength = minLength - requiredChars.length;
        for (let i = 0; i < remainingLength; i++) {
            requiredChars.push(allChars[Math.floor(Math.random() * allChars.length)]);
        }
        return requiredChars
            .sort(() => Math.random() - 0.5)
            .join('');
    }
}
