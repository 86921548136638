import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { RewardsReportInterface } from './rewards-report.interface';
import Chart from 'chart.js/auto';


@Component({
    selector: 'app-analytics-rewards-report',
    templateUrl: './analytics-rewards-report.component.html',
    styleUrls: ['./analytics-rewards-report.component.scss']
})
export class AnalyticsRewardsReportComponent {
    @ViewChild(MatSort) sort!: MatSort;
    private teamId = '';
    public rewardsList: MatTableDataSource<{ columnName: string, columnValue: number }> = new MatTableDataSource<{ columnName: string, columnValue: number }>([]);
    public columnsToDisplay: string[] = ['columnName', 'columnValue'];
    public chart: any = [];

    constructor(
        private transactionService: TransactionsService,
        private teamService: TeamsService
    ) {
    }

    ngOnInit(): void {
        this.teamService.wellspaceChangedObservable.subscribe((team: any): void => {
            if (team && team.id) {
                this.teamId = team.id;
                this.getRewardsList();
            }
        });
    }

    getRewardsList(): void {
        if (this.teamId) {
            this.rewardsList.data = [];
            this.transactionService.getRewardsReport(this.teamId)
                .then((rewardsReportData: RewardsReportInterface): void => {
                    const data = [
                        {
                            columnName: 'Total Rewards Sent ($)',
                            columnValue: rewardsReportData.rewardsSent ?? 0
                        },
                        {
                            columnName: 'Rewards Sent to Allowance Balance ($)',
                            columnValue: rewardsReportData.allowanceRewardsSent ?? 0
                        },
                        {
                            columnName: 'Rewards Sent to Wallit Balance ($)',
                            columnValue: rewardsReportData.wallitRewardsSent ?? 0
                        }
                    ];
                    this.rewardsList.data = data;
                    this.setChart(rewardsReportData);
            }).catch(err => {
                this.rewardsList.data = [];
                console.error('Error in get rewards report', err);
            });
        }
    }

    setChart(rewardsReportData: RewardsReportInterface): void {
        if (this.teamId) {
            this.chart = new Chart('chart', {
                type: 'pie',
                data: {
                    labels: ['Rewards Sent to Allowance Balance ($)', 'Rewards Sent to Wallit Balance ($)'],
                    datasets: [
                        {
                            label: '$',
                            data: [
                                rewardsReportData.allowanceRewardsSent,
                                rewardsReportData.wallitRewardsSent
                            ],
                        },
                    ],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    return new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(tooltipItem.raw as number);
                                }
                            }
                        }
                    }
                }
            });
        }
    }
}
