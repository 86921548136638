import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import {
    ITransactionInterface
} from '../../components/analytics/analytics-peer-to-peer/peer-to-peer-transaction.interface';

export interface Transaction {
    transactionId: string;
    transactionDate: string;
    firstName: string;
    lastName: string;
    userId: string;
    amount: number;
    categories: Array<string>;
}

export interface Purchase {
    id: string;
    userId: string;
    purchaseDate: string;
    description: string;
    amount: string;
    purpose: string;
    merchant: string;
    category: string;
    project: string;
    billable: boolean;
    receiptUrl: string;
    state: string;
    transactionId: string;
    info: string;
}

@Injectable({
    providedIn: 'root'
})

export class TransactionsService {

    public transactionStatuses = [
        {number: 0, message: 'All' },
        {number: 3, message: 'Reimbursement requested' },
        {number: 1, message: 'Reimbursement denied' },
        {number: 2, message: 'Reimbursed' }
    ];

    public purchaseStatuses = ['All', 'Reimbursement denied', 'Reimbursed', 'Reimbursement requested', 'Reimbursement approved'];

    constructor(protected restangular: Restangular) {}

    listTransactions(teamId: string, userId: string, page: number, limit: number, searchValue: string, statusFilter: number): Promise<any> {
        let baseUrl = `/v4/teams/${teamId}/`;
        const filters = [];
        filters.push(`&status=${statusFilter}`);
        baseUrl += `users/${userId}/`;
        return this.restangular.all(baseUrl + `plaid/transaction-history?like=${searchValue}&page=${page}&limit=${limit}${filters.join('')}`).getList().toPromise();
    }

    reimburseTransaction(teamId: string, userId: string, transactionId: string, isPartial: boolean = false): Promise<any> {
        return this.restangular.one('v4/teams', teamId).one('users', userId).one('transactions', transactionId).one('teameligible', '1').customPUT({isPartial}).toPromise();
    }

    denyTransaction(transactionId: string, teamId: string, message: string = ''): Promise<any> {
        return this.restangular.one('v4').one('teams', teamId).one('transactions', transactionId).one('reimburse-deny').customPUT(message && { message }).toPromise();
    }

    listReimbursements(teamId: string, userId: string, page: number, limit: number, searchValue: string, statusFilter: number): Promise<any> {
        return this.restangular.one(`teams/${teamId}/activity`).get({ like: searchValue, page, limit, statusFilter}).toPromise();
    }

    getPurchases(teamId: string, userId: string, filter: string): Promise<any> {
        return this.restangular.one('teams', teamId).one('user', userId).one('purchases').getList().toPromise().then((results: any[]) => results.filter((result: any) => (result.state === null ? '' : result.state) === filter));
    }

    setPurchaseState(teamId: string, userId: string, purchaseId: string, state: string, info: string): Promise<any> {
        return this.restangular.one('teams', teamId).one('user', userId).one('purchases', purchaseId).customPUT({state, info}).toPromise();
    }

    getTeamPeerToPeerTransactions(teamId: string, activityTransactionStatus: string): Promise<ITransactionInterface[]> {
        return this.restangular.one('teams', teamId).one('activity', activityTransactionStatus).getList().toPromise();
    }

}
