<div class="topline">
    <mat-form-field>
        <mat-label>Product Filter</mat-label>
        <mat-select (selectionChange)="setProductFilter($event)">
            <mat-option value="all">All</mat-option>
            <mat-option *ngFor="let product of products" [value]="product.vendorSlug + '-' + product.productSlug">{{product.vendorName + ' ' + product.productName}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Invitation Filter</mat-label>
        <mat-select (selectionChange)="setEmailFilter($event)">
            <mat-option value="all">All</mat-option>
            <mat-option value="invited">Invited</mat-option>
            <mat-option value="signedup">Signed Up</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <input class="search" #search matInput placeholder="Search by name, SSN, Product...">
    </mat-form-field>
    <app-paginator [reload]="reload" [itemCount]="itemCount" (pageEvent)="pageEvent($event)"></app-paginator>
</div>
<app-member-manager *ngIf="table" [reload]="reload" [table]="table" [companyId]="clientId" state="enrolled"></app-member-manager>
