<form [formGroup]="form">
    <div class="controls">
    </div>
</form>
<div class="header" *ngIf="info">
    <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'API-Log-Reconciliation-' + info.team?.name})"><mat-icon>file_download</mat-icon>Export to CSV</button>
    <h2 class="teamname">{{info.team?.name}}</h2>
</div>
<table class="table" mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="items">
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Date</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.createdAt | date: 'mediumDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Method</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.method}}</td>
    </ng-container>
    <ng-container matColumnDef="endpoint">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Endpoint</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.apiCalled}}</td>
    </ng-container>
    <ng-container matColumnDef="member">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Sender</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.sender}}</td>
    </ng-container>
    <ng-container matColumnDef="body">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Body</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.request.body}}</td>
    </ng-container>
    <ng-container matColumnDef="responsecode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Response Code</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.responseCode}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
<span *ngIf="items.data?.length === 0">No API log transactions</span>
