<h1>All Wellspaces</h1>
<button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'All Teams'})"><mat-icon>file_download</mat-icon>Export to CSV</button>
<br><input class="filterinput" matInput [(ngModel)]="filter" (keyup)="applyFilter($event)" placeholder="Filter by...">
<button mat-button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
  <mat-icon>close</mat-icon>
</button>
<table class="table" mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="items">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Team Name</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.name}}</td>
    </ng-container>
    <ng-container matColumnDef="client">
        <th class="client-cell" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Client</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.client && item.client.name ? item.client.name : '---'}}</td>
    </ng-container>
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Company</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.companyName}}</td>
    </ng-container>
    <ng-container matColumnDef="ownerName" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Team Owner Name</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.ownerName}} </td>
    </ng-container>
    <ng-container matColumnDef="createdAt" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Created</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.createdAt | date : 'medium'}} </td>
    </ng-container>
    <ng-container matColumnDef="approved" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Approved</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.approved ? 'Yes' : 'No'}} </td>
    </ng-container>
    <ng-container matColumnDef="isInvoicePlan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Invoicing</th>
        <td class="cell" mat-cell *matCellDef="let item"> {{item.isInvoicePlan ? 'Yes' : 'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="actionmenu" stickyEnd>
        <th mat-header-cell matTooltip="Admin Team Actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item"><button mat-button class="cell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button *ngIf="!item.approved" mat-menu-item (click)="approveTeam(item)">Approve</button>
        <button mat-menu-item (click)="teamDetails(item)">Details</button>
        <button mat-menu-item (click)="reconciliation(item)">Transaction Reconciliation</button>
        <button mat-menu-item (click)="triggerBilling(item)">Trigger Billing Cycle</button>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="sendMenu" [matMenuTriggerData]="{item: item}">Transfer</button>
        <button mat-menu-item (click)="loginTeamAdmin(item)">Log in as Team Leader</button>
        <button mat-menu-item (click)="loginTeamMember(item)">Log in as Team Member</button>
        <div *ngIf="checkClientFeaturePermission()">
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="openCustomerLinkModal(item)">Link customer to a client</button>
            <button mat-menu-item [disabled]="!item.client" (click)="createAnInvoice(item)">Create an invoice</button>
        </div>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="setCompany(item)">Set Company</button>
        <button mat-menu-item (click)="setTransactionPrivacy(item)">Set Transaction Privacy</button>
        <button mat-menu-item (click)="setInvoicing(item)">Invoicing</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="deleteWellspace(item)">Delete</button>
    </ng-template>
</mat-menu>
<mat-menu #sendMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="transferToWallit(item)">To Member Wallit Balance</button>
        <button mat-menu-item (click)="transferToAllowance(item)">To Member Allowance Balance</button>
        <button mat-menu-item (click)="transferAllowanceToWallit(item)">Allowance Balance to Wallit Balance</button>
    </ng-template>
</mat-menu>
