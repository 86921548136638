<div *ngIf="team && team.approved">
    <div>
        <span class="title">Add New Payment Method</span>
        <form id="setup-form" class="form">
          <div #cardelement id="card-element"></div>
            <div #carderrors id="card-errors" role="alert" style="display: none"></div>
            <div #cardsuccess id="card-success" role="alert" style="display: none"></div>
          <button mat-raised-button color="primary" [disabled]="!formValid || validating" class="validatebutton" id="submit" (click)="validateCard()">Add Payment Method</button>
          <span class="buttoninfo">Your payment method will not be charged at this time, but will be set as the default for future charges.</span>
        </form>
        <span class="mandateheader">Additional Information</span>
        <span class="mandatetext">
        By providing a payment method, you are giving us permission to charge you for services you later select based on the fee schedule in effect at that time. You may cancel
            or suspend payments at any time.
        </span>
    </div>
</div>
<div *ngIf="team && !team.approved">
    <span class="mandatetext">
        Your team has not yet been approved by Wallit. Once it has been approved, you will be able to return here and set up your payment method.
    </span>
</div>
