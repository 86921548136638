<div *ngIf="team">
    <h1 mat-dialog-title>
      Upload Rewards to Members of Team {{team.name}}
    </h1>
    <div mat-dialog-content>
        <span class="info">The amounts you specify will be sent from this team's default payment method to the team members you specify in the uploaded file.</span>
        <span class="info">The uploaded file must be in CSV or XLSX format and have the following fields in any order with the first row being the field names: date (optional), email (of the team member), amount, message, from (optional). All fields with the exception of the email carry forward to subsequent rows.</span>
        <span class="info">Additionally, any funds sent via Upload Rewards will be automatically added to the respective members’ Allowance balance.</span>
        <span class="info">
            <a download="filename" target="_blank" href="/assets/files/Wallit-rewards-upload-template.csv">
            Download Template
            </a>
        </span>
    </div>
    <div *ngIf="result && result.errorDetails.length > 0" class="errors">
        <span *ngFor="let error of result.errorDetails">
            Line {{error.lineNumber}}: {{error.type}} {{error.info ? ': ' + error.info : ''}}
        </span>
    </div>
    <div class="success" *ngIf="successMessage">
        {{successMessage}}
    </div>
    <app-fileupload *ngIf="!successMessage" (doneEvent)="gotFileContents($event)"></app-fileupload>
    <div class="buttons" mat-dialog-actions>
        <button mat-raised-button (click)="onDismiss()">{{successMessage ? 'Done' : 'Cancel'}}</button>
    </div>
</div>
