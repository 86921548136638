import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import * as moment from 'moment';
import {UsersInsuranceService} from '../../services/usersinsurance/users.insurance.service';
import {UsersService} from '../../services/users/users.service';
import {UtilsService} from '../../services/utils/utils.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../snackbar/snackbar.component';

@Component({
  selector: 'app-insurance-primary',
  templateUrl: './insurance-primary.component.html',
  styleUrls: ['./insurance-primary.component.scss']
})
export class InsurancePrimaryComponent implements OnInit {

  public form;
  public maxDate = moment().toDate();
  private primaryInfo: any;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private usersInsuranceService: UsersInsuranceService,
      private usersService: UsersService,
      private snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      gender: new UntypedFormControl(''),
      tobaccoUseDate: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    this.usersInsuranceService.getInsurancePrimary(this.usersService.getCurrentUserId()).then(result => {
      if (result.id) {
        this.primaryInfo = result;
        this.resetClicked();
      }
    });
  }

  resetClicked(): void {
    this.form.reset();
    this.form.get('gender')?.setValue(this.primaryInfo?.gender);
    this.form.get('tobaccoUseDate')?.setValue(this.primaryInfo?.tobaccoUseDate);
  }

  removeTobaccoDate(): void {
    this.form.get('tobaccoUseDate')?.setValue('');
  }

  saveClicked(): void {
    const newInfo = {
      gender: this.form.get('gender')?.value,
      tobaccoUseDate: this.form.get('tobaccoUseDate')?.value
    };
    if (this.primaryInfo) {
      this.usersInsuranceService.modifyInsurancePrimary(this.usersService.getCurrentUserId(), newInfo).then(_ => {
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'User insurance primary info updated'});
        this.form.markAsPristine();
      });
    } else {
      this.usersInsuranceService.createInsurancePrimary(this.usersService.getCurrentUserId(), this.form.get('gender')?.value, {}, this.form.get('tobaccoUseDate')?.value
      ).then(_ => {
        this.primaryInfo = newInfo;
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'User insurance primary info created'});
        this.form.markAsPristine();
      });
    }
  }

}
