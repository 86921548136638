<h1 mat-dialog-title>
    Login History for {{userName}}
</h1>
<div mat-dialog-content>
    <table class="table" mat-table matSort [dataSource]="logins">
        <ng-container matColumnDef="dayOfLogin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Login Date</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.dayOfLogin | date: 'mediumDate'}}</td>
        </ng-container>
        <ng-container matColumnDef="dailyLoginCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None"># of Logins</th>
            <td class="cell" mat-cell *matCellDef="let item">{{item.dailyLoginCount}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
    </table>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onConfirm()">Done</button>
</div>
