import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

interface Button {
  label: string;
  link: string;
}

@Component({
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})

export class SnackBarComponent implements AfterViewInit {

  @ViewChild('contents') contents: any;

  private message;
  public buttons: Array<Button> = [];

  constructor(
      private snackBar: MatSnackBar,
      private router: Router,
      @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    if (typeof data === 'string') {
      this.message = data;
    } else {
      this.message = data.message;
      this.buttons = data.buttons;
    }
  }

  ngAfterViewInit(): void {
    this.contents.nativeElement.innerHTML = this.message;
  }

  close(): void {
    this.snackBar.dismiss();
  }

  goto(link: string): void {
    this.close();
    this.router.navigate([link]);
  }


}
