import { Injectable } from '@angular/core';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../components/modal/confirmdialog/confirmdialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FieldInputDialogComponent, FieldInputDialogModel} from '../../components/modal/fieldinputdialog/fieldinputdialog.component';
import {
  TeamOneTimeRewardComponent,
  TeamOneTimeRewardComponentModel
} from '../../components/modal/onetimerewarddialog/team-one-time-reward.page';
import {MemberRewardDialogComponent, MemberRewardDialogModel} from '../../components/modal/memberrewarddialog/memberrewarddialog.component';
import {EnterPinDialogComponent, EnterPinDialogModel} from '../../components/modal/enterpindialog/enterpindialog.component';
import {SendFundsComponent, SendFundsComponentModel, SendMode} from '../../components/modal/sendfundsdialog/send-funds-dialog.page';
import {LoginMemberComponent, LoginMemberComponentModel} from '../../components/modal/loginmemberdialog/login-member.page';
import {EditProgramDialogComponent, EditProgramDialogModel} from '../../components/modal/editprogramdialog/editprogramdialog.component';
import {EditDateDialogComponent, EditDateDialogModel} from '../../components/modal/editdatedialog/editdatedialog.component';
import {UploadRewardsComponent, UploadRewardsComponentModel} from '../../components/modal/uploadrewardsdialog/uploadrewardsdialog.page';
import {EditApproverDialogComponent, EditApproverDialogModel} from '../../components/modal/editapproverdialog/editapproverdialog.component';
import {DialogRef} from '@angular/cdk/dialog';
import {AdduserdialogComponent, AdduserDialogModel} from '../../components/modal/adduserdialog/adduserdialog.component';
import {LoginlistdialogComponent, LoginlistdialogModel} from '../../components/modal/loginlistdialog/loginlistdialog.component';
import {MovieDialogComponent, MovieDialogModel} from '../../components/modal/moviedialog/moviedialog.component';

@Injectable({
  providedIn: 'root'
})
export class UiAlertService {

  static content = {
    HOME_PAGE: 'https://s3.amazonaws.com/partners.storage/vendors/rxvalet/VCR2439PRCOMPLETE+PREM-0+Video+Presentation.mp4',
    HOME_PAGE_POSTER: 'https://s3.amazonaws.com/partners.storage/vendors/rxvalet/RXValet+Video+1.png',
    MANAGING_AGENTS: 'SsNfAOTZNZY',
    MANAGING_CUSTOMERS: 'SsNfAOTZNZY',
    MODIFY_ADD_CUSTOMERS: 'SsNfAOTZNZY',
    ADD_REMOVE_SUPPORT_AGENTS: 'SsNfAOTZNZY'
  };

  constructor(
      private dialog: MatDialog,
  ) { }

  presentMovie(title: string, videoId: string): Promise<boolean> {
    const dialogRef = this.dialog.open(MovieDialogComponent, {
      data: new MovieDialogModel(title, videoId)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  presentMessage(message: string, func: ((arg: MatDialogRef<ConfirmDialogComponent, any>) => void) | null = null): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('Confirm', message, true)
    });
    if (func) {
      func(dialogRef);
    }
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  presentAlertConfirm(message: string, func: ((arg: MatDialogRef<ConfirmDialogComponent, any>) => void) | null = null): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('Confirm', message)
    });
    if (func) {
      func(dialogRef);
    }
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  presentAddUser(user: any = null): Promise<string | undefined> {
    const dialogRef = this.dialog.open(AdduserdialogComponent, {
      data: new AdduserDialogModel(user)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  presentFieldInput(title: string, fieldLabel: string, value: string = ''): Promise<string | undefined> {
    const dialogRef = this.dialog.open(FieldInputDialogComponent, {
      data: new FieldInputDialogModel(title, fieldLabel, value)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  presentDateInput(title: string, fieldLabel: string, value: string = ''): Promise<string | undefined> {
    const dialogRef = this.dialog.open(EditDateDialogComponent, {
      data: new EditDateDialogModel(title, fieldLabel, value)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  enterPIN(phone: string): Promise<string | undefined> {
    const dialogRef = this.dialog.open(EnterPinDialogComponent, {
      data: new EnterPinDialogModel(phone)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  teamOneTimeReward(isP2p: boolean, toTeam?: any): Promise<boolean> {
    const dialogRef = this.dialog.open(TeamOneTimeRewardComponent, {
      data: new TeamOneTimeRewardComponentModel(isP2p, toTeam)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  memberOneTimeReward(member: any, isP2p: boolean): Promise<number> {
    const dialogRef = this.dialog.open(MemberRewardDialogComponent, {
      data: new MemberRewardDialogModel(member, isP2p)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  memberLogin(team: any): Promise<number> {
    const dialogRef = this.dialog.open(LoginMemberComponent, {
      data: new LoginMemberComponentModel(team)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  superAdminTransfer(teamId: string, sendMode: SendMode): Promise<number> {
    const dialogRef = this.dialog.open(SendFundsComponent, {
      data: new SendFundsComponentModel(teamId, sendMode)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  uploadRewards(teamId: string): Promise<any> {
    const dialogRef = this.dialog.open(UploadRewardsComponent, {
      data: new UploadRewardsComponentModel(teamId)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  editTeamProgram(program: any): Promise<number> {
    const dialogRef = this.dialog.open(EditProgramDialogComponent, {
      data: new EditProgramDialogModel(program)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  editTeamApprover(approval: any): Promise<any> {
    const dialogRef = this.dialog.open(EditApproverDialogComponent, {
      data: new EditApproverDialogModel(approval)
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

  showUserLogins(user: any): Promise<any> {
    const dialogRef = this.dialog.open(LoginlistdialogComponent, {data: new LoginlistdialogModel(user)});
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });
    });
  }

}
