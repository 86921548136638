import { Injectable } from '@angular/core';
import {ExportToCsv} from 'export-to-csv';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ExporttocsvService {

  constructor() { }

  replaceUndefinedWithEmptyString(obj: any): any {
    return _.transform(obj, (result, value, key) => {
      result[key] = _.isUndefined(value) ? '' : value;
      // If the value is an object, apply the transformation recursively
      if (_.isObject(value) && !_.isArray(value)) {
        result[key] = this.replaceUndefinedWithEmptyString(value);
      }
    });
  }

  export(title: string, data: any): Promise<any> {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    return csvExporter.generateCsv(this.replaceUndefinedWithEmptyString(data));
  }

}
