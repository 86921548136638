import { Component, OnInit } from '@angular/core';
import {TeamsService} from '../../services/teams/teams.service';
import {TeamprogramsService} from '../../services/teamprograms/teamprograms.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {TeamRewardSchedulesService} from '../../services/teamrewardschedules/teamrewardschedules.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import moment from "moment";

enum Plans {
  Free = 'Free',
  Plus = 'Plus',
  PlusAnnual = 'PlusAnnual'
}

@Component({
  selector: 'app-program',
  templateUrl: './program.page.html',
  styleUrls: ['./program.page.scss'],
})

export class ProgramComponent implements OnInit {

  public team: any;

  public programSettings = [
    {id: '', enabled: false, name: 'Welcome Bonus', description: 'Send an automated bonus when team agency-members join the team', type: 'welcome', amount: 5, message: '', updatedMessage: 'Welcome bonus updated' },
    {id: '', enabled: false, name: 'Birthday Bonus', description: 'Send an automated bonus on a team member\'s birthday', type: 'birthday', amount: 25, message: '', updatedMessage: 'Birthday bonus updated' },
    {id: '', enabled: false, name: 'Anniversary Bonus', description: 'Send an automated bonus on a team member\'s work anniversary', type: 'anniversary', amount: 50, message: '', updatedMessage: 'Anniversary bonus updated' },
  ];

  public historyItems: any = [];
  public scheduledItems: any = [];
  public itemsSent: any = [];

  constructor(
      private teamsService: TeamsService,
      private teamProgramsService: TeamprogramsService,
      private uiAlertService: UiAlertService,
      private teamRewardsSchedulesService: TeamRewardSchedulesService,
      private snackBar: MatSnackBar,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const load = () => {
      this.teamProgramsService.getPrograms(this.team.id).then(programs => {
        this.programSettings.forEach(setting => {
          const thisProgram = programs.find((program: any) => program.type === setting.type);
          setting.enabled = !!thisProgram;
          if (thisProgram) {
            setting.amount = thisProgram.amount;
            setting.id = thisProgram.id;
            setting.message = thisProgram.message;
          }
        });
      });
      this.teamProgramsService.getProgramLogs(this.team.id).then(logs => {
        this.historyItems = logs;
      });
      this.loadRewardSchedules();
    };
    this.route.params.subscribe(params => {
      if (params.teamId && params.teamId !== 'current') {
        this.teamsService.getTeamInfo(params.teamId).then(team => {
          this.team = team;
          load();
        });
      } else {
        this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
          if (wellspace) {
            this.team = wellspace;
            load();
          }
        });
      }
    });
  }

  async loadRewardSchedules(): Promise<void> {
    this.scheduledItems = [];
    this.itemsSent = [];
    const schedules = await this.teamRewardsSchedulesService.getRewardSchedules(this.team.id);
    for (const schedule of schedules) {
      schedule.data.forEach((item: { id: any; data: any; email: any, dateSent: any}) => {
        item.id = schedule.id;
        item.data = JSON.parse(JSON.stringify(schedule.data.filter((memberInfo: { email: any; }) => memberInfo.email !== item.email)));
        item.dateSent = schedule.dateSent;
      });
      if (schedule.dateSent) {
        this.checkIfArrayHasItem(this.itemsSent, schedule);
      } else {
        this.checkIfArrayHasItem(this.scheduledItems, schedule);
      }
    }
    this.editItemsMessage(this.scheduledItems);
    this.editItemsMessage(this.itemsSent);
  }

  private checkIfArrayHasItem(list: Array<any>, item: any) {
    const hasItem = list.filter((index: any) => index.id === item.id).length > 0;
    if (!hasItem) {
      list.push(...item.data);
    }
  }

  private editItemsMessage(itemList: Array<any>): void {
    itemList.forEach((item: any) => {
      if (item.from) {
        item.message = `From ${item.from}: ` + item.message;
      }
    });
  }

  editProgram(program: any): void {
    this.uiAlertService.editTeamProgram(program).then(result => {
      this.programSettings = JSON.parse(JSON.stringify(this.programSettings));
    });
  }

  toggleProgram(event: any, program: any): void {
    if (event.checked) {
      this.teamProgramsService.createProgram(this.team.id, program.type, program.amount, '').then(result => {
        program.id = result.id;
        program.enabled = true;
      });
    } else {
      this.teamProgramsService.deleteProgram(this.team.id, program.id);
      program.enabled = false;
    }
  }

  removeItem(item: any): void {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this scheduled reward payment?').then(ok => {
      if (ok) {
        this.teamRewardsSchedulesService.modifyRewardSchedule(this.team.id, item.id, {data: item.data}).then(_ => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Scheduled reward payment has been removed'});
          this.loadRewardSchedules();
        });
      }
    });
  }

  removeAllRewards(): void {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove all scheduled reward payments?').then(ok => {
      if (ok) {
        this.teamRewardsSchedulesService.deleteAllRewardSchedules(this.team.id).then(_ => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'All scheduled reward payments have been removed'});
          this.loadRewardSchedules();
        });
      }
    });
  }

  isAfterCurrentDate(targetDate: string): boolean {
    const now = moment.now();
    const date = moment.parseZone(targetDate);
    return date.isAfter(now);
  }

}
