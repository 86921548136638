import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Dictionary} from 'highcharts';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UtilsService} from '../../../services/utils/utils.service';
import moment from 'moment';

export interface Field {
  name: string;
  label: string;
  fieldType: FieldType;
  values?: Array<string>;
  pattern?: string;
  required?: boolean;
  readOnly?: boolean;
  minDate?: string;
  maxDate?: string;
  invisible?: (arg0: any) => boolean;
}

export enum FieldType {
  F_STRING,
  F_NUMBER,
  F_PASTDATE,
  F_FUTUREDATE,
  F_EMAIL,
  F_DROPDOWN,
  F_PHONE,
  F_SSN
}

@Component({
  selector: 'app-member-addedit',
  templateUrl: './member-addedit.component.html',
  styleUrls: ['./member-addedit.component.scss']
})
export class MemberAddeditComponent implements OnInit {

  protected form!: UntypedFormGroup;
  protected fieldType = FieldType;
  protected errorMessage = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MemberAddeditComponent>,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: MemberAddeditDialogModel,
  ) {
    this.data = data;
    const controls: Dictionary<any> = {};
    this.data.fields.forEach(field => {
      if (field.fieldType === FieldType.F_PASTDATE) {
        const today = new Date();
        const hundredYearsAgo = new Date();
        hundredYearsAgo.setFullYear(today.getFullYear() - 100);
        field.minDate = hundredYearsAgo.toISOString().split('T')[0];
        field.maxDate = today.toISOString().split('T')[0];
      }
      if (field.fieldType === FieldType.F_FUTUREDATE) {
        const today = new Date();
        const oneYearInTheFuture = new Date();
        oneYearInTheFuture.setFullYear(today.getFullYear() + 1);
        field.maxDate = oneYearInTheFuture.toISOString().split('T')[0];
        field.minDate = today.toISOString().split('T')[0];
      }
      if (field.fieldType === FieldType.F_PASTDATE || field.fieldType === FieldType.F_FUTUREDATE) {
        if (this.data.data) {
          this.data.data[field.name] = moment(this.data.data[field.name]).toISOString();
        }
      }
      controls[field.name] = new UntypedFormControl(this.data.data ? this.data.data[field.name] : null, field.required ? [Validators.required] : []);
    });
    this.form = this.formBuilder.group(controls);
    this.utilsService.getFormErrors(this.form, errorMessage => this.errorMessage = errorMessage);
    this.form.valueChanges.subscribe(changes => {
      this.utilsService.getFormErrors(this.form, errorMessage => this.errorMessage = errorMessage);
    });
  }

  ngOnInit(): void {
  }

  okClicked(): void {
    this.dialogRef.close(this.form.value);
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }

  getElementById(id: string): any {
    return document.getElementById(id);
  }
}

export class MemberAddeditDialogModel {
  constructor(public data: Dictionary<any> | null, public fields: Array<Field>, public title: string) {
  }
}


