<div class="topline">
    <h1 mat-dialog-title>
        {{user ? 'Modify Existing Customer' : 'Add New Customer'}}
    </h1>
    <button mat-fab (click)="playHelpMovie()"><mat-icon>movie</mat-icon></button>
</div>
<div mat-dialog-content>
    <form class="form" [formGroup]="form">
        <mat-form-field class="rightspacer">
            <mat-label>Partner</mat-label>
            <mat-select formControlName="partner">
                <mat-option *ngFor="let partner of partners" [value]="partner.partnerId">{{partner.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field hintLabel="Max 50 characters">
            <mat-label>First Name</mat-label>
            <input matInput #input maxlength="50" placeholder="" formControlName="firstname">
            <mat-hint align="end">{{input.value.length || 0}}/50</mat-hint>
        </mat-form-field>
        <mat-form-field hintLabel="Max 50 characters">
            <mat-label>Last Name</mat-label>
            <input matInput #input2 maxlength="50" placeholder="" formControlName="lastname">
            <mat-hint align="end">{{input2.value.length || 0}}/50</mat-hint>
        </mat-form-field>
        <mat-form-field hintLabel="me@example.com">
            <mat-label>Email</mat-label>
            <input matInput #input3  placeholder="" formControlName="email">
            <button *ngIf="!emailVerified && !user" matSuffix class="verifybutton" [disabled]="true" mat-raised-button>Save Before Verifying</button>
            <button *ngIf="emailVerified && user" matSuffix class="verifybutton" [disabled]="true" mat-raised-button>Verified</button>
            <button *ngIf="!emailVerified && user" matSuffix color="accent" class="verifybutton" mat-raised-button [disabled]="!form.get('email')?.valid" (click)="forceVerifyEmail()">Force Verify</button>
            <button *ngIf="!emailVerified && user" matSuffix color="accent" class="verifybutton" mat-raised-button [disabled]="!form.get('email')?.valid" (click)="verifyEmail()">User Verify</button>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Mobile Number</mat-label>
            <input matInput maxlength="10" minLength="10" placeholder="" formControlName="tel" type="tel">
            <!--
            <ngx-mat-intl-tel-input
                    [onlyCountries]="['us']"
                    [enablePlaceholder]="false"
                    [enableSearch]="false"
                    name="tel"
                    formControlName="tel">
            </ngx-mat-intl-tel-input>
            -->
            <button *ngIf="!phoneVerified && !user" matSuffix class="verifybutton" [disabled]="true" mat-raised-button>Save Before Verifying</button>
            <button *ngIf="phoneVerified && user" matSuffix class="verifybutton" [disabled]="true" mat-raised-button>Verified</button>
            <button *ngIf="!phoneVerified && user" matSuffix color="accent" class="verifybutton" mat-raised-button [disabled]="!!form.get('tel')?.errors || form.get('tel')?.value?.length < 3" (click)="forceVerifyPhoneNumber()">Force Verify</button>
            <button *ngIf="!phoneVerified && user" matSuffix color="accent" class="verifybutton" mat-raised-button [disabled]="!!form.get('tel')?.errors || form.get('tel')?.value?.length < 3" (click)="verifyPhoneNumber()">User Verify</button>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="dob">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Address 1</mat-label>
            <input matInput placeholder="" formControlName="address1">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Address 2</mat-label>
            <input matInput placeholder="" formControlName="address2">
        </mat-form-field>
        <mat-form-field>
            <mat-label>City</mat-label>
            <input matInput placeholder="" formControlName="city">
        </mat-form-field>
        <mat-form-field>
            <mat-label>State</mat-label>
            <input matInput placeholder="" formControlName="state">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Zip Code</mat-label>
            <input matInput placeholder="" formControlName="postalCode">
        </mat-form-field>
        <mat-form-field>
            <mat-label>SSN</mat-label>
            <div class="ssn-field">
                <input matInput placeholder="*** ** ****" formControlName="ssn" [type]="hideSsn ? 'password' : 'text'">
                <mat-icon class="hide-password-icon" [svgIcon]="hideSsn ? 'icon-eye-lock' : 'icon-eye'" (click)="hideSsn = !hideSsn"></mat-icon>
            </div>
        </mat-form-field>
    </form>
    <div class="buttons" mat-dialog-actions>
        <button mat-raised-button color="primary" class="primarybutton" shape="" [disabled]="saving" (click)="saveClicked($event)">{{saving ? 'Saving...' : 'Save'}}</button>
        <button mat-raised-button (click)="onDismiss()">Cancel</button>
    </div>
</div>
