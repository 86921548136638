import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SessionService} from '../../../services/session/session.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-introdialog',
  templateUrl: './introdialog.component.html',
  styleUrls: ['./introdialog.component.scss']
})
export class IntroDialogComponent implements AfterViewInit {

  @ViewChild('content') content?: any;

  public index = 0;
  public items = [
    {content: `<div style="text-align: center;"><b>Welcome to Wallit!</b><br><br>Here's our super-fast<br>explainer on how to use Wallit<br><br>You'll be set up in just a few minutes.</div>`, button: 'Continue', next: true},
    {content: `<div style="text-align: center;">Wallit operates around <b>Wellspaces</b><br><br><b>Wellspaces</b> are where you choose\
 the benefits and rewards that fit your life.<br><br>Use wellspaces to run wellness benefits, rewards and recognition,\
  expense reimbursement programs, and more.</div>`, button: 'Next', next: true},

    {content: `<div style="text-align: center;">Wellspaces are linked to <b>Categories</b><br><br>\
<b>Categories</b> represent the places people user their credit and debit cards to access their benefits and rewards.<br><br>\
Common examples of Categories are Food, Finance, Family Care.</div>`, button: 'Continue', next: true},
    {content: `<div style="text-align: center;"><b>How Wallit Works</b><br><br>\
1.&nbsp;&nbsp;Select your benefit categories<br>\
2.&nbsp;&nbsp;Add a monthly allowance<br>\
3.&nbsp;&nbsp;Add your team members<br>\
4.&nbsp;&nbsp;Add a funding source<br>\
5.&nbsp;&nbsp;Invite your team</div>`, button: 'I’m ready!  Let’s get started.', next: false},
  ];

  constructor(
      public dialogRef: MatDialogRef<IntroDialogComponent>,
      private sessionService: SessionService,
      private router: Router
  ) {
  }

  ngAfterViewInit(): void {
    this.loadContent();
  }

  loadContent(): void {
    if (this.content) {
      this.content.nativeElement.innerHTML = this.items[this.index].content;
    }
  }

  onButton(): void {
    if (this.items[this.index].next) {
      this.index = ++this.index % this.items.length;
      this.loadContent();
    } else {
      this.dialogRef.close(false);
      this.sessionService.createTeam = true;
      this.router.navigate(['dummy_url']); // wioll redirect to root and trigger team create
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

}
