<div class="pagewrapper" *ngIf="team">
    <div class="teaser" *ngIf="!this.team.role && team.plan === freePlan">
        <img class="teaserimage" src="assets/graphics/getting_started.png">
        <div class="teaserright">
            <span>Want even more <span class="bold">choice</span> and <span class="bold">control?</span></span>
            <span class="link" (click)="changePlan()">Upgrade to the Plus plan</span>
        </div>
    </div>
    <div class="editsection">
        <span><span class="listitem">1. Wellspace: </span>{{ ' ' + team.name }}</span>
        <button mat-button shape="" class="editbutton" (click)="editName()">Edit</button>
    </div>
    <div class="editsection">
        <div class="center-toggle-content">
            <mat-slide-toggle #reimbursementsToggle [checked]="team?.disabled === 0" (change)="enabledChanged($event)">
                Allow reimbursements
            </mat-slide-toggle>
            <mat-spinner class="team-edit-toggle-spinner" [color]="'accent'" [diameter]="20"
                         *ngIf="showReimbursementsSpinner"></mat-spinner>
        </div>
    </div>
    <div class="editsection">
        <div class="center-toggle-content">
            <mat-slide-toggle #monthlyAllowanceToggle [checked]="team?.topupDisabled === 0"
                              (change)="topupEnabledChanged($event)">
                Enable monthly allowance
            </mat-slide-toggle>
            <mat-spinner class="team-edit-toggle-spinner" [color]="'accent'" [diameter]="20"
                         *ngIf="showMonthlyAllowanceSpinner"></mat-spinner>
        </div>
    </div>
    <div class="editsection">
        <span><span class="listitem">2. Benefits:</span></span>
        <button mat-button shape="" class="editbutton" (click)="editCategories()">Edit</button>
    </div>
    <span class="editsection">Reimbursement Categories: {{ team.categories.length }}</span>
    <div class="categories">
        <mat-chip-grid>
            <mat-chip *ngFor="let category of categories">
                <div class="chipinfo">
                    <span>{{ category.category }}</span>
                    <span class="taxable" *ngIf="category.taxable">Taxable</span>
                </div>
            </mat-chip>
        </mat-chip-grid>
    </div>
    <span class="editsection">Monthly allowance amount: ${{ team.amount.toFixed(2) }}</span>
    <div *ngIf="!this.team.role" class="paymentsection">
        <span class="editsection">Plan: {{ this.team.plan }}</span>
        <span class="linkbutton" (click)="changePlan()">Change Plan</span>
    </div>
    <div *ngIf="this.team.role" class="paymentsection">
        <span class="editsection" *ngIf="!paymentMethod">Funding Method:</span>
        <span class="linkbutton">You are a delegated admin so you can't change the funding method. Only team owners can change the funding method.</span>
    </div>
    <div *ngIf="!this.team.role && !paymentMethod" class="paymentsection">
        <span class="editsection">Funding Method: None</span>
        <span class="linkbutton" (click)="addPaymentMethod()">Add funding method</span>
    </div>
    <div *ngIf="!this.team.role && paymentMethod" class="paymentsection">
        <span class="editsection">Default Funding Method: {{ paymentMethod.bankInfo.bankName }}
            ****{{ paymentMethod.stripeLast4 }}</span>
        <span class="linkbutton" (click)="changePaymentMethod()">Change funding method/View charge history</span>
    </div>
    <div class="editsection">
        <span><span class="listitem">3. Team Members: </span>{{ members.length }}</span>
        <button mat-button shape="" class="editbutton" (click)="editMembers()">Edit</button>
    </div>
    <div *ngIf="showFundMembersWarningBox && team.amount > 0 && !paymentMethod?.bankInfo?.isPending && !monthlyAllowancesMemberCheck">
        <div class="warningbox" *ngIf="newMembers.length && !this.team.role">
            <span>
                You have set allowances for
                <span style="font-weight: bold;">{{ newMembers.length }} member{{ newMembers.length > 1 ? 's' : '' }}
                    that you need to fund</span>
                before they can start receiving a monthly allowance.
            </span>
            <span *ngIf="newMembers.length && !paymentMethod && !this.team.role">But first, you need to </span>
            <span *ngIf="newMembers.length && !paymentMethod && !this.team.role" class="linkbutton"
                  (click)="addPaymentMethod()">add a funding method.</span>
        </div>
        <div *ngIf="team.amount > 0 && newMembers.length && paymentMethod && !paymentMethod?.bankInfo?.isPending && !monthlyAllowancesMemberCheck"
             class="funding-member-btn-container">
            <button mat-raised-button class="donebutton" color="primary" (click)="fundAllowancesClicked()">
                Fund Member Allowances
            </button>
            <div class="center-toggle-content">
                <mat-slide-toggle #proRateFundingToggle [checked]="team.proRate && team.proRate === 0"
                                  (change)="proRateToggleChange($event)">
                    Start at beginning of next month
                </mat-slide-toggle>
            </div>
        </div>
    </div>
    <div *ngIf="team.amount === 0 && !paymentMethod?.bankInfo?.isPending" class="warningbox">
        <span>You have not yet set an allowance amount or benefits categories so you will only be able to send instant one-time rewards to team members.</span>
    </div>
    <div *ngIf="paymentMethod?.bankInfo?.isPending" class="warningbox">
        <span>The current payment method status is 'Pending'. To proceed, please select a verified payment method.</span>
    </div>
    <div class="list">
        <div class="card" *ngFor="let member of members">
            <app-user-item (memberChangedEvent)="loadTeamMembers()" class="useritem"
                           [showHeader]="member === members[0]"
                           [team]="team" [wallitBalance]="isAdmin" [member]="member" [detail]="true"></app-user-item>
        </div>
        <span class="nomembers" *ngIf="members && members.length === 0">No team members</span>
    </div>
    <span class="approvalwarning" *ngIf="!team.approved">Your team is awaiting approval by Wallit.</span>
    <span class="questions">Have questions about your wellspace?</span>
    <span class="questions">Drop us a note at <a class="link"
                                                 href="mailto:help@wallit.app">help&#64;wallit.app</a></span>
    <div class="buttons">
        <button mat-raised-button *ngIf="team.amount === 0 && newMembers.length && paymentMethod" class="donebutton"
                color="primary" (click)="sendEmailsClicked()">Invite Team Members
        </button>
        <button [disabled]="!team.approved" mat-raised-button
                *ngIf="team && paymentMethod && !paymentMethod?.bankInfo?.isPending" class="donebutton" color="primary"
                (click)="sendOnetimeClicked()">One Time Reward
        </button>
        <button [disabled]="!team.approved" mat-raised-button
                *ngIf="team && paymentMethod && !paymentMethod?.bankInfo?.isPending" class="donebutton" color="primary"
                (click)="uploadRewardsClicked()">Upload Rewards
        </button>
        <button [disabled]="!team.approved" mat-raised-button
                *ngIf="team && paymentMethod && !paymentMethod?.bankInfo?.isPending" class="donebutton" color="primary"
                (click)="sendOnetimeP2pClicked()">Member-to-Member Top Up
        </button>
        <button mat-raised-button *ngIf="team" class="donebutton" color="warn" (click)="deleteClicked()">Delete
            Wellspace
        </button>
    </div>
</div>
