import { Injectable } from '@angular/core';
import {AppSettings} from '../../app.settings';
import {Dictionary} from 'highcharts';
import {Restangular} from 'ngx-restangular';

export interface Partner {
   partnerId?: string;
   name: string;
   shortName: string;
   logoUrl: string;
   horizontalLogoUrl: string;
   lightLogoUrl: string;
   horizontalLogoOnWhite: string;
   verticaLogoOnWhite: string;
   redirectUrl: string;
   configjson: Dictionary<any>;
   signupDisabled: boolean;
   tagline: string;
   ourlyPrimaryColor: string;
   ourlySecondaryColor: string;
   wallitGradientNEColor: string;
   wallitGradientSWColor: string;
}

@Injectable({
    providedIn: 'root'
})

export class PartnersService {

    constructor(
        private restangular: Restangular,
    ) {
    }

    getAllPartners(): Promise<Partner[]> {
        return this.restangular.one('public/partners').getList().toPromise() as Promise<Partner[]>;
    }

    createPartner(partner: Partner): Promise<Partner> {
        return this.restangular.one('admin/partners').customPOST(partner).toPromise() as Promise<Partner>;
    }

    public changePartnerLogo(partnerId: string, logo: string, logoType: 'avatar' | 'lightlogo' | 'horizontallogo' | 'horizontallightlogo'): Promise<void> {
        return this.restangular.one(`admin/partners`).one(partnerId, logoType).customPUT(logo);
    }

}
