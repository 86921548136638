import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-advertising-ads',
  templateUrl: './advertising-ads.component.html',
  styleUrls: ['./advertising-ads.component.scss']
})
export class AdvertisingAdsComponent implements OnInit {

  public config: TableConfig = {
    title: 'Ads',
    dbTableName: 'banners',
    expand: [],
    columns: [
      {name: 'name', title: 'Ad Name'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
