import { Component, OnInit, ViewChild } from '@angular/core';
import { TransactionsService } from '../../../../services/transactions/transactions.service';
import { TeamsService } from '../../../../services/teams/teams.service';
import { MatSort } from '@angular/material/sort';
import { AllowanceAllocationReportInterface } from './allowance-allocation-report.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Chart } from 'chart.js';

@Component({
    selector: 'app-analytics-allowance-allocation-report',
    templateUrl: './analytics-allowance-allocation-report.component.html',
    styleUrls: ['./analytics-allowance-allocation-report.component.scss']
})
export class AnalyticsAllowanceAllocationReportComponent implements OnInit {
    @ViewChild(MatSort) sort!: MatSort;
    private teamId = '';
    public allowanceAllocationList: MatTableDataSource<AllowanceAllocationReportInterface> = new MatTableDataSource<AllowanceAllocationReportInterface>([]);
    public columnsToDisplay: string[] = ['month', 'monthlyAllowanceSent', 'monthlyAllowanceSpent'];
    public chart: any = [];

    constructor(
        private transactionService: TransactionsService,
        private teamService: TeamsService
    ) { }

    ngOnInit(): void {
        this.teamService.wellspaceChangedObservable.subscribe((team: any): void => {
            if (team && team.id) {
                this.teamId = team.id;
                this.getAllowanceAllocationList();
            }
        });
    }

    getAllowanceAllocationList(): void {
        if (this.teamId) {
            this.allowanceAllocationList.data = [];
            this.transactionService.getAllowanceAllocationReport(this.teamId).then((allowanceAllocationReportData: AllowanceAllocationReportInterface[]): void => {
                this.allowanceAllocationList.data = allowanceAllocationReportData;
                this.allowanceAllocationList.sort = this.sort;
                this.setChart(allowanceAllocationReportData);
            }).catch(err => {
                this.allowanceAllocationList.data = [];
                console.error('Error in get allowance allocation report', err);
            });
        }
    }


    setChart(allowanceAllocationReportData: AllowanceAllocationReportInterface[]): void {
        if (this.teamId) {
            this.chart = new Chart('chart', {
                type: 'line',
                data: {
                    labels: allowanceAllocationReportData.map(report => report.month),
                    datasets: [
                        {
                            label: 'Monthly Allowances Sent ($)',
                            data: allowanceAllocationReportData.map(report => report.monthlyAllowanceSent),
                        },
                        {
                            label: 'Monthly Allowances Spent ($)',
                            data: allowanceAllocationReportData.map(report => report.monthlyAllowanceSpent),
                        }
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    return `${tooltipItem.dataset.label}: ` + new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(tooltipItem.raw as number);
                                }
                            }
                        }
                    }
                }
            });

            this.chart.nativeElement.style.maxHeight = '60vh'; // Matches CSS max-height
        }
    }
}
