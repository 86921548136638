import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-advertising-creatives',
  templateUrl: './advertising-creatives.component.html',
  styleUrls: ['./advertising-creatives.component.scss']
})
export class AdvertisingCreativesComponent implements OnInit {

  public config: TableConfig = {
    title: 'Creatives',
    dbTableName: 'creatives',
    expand: [],
    columns: [
      {name: 'name', title: 'Creative Name'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
