import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../services/session/session.service';

@Component({
  selector: 'app-token',
  templateUrl: './token.page.html',
  styleUrls: ['./token.page.scss'],
})
export class TokenComponent implements OnInit {

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.sessionService.setToken(params.token, params.refresh);
      this.sessionService.createTeam = 'create' in params;
      this.router.navigate(['']);
    });
  }

}
