<h1 *ngIf="team" mat-dialog-title>
    <span>{{messages[sendMode].title}}</span>
</h1>
<div *ngIf="team" mat-dialog-content>
    <span class="info">{{messages[sendMode].info}}</span>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
            <mat-form-field *ngIf="sendMode !== SendMode.ALLOWANCE_TO_WALLIT" formFieldName="amount">
                <mat-label>Amount to send ($)</mat-label>
                <input type="number" matInput placeholder="" formControlName="amount">
            </mat-form-field>
            <mat-form-field formFieldName="message">
                <mat-label>Message</mat-label>
                <textarea class="message" rows="4" matInput formControlName="message"></textarea>
            </mat-form-field>
    </form>
    <app-team-member-selector [team]="team" class="teamlist" (membersSelectedEvent)="membersChanged($event)"></app-team-member-selector>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="members.length === 0 || (!form.valid && sendMode !== SendMode.ALLOWANCE_TO_WALLIT) || uploading" class="donebutton" color="primary" (click)="sendRewards()">Send</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
