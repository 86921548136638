import {Component, OnInit} from '@angular/core';
import {TeamsService} from '../../services/teams/teams.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {StripeService} from '../../services/stripe/stripe.service';
import {FormBuilder} from '@angular/forms';
import {SessionService} from '../../services/session/session.service';
import {MatTableDataSource} from '@angular/material/table';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {MatSnackBar} from '@angular/material/snack-bar';


interface BankInfo {
  bankType: string;
  email: string;
  bankName: string;
  customerId: string;
  paymentMethodReferenceId: string;
  userAcceptedPaymentMethod: string;
}

interface AvailablePaymentInfo {
  id: string;
  account: string;
  stripeLast4: string;
  stripeCustomerId: string;
  stripePaymentMethod: string;
  bankInfo: BankInfo;
}

@Component({
  selector: 'app-stripe-available-payment-methods',
  templateUrl: './stripe-available-payment-methods.component.html',
  styleUrls: ['./stripe-available-payment-methods.component.scss'],
})
export class StripeAvailablePaymentMethodsComponent implements OnInit {

  public charges: any;
  public items = new MatTableDataSource<AvailablePaymentInfo>();
  public columnsToDisplay = ['account', 'action'];
  public form: any;
  public team: any;

  constructor(
    private teamsService: TeamsService,
    private sessionService: SessionService,
    private uiAlertService: UiAlertService,
    private spinnerService: SpinnerService,
    private stripeService: StripeService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      if (wellspace) {
        this.spinnerService.show('Loading...');
        this.team = wellspace;
        this.loadAvailablePaymentMethods()
          .then(() => {
            this.spinnerService.hide();
          }).catch((err: any) => {
          console.error('Error: ', err);
          this.spinnerService.hide();
        });
      }
    });
  }

  async loadAvailablePaymentMethods(): Promise<void> {


    try {
      const availableMethods = await this.teamsService.getAvailablePaymentMethods(this.team.id, this.sessionService.getUserId());
      this.items.data = availableMethods.map((item: any) => {
        return {
          id: item.id,
          account: `${item.bankInfo.bankName} ****${item.stripeLast4}`,
          stripeLast4: item.stripeLast4,
          stripeCustomerId: item.stripeCustomerId,
          stripePaymentMethod: item.stripePaymentMethod,
          bankInfo: {
            bankType: item.bankInfo.type,
            email: item.bankInfo.email,
            bankName: item.bankInfo.bankName,
            customerId: item.bankInfo.customerId,
            paymentMethodReferenceId: item.bankInfo.paymentMethodReferenceId,
            userAcceptedPaymentMethod: item.bankInfo.userAcceptedPaymentMethod,
          },
        };
      });

    } catch (err) {
      console.log(err);
    }
  }

  async removePaymentMethod(item: AvailablePaymentInfo): Promise<void> {
    const message = 'Please confirm to remove this available payment method';
    try {
      const ok = await this.uiAlertService.presentAlertConfirm(message);
      if (ok) {
        this.spinnerService.show('Removing payment method...');
        await this.teamsService.removeAvailablePaymentFromTeam(this.team.id, this.sessionService.getUserId(), item.id);
        await this.loadAvailablePaymentMethods();
        this.spinnerService.hide();
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'Payment method successfully removed'});
      }
    } catch (err) {
      this.spinnerService.hide();
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Failed to remove payment method'});
    }
  }

  async attachPaymentMethod(item: AvailablePaymentInfo): Promise<void> {
    const message = 'Please confirm to link this payment method with your team.';
    try {
      const ok = await this.uiAlertService.presentAlertConfirm(message);
      if (ok) {
        this.spinnerService.show('Linking payment method...');
        await this.teamsService.attachPaymentToTeam(this.team.id, this.sessionService.getUserId(), item.id);
        await this.loadAvailablePaymentMethods();
        this.spinnerService.hide();
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'Payment method successfully linked'});
      }
    } catch (err) {
      this.spinnerService.hide();
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Failed to link payment method'});
    }
  }

}
