import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

interface Company {
    id: string;
    name: string;
    metadata: any;
}

export interface Roles {
    manager: Company;

    supportAdmin: Company;
    supportAgent: Company;

    broker: Company;
    agency: Company;
    member: Company;
    client: Company;
}

@Injectable({
    providedIn: 'root'
})
export class TeamCompanyUsersService {

    public roles!: Roles;

    constructor(
        private restangular: Restangular,
    ) { }

    getCompanyUsers(companyId: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers', companyId).getList().toPromise();
    }

    getAllCompanyUsers(): Promise<any> {
        return this.restangular.one('teams').one('companyusers').getList().toPromise();
    }

    getCompanyUsersByUserId(userId: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers').one('managers').one('me').getList().toPromise();
    }

    deleteCompanyUser(companyUserId: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers', companyUserId).remove().toPromise();
    }

    deleteCompanyUserAllPartners(companyUserId: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers', companyUserId).one('all').remove().toPromise();
    }

    addCompanyUserAllPartners(companyUserId: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers', companyUserId).one('all').customPOST().toPromise();
    }

    modifyCompanyUser(companyUserId: string, fields: any): Promise<any> {
        return this.restangular.one('teams').one('companyusers', companyUserId).customPUT({
            ...fields
        }).toPromise();
    }

    createCompanyUser(companyId: string, userId: string, role: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers').customPOST({
            companyId,
            userId,
            role
        }).toPromise();
    }

    inviteCompanyManager(companyName: string, email: string): Promise<any> {
        return this.restangular.one('teams').one('companyusers').one('invite').customPOST({
            companyName,
            email,
        }).toPromise();
    }

    getManagers(): Promise<any> {
        return this.restangular.one('teams').one('companies').one('managers').one('all').getList().toPromise().then((result: any[]) => {
            this.roles = {
                supportAdmin: result.find((teamAdminUser: { role: string; }) => teamAdminUser.role === 'supportmanager'),
                supportAgent:  result.find((teamAdminUser: { role: string; }) => teamAdminUser.role === 'supportagent'),
                manager:  result.find((teamAdminUser: { role: string; }) => teamAdminUser.role === 'manager'),
                broker:  result.find((teamAdminUser: { role: string; }) => teamAdminUser.role === 'broker'),
                agency:  result.find((teamAdminUser: { role: string; }) => teamAdminUser.role === 'agency'),
                member:  result.find((teamAdminUser: { role: string; }) => teamAdminUser.role === 'member'),
                client:  result.find((teamAdminUser: { role: string; }) => teamAdminUser.role === 'client')
            };
            return result;
        });
    }

}
