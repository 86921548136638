import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CompanyProductsDialogModel} from '../companyproductsdialog/companyproductsdialog.component';

@Component({
  selector: 'app-clientsdialog',
  templateUrl: './clientsdialog.component.html',
  styleUrls: ['./clientsdialog.component.scss']
})

export class ClientsDialogComponent implements OnInit, AfterViewInit {

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public selection = new SelectionModel<any>(true, []);
  public items = new MatTableDataSource<any>();
  public allColumnLabels = ['select', 'name'];
  private clientIds;
  public name;
  public company: any;
  public companyId;

  constructor(
      private vendorProductsService: VendorProductsService,
      private teamCompaniesService: TeamCompaniesService,
      public dialogRef: MatDialogRef<ClientsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ClientsDialogModel
  ) {
    this.clientIds = data.clientIds;
    this.companyId = data.companyId;
    this.name = data.name;
  }

  ngOnInit(
  ): void {
    this.teamCompaniesService.getCompany(this.companyId).then(company => {
      this.company = company;
      this.teamCompaniesService.getCompanies().then(companies => {
        this.items.data = companies.filter((thisCompany: { parentId: any; type: string; }) => thisCompany.parentId === this.company.id && thisCompany.type === 'employer');
        this.items.data.forEach(item => {
          if (this.clientIds?.find(clientId => item.id === clientId)) {
            this.selection.select(item);
          }
        });
      });
    });
  }

  ngAfterViewInit(): void {
    if (this.sort) {
      this.items.sort = this.sort;
      this.sort.active = 'vendorName';
      this.sort.direction = 'asc';
    }
  }

  okClicked(): void {
    this.dialogRef.close(this.selection.selected.map(client => client.id));
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.data.length;
    return numSelected === numRows;
  }

  isSomeSelected(): boolean {
    return this.selection.selected.length > 0;
  }

  /* Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(ref: { checked: boolean; }): void {
    if (this.isSomeSelected()) {
      this.selection.clear();
      ref.checked = false;
    } else {
      ref.checked = true;
      this.items.data.forEach((row: any) => this.selection.select(row));
    }
  }

}

export class ClientsDialogModel {

  constructor(public name: string, public clientIds: Array<string>, public companyId: string) {
  }

}
