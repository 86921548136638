import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {ClientService} from '../../../services/client/client.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {ClientsInterface} from '../../clients/clients.interface';

@Component({
  selector: 'app-customer-link-modal',
  templateUrl: './customer-link-modal.component.html',
  styleUrls: ['./customer-link-modal.component.scss']
})
export class CustomerLinkModalComponent implements OnInit {
  public dialogData: any;
  public team: any;
  public allClients: ClientsInterface[] = [];
  public clientFormValue: any = new FormControl('', Validators.required);

  constructor(
      public dialogRef: MatDialogRef<CustomerLinkModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public clientService: ClientService,
      public snackBar: MatSnackBar,
      public formBuilder: FormBuilder,
      private uiAlertService: UiAlertService
  ) {
    this.dialogData = data;
    this.team = this.dialogData?.team;
  }

  ngOnInit(): void {
    this.loadClientList();
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }

  loadClientList(): void {
    this.clientService.getAllClients().then((clients: ClientsInterface[]): void => {
      this.allClients = clients;
    }).catch(err => {
      this.allClients = [];
      this.snackBar.openFromComponent(SnackBarComponent, { data: 'Error in get all clients'});
      console.error('Error in get all clients', err);
    });
  }

  linkCustomerToClient(): void {
    const client  = this.clientFormValue?.value;
    this.uiAlertService.presentAlertConfirm(`Are you sure you want to link client ${client.name} to team ${this.team.name}?`).then(confirm => {
      if (confirm) {
        if (this.team.id && this.team.id !== '' && client.id && client.id !== '') {
          this.clientService.linkCustomerToClient(client.id, this.team.id).then((): void => {
            this.dialogRef.close(this.dialogData);
          }).catch(err => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: err.error && err.error.message ? err.error.message : 'An error has occurred.'});
            console.error('Error in link customer to a clients', err);
          });
        } else {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Client or Team not found.'});
        }
      }
    });
  }

}


export class CustomerLinkModal {
  constructor(public team: any, public allTeams: any) {}
}
