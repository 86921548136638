import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamsService } from '../../services/teams/teams.service';
import { CanDeactivateGuard } from '../../guards/can-deactivate/can-deactivate.guard'
import { Observable } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../components/modal/confirmdialog/confirmdialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-managewellspace',
  templateUrl: './managewellspace.component.html',
  styleUrls: ['./managewellspace.component.scss']
})
export class ManageWellspaceComponent implements OnInit, CanDeactivateGuard{

  @ViewChild('tabs') tabs: any;

  hasUnsavedChanges: boolean = false;

  public links = [
    { title: 'Overview', link: '/managewellspace/overview'},
    { title: 'Members', link: '/managewellspace/agency-members'},
    { title: 'Benefits', link: '/managewellspace/categories'},
    { title: 'Plan', link: '/managewellspace/plan'},
    { title: 'Scheduled Rewards', link: '/managewellspace/program'},
  ];
  public activeLink: any;
  public teamId = 'current';
  isApproved = false;

  constructor(
    private router: Router,
    private teamsService: TeamsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any) => {
      if (wellspace) {
        this.isApproved = wellspace?.approved === 1;
      }
    });
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    if (urlSegments.length > 2) {
      this.teamId = urlSegments[2].toString();
      this.links.forEach(link => link.link = link.link + '/' + this.teamId);
      if (this.teamId !== 'current') {
        this.teamsService.getTeamInfo(this.teamId).then(team => {
          this.teamsService.setWellspace(team);
        });
      }
    }
    // urlSegments.length = 2;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
  }

  public setHasUnsavedChanges(hasUnsavedChanges: boolean): void {
    hasUnsavedChanges ? this.hasUnsavedChanges = false : this.hasUnsavedChanges = true;
  }

  public canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.hasUnsavedChanges) { 
      return new Promise((resolve) => {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
          data: new ConfirmDialogModel('You have unsaved changes.', 'Do you really want to leave?')
        });
        
        dialogRef.afterClosed().subscribe(result => {
          resolve(result);
        });
      });
    }
    return true;
  }

  public onLinkClick(link: any): void {
    if (this.hasUnsavedChanges) {
      const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        data: new ConfirmDialogModel('You have unsaved changes.', 'Do you really want to leave?')
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.activeLink = link;
          this.hasUnsavedChanges = false;
          this.router.navigate([link.link]);
        }
      });
    } else {
      this.activeLink = link;
      this.router.navigate([link.link]);
    }
  }
}
