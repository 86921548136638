import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {ClientService} from '../../services/client/client.service';

@Injectable({
  providedIn: 'root'
})
export class ClientFeatureGuard implements CanActivate {
  constructor(private clientService: ClientService, public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.clientService.checkUserFeaturePermission()) {
      void this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
