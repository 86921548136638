import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogModel} from '../confirmdialog/confirmdialog.component';
import {UtilsService} from '../../../services/utils/utils.service';
import {UsersService} from '../../../services/users/users.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-loginlistdialog',
  templateUrl: './loginlistdialog.component.html',
  styleUrls: ['./loginlistdialog.component.scss']
})
export class LoginlistdialogComponent {

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public userName;
  public logins = new MatTableDataSource<any>();
  private user;

  public columnsToDisplay = ['dayOfLogin', 'dailyLoginCount'];

  constructor(
      public dialogRef: MatDialogRef<LoginlistdialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: LoginlistdialogModel,
      private utilsService: UtilsService,
      private usersService: UsersService
  ) {
    this.user = data.user;
    this.userName = this.utilsService.getUserName(this.user);
    this.usersService.getLogins(this.user.id).then(logins => {
      this.logins.data = logins;
      this.logins.sort = this.sort;
    });
  }

  onConfirm(): void {
    this.dialogRef.close();
  }
}

export class LoginlistdialogModel {
  constructor(public user: any) {
  }
}
