import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SessionService} from '../../../services/session/session.service';

@Component({
  selector: 'app-login-member',
  templateUrl: './login-member.page.html',
  styleUrls: ['./login-member.page.scss'],
})
export class LoginMemberComponent implements OnInit {

  public team: any;
  public title = '';
  public member: any;

  constructor(
      public dialogRef: MatDialogRef<LoginMemberComponent>,
      @Inject(MAT_DIALOG_DATA) public data: LoginMemberComponentModel,
      private sessionService: SessionService
  ) {
    this.team = data.team;
  }

  ngOnInit(): void {
  }

  membersChanged(event: any): void {
    this.member = event[0];
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  loginAsMember(): void {
    this.sessionService.loginByUserId(this.member.userId);
  }

}

export class LoginMemberComponentModel {
  constructor(public team: any) {
  }
}
