import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TeamsService} from '../../services/teams/teams.service';
import {SpinnerService} from '../../services/spinner/spinner.service';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent implements OnInit, AfterViewInit {

  public links = [
    { title: 'Transactions', link: '/purchases/transactions'},
    { title: 'Receipts to Approve', link: '/purchases/awaitingapproval'},
    { title: 'Approved Receipts', link: '/purchases/approved'},
    { title: 'Denied Receipts', link: '/purchases/denied'},
    { title: 'Reimbursed Receipts', link: '/purchases/reimbursed'},
    { title: 'Configure Approvers', link: '/purchases/configure'},
  ];
  public activeLink: any;

  constructor(
      private router: Router,
      private teamsService: TeamsService,
      private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 2;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
  }

  ngAfterViewInit(): void {
  }

}

