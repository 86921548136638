import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {
  EditCompanyManagersDialogComponent,
  EditCompanyManagersDialogModel
} from '../../modal/editcompanymanagersdialog/editcompanymanagersdialog.component';
import {TeamCompanyUsersService} from '../../../services/teamcompanyusers/teamcompanyusers.service';
import {MatDialog} from '@angular/material/dialog';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  CompanyProductsDialogComponent,
  CompanyProductsDialogModel
} from '../../modal/companyproductsdialog/companyproductsdialog.component';
import {rememberedAgencyId} from '../agency/agency.component';
import * as _ from 'lodash';
import {ClientsDialogComponent, ClientsDialogModel} from '../../modal/clientsdialog/clientsdialog.component';

@Component({
  selector: 'app-agency-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  @Input() enabled = false;
  @Input() companyId = '';
  @Output() countEvent = new EventEmitter<number>();

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public columnsToDisplay = ['name', 'email', 'actionmenu'];
  public members = new MatTableDataSource<any>();

  constructor(
      private teamCompanyUsersService: TeamCompanyUsersService,
      private teamCompaniesService: TeamCompaniesService,
      private dialog: MatDialog,
      private uiAlertService: UiAlertService,
      private snackBar: MatSnackBar
  ){
  }

  ngOnInit(): void {
    this.loadItems();
    this.members.sort = this.sort;
  }

  private loadItems(): void {
    this.teamCompanyUsersService.getCompanyUsers(this.companyId).then((result: any[]) => {
      this.members.data = result.filter((companyUser: { role: string; }) => companyUser.role === 'member');
      this.countEvent.emit(this.members.data.length);
    });
  }

  addMember(): void {
    this.teamCompaniesService.getCompany(this.companyId).then(company => {
      const dialogRef = this.dialog.open(EditCompanyManagersDialogComponent, {
        data: new EditCompanyManagersDialogModel(company, 'members')
      });
      dialogRef.afterClosed().subscribe((dialogResult: any) => {
        this.loadItems();
      });
    });
  }

  removeMember(member: any): void {
    this.uiAlertService.presentAlertConfirm(`Do you really want to remove agency member ${member.name}?`).then(ok => {
      if (ok) {
        this.teamCompanyUsersService.deleteCompanyUser(member.id).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Agency member ${member.name} removed`});
          this.loadItems();
        });
      }
    });
  }

  setClients(member: any): void {
    const dialogRef = this.dialog.open(ClientsDialogComponent, {
      data: new ClientsDialogModel(member.name, member.metadata.clients, rememberedAgencyId)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined) {
        this.teamCompanyUsersService.modifyCompanyUser(member.id, {metadata: _.merge(member.metadata, { clients: dialogResult})}).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Clients set for agency member ${member.name}`});
        });
      }
    });
  }

}
