<mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex">
    <mat-tab *ngFor="let dependent of dependents">
        <ng-template mat-tab-label>
            <span (click)="onClick(dependent)">{{dependent.firstName || 'No Name'}}</span>
        </ng-template>
        <form *ngIf="dependent.firstName !== addTabName" class="form" [formGroup]="dependent.form">
            <mat-form-field>
                <mat-label>Relationship</mat-label>
                <mat-select formControlName="relationship">
                    <mat-option value="Spouse">Spouse</mat-option>
                    <mat-option value="Child">Child</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field hintLabel="Gender assigned at birth">
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender">
                    <mat-option value="M">M</mat-option>
                    <mat-option value="F">F</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field hintLabel="Max 50 characters">
                <mat-label>First Name</mat-label>
                <input matInput #input maxlength="50" placeholder="" formControlName="firstName">
                <mat-hint align="end">{{input.value.length || 0}}/50</mat-hint>
            </mat-form-field>
            <mat-form-field hintLabel="Max 50 characters">
                <mat-label>Last Name</mat-label>
                <input matInput #input2 maxlength="50" placeholder="" formControlName="lastName">
                <mat-hint align="end">{{input2.value.length || 0}}/50</mat-hint>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Date of Birth</mat-label>
                <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="dob">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field hintLabel="Date last smoking. Leave blank if never">
                <mat-label>Tobacco Use End Date</mat-label>
                <input matInput [matDatepicker]="picker2" [max]="maxDate" formControlName="tobaccoUseDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </form>
        <div *ngIf="dependent.firstName !== addTabName" class="buttons">
            <button mat-raised-button color="primary" class="primarybutton" shape="" [disabled]="!dependent.form.valid || !dependent.form.dirty" (click)="saveClicked(dependent)">Save</button>
            <button mat-raised-button color="primary" class="primarybutton" shape="" [disabled]="!dependent.form.dirty" (click)="resetClicked(dependent)">Reset</button>
            <button mat-raised-button color="primary" class="primarybutton" shape="" (click)="removeClicked(dependent)">Remove</button>
        </div>
    </mat-tab>
</mat-tab-group>
