<div *ngIf="team">
    <span class="teamname">{{team.name}}</span>
    <div class="planwrapper">
        <div [class]="team.plan === plan.plan ? 'plans currentplan' : 'plans'" *ngFor="let plan of plans">
            <span class="planname">{{plan.name}}</span>
            <span class="rate">{{plan.rate}}</span>
            <button mat-raised-button [disabled]="team.plan === plan.plan || (!team.defaultPaymentMethod && plan !== plans[0]) || !isTeamApproved" color="primary" class="editbutton" (click)="selectPlan(plan)">
                {{team.plan === plan.plan ? 'Current Plan' : buttonMessage(plan)}}
            </button>
        </div>
    </div>
    <span *ngIf="!team.defaultPaymentMethod">After you have selected a payment method, you may then choose a different paid plan.</span>
</div>
