import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../../services/users/users.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {TeamsService} from '../../services/teams/teams.service';
import {StripeService} from '../../services/stripe/stripe.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {MemberRewardDialogComponent, MemberRewardDialogModel} from '../modal/memberrewarddialog/memberrewarddialog.component';
import {MatDialog} from '@angular/material/dialog';
import {RefundAllowanceDialogComponent, RefundAllowanceDialogModel} from '../modal/refundallowancedialog/refundallowancedialog.component';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
})
export class UserItemComponent implements OnInit, AfterViewInit {

  private hasAvatar = false;

  @Input() member: any;
  @Input() detail = false;
  @Input() team: any;
  @Input() showHeader = false;
  @Input() wallitBalance = false;
  @ViewChild('avatar') avatar: any;
  @ViewChild('item') item: any;
  @Output() memberDeletedEvent: EventEmitter<any> = new EventEmitter<any>();
  hasPendingACHCount = false;
  public newMembers: Array<any> = [];
  public members: Array<any> = [];
  constructor(
      private router: Router,
      private usersService: UsersService,
      private uiAlertService: UiAlertService,
      private teamsService: TeamsService,
      private snackBar: MatSnackBar,
      private stripeService: StripeService,
      private spinnerService: SpinnerService,
      private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.hasAvatar = this.usersService.getAvatar(this.member, this.avatar.nativeElement);
    this.item.detail = this.detail;
  }

  getMemberName(member: any): string {
    return (member.firstName || '') + ' ' + (member.lastName || '');
  }

  getEmail(member: any): string {
    return member.email;
  }

  getLoginName(member: any): string {
      return member.loginName ? '@' + member.loginName : '';
  }

  showUserDetail(event: any, user: any): void {
    event.stopPropagation();
    if (user.userId) {
      this.router.navigate(['/userdetail', user.userId]);
    }
  }

  showPendingAchSnack(snackMessage: string): void {
    this.spinnerService.hide();
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: snackMessage
    });
  }

  async checkIfMemberHasSomeAchPending(teamId: string): Promise<boolean> {
    this.spinnerService.show('Loading...');
    const pendingCharge: any = await this.teamsService.checkTeamPendingCharges(teamId);
    return !!(pendingCharge && pendingCharge.hasPendingCharges);
  }

  async refundMember(member: any): Promise<void> {
    const amountString = parseFloat(member.balance).toFixed(2);
    if (amountString === '0.00') {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `This member has a zero allowance balance so there is nothing to refund`});
      return;
    }
    const dialogRef = this.dialog.open(RefundAllowanceDialogComponent, {
      data: new RefundAllowanceDialogModel(member)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== null) {
        this.uiAlertService.presentAlertConfirm(`$${dialogResult} will be credited to your account. Do you really want to refund from this team member's allowance?`).then(async confirm => {
          if (confirm) {
            this.teamsService.getCustomerId(this.team).then(customerId => {
              this.stripeService.issueRefund(customerId, dialogResult);
            });
            this.member.balance -= parseFloat(dialogResult);
            this.teamsService.modifyTeamMember(this.team.id, this.member.memberId, {balance: this.member.balance});
            this.memberChanged();
            this.snackBar.openFromComponent(SnackBarComponent, {data: `$${dialogResult} has been refunded`});
          }
        });
      }
    });
  }

  async deleteMember(member: any, dontDelete: boolean = false): Promise<void> {
    try {
      let amountToRefund = 0;
      let hasP2pBalance = false;
      if (member?.p2pBalance > 0) {
        hasP2pBalance = true;
        amountToRefund += parseFloat(member.p2pBalance);
      }
      if (member?.balance > 0){
        amountToRefund += parseFloat(member.balance);
      }
      const message = 'Are you sure you want to remove this team member? Their remaining allowance';
      const refundMessage = hasP2pBalance
          ? ` and peer recognition balance totaling $${amountToRefund.toFixed(2)} will be refunded.`
          : ` balance totaling $${amountToRefund.toFixed(2)} will be refunded.`;

      const info = `${message}${refundMessage}`;
      const confirm = await this.uiAlertService.presentAlertConfirm(`${info}`);

      if (confirm) {
        const hasAchPending = await this.checkIfMemberHasSomeAchPending(member.teamId);

        if (hasAchPending) {
          this.showPendingAchSnack(
              'A transfer is in progress at the moment. To ensure the integrity of the operation, ' +
              'it is not possible to make further changes at this time. Please wait until the transfer is completed to proceed with your actions.'
          );
          return;
        }

        if (member.balance > 0) {
          const customerId = await this.teamsService.getCustomerId(this.team);
          await this.stripeService.issueRefund(customerId, amountToRefund);
          this.snackBar.openFromComponent(SnackBarComponent, { data: `This team member has a remaining balance of $${amountToRefund.toFixed(2)} which will be refunded to your default payment method.` });

          if (dontDelete) {
            await this.teamsService.modifyTeamMember(this.team.id, member.memberId, { balance: 0 });
          } else {
            await this.teamsService.removeTeamMember(member.memberId, member.teamId);
            this.showPendingAchSnack('Team member removed');
          }
          this.memberDeletedEvent.emit(true);
        } else {
          await this.teamsService.removeTeamMember(member.memberId, member.teamId);
          this.showPendingAchSnack('Team member removed');
          this.memberDeletedEvent.emit(true);
        }
      }
    } catch (error: any) {
      console.error('Error in deleteMember:', error);
      this.showPendingAchSnack(error.message || 'An error occurred');
    }
  }

  resendMember(member: any): void {
    this.teamsService.resendEmailToTeamMember(member.memberId, this.team.id).then((response) => {
      member.message = 'Email Resent';
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Email has been sent again'});
    });
  }

  private async hasFundingSource(): Promise<any> {
    const hasSource = await this.teamsService.getPaymentMethod(this.team);
    if (!hasSource) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'You need to set up a payment method before sending rewards'});
    }
    return hasSource !== null;
  }

  async rewardMember(member: any): Promise<any> {
    if (await this.hasFundingSource()) {
      this.uiAlertService.memberOneTimeReward(member, false).then(result => {
        this.member.balance = parseFloat(this.member.balance) + result;
        this.member = JSON.parse(JSON.stringify(this.member));
      });
    }
  }

    async p2pTopupMember(member: any): Promise<any> {
      if (await this.hasFundingSource()) {
        this.uiAlertService.memberOneTimeReward(member, true).then(result => {
          this.member.balance = parseFloat(this.member.balance) + result;
          this.member = JSON.parse(JSON.stringify(this.member));
        });
      }
    }

  private memberChanged(): void {
    this.member = JSON.parse(JSON.stringify(this.member));
  }

  changeEmploymentDate(member: any): void {
    this.uiAlertService.presentDateInput('Change Employment Date', 'Employment Date', member.employmentDate).then(result => {
      if (result) {
        this.teamsService.modifyTeamMember(this.team.id, member.memberId, { employmentDate: result }).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member employment date changed'});
          this.member.employmentDate = result;
          this.memberChanged();
        });
      }
    });
  }

  changeEmployeeNumber(member: any): void {
    this.uiAlertService.presentFieldInput('Change Employee Number/ID', 'Employee Number/ID', member.employeeNumber).then(result => {
      if (result) {
        this.teamsService.modifyTeamMember(this.team.id, member.memberId, { employeeNumber: result }).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member employee number/ID changed'});
          this.member.employeeNumber = result;
          this.memberChanged();
        });
      }
    });
  }

  toggleAdmin(member: any): void {
    const isAdmin = member.role === 'admin';
    this.member.role = isAdmin ? '' : 'admin';
    this.teamsService.modifyTeamMember(this.team.id, member.memberId, {role: this.member.role}).then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: isAdmin ? 'Admin rights removed' : 'Admin rights added'});
      this.memberChanged();
    });
  }

  enableOrDisableUserMonthlyAllowance(user: any, monthAllowanceValue: 0 | 1): void {
    this.spinnerService.show('Processing');
    this.usersService.enableOrDisableUserMonthlyAllowance(this.team.id, user.userEmail, monthAllowanceValue).then((): void => {
      this.member.canReceiveAllowance = monthAllowanceValue;
      this.memberChanged();
      this.teamsService.setWellspace(this.team);
      this.spinnerService.hide();
      this.snackBar.openFromComponent(SnackBarComponent, {data: user && user.canReceiveAllowance === 1 ? 'Enabled monthly allowance' : 'Disabled monthly allowance'});
    }).catch(err => {
      console.error('Error in change user monthly allowance', err);
      this.spinnerService.hide();
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Failed to disable member allowance'});
    });
  }

  async changeUserMonthlyAllowance(user: any): Promise<void> {
    const monthAllowanceValue: 0 | 1 = user && user.canReceiveAllowance === 1 ? 0 : 1;
    let message = '';
    if (monthAllowanceValue === 0) {
      message = `Turning off a member's allowance will affect your subscription plan for the upcoming month if they've already received their allowance. Would you like to proceed?`;
    } else {
      message = `Would you like to enable the member ${user.firstName ?? ''}'s allowance?`;
    }
    const ok = await this.uiAlertService.presentAlertConfirm(message);
    if (ok) {
      this.enableOrDisableUserMonthlyAllowance(user, monthAllowanceValue);
    }
  }

}
