<mat-chip-grid #chipList>
    <mat-chip *ngFor="let tag of tags">
        {{tag}}
        <mat-icon matChipRemove (click)="remove(tag)">cancel</mat-icon>
    </mat-chip>
    <input #tagInput placeholder="New tag..."
           [matChipInputFor]="chipList"
           [matAutocomplete]="auto"
           (matChipInputTokenEnd)="add($event)" (input)="change($event)"/>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let tag of filteredTags" [value]="tag">{{tag}}</mat-option>
    </mat-autocomplete>
</mat-chip-grid>
