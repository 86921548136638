import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {UsersInsuranceService} from '../../services/usersinsurance/users.insurance.service';
import {UsersService} from '../../services/users/users.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment/moment';
import {MatTabGroup} from '@angular/material/tabs';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';

@Component({
  selector: 'app-insurance-beneficiaries',
  templateUrl: './insurance-beneficiaries.component.html',
  styleUrls: ['./insurance-beneficiaries.component.scss']
})
export class InsuranceBeneficiariesComponent implements OnInit {

  // @ts-ignore
  @ViewChild('tabs') tabGroup: MatTabGroup;

  public beneficiaries: any;
  public maxDate = moment().toDate();
  public addTabName = 'Add Beneficiary';
  private counter = 1;
  public selectedTabIndex = 0;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private usersInsuranceService: UsersInsuranceService,
      private usersService: UsersService,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService
  ) {
  }

  buildForm(beneficiary: any): void {
    beneficiary.form = this.formBuilder.group({
      beneficiaryType: new UntypedFormControl(beneficiary.beneficiaryType),
      relationship: new UntypedFormControl(beneficiary.relationship, Validators.required),
      firstName: new UntypedFormControl(beneficiary.firstName),
      lastName: new UntypedFormControl(beneficiary.lastName),
      ssn: new UntypedFormControl(beneficiary.ssn),
    });
    beneficiary.form.get('firstName').valueChanges.subscribe((value: any) => {
      beneficiary.firstName = value;
      this.beneficiaries = this.beneficiaries.slice();
    });
  }

  ngOnInit(): void {
    this.usersInsuranceService.getInsuranceBeneficiaries(this.usersService.getCurrentUserId()).then(result => {
      this.beneficiaries = result.concat({firstName: this.addTabName});
      this.beneficiaries.forEach((beneficiary: any) => this.buildForm(beneficiary));
    });
  }

  onClick(beneficiary: any): void {
    if (beneficiary.firstName === this.addTabName) {
      this.beneficiaries.unshift({firstName: `New Beneficiary ${this.counter++}`});
      this.buildForm(this.beneficiaries[0]);
      this.tabGroup.selectedIndex = 1;
      setTimeout(() => {
        this.tabGroup.selectedIndex = 0;
      }, 50);
    }
  }

  resetClicked(beneficiary: any): void {
    beneficiary.form.reset();
  }

  saveClicked(beneficiary: any): void {
    const newInfo = {
      beneficiaryType: beneficiary.form.get('beneficiaryType')?.value,
      firstName: beneficiary.form.get('firstName')?.value,
      lastName: beneficiary.form.get('lastName')?.value,
      ssn: beneficiary.form.get('ssn')?.value,
      relationship: beneficiary.form.get('relationship')?.value
    };
    if (beneficiary.id) {
      this.usersInsuranceService.modifyInsuranceBeneficiary(this.usersService.getCurrentUserId(), beneficiary.id, newInfo).then(_ => {
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'User insurance beneficiary info updated'});
        beneficiary.form.markAsPristine();
      });
    } else {
      this.usersInsuranceService.createInsuranceBeneficiary(
          this.usersService.getCurrentUserId(),
          beneficiary.form.get('beneficiaryType')?.value,
          beneficiary.form.get('firstName')?.value,
          beneficiary.form.get('lastName')?.value,
          beneficiary.form.get('ssn')?.value,
          beneficiary.form.get('relationship')?.value
      ).then(_ => {
        beneficiary = newInfo;
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'User insurance beneficiary info created'});
        beneficiary.form.markAsPristine();
      });
    }
  }

  removeClicked(beneficiary: any): void {
    if (beneficiary.id) {
      this.uiAlertService.presentAlertConfirm('Do you really want to remove this beneficiary?').then(ok => {
        if (ok) {
          this.usersInsuranceService.deleteInsuranceBeneficiary(this.usersService.getCurrentUserId(), beneficiary.id).then(_ => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'Beneficiary removed'});
            this.beneficiaries.splice(this.beneficiaries.findIndex((item: { id: string; }) => item.id === beneficiary.id), 1);
            this.beneficiaries = this.beneficiaries.slice();
          });
        }
      });
    } else {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Beneficiary removed'});
      this.beneficiaries.splice(this.tabGroup.selectedIndex, 1);
      this.beneficiaries = this.beneficiaries.slice();
    }
  }

}
