import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-analytics-team-overview',
  templateUrl: './analytics-team-overview.component.html',
  styleUrls: ['./analytics-team-overview.component.scss']
})
export class AnalyticsTeamOverviewComponent {
  @Input() analytics: any;
}
