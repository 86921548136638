<button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: fileName})"><mat-icon>file_download</mat-icon>Export to CSV</button>
<table class="table" mat-table matSort matTableExporter [hiddenColumns]="" #exporter="matTableExporter" [dataSource]="items">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Category Name</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.name}}</td>
    </ng-container>
    <ng-container matColumnDef="transactionCount" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Reimbursements</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.transactionCount}} </td>
    </ng-container>
    <ng-container matColumnDef="totalAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Total Amount</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.totalAmount | currency}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
