import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SetTeamPrivacyComponentModel} from '../setteamprivacydialog/setteamprivacydialog.component';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {StripeService} from '../../../services/stripe/stripe.service';
import {MatTableDataSource} from '@angular/material/table';
import {Transaction} from '../../../services/transactions/transactions.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {PaymentTargets, TeamsService} from '../../../services/teams/teams.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-setinvoicingdialog',
  templateUrl: './setinvoicingdialog.component.html',
  styleUrls: ['./setinvoicingdialog.component.scss']
})
export class SetInvoicingDialogComponent implements OnInit {

  public form: any;
  public team;
  public items = new MatTableDataSource<any>();
  public columnsToDisplay = ['date', 'amount', 'balance', 'type'];
  private showAll = false;
  private allItems = [];
  private currentTabIndex = 0;
  private paymentMethods: any;
  public invoicing =  false;
  public credit = 0;
  public labels = Object.values(PaymentTargets);

  constructor(
      private formBuilder: UntypedFormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: SetInvoicingComponentModel,
      public dialogRef: MatDialogRef<SetInvoicingDialogComponent>,
      private stripeService: StripeService,
      private teamsService: TeamsService,
      private snackBar: MatSnackBar
  ) {
    this.team = data.team;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      invoicing: new UntypedFormControl(),
      credit: new UntypedFormControl(0)
    });
    this.teamsService.getPaymentMethods(this.team.id).then(result => {
      this.paymentMethods = result;
      this.setTab(0);
    });
  }

  private getInvoicingMethodByTarget(target: PaymentTargets): any {
    return this.paymentMethods.find((method: any) => method.bankInfo.bankName === 'invoice' && method.paymentTargets.indexOf(target) >= 0);
  }

  setTab(index: number): void {
    this.currentTabIndex = index;
    const method = this.getInvoicingMethodByTarget(this.labels[index]);
    this.form.get('invoicing').setValue(!!method, {emitEvent: false});
    this.stripeService.getCustomerBalance(method?.stripeCustomerId).then(balance => this.credit = balance);
    this.reloadItems();
  }

  private reloadItems(): void {
    this.stripeService.getCustomerBalanceTransactions(this.getPaymentMethod()?.stripeCustomerId).then(result => {
      this.allItems = result;
      this.setFilter();
    });
  }

  private getPaymentMethod(): any {
    return this.paymentMethods?.find((method: { paymentTargets: any[]; }) => method.paymentTargets.find(target => target === this.labels[this.currentTabIndex]));
  }

  showAllChanged(event: any): void {
    this.showAll = event.checked;
    this.setFilter();
  }

  private setFilter(): void {
    this.items.data = this.showAll ? this.allItems : this.allItems.filter((item: { type: string; }) => item.type === 'adjustment');
  }

  invoicingChanged(event: any): void {
    this.form.get('invoicing').setValue(event.checked);
    const paymentTarget = this.labels[this.currentTabIndex];
    if (event.checked) {
      this.teamsService.addInvoiceMethod(this.team.id, paymentTarget);
    } else {
      this.stripeService.removeCard(this.getPaymentMethod().id);
    }
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }

  addCredit(): void {
    this.stripeService.addToCustomerBalance(this.getPaymentMethod()?.stripeCustomerId, this.form.get('credit').value).then(_ => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `Credit balance updated`});
      this.reloadItems();
    }).catch(error => {
      console.log('Add Credit failed');
    });
  }

  onTabChanged(event: any): void {
    this.setTab(event.index);
  }

}

export class SetInvoicingComponentModel {
  constructor(public team: any) {
  }
}
