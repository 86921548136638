import {Component, Input, OnChanges, Pipe, PipeTransform, SimpleChanges, ViewChild} from '@angular/core';
import {Purchase, TransactionsService} from '../../../services/transactions/transactions.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TeamsService} from '../../../services/teams/teams.service';
import {ActivatedRoute} from '@angular/router';
import {FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {StripeService} from '../../../services/stripe/stripe.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ImageDialogComponent, ImageDialogModel} from '../../modal/imagedialog/imagedialog.component';
import {UsersService} from '../../../services/users/users.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {FeeService} from 'src/app/services/fee/fee.service';

@Component({
    selector: 'app-analytics-approve',
    templateUrl: './analytics-approve.component.html',
    styleUrls: ['./analytics-approve.component.scss']
})
export class AnalyticsApproveComponent implements OnChanges {

    constructor(
        private transactionsService: TransactionsService,
        private teamsService: TeamsService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private snackBar: MatSnackBar,
        private stripeService: StripeService,
        private uiAlertService: UiAlertService,
        private usersService: UsersService,
        private dialog: MatDialog,
        private spinnerService: SpinnerService,
        private feeService: FeeService,
    ) {
        this.form = this.formBuilder.group({
                member: new UntypedFormControl(0),
                status: new UntypedFormControl(0)
            }
        );
        this.form.valueChanges.subscribe(() => {
            const memberIndex = this.form.get('member')?.value;
            this.userId = memberIndex ? this.members[memberIndex].userId : 'all';
            this.loadData().then(results => {
                this.items.data = results;
            });
        });
    }

    @Input() activeTitle: string | undefined;

    public columnsToDisplay = [
        'image', 'purchaseDate', 'merchant', 'purpose', 'project', 'user', 'employeeNumber', 'category', 'amount', 'billable', 'taxable', 'state', 'action'
    ];

    SCROLL_CHUNK = 1000;

    @Input() analytics: any;
    @Input() filter = '';
    // @ts-ignore
    @ViewChild(MatSort) sort: MatSort;

    public statuses = this.transactionsService.purchaseStatuses;
    public members: any;

    public items = new MatTableDataSource<Purchase>();
    private page = 0;
    private teamId = '';
    private searchValue = '';
    private team: any;

    public userId = 'all';
    public form: FormGroup;
    public fileName = '';

    serviceFee = 0;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.activeTitle) {
            this.updateColumnsToDisplay();
        }
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params.userId) {
                this.userId = params.userId;
            }
            this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any) => {
                if (wellspace) {
                    this.teamId = wellspace.id;
                    this.team = wellspace;
                    this.teamsService.getTeamMembers(this.teamId).then(members => {
                        this.members = members;
                        this.members.unshift({firstName: 'All', lastName: 'members'});
                        const index = members.findIndex((thisMember: any) => thisMember.userId === this.userId);
                        this.form.get('member')?.setValue(index < 0 ? 0 : index);
                        const member = this.members[index < 0 ? 0 : index];
                        this.fileName = `${wellspace.name} reimbursements ${member.firstName} ${member.lastName}`;
                        this.loadData().then(results => {
                            this.items.data = results;
                            this.items.sort = this.sort;
                        });
                    });
                }
            });
        });
    }

    private async loadData(): Promise<any> {
        let purchases = await this.transactionsService.getPurchases(this.teamId, this.userId, this.filter === 'Reimbursement approved' ? 'Reimbursed' : this.filter);
        if (this.activeTitle === 'Approved Receipts' || this.activeTitle === 'Reimbursed Receipts') {
            const partialReimbursements = await this.transactionsService.getPurchases(this.teamId, this.userId, 'Partial Reimbursed');
            purchases.push(...partialReimbursements);
        };
        purchases = purchases.map((purchase: any) => {
            const thisMember = this.members.find(
                (member: any) => member.userId === purchase.userId
            );
            purchase.employeeNumber = thisMember?.employeeNumber;
            purchase.firstName = thisMember?.firstName;
            purchase.lastName = thisMember?.lastName;
            return purchase;
        });
        purchases.sort((a: any, b: any) => {
            return a.createdAt > b.createdAt ? -1 : 1;
        });
        let transactions = await this.transactionsService.listTransactions(this.teamId, this.userId, this.page, this.SCROLL_CHUNK, this.searchValue, 2);
        transactions = transactions.map((transaction: any) => {
            transaction.employeeNumber = this.members.find((member: any) => member.userId === transaction.userId).employeeNumber;
            return transaction;
        });
        return this.filter === 'Reimbursed' ? [...purchases, ...transactions] : purchases;
    }

    filterItems(event: any): void {
        this.searchValue = event.target.value;
        this.loadData().then(results => {
            this.items.data = results;
        });
    }

    async reimbursePurchase(item: Purchase): Promise<void> {
        if (this.team.defaultPaymentMethod === '') {
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'A payment method is needed for receipt approval'});
            return;
        }

        this.serviceFee = await this.feeService.getPaymentMethodFee(this.team);

        const amount = parseInt(item.amount, 10);
        const amountPlusFee = amount * (1 + this.serviceFee);

        this.uiAlertService.presentAlertConfirm(`This will initiate a one time charge to your default payment method of $${amountPlusFee.toFixed(2)}. ${this.serviceFee > 0 ? `This includes a service fee of $${(amountPlusFee - amount).toFixed(2)}.` : ''}`).then(async (confirm: any) => {
            if (confirm) {
                this.uiAlertService.presentFieldInput('Reimbursement Approval Details', 'Info', '').then(async result => {
                    if (result) {
                        this.spinnerService.show();
                        const member = this.members.find((thisMember: {
                            userId: string;
                        }) => thisMember.userId === item.userId);
                        const customerId = await this.teamsService.getCustomerId(this.team);
                        this.stripeService.makeOneTimePayment(customerId, '_RECEIPT_', amount, [member?.userEmail], item.info, {
                            receiptUrl: item.receiptUrl,
                            purpose: item.purpose,
                            merchant: item.merchant,
                            purchaseDate: item.purchaseDate
                        }, await this.teamsService.getPaymentMethod(this.team), this.team.id, 'REIMBURSEMENT', this.team.id, true).then(() => {
                            this.transactionsService.setPurchaseState(this.teamId, item.userId, item.id, 'Reimbursed', result).then(() => {
                                this.loadData().then(results => {
                                    this.items.data = results;
                                });
                                this.spinnerService.hide();
                                this.snackBar.openFromComponent(SnackBarComponent, {data: 'Purchase has been reimbursed to the member\'s Wallit balance'});
                            }).catch(err => {
                                this.spinnerService.hide();
                                this.snackBar.openFromComponent(SnackBarComponent, {data: err.error && err.error.message ? err.error.message : 'An error has occurred.'});
                                console.error(err);
                            });
                        }).catch(err => {
                            this.spinnerService.hide();
                            this.snackBar.openFromComponent(SnackBarComponent, {data: err.error && err.error.message ? err.error.message : 'An error has occurred.'});
                            console.error(err);
                        });
                    }
                });
            }
        });
    }

    denyPurchase(item: Purchase): void {
        this.uiAlertService.presentFieldInput('Reimbursement Denial Details', 'Info', '').then(result => {
            if (result) {
                this.transactionsService.setPurchaseState(this.teamId, item.userId, item.id, 'Reimbursement denied', result).then(() => {
                    this.loadData().then(results => {
                        this.items.data = results;
                    });
                    this.snackBar.openFromComponent(SnackBarComponent, {data: 'Purchase reimbursement has been denied'});
                });
            }
        });
    }

    async makePayments(): Promise<void> {
        const payments = this.items.data.map(purchase => ({
            user: purchase.userId,
            amount: purchase.amount,
            date: purchase.purchaseDate,
            merchant: purchase.merchant,
            info: purchase.info
        }));
        let amount = 0;
        payments.forEach(payment => {
            amount += parseFloat(payment.amount);
        });

        const fee = await this.feeService.getFee(this.teamId);
        this.serviceFee = fee.ach.fee;

        const amountPlusFee = amount * (1 + this.serviceFee);
        const paymentMethodDescription = ` to your default payment method`;
        this.uiAlertService.presentAlertConfirm(`This will initiate a one time ${this.team.invocieEmail ? 'invoice' : 'charge'} of $${amountPlusFee.toFixed(2)} for ${payments.length} purchase${payments.length === 1 ? '' : 's'}${paymentMethodDescription}. ${this.serviceFee > 0 ? `This includes a service fee of $${(amountPlusFee - amount).toFixed(2)}.` : ''}`).then(async (confirm: any) => {
            if (confirm) {
                await this.stripeService.makePurchasePayments(this.team.id, payments).then(() => {
                }).then(() => {
                    this.items.data.forEach(purchase => purchase.state = 'Reimbursed');
                    this.items.data = [];
                    this.snackBar.openFromComponent(SnackBarComponent, {data: `Purchase reimbursements made.`});
                }).catch((error: any) => {
                    const info = error.error;
                    this.snackBar.openFromComponent(SnackBarComponent, {data: `${info.raw.message}: ${info.raw.decline_code.split('_').join(' ')}`});
                });
            }
        });
    }

    getState(item: Purchase): string {
        return item.state ? item.state : 'Awaiting Approval';
    }

    showReceipt(item: Purchase): void {
        this.dialog.open(ImageDialogComponent, {data: new ImageDialogModel('Receipt', item.receiptUrl)});
    }

    private updateColumnsToDisplay(): void {
        const reimbursedToWallitIndex = this.columnsToDisplay.indexOf('reimbursedToWallit');
        if (this.activeTitle !== 'Receipts to Approve' && this.activeTitle !== 'Denied Receipts') {
            if (reimbursedToWallitIndex === -1) {
                this.columnsToDisplay.splice(2, 0, 'reimbursedToWallit');
            }
        } else {
            if (reimbursedToWallitIndex !== -1) {
                this.columnsToDisplay.splice(reimbursedToWallitIndex, 1);
            }
        }
    }

}

@Pipe({
    name: 'getState',
    pure: true
})
export class GetStatePipe implements PipeTransform {

    transform(item: any, thisArg: AnalyticsApproveComponent): string {
        return thisArg.getState(item);
    }

}


