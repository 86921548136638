import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamsService } from '../../services/teams/teams.service';
import { SessionService } from '../../services/session/session.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { FeatureFlagService } from 'src/app/services/feature-flag/feature-flag.service';

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

    public allLinks = [
        { title: 'All Teams Overview', link: '/analytics/overview' },
        { title: 'Current Team Overview', link: '/analytics/team-overview' },
        { title: 'Fund Allocation Overview', link: '/analytics/allocation-report' },
        { title: 'Allowance Allocation', link: '/analytics/allowance-allocation-report' },
        { title: 'Rewards Report', link: '/analytics/rewards-report' },
        { title: 'Spending Report', link: '/analytics/spending-report' },
        { title: 'Payment Report', link: '/analytics/payment-report' },
        { title: 'Members', link: '/analytics/agency-members' },
        { title: 'Categories', link: '/analytics/categories' },
        { title: 'Reimbursements', link: '/analytics/reimbursements' },
        { title: 'All Teams Allowances', link: '/analytics/allowances' },
        { title: 'Current Team Allowances', link: '/analytics/team-allowances' },
        { title: 'Peer To Peer', link: '/analytics/peer-to-peer' }
    ];
    public links: { title: string, link: string }[] = this.allLinks;
    public activeLink: any;
    public analytics: any;
    public areReportsEnabled: boolean = false;

    constructor(
        private router: Router,
        private teamsService: TeamsService,
        private sessionService: SessionService,
        private spinnerService: SpinnerService,
        private featureFlagService: FeatureFlagService
    ) { 
        this.checkReportsEnabled();
    }

    async ngOnInit(): Promise<void> {
        const parsedUrl = this.router.parseUrl(this.router.url);
        const urlSegments = parsedUrl.root.children.primary.segments;
        urlSegments.length = 2;
        this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
        this.teamsService.wellspaceChangedObservable.subscribe(async wellspace => {
            if (wellspace) {
                this.spinnerService.show();
                // @ts-ignore
                this.teamsService.getTeamAnalytics(this.sessionService.getUserId(), wellspace.id).then(analytics => {
                    this.spinnerService.hide();
                    this.analytics = analytics;
                });
                await this.checkReportsEnabled();
            }
        });
    }

    async checkReportsEnabled() {
        this.areReportsEnabled = await this.featureFlagService.isEnabled('REPORTS');
        if (!this.areReportsEnabled) {
            this.links = this.allLinks.filter(link => {
                return ![
                    '/analytics/allowance-allocation-report',
                    '/analytics/rewards-report',
                    '/analytics/payment-report',
                    '/analytics/spending-report'
                ].includes(link.link);
            });
        } else {
            this.links = this.allLinks;
        }
    }

}
