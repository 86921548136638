import {AfterViewInit, Component, Input} from '@angular/core';
import {TeamsService} from '../../../../services/teams/teams.service';
import {UsersService} from '../../../../services/users/users.service';
import * as Highcharts from 'highcharts';
import * as WebDataRocks from 'webdatarocks';

@Component({
  selector: 'app-allowances-highcharts-container',
  templateUrl: './allowances-highcharts-container.component.html',
  styleUrls: ['../analytics-common.scss']
})
export class AllowancesHighchartsContainerComponent implements AfterViewInit {
  @Input() teamType: 'all-team' | 'current-team' = 'all-team';
  public allowanceActivity = [];
  public chartTypes = [
    'area', 'areaspline', 'bar',
    'column', 'waterfall', 'funnel',
    'pie', 'arearange', 'areasplinerange',
    'columnrange', 'errorbar', 'bubble',
    'scatter', 'polygon', 'spline'
  ];
  private pivot: any;
  showSpinner = true;

  constructor(
      private teamsService: TeamsService,
      private usersService: UsersService
  ) { }

  ngAfterViewInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe({
      next: (wellspace: any): void => {
        this.showSpinner = true;
        if (wellspace) {
          setTimeout(() => {
            this.teamsService.getMemberAnalytics(this.usersService.getCurrentUserId()).then((result: any) => {
              if (this.teamType === 'current-team') {
                this.allowanceActivity = result.filter((allowance: any) => allowance.teamName === wellspace.name);
              } else {
                this.allowanceActivity = result;
              }
              this.createChart();
            }).catch(err => {
              this.showSpinner = false;
              console.error('Error in getMemberAnalytics', err);
            });
          }, 600);
        }
      }, error: err => {
        this.showSpinner = false;
        console.error('Error in highcharts allowances container', err);
      }
    });
  }

  createChart(): void {
    const customizeToolbar = (toolbar: any): void  => {
      const tabs = toolbar.getTabs();
      toolbar.getTabs = () => {
        delete tabs[0];
        tabs.push({
          id: 'wdr-tab-default',
          title: 'Chart',
          handler: chartTypeHandler,
          icon: toolbar.icons.format,
          menu: this.chartTypes.map(chartType => ({title: chartType, id: 'wdr-tab-charttype-' + chartType, args: chartType, handler: chartTypeHandler}))
        });
        return tabs;
      };
    };
    const createChart = (chartType: string) => {
      // @ts-ignore
      pivot.highcharts.getData({
        type: chartType
      }, (data: any) => {
        Highcharts.chart('highchartsContainer', data);
      }, (data: any) => {
        Highcharts.chart('highchartsContainer', data);
      });
    };
    const chartTypeHandler = (args: any): void => {
      createChart(args);
    };
    this.pivot = new WebDataRocks({
      container: '#wdr-container',
      beforetoolbarcreated: customizeToolbar,
      report: {
        formats: [
          {
            name: '',
            thousandsSeparator: ',',
            decimalSeparator: '.',
            decimalPlaces: 2,
            currencySymbol: '$',
            currencySymbolAlign: 'left',
            nullValue: '',
            textAlign: 'right',
            isPercent: false
          }
        ],
        dataSource: {
          data: this.allowanceActivity
        },
        slice: {
          rows: [
            {
              uniqueName: 'transactionDate.Year'
            },
            {
              uniqueName: 'transactionDate.Month'
            },
            {
              uniqueName: 'transactionDate.Day'
            }
          ],
          columns: [
            {
              uniqueName: 'teamName'
            },
            {
              uniqueName: 'memberName'
            },
            {
              uniqueName: 'Measures'
            }
          ],
          measures: [
            {
              uniqueName: 'transactionAmount',
              aggregation: 'sum'
            }
          ]
        },
      }, // 'https://cdn.webdatarocks.com/reports/report.json',
      toolbar: true,
      reportcomplete: () => {
        this.pivot.off('reportcomplete');
        // createChart('line');
      }
    });
    this.showSpinner = false;
  }

}
