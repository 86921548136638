<h1 mat-dialog-title>
  Refund allowance to team member
</h1>
<div mat-dialog-content>
    <span class="teammembername">{{member.email}}</span>
    <span class="info">The amount you specify will be refunded from this team member's current allowance balance of ${{balance}}. You will be able to approve the refund before it happens.</span>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
            <mat-form-field>
                <mat-label>Amount to refund ($)</mat-label>
                <input type="number" [max]="balance" matInput placeholder="" formControlName="amount">
            </mat-form-field>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!form.valid" class="donebutton" color="primary" (click)="refundClicked()">Refund Allowance</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
