<div *ngIf="members" class="wrapper">
    <div class="topinfo">
        <div class="infoitem">
            <span class="title">Members</span>
            <mat-icon matTooltip="All people who are members of this team">help_outline</mat-icon>
        </div>
        <span class="count">{{members.length}}</span>
    </div>
    <div class="topinfo">
        <div class="infoitem">
            <span class="title">Weekly Active Members</span>
            <mat-icon matTooltip="Active members have either logged in or made a qualifying purchase">help_outline</mat-icon>
        </div>
        <span class="count">{{activeCount}}</span>
    </div>
    <div class="topinfo">
        <div class="infoitem">
            <span class="title">Reimbursements</span>
            <mat-icon matTooltip="Total reimbursements for this wellspace">help_outline</mat-icon>
        </div>
        <span class="count">${{totalReimbursements}}</span>
    </div>
</div>
