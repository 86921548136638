import {Component, Inject, OnInit} from '@angular/core';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-setteamcompanydialog',
  templateUrl: './setteamprivacydialog.component.html',
  styleUrls: ['./setteamprivacydialog.component.scss']
})
export class SetTeamPrivacyDialogComponent implements OnInit {

  public companies: any;
  public form: any;
  public dialogData;

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private formBuilder: UntypedFormBuilder,
      public dialogRef: MatDialogRef<SetTeamPrivacyDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: SetTeamPrivacyComponentModel
  ) {
    this.dialogData = data;
  }

  ngOnInit(): void {
      this.form = this.formBuilder.group({
        privacy: new UntypedFormControl(this.dialogData.privacy, [Validators.required]),
      });
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }

  removeCompany(): void {
    this.dialogRef.close('');
  }

  privacyChanged(event: any): void {
    this.form.get('privacy').setValue(event.checked);
  }

  setPrivacy(): void {
    this.dialogRef.close(this.form.get('privacy')?.value);
  }

}

export class SetTeamPrivacyComponentModel {
  constructor(public privacy: boolean) {
  }
}
