<h1 mat-dialog-title>
  Login as a Team Member
</h1>
<div mat-dialog-content>
    <app-team-member-selector [multiple]="false" [team]="team" class="teamlist" (membersSelectedEvent)="membersChanged($event)"></app-team-member-selector>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!member" class="donebutton" color="primary" (click)="loginAsMember()">Login as Member</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
