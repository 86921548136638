<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content>
  <p #message></p>
</div>
<div class="buttons" mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{okOnly ? 'OK' : 'Yes'}}</button>
  <button *ngIf="!okOnly" mat-button (click)="onDismiss()">No</button>
</div>
