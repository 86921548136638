import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TeamsService} from '../../services/teams/teams.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-team-member-selector',
  templateUrl: './team-member-selector.component.html',
  styleUrls: ['./team-member-selector.component.scss'],
})
export class TeamMemberSelectorComponent implements OnInit {

  @Input() team: any;
  @Input() multiple = true;
  @Output() membersSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  public members: Array<any> | undefined;
  public filteredMembers: Array<any> = [];
  private searchValue = '';

  constructor(
      private teamsService: TeamsService,
      private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any) => {
      const thisTeam = this.team ? this.team : wellspace;
      this.teamsService.getTeamMembers(thisTeam?.id).then(members => {
        this.teamsService.buildMemberList(members, thisTeam);
        this.members = members;
        this.filteredMembers = JSON.parse(JSON.stringify(this.members));
      });
    });
  }

  private membersUpdated(): void {
    this.filteredMembers = JSON.parse(JSON.stringify(this.members?.filter((member: any) => !this.searchValue || member.email.indexOf(this.searchValue) >= 0)));
    this.membersSelectedEvent.emit(this.members?.filter((member: any) => member.selected));
  }

  memberCheckboxClicked(event: any, filteredMember: any): void {
    if (!filteredMember.userId) {
      this.snackbar.openFromComponent(SnackBarComponent, {data: 'You can only send payments to users who have already signed up.'});
      event.preventDefault();
      return;
    }
    if (!this.multiple) {
      this.members?.forEach(thisMember => thisMember.selected = false);
    }
    const member = this.members?.find((thisMember: any) => thisMember.email === filteredMember.email);
    member.selected = member.selected ? false : true;
    this.membersUpdated();
  }

  selectAll(): void {
    this.members?.filter((member) => member.id).forEach((member: any) => member.selected = true);
    this.membersUpdated();
  }

  deselectAll(): void {
    this.members?.forEach((member: any) => member.selected = false);
    this.membersUpdated();
  }

  filterItems(event: any): void {
    this.searchValue = event.target.value;
    this.membersUpdated();
  }

}
