import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TeamsService} from '../../services/teams/teams.service';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {UsersService} from '../../services/users/users.service';
import {StripeService} from '../../services/stripe/stripe.service';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../../services/spinner/spinner.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
declare var ExcelJS: any;
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-team-add-members',
  templateUrl: './team-add-members.page.html',
  styleUrls: ['./team-add-members.page.scss'],
})
export class TeamAddMembersPageComponent implements OnInit, AfterViewInit {

  @ViewChild('email') emailControl: any;

  public members: Array<any> = [];
  public newMembers: Array<any> = [];
  public form: any;
  public result: any;
  public uploading = false;
  public currentWellspace: any;
  public isAdmin = this.usersService.isAdmin();

  constructor(
      private route: ActivatedRoute,
      private teamsService: TeamsService,
      private formBuilder: UntypedFormBuilder,
      private usersService: UsersService,
      private stripeService: StripeService,
      private router: Router,
      private snackBar: MatSnackBar,
      private spinnerService: SpinnerService,
      private httpClient: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.teamId && params.teamId !== 'current') {
        this.teamsService.getTeamInfo(params.teamId).then(team => {
          this.currentWellspace = team;
          this.loadTeamMembers();
        });
      } else {
        this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
          if (wellspace) {
            this.currentWellspace = wellspace;
            this.loadTeamMembers();
          }
        });
      }
    });
    this.form = this.formBuilder.group({
      email: new UntypedFormControl('', Validators.required),
    });
  }

  ngAfterViewInit(): void {
    this.emailControl.nativeElement.focus();
  }

  loadTeamMembers(): void {
    this.teamsService.getTeamMembers(this.currentWellspace.id, true).then(members => {
      this.newMembers = this.teamsService.buildMemberList(members, this.currentWellspace);
      this.members = members;
    });
  }

  addMember(): void {
    this.spinnerService.show('Loading...');
    this.teamsService.checkTeamPendingCharges(this.currentWellspace.id).then((res: any): void => {
      if (res && res.hasPendingCharges) {
        this.spinnerService.hide();
        this.snackBar.openFromComponent(SnackBarComponent, {
          data: 'A transfer is in progress at the moment. To ensure the integrity of the operation, ' +
              'it is not possible to make further changes at this time. Please wait until the transfer is completed to proceed with your actions.'
        });
      }else {
        const email = this.form.get('email').value.trim();
        this.uploading = true;
        this.teamsService.addTeamMember(this.currentWellspace.id, email).then(id => {
          this.form.get('email').setValue('');
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Team member added'});
          this.newMembers.push(email);
          this.loadTeamMembers();
          this.uploading = false;
          this.spinnerService.hide();
        }).catch(error => {
          this.uploading = false;
          if (error.status === 409) {
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'Team member already in workspace'});
          } else {
            this.snackBar.openFromComponent(SnackBarComponent, {data: error.error});
          }
          this.spinnerService.hide();
        });
      }
    });

  }

  memberDeleted(member: any, dontDelete: boolean): void {
    if (member.balance > 0) {
      this.teamsService.getCustomerId(this.currentWellspace).then(customerId => {
        this.stripeService.issueRefund(customerId, member.balance).then(_ => {
          if (dontDelete) {
            this.teamsService.modifyTeamMember(this.currentWellspace.id, member.memberId, {balance: 0}).then(__ => {
              this.loadTeamMembers();
            });
          } else {
            this.loadTeamMembers();
          }
        });
      });
      this.snackBar.openFromComponent(SnackBarComponent, {data: `This team member has a remaining balance of $${member.balance.toFixed(2)} which will be refunded to your default payment method.`});
    } else {
      this.loadTeamMembers();
    }
  }

  uploadMembers(data: any): void {
    this.result = [];
    this.teamsService.uploadMemberList(this.currentWellspace.id, data).then(result => {
      this.result = result;
      this.snackBar.openFromComponent(SnackBarComponent, {data: `${result.newCount} members added.`});
      this.loadTeamMembers();
    }).catch(error => {
      this.snackBar.openFromComponent(SnackBarComponent, error.error);
    });
  }

  exportCensus(): void {
    const sheetMembers: Array<any> = this.members.map(member => ({
      dob: new Date(member.birthdate ? moment(member.birthdate).format('MM/DD/YYYY') : '01/01/1978'),
      sex: 'Male',
      zip: member.postalCode || '00000',
      class: 'Employee',
      type: 'Employee',
      tobacco: 'No',
      firstName: member.firstName || 'None',
      lastName: member.lastName.split(' (')[0] || 'None',
      ftpt: 'Full Time',
      email: member.email
    }));
    this.httpClient.get('/assets/files/myhealthily-census.xlsx', {responseType: 'arraybuffer'}).subscribe(data => {
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.load(data).then(() => {
        const workSheet = workbook.getWorksheet('Sheet1');
        workSheet.tables = {};
        workSheet.insertRows(5, sheetMembers.map(member => Object.values(member)));
        for (let i = 5; i < 5 + sheetMembers.length; i++) {
          workSheet.getCell(i, 1).numFmt = 'mm/dd/yyyy';
        }
        const fileName = `${this.currentWellspace.name}-census.xlsx`;
        workbook.xlsx.writeBuffer().then( (d: any) => {
          const blob = new Blob( [d], {type: 'application/octet-stream'} );
          saveAs( blob, fileName);
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Team member census exported to ${fileName}.`});
        });
      });
    });
  }

}
