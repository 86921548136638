import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-analytics-team-allowances',
  templateUrl: './analytics-team-allowances.component.html',
  styleUrls: ['./analytics-team-allowances.component.scss']
})
export class AnalyticsTeamAllowancesComponent {
  @Input() analytics: any;
}
