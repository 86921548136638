
<h1>Enter Validation Code</h1>

<div class="content">
    <span>We need to validate your email address. Please check your email for a code.</span>
    <p>We've sent a 6-digit code to <b>{{changedEmail ? changedEmail : email}}</b>. <br>The code expires shortly, so please enter it soon.</p>
    <div class="inputwrapper">
        <code-input [isCodeHidden]="false"
            [isNonDigitsCode]="false"
            [codeLength]="6"
            (codeCompleted)="onCodeCompleted($event)">
        </code-input>
    </div>
    <div class="page-footer">
        <span class="spam">Can’t find your code? Check your spam folder!</span>
        <span class="button" (click)="sendEmail()">Resend Validation Code</span>
        <span class="button" (click)="logOut()">Sign Out</span>
    </div>

</div>