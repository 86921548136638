<ng-container *ngIf="enabled">
    <ng-container *ngIf="producers.data.length === 0">
        <app-inlinemessage class="info" level="warning" message="There are no members assigned to clients for this agency. A producer is a member assigned to one or more clients."></app-inlinemessage>
    </ng-container>
    <table mat-table matSort class="table" *ngIf="producers.data.length > 0" [dataSource]="producers">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Producer</th>
            <td mat-cell *matCellDef="let item">{{item.name}}</td>
        </ng-container>
        <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef>Client</th>
            <td mat-cell *matCellDef="let item">{{item.clientName}}</td>
        </ng-container>
        <ng-container matColumnDef="actionmenu" stickyEnd>
            <th mat-header-cell matTooltip="Agency Actions" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item"><button mat-button class="cell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button></td>
        </ng-container>
        <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-item="item">
                <button mat-menu-item (click)="removeProducer(item)">Remove Producer</button>
            </ng-template>
        </mat-menu>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
    </table>
</ng-container>
