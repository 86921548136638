<table class="table">
    <tr lines="none" [class]="invoice.expanded ? 'expanded' : ''" *ngFor="let invoice of invoices">
        <!--<div class="itemwrapper">
                <div class="itemtop">-->
        <td class="chargedate">
            <mat-icon class="expandcollapse" (click)="expandCollapse(invoice)">
                {{ invoice.expanded ? 'arrow_drop_down' : 'arrow_right' }}
            </mat-icon>
            {{ invoice.date | date : 'MMM d, y, h:mm:ss a' }}
        </td>
        <td text-wrap class="chargedescription" (click)="openInvoice(invoice.url)">{{ invoice.info }}</td>
        <td class="chargeamount">{{ invoice.amount | currency }}</td>
        <td class="chargedescription">
            {{ invoice.status }}
        </td>
        <!--</div>-->
        <div class="detailswrapper" *ngIf="invoice.expanded">
            <span>{{ invoice.details.paymentType }}</span>
            <div class="details-container">
                <div class="details" *ngFor="let line of invoice?.details?.fee">
                    <div class="description">{{ line.description }}</div>
                    <div class="amount">{{ line?.amount | currency }}</div>
                    <div class="diff">{{ line?.diff | currency }}</div>
                </div>
            </div>
        </div>
        <!--</div>-->
    </tr>
</table>
