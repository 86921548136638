<button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: fileName})"><mat-icon>file_download</mat-icon>Export to CSV</button>
<table class="table" mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="items">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">User Name</th>
      <td class="cell" mat-cell *matCellDef="let item">{{item.name}}</td>
    </ng-container>
    <ng-container matColumnDef="transactionCount" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Reimbursements</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.transactionCount}} </td>
    </ng-container>
    <ng-container matColumnDef="totalAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Total Amount</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.totalAmount | currency}} </td>
    </ng-container>
    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Balance</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.balance | currency}} </td>
    </ng-container>
    <ng-container matColumnDef="disabled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Status</th>
      <td class="cell" mat-cell *matCellDef="let item"> {{item.disabled ? 'On Hold' : 'Active'}} </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="cell" mat-cell *matCellDef="let item">
          <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="memberMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
      </td>
    </ng-container>
    <mat-menu #memberMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="showReimbursements(item)">Show Reimbursements</button>
        </ng-template>
    </mat-menu>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
