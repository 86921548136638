import {Component, OnInit, ViewChild} from '@angular/core';
import {FieldType, MemberAddeditComponent, MemberAddeditDialogModel} from '../member-addedit/member-addedit.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {VendorGroupsQuestionsService} from '../../../services/vendor-groups-questions/vendor-groups-questions.service';
import {VendorGroupsPlansService} from '../../../services/vendor-groups-plans/vendor-groups-plans.service';
import {VendorGroupsProductsService} from '../../../services/vendor-groups-products/vendor-groups-products.service';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-vendor-groups-questions',
  templateUrl: './vendor-groups-questions.component.html',
  styleUrls: ['./vendor-groups-questions.component.scss']
})
export class VendorGroupsQuestionsComponent implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;

  protected allColumnNames = ['groupPlanId', 'category', 'sequence', 'questionText', 'type', 'metadata', 'actionmenu'];
  protected items = new MatTableDataSource<any>();
  private plans: any;
  private groupProducts: any;

  private addEditFields = [
    {name: 'groupPlanId', label: 'Vendor Plan', fieldType: FieldType.F_DROPDOWN, values: [], required: true},
    {name: 'category', label: 'Category', fieldType: FieldType.F_STRING, required: true},
    {name: 'sequence', label: 'Sequence #', fieldType: FieldType.F_NUMBER, required: true},
    {name: 'questionText', label: 'Question Text', fieldType: FieldType.F_STRING, required: true},
    {name: 'type', label: 'Type', fieldType: FieldType.F_DROPDOWN, values: ['Yes/No:boolean', 'Text:string', 'Number:number'], required: true},
    {name: 'metadata', label: 'Metadata', fieldType: FieldType.F_JSON, required: false},
  ];

  constructor(
      private vendorGroupsPlansService: VendorGroupsPlansService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private vendorGroupsQuestionsService: VendorGroupsQuestionsService,
      private vendorGroupsProductsService: VendorGroupsProductsService
  ) {

  }

  ngOnInit(): void {
    this.reloadItems();
  }

  getGroupPlanName(item: any): string {
    const plan = this.plans?.find((thisPlan: { id: any; }) => thisPlan.id === item.groupPlanId);
    const product = this.groupProducts?.find((thisProduct: { id: any; }) => thisProduct.id === plan?.vendorGroupProductId)
    return plan ? `${product?.vendorProductName}-${plan?.metadata.tier}` : '';
  }

  private reloadItems(): void {
    this.vendorGroupsQuestionsService.getAllVendorGroupQuestions().then(questions => {
      this.items.data = questions;
      this.items.sort = this.sort;
    });
    this.vendorGroupsPlansService.getAllVendorGroupPlans().then(plans => {
      this.plans = plans;
      this.vendorGroupsProductsService.getAllVendorGroupProducts().then(products => {
        this.groupProducts = products;
        this.addEditFields[0].values = plans.map((plan: any) => {
          const product = this.groupProducts.find(((thisProduct: { id: any; }) => plan.vendorGroupProductId === thisProduct.id));
          return `${product.vendorProductName}-${plan.metadata.tier}:${plan.id}`;
        }).sort();
      });
    });
  }

  addItem(): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(null, this.addEditFields, 'Group Question')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.vendorGroupsQuestionsService.createVendorGroupQuestion(dialogResult).then(() => {
          this.reloadItems();
          this.snackBar.open('New group question created');
        });
      }
    });
  }

  editItem(item: any): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(item, this.addEditFields, 'Group Question')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.vendorGroupsQuestionsService.modifyVendorGroupQuestion(item.id, dialogResult).then(() => {
          this.reloadItems();
          this.snackBar.open('Group question updated');
        });
      }
    });
  }

  duplicateItem(item: any): void {
    this.vendorGroupsQuestionsService.createVendorGroupQuestion(item).then(() => {
      this.reloadItems();
      this.snackBar.open('Duplicate group question created');
    });
  }

  removeItem(item: any): void {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this group question?').then(ok => {
      this.vendorGroupsQuestionsService.deleteVendorGroupQuestion(item.id).then(() => {
        this.reloadItems();
        this.snackBar.open('Group question removed');
      });
    });
  }

  truncateJSONString(str: string, maxLength: number): string {
    str = JSON.stringify(str);
    return str.length <= maxLength ? str : str.slice(0, maxLength - 3) + '...';
  }

}
