import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as WebDataRocks from 'webdatarocks';
import {WebdatarocksComponent} from 'ng-webdatarocks';
import {TeamsService} from '../../../../services/teams/teams.service';
import {UsersService} from '../../../../services/users/users.service';

@Component({
  selector: 'app-highcharts-container',
  templateUrl: './highcharts-container.component.html',
  styleUrls: ['../analytics-common.scss']
})
export class HighchartsContainerComponent implements AfterViewInit {
  @ViewChild('pivot1') child?: WebdatarocksComponent;
  @Input() teamType: 'all-team' | 'current-team' = 'all-team';
  public chartTypes = [
    'area', 'areaspline', 'bar',
    'column', 'waterfall', 'funnel',
    'pie', 'arearange', 'areasplinerange',
    'columnrange', 'errorbar', 'bubble',
    'scatter', 'polygon', 'spline'
  ];
  private allTeamAnalytics: any;
  private pivot: any;
  showSpinner = true;

  constructor(
      private teamsService: TeamsService,
      private usersService: UsersService
  ) {}

  ngAfterViewInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe({
      next: (wellspace: any): void => {
        this.showSpinner = true;
        if (wellspace) {
          setTimeout(() => {
            this.teamsService.getAllTeamsAnalytics(this.usersService.getCurrentUserId()).then(result => {
              if (this.teamType === 'current-team') {
                this.allTeamAnalytics = result.transactions.filter((transaction: any) => transaction.teamName === wellspace.name);
              } else {
                this.allTeamAnalytics = result.transactions;
              }
              this.createChart();
            });
          }, 600);
        }
      },
      error: err => {
        this.showSpinner = false;
        console.error('Error in highcharts container', err);
      }
    });
  }

  createChart(): void {
    const highchartsContainer = document.getElementById('highchartsContainer');
    if (highchartsContainer) {
      const createChart = (chartType: string) => {
        // @ts-ignore
        pivot.highcharts.getData({
          type: chartType
        }, (data: any) => {
          Highcharts.chart('highchartsContainer', data);
        }, (data: any) => {
          Highcharts.chart('highchartsContainer', data);
        });
      };
      const customizeToolbar = (toolbar: any): void  => {
        const tabs = toolbar.getTabs();
        toolbar.getTabs = () => {
          delete tabs[0];
          tabs.push({
            id: 'wdr-tab-default',
            title: 'Chart',
            handler: chartTypeHandler,
            icon: toolbar.icons.format,
            menu: this.chartTypes.map(chartType => ({title: chartType, id: 'wdr-tab-charttype-' + chartType, args: chartType, handler: chartTypeHandler}))
          });
          return tabs;
        };
      };
      const chartTypeHandler = (args: any): void => {
        createChart(args);
      };
      this.pivot = new WebDataRocks({
        container: '#wdr-container',
        beforetoolbarcreated: customizeToolbar,
        report: {
          formats: [
            {
              name: '',
              thousandsSeparator: ',',
              decimalSeparator: '.',
              decimalPlaces: 2,
              currencySymbol: '$',
              currencySymbolAlign: 'left',
              nullValue: '',
              textAlign: 'right',
              isPercent: false
            }
          ],
          dataSource: {
            data: this.allTeamAnalytics
          },
          slice: {
            rows: [
              {
                uniqueName: 'memberName'
              }
            ],
            columns: [
              {
                uniqueName: 'teamName'
              },
              {
                uniqueName: 'Measures'
              }
            ],
            measures: [
              {
                uniqueName: 'amount',
                aggregation: 'sum'
              }
            ]
          },
        }, // 'https://cdn.webdatarocks.com/reports/report.json',
        toolbar: true,
        reportcomplete: () => {
          this.pivot.off('reportcomplete');
          createChart('line');
        }
      });
      this.showSpinner = false;
    }
  }

}
