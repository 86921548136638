import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {ClientsInterface} from '../../components/clients/clients.interface';
import {SessionService} from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private restAngular: Restangular, private sessionService: SessionService) { }

  linkCustomerToClient(clientId: string, teamId: string): Promise<any> {
    return this.restAngular.one('teams', teamId).customPUT({clientId: `${clientId}`}).toPromise();
  }

  createInvoice(teamId: string, isInvoicePlan: boolean): Promise<any> {
    return this.restAngular.one('teams', teamId).one('invoice', isInvoicePlan).customPUT().toPromise();
  }

  getAllClients(): Promise<ClientsInterface[]> {
    return this.restAngular.one('clients').get().toPromise();
  }

  createNewClient(client: ClientsInterface): Promise<void> {
    return this.restAngular.one('clients').customPOST(client).toPromise();
  }

  updateClient(client: ClientsInterface): Promise<void> {
    return this.restAngular.one('clients', client.id).customPUT(client).toPromise();
  }

  checkUserFeaturePermission(): boolean {
    const allowedUsers: string[] = [
        'c0d1bb40-48f2-11ee-a5ee-1733f14a64bf', '214db3d0-48f2-11ee-a5ee-1733f14a64bf' , 'f5d62fa0-889a-11ee-a2ac-077d5bf25284'
    ];
    return allowedUsers.includes(this.sessionService.getUserId());
  }
}
