import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {StripeService} from '../../services/stripe/stripe.service';
import {TeamsService} from '../../services/teams/teams.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {DomSanitizer} from '@angular/platform-browser';

interface PaymentInfo {
  id: string;
  logo: string;
  info1: string;
  info2: string;
  stripePaymentMethod: string;
  microdepositInfo: string;
  default?: boolean;
}

@Component({
  selector: 'app-stripepaymentmethods',
  templateUrl: './stripepaymentmethods.component.html',
  styleUrls: ['./stripepaymentmethods.component.scss']
})
export class StripePaymentMethodsComponent implements OnInit {

  public items = new MatTableDataSource<PaymentInfo>();
  public columnsToDisplay = ['default', 'logo', 'info1', 'status-info',  'info2', 'info3', 'verify-info', 'action'];
  private team: any;
  microDepositWindow: Window | null = null;

  constructor(
      private stripeService: StripeService,
      private teamsService: TeamsService,
      private uiAlertService: UiAlertService,
      private snackBar: MatSnackBar,
      private domSantizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      if (wellspace) {
        this.team = wellspace;
        this.loadPaymentMethods().then(() => {
          if (this.items.data.length === 1) {
            if (!this.items.data[0].default) {
              this.setDefaultPaymentMethod(this.items.data[0]);
            }
          }
        });
      }
    });
  }

  loadPaymentMethods(): Promise<void> {
    return this.teamsService.getPaymentMethods(this.team.id).then(result => {
      result = result.filter((item: any) => item.stripePaymentMethod || item.microdepositInfo || item?.bankInfo?.type === 'invoice');
      result.forEach((item: any) => {
        if (item.bankInfo.logo) {
          item.bankInfo.logo = this.domSantizer.bypassSecurityTrustUrl(item.bankInfo.logo);
        }
        item.info1 = item?.bankInfo?.type === 'invoice' ? 'Invoice' : `${item.bankInfo.bankName ?? ''} ****${item.stripeLast4 ?? ''}`;
        item.info2 = item.bankInfo.expires || '';
        item.info3 = `${item.paymentTargets.join(', ')}`;
        item.default = item.stripePaymentMethod === this.team.defaultPaymentMethod;
      });
      this.items.data = result;
      if (result.length === 1) {
        this.teamsService.modifyTeam(this.team.id, {defaultPaymentMethod: result[0].stripePaymentMethod}).then(() => {
          result[0].default = true;
          this.items.data = this.items.data.slice();
        });
      }
    });
  }

  setDefaultPaymentMethod(item: PaymentInfo): void {
    this.teamsService.modifyTeam(this.team.id, {defaultPaymentMethod: item.stripePaymentMethod}).then(() => {
      this.team.defaultPaymentMethod = item.stripePaymentMethod;

      let wellspaceFromLocalhost: any = localStorage.getItem('selected-wellspace');
      if (wellspaceFromLocalhost){
        wellspaceFromLocalhost = JSON.parse(wellspaceFromLocalhost);
        wellspaceFromLocalhost.defaultPaymentMethod = item.stripePaymentMethod;
        localStorage.setItem('selected-wellspace', JSON.stringify(wellspaceFromLocalhost));
      }
      this.loadPaymentMethods();
    });
  }

  removePaymentMethod(item: PaymentInfo): void {
    let message = 'Do you really want to remove this payment method?';
    if (this.items.data.length === 1) {
      message = 'This is the only payment method you have. If you remove it, any allowances or premium services will not renew and you will not be able to deliver instant rewards. ' + message;
    } else {
      if (item.stripePaymentMethod === this.team.defaultPaymentMethod) {
        message = 'This is your default payment method. If you remove it, your first payment method will be set to be the default. ' + message;
      }
    }
    this.uiAlertService.presentAlertConfirm(message).then(ok => {
      if (ok) {
        this.stripeService.removeCard(item.id).then(() => {
          this.items.data.splice(this.items.data.findIndex(thisItem => item.id === thisItem.id), 1);
          this.items.data = this.items.data.slice();
          this.teamsService.modifyTeam(this.team.id, {defaultPaymentMethod: this.items.data.length === 0 ? '' : this.items.data[0].stripePaymentMethod}).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'Payment method successfully removed'});
          });
        });
      }
    });
  }

  openStripeVerifyAccountModal(microDepositUrl: string): void {
    if (microDepositUrl && microDepositUrl !== '') {
      this.microDepositWindow = window.open(microDepositUrl, '_blank');
      window.onfocus = () => window.location.reload();
    }
  }

}
