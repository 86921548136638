import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {TeamsService} from '../../../services/teams/teams.service';

interface CategoryInfo {
  name: string;
  transactionCount: number;
  totalAmount: number;
}

@Component({
  selector: 'app-analytics-categories',
  templateUrl: './analytics-categories.component.html',
  styleUrls: ['./analytics-categories.component.scss']
})
export class AnalyticsCategoriesComponent implements OnInit, OnChanges {

  @Input() analytics: any;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public items = new MatTableDataSource<CategoryInfo>();
  public columnsToDisplay = ['name', 'transactionCount', 'totalAmount'];
  public fileName = '';

  constructor(
      private teamsService: TeamsService
  ) {
  }

  ngOnInit(): void {
    this.loadItems();
  }

  ngOnChanges(): void {
    this.loadItems();
  }

  private loadItems(): void {
    this.items.data = [];
    if (this.analytics) {
      this.analytics.transactions.forEach((transaction: any) => {
        const foundItem = this.items.data.find(item => item.name === transaction.category);
        if (!foundItem) {
          this.items.data.push({name: transaction.category, transactionCount: 1, totalAmount: transaction.amount});
        } else {
          if (transaction) {
            foundItem.transactionCount++;
            foundItem.totalAmount += transaction.amount;
          }
        }
      });
      this.items.sort = this.sort;
      this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
        if (wellspace) {
          // @ts-ignore
          this.fileName = `${wellspace.name} categories`;
        }
      });
      }
  }

}
