import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {TeamprogramsService} from '../../../services/teamprograms/teamprograms.service';
import {TeamsService} from '../../../services/teams/teams.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TeamApprovalsService} from '../../../services/teamapprovals/teamapprovals.service';

@Component({
  selector: 'app-editapproverdialog',
  templateUrl: './editapproverdialog.component.html',
  styleUrls: ['./editapproverdialog.component.scss']
})
export class EditApproverDialogComponent implements OnInit {

  public form;
  public approval;
  private team: any;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private teamsService: TeamsService,
      private snackBar: MatSnackBar,
      public dialogRef: MatDialogRef<EditApproverDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: EditApproverDialogModel
  ) {
    this.approval = data.approval;
    this.form = this.formBuilder.group({
      amount: new UntypedFormControl(this.approval.amount, [Validators.required]),
      email: new UntypedFormControl(this.approval.email, [Validators.required]),
      name: new UntypedFormControl(this.approval.name)
    });
  }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      this.team = wellspace;
    });
  }

  private getFormField(name: string): any {
    const field = this.form.get(name);
    return field ? field.value : undefined;
  }

  okClicked(): void {
    this.approval.amount = this.getFormField('amount');
    this.approval.email = this.getFormField('email');
    this.approval.name = this.getFormField('name');
    this.dialogRef.close(this.approval);
  }

  onDismiss(): void {
    this.dialogRef.close(0);
  }

}

export class EditApproverDialogModel {
  constructor(public approval: any) {
  }
}

