<div class="header">
    <h1>Provisioning</h1>
    <mat-slide-toggle (change)="sandBoxChanged($event)">Sandbox</mat-slide-toggle>
</div>
<ng-template #itemlist let-items="items" let-type="type">
    <mat-menu #itemMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="setCurrentItem(type, item)">Set Current</button>
            <button mat-menu-item (click)="viewItem(type, item)">Details</button>
        </ng-template>
    </mat-menu>
    <mat-list class="itemlist fixed-column" *ngIf="items.length > 0">
        <mat-list-item *ngFor="let item of items" class="hover-highlight">
            <div class="listitem">
                <span>{{item.name}}</span>
                <button mat-button [matMenuTriggerFor]="itemMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more">more_vert</mat-icon></button>
            </div>
        </mat-list-item>
    </mat-list>
</ng-template>
<div class="pagewrapper">
    <div>
        <app-provisioning-tile headerClass="color6" title="Product Integration">
            <ng-container middle>
                <form [formGroup]="vendorProductForm">

                </form>
                <ng-container *ngTemplateOutlet="itemlist; context: { items: vendorProducts, type: 'vendorproduct' }"></ng-container>
            </ng-container>
        </app-provisioning-tile>
        <app-provisioning-tile headerClass="color1" title="Branding">
            <ng-container middle>
                <form [formGroup]="brandingForm">
                    <mat-form-field>
                        <mat-label>Brand Logo</mat-label>
                        <div class="logowrapper">
                            <img class="logoavatar" #avatar>
                            <input matInput class="hidden" type="text">
                        </div>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput #input placeholder="" formControlName="name">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>URL Subdomain</mat-label>
                        <input matInput #input placeholder="" formControlName="url">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Primary Color</mat-label>
                        <div class="colorwrapper">
                            <input matInput #input placeholder="" formControlName="fgColor">
                            <div class="swatch" [ngStyle]="{background: brandingForm.get('fgColor')?.value}"></div>
                        </div>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Secondary Color</mat-label>
                        <div class="colorwrapper">
                            <input matInput #input placeholder="" formControlName="bgColor">
                            <div class="swatch" [ngStyle]="{background: brandingForm.get('bgColor')?.value}"></div>
                        </div>
                    </mat-form-field>
                </form>
                <ng-container *ngTemplateOutlet="itemlist; context: { items: partnerItems, type: 'partner' }"></ng-container>
            </ng-container>
            <ng-container buttons>
                <button mat-raised-button color="secondary" (click)="brandingShuffle()">Shuffle</button>
                <button mat-raised-button color="primary" (click)="brandingDone()">Create {{brandingCount}} Brand{{brandingCount != 1 ? 's' :''}}</button>
                <mat-slider min="1" max="10" step="1" showTickMarks discrete (change)="onBrandingCountChange($event)">
                    <input matSliderThumb>
                </mat-slider>
            </ng-container>
        </app-provisioning-tile>
        <app-provisioning-tile headerClass="color2" [title]="'Company' + (currentPartner ? ' in ' + currentPartner.name : '')">
            <ng-container middle>
                <div class="innermiddle">
                    <form [formGroup]="companyForm">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input matInput #input placeholder="" formControlName="name">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>EIN</mat-label>
                            <input matInput #input placeholder="" formControlName="ssn">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>SIC Code</mat-label>
                            <input matInput #input placeholder="" formControlName="sic">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Address 1</mat-label>
                            <input matInput #input placeholder="" formControlName="address1">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>City</mat-label>
                            <input matInput #input placeholder="" formControlName="city">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>State</mat-label>
                            <input matInput #input placeholder="" formControlName="state">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Zip</mat-label>
                            <input matInput #input placeholder="" formControlName="zip">
                        </mat-form-field>
                    </form>
                    <ng-container *ngTemplateOutlet="itemlist; context: { items: companyItems, type: 'company' }"></ng-container>
                </div>
            </ng-container>
            <ng-container buttons>
                <button mat-raised-button color="secondary" (click)="companyShuffle()">Shuffle</button>
                <button mat-raised-button color="primary" (click)="companyDone()">Create Company</button>
            </ng-container>>
        </app-provisioning-tile>
        <app-provisioning-tile headerClass="color3" [title]="'Team' + (currentCompany ? ' in ' + currentCompany.name : '')">
            <ng-container middle>
                <div class="innermiddle">
                    <form [formGroup]="teamForm">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input matInput #input placeholder="" formControlName="name">
                        </mat-form-field>
                    </form>
                    <ng-container *ngTemplateOutlet="itemlist; context: { items: teamItems, type: 'team' }"></ng-container>
                </div>
            </ng-container>
            <ng-container buttons>
                <button mat-raised-button color="secondary" (click)="teamShuffle()">Shuffle</button>
                <button mat-raised-button color="primary" (click)="teamDone()">Create Team</button>
            </ng-container>
        </app-provisioning-tile>
        <app-provisioning-tile headerClass="color4" [title]="'Member' + (currentTeam ? ' in ' + currentTeam.name : '')">
            <ng-container middle>
                <div class="innermiddle">
                    <form [formGroup]="memberForm">
                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input matInput #input placeholder="" formControlName="email">
                        </mat-form-field>
                    </form>
                    <ng-container *ngTemplateOutlet="itemlist; context: { items: memberItems, type: 'member' }"></ng-container>
                </div>
            </ng-container>
            <ng-container buttons>
                <button mat-raised-button color="secondary" (click)="memberShuffle()">Shuffle</button>
                <button mat-raised-button color="primary" (click)="memberDone()">Create Member</button>
            </ng-container>
        </app-provisioning-tile >
    </div>
    <div class="rightside">
        <app-provisioning-tile headerClass="color5" title="Action Log">
            <ng-container middle>
                <div *ngFor="let logItem of logItems">{{logItem}}<br></div>
            </ng-container>
            <ng-container buttons>
                <button [disabled]="logItems.length === 0" mat-raised-button color="secondary" (click)="clearLog()">Clear All</button>
                <button [disabled]="logItems.length === 0" mat-raised-button color="primary" (click)="logCopyToClipboard()">Copy to Clipboard</button>
            </ng-container>
        </app-provisioning-tile>
    </div>
</div>
