import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-advertising-flights',
  templateUrl: './advertising-flights.component.html',
  styleUrls: ['./advertising-flights.component.scss']
})
export class AdvertisingFlightsComponent implements OnInit {

  public config: TableConfig = {
    title: 'Flights',
    dbTableName: 'flights',
    expand: [],
    columns: [
      {name: 'name', title: 'Flight Name'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
