import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StripeService} from '../../services/stripe/stripe.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../snackbar/snackbar.component';
import {TeamsService} from '../../services/teams/teams.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  private allLinks = [
    { title: 'Payment Methods', link: '/billing/stripepaymentmethods'},
    { title: 'Add Available Payment Method', link: '/billing/stripeavailablepaymentmethods'},
    { title: 'Add Payment Method', link: '/billing/stripecreditcard'},
    { title: 'Charge History', link: '/billing/stripechargehistory'},
  ];
  public links: any;
  public activeLink: any;
  public badgeCount = 0;
  public balance = 0;

  constructor(
      private router: Router,
      private stripeService: StripeService,
      private route: ActivatedRoute,
      private snackBar: MatSnackBar,
      private teamsService: TeamsService
  ) { }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe((res: any) => {
      const wellspace = res || JSON.parse(localStorage.getItem('selected-wellspace') as string)
      this.links = wellspace.role ? [this.allLinks[3]] : this.allLinks;
    });
    this.route.queryParams.subscribe(params => {
      if (params.teamName) {
        this.snackBar.openFromComponent(SnackBarComponent, {data: `Payment method added to ${params.teamName}`});
      }
    });
    this.activeLink = this.links.find((link: { link: string; }) => link.link === this.router.url.split('?')[0]);
    this.stripeService.pendingACHCount.subscribe(count => {
      this.badgeCount = count;

      this.stripeService.getCustomerBalance().then(balance => {
        this.balance = balance / 100;
      });
    });
  }

}
