import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { TeamsService } from '../teams/teams.service';
import { AppSettings } from '../../app.settings';
import { UiAlertService } from '../ui-alert/ui-alert.service';
import { SessionService } from '../session/session.service';
import { BehaviorSubject } from 'rxjs';
import { SnackBarComponent } from '../../components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Dictionary } from 'highcharts';
import { SpinnerService } from '../spinner/spinner.service';
import { HttpErrorResponse } from '@angular/common/http';

declare var Stripe: any;

const stripe = Stripe(AppSettings.getStripeInfo().key,
    { betas: ['us_bank_account_beta_2'] }
);

export enum Plan {
    Free = 'Free',
    Plus = 'Plus',
    PlusAnnual = 'PlusAnnual'
}

@Injectable({
    providedIn: 'root'
})
export class StripeService {

    public PREMIUM_MONTHLY_FEE = 7.00;
    public PREMIUM_ANNUAL_FEE = 66.00;
    public STANDARD_CATEGORIES = 6;

    public pendingACHCount = new BehaviorSubject(0);
    private currentWellspace: any;

    // private stripe2: any;

    constructor(
        private snackBar: MatSnackBar,
        private restangular: Restangular,
        private sessionService: SessionService,
        private teamsService: TeamsService,
        private uiAlertService: UiAlertService,
        private spinnerService: SpinnerService
    ) {
        // this.stripe2 = this.setupStripe();
        this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
            this.currentWellspace = wellspace;
        });
        setInterval(() => {
            if (this.currentWellspace) {
                this.checkIfHasSomePendingAch(this.currentWellspace.id);
            }
        }, 300 * 1000);
    }

    /*
    private async setupStripe(): Promise<any> {
      return await loadStripe(AppSettings.getStripeInfo().key);
    }
     */

    getCustomerBalance(customerId?: string): Promise<number> {
        if (!customerId) {
            return Promise.resolve(0);
        }
        return this.teamsService.getCustomerId(this.currentWellspace).then(newCustomerId => {
            return this.getCustomer(customerId || newCustomerId).then(customer => {
                return customer.balance;
            });
        });
    }

    addToCustomerBalance(customerId: string, amount: number): Promise<any> {
        return this.restangular.one('users', customerId).one('stripe', 'addtobalance').customPUT({ amount }).toPromise();
    }

    getCustomerBalanceTransactions(customerId: string): Promise<any> {
        return this.restangular.one('users', customerId).one('stripe', 'balancetransactions').getList().toPromise();
    }

    getBankAccount(clientSecret: string, name: string, email: string): Promise<any> {
        return stripe.collectUsBankAccountForSetup(clientSecret, {
            billing_details: {
                name,
                email,
            },
            metadata: 'YYY',
        }).then((result: any) => {
            return result;
        });
    }

    confirmBankAccountSetup(clientSecret: string): Promise<any> {
        return stripe.confirmUsBankAccountSetup(clientSecret);
    }

    addTeamId(setupIntentId: string, teamId: string): Promise<any> {
        return this.restangular.one('users', this.sessionService.getUserId()).one('stripe').one('banksetupintent').customPUT({
            setupIntentId,
            teamId
        }).toPromise();
    }

    changePlan(team: any, members: Array<any>, planName: string, doneFunc: () => void): void {
        const periodName = planName === Plan.Plus ? 'Monthly' : 'Annual';
        const fee = planName === Plan.Plus ? this.PREMIUM_MONTHLY_FEE : this.PREMIUM_ANNUAL_FEE;
        const chargeAmount = (members.length || 1) * fee;
        const oldChargeAmount = members.length * (team.plan === 'PlusAnnual' ? this.PREMIUM_ANNUAL_FEE: this.PREMIUM_MONTHLY_FEE);
        const oldPeriodName = team.plan === Plan.Plus ? 'Monthly' : 'Annual';
        switch (planName) {
            case Plan.Free:
                const modifyTeam = () => this.teamsService.modifyTeam(team.id, { plan: Plan.Free }).then(() => {
                    this.snackBar.openFromComponent(SnackBarComponent, { data: 'Payment cancelled and plan changed to Free' });
                    team.plan = Plan.Free;
                    doneFunc();
                });
                if (members.length === 0) {
                    modifyTeam();
                    break;
                }
                let moreInfo = team.categories.length > this.STANDARD_CATEGORIES ? `Your ${team.categories.length} current categories will be reduced to ${this.STANDARD_CATEGORIES}.` : '';
                const membersWithCustomAllowanceCount = members.find(member => member.amount !== team.amount)?.length;
                if (membersWithCustomAllowanceCount > 0) {
                    moreInfo += ` Your ${membersWithCustomAllowanceCount} members with a custom allowance will have it reset to the team allowance of $${team.amount}`;
                }
                this.uiAlertService.presentAlertConfirm(`Downgrade to Free Plan for ${team.name}.<br><br>This will cancel the ${oldPeriodName.toLowerCase()} recurring charge of $${oldChargeAmount.toFixed(2)} for the Plus ${oldPeriodName} plan for ${members.length} member${members.length === 1 ? '' : 's'}. ${moreInfo}`)
                    .then(async confirm => {
                        if (confirm) {
                            this.teamsService.getCustomerId(team).then(async customerId => {
                                this.updatePlan(customerId, chargeAmount * members.length, members.length, await this.teamsService.getPaymentMethod(team), team.id, planName).then(() => {
                                    modifyTeam();
                                }).catch((err: HttpErrorResponse) => {
                                    this.spinnerService.hide();
                                    this.snackBar.openFromComponent(SnackBarComponent, { data: 'Unable to update plan.' });
                                });
                            });
                        } else {
                            doneFunc();
                        }
                    });
                break;
            case Plan.Plus:
            case Plan.PlusAnnual:
                const period = planName === Plan.Plus ? 'month' : 'year';
                this.uiAlertService.presentAlertConfirm(
                    `${members.length} member${members.length === 1 ? '' : 's'}.<br><br>Upgrade to Plus ${planName === Plan.PlusAnnual ? 'Annual' : ''} Plan.<br><br>This will initiate ${planName === Plan.Plus ? 'a monthly' : 'an annual'} recurring charge of ` +
                    `$${fee} for each active member on the first day of each ${period}. For the initial ${period}, the amount will be pro-rated and charged immediately.`
                ).then(async confirm => {
                    if (confirm) {
                        this.teamsService.getCustomerId(team).then(async customerId => {
                            this.updatePlan(customerId, chargeAmount * members.length, members.length, await this.teamsService.getPaymentMethod(team), team.id, planName).then(() => {
                                this.teamsService.modifyTeam(team.id, { plan: planName }).then(() => {
                                    this.snackBar.openFromComponent(SnackBarComponent, { data: `Payment made and plan changed to Plus ${periodName}` });
                                    team.plan = planName;
                                    doneFunc();
                                });
                            }).catch((err: HttpErrorResponse) => {
                                this.spinnerService.hide();
                                this.snackBar.openFromComponent(SnackBarComponent, { data: 'Unable to update plan.' });
                            });
                        });
                    } else {
                        doneFunc();
                    }
                });
                break;
        }
    }

    confirmSetup(config: any): Promise<any> {
        return stripe.confirmSetup(config);
    }

    removeCard(paymentMethodId: string): Promise<any> {
        return this.restangular.one('users', paymentMethodId).one('stripe').remove().toPromise();
    }

    getCharges(customerId: string): Promise<any> {
        return this.restangular.one('users', customerId).one('stripe', 'getcharges').getList().toPromise().then((charges: Array<any>) => {
            let count = 0;
            charges.forEach(charge => {
                if (charge.status === 'pending') {
                    count++;
                }
            });
            this.pendingACHCount.next(count);
            return charges;
        });
    }

    getChargesV2(customerId: string, paymentMethod: string): Promise<any> {
        return this.restangular
            .one('users', customerId)
            .one('stripe', 'paymentMethod')
            .one(paymentMethod, 'charges')
            .getList().toPromise().then((charges: Array<any>) => {
                let count = 0;
                charges.forEach(charge => {
                    if (charge.status === 'pending') {
                        count++;
                    }
                });
                this.pendingACHCount.next(count);
                return charges;
            });
    }

    checkIfHasSomePendingAch(teamId: string): Promise<void> {
        return this.restangular.one('teams', teamId).one('pendingcharges').get({ count: true }).toPromise().then((res: any) => {
            if (res && res.count) {
                this.pendingACHCount.next(res.count);
            }
            return res;
        });
    }

    getInvoices(customerId: string): Promise<any> {
        return this.restangular.one('users', customerId).one('stripe', 'getinvoices').getList().toPromise();
    }

    getInvoicesV2(customerId: string, paymentMethod: string, chargeHistory: boolean): Promise<any> {
        return this.restangular
            .one('users', customerId)
            .one('stripe')
            .one('paymentMethod', paymentMethod)
            .one('invoices')
            .one(chargeHistory)
            .getList().toPromise();
    }

    getCustomer(customerId: string): Promise<any> {
        return this.restangular.one('users', customerId).one('stripe', 'getcustomer').get().toPromise();
    }

    getElements(): any {
        return stripe.elements({ mode: 'setup', currency: 'usd' /*, setupFutureUsage: 'on_session' */ });
    }

    decodeDestination(destination: string): string {
        return destination.replace(/:/g, '/');
    }

    makePayment(customerId: string, description: string, amount: number, newMembers: Array<any>, stripePaymentMethod: string, serviceFee: number, teamId: string, planName: string, toTeamId: string, proRate?: boolean): Promise<any> {
        return this.restangular.one('users', stripePaymentMethod).one('stripe').one(`makepayment`).customPOST({
            customerId,
            amount,
            newMembers,
            description,
            serviceFee,
            teamId,
            planName,
            toTeamId,
            proRate
        }).toPromise();
    }

    makePurchasePayments(teamId: string, purchases: any): Promise<any> {
        return this.restangular.one('users').one('stripe').one('makepurchasepayments').customPOST(teamId, purchases).toPromise();
    }

    makeOneTimePayment(
        customerId: string,
        description: string,
        amount: number,
        memberEmails: Array<string>,
        message: string,
        note: string | {
            [key: string]: any
        },
        stripePaymentMethod: string,
        teamId: string, type: string,
        toTeamId: string,
        toWallit: boolean = false
    ): Promise<any> {
        return this.restangular.one('users', stripePaymentMethod).one('stripe').one('makeonetimepayment').customPOST({
            customerId,
            amount,
            memberEmails,
            message,
            note,
            description,
            teamId,
            type,
            toTeamId,
            toWallit
        }).toPromise();
    }

    updatePlan(customerId: string, amount: number, memberCount: number, stripePaymentMethod: string, teamId: string, planName: string): Promise<any> {
        return this.restangular.one('users', stripePaymentMethod).one('stripe').one('updateplan').customPOST({
            customerId,
            amount,
            memberCount,
            teamId,
            planName
        }).toPromise();
    }

    cancelPayment(customerId: string, stripePaymentMethod: string, teamId: string): Promise<any> {
        return this.restangular.one('users', stripePaymentMethod).one('stripe').one('cancelpayment').customPOST({
            customerId,
            teamId
        }).toPromise();
    }

    changeAllowance(customerId: string, amount: number, allMembers: number, serviceFee: string, teamId: string, paymentMethod: string): Promise<any> {
        return this.restangular.one('users').one('stripe').one('changeallowance').customPOST({
            customerId,
            amount,
            totalMemberCount: allMembers,
            serviceFee,
            teamId,
            paymentMethod
        }).toPromise();
    }

    getSetupIntent(teamId: string, email: string, paymentTargets: Array<string>): Promise<any> {
        return this.restangular.one('users', this.sessionService.getUserId()).one('stripe').one('getsetupintent').get({
            email: encodeURIComponent(email),
            teamId,
            paymentTargets: paymentTargets.join(',')
        }).toPromise();
    }

    async getBankSetupIntent(teamId: string, customerId: string): Promise<any> {
        return this.restangular.one('users', this.sessionService.getUserId()).one('stripe').one('banksetupintent').get({
            teamId,
            customerId: customerId ? customerId : ''
        }).toPromise();
    }

    triggerBillingCycle(customerId: string): Promise<any> {
        return this.restangular.one('users', customerId).one('stripe', 'triggerbillingcycle').put().toPromise();
    }

    issueRefund(customerId: string, amount: number): Promise<any> {
        return this.restangular.one('users', customerId).one('stripe', 'issuerefund').customPUT({ amount }).toPromise();
    }

    searchSubscriptions(brandId: string, fields: Dictionary<any>): Promise<any> {
        for (const [key, value] of Object.entries(fields)) {
            if (!value) {
                delete fields[key];
            }
        }
        return this.restangular.one('stripe').one('search', brandId).get(fields).toPromise();
    }

    private encodeDestination(destination: string): string {
        return destination.replace(/\//g, ':');
    }

}

