import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class VendorGroupsQuestionsService {

  constructor(
      private restangular: Restangular
  ) { }

  getAllVendorGroupQuestions(): Promise<any> {
    return this.restangular.one('/vendors/groups/questions').get().toPromise();
  }

  createVendorGroupQuestion(metadata: any): Promise<any> {
    return this.restangular.one('/vendors/groups/questions').customPOST({
      ...metadata,
    }).toPromise();
  }

  modifyVendorGroupQuestion(id: string, fields: any): Promise<any> {
    return this.restangular.one('/vendors/groups/questions', id).customPUT({
      ...fields
    }).toPromise();
  }

  deleteVendorGroupQuestion(id: string): Promise<any> {
    return this.restangular.one('vendors/groups/questions', id).remove().toPromise();
  }
}
