import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {EditCompanyDialogComponent, EditCompanyDialogModel} from '../../modal/editcompanydialog/editcompanydialog.component';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {
  CompanyProductsDialogComponent,
  CompanyProductsDialogModel
} from '../../modal/companyproductsdialog/companyproductsdialog.component';
import * as _ from 'lodash';
import {rememberedAgencyId} from '../agency/agency.component';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {TeamCompanyUsersService} from '../../../services/teamcompanyusers/teamcompanyusers.service';

@Component({
  selector: 'app-agency-clients',
  templateUrl: './agency-clients.component.html',
  styleUrls: ['./agency-clients.component.scss']
})
export class AgencyClientsComponent implements OnInit, OnChanges {

  @Input() enabled = false;
  @Input() companyId = '';
  @Output() countEvent = new EventEmitter<number>();

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public columnsToDisplay = ['name', 'city', 'state', 'actionmenu'];
  public clients = new MatTableDataSource<any>();
  private allCompanies!: any[];

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private uiAlertService: UiAlertService,
      private usersInsuranceService: UsersInsuranceService,
      private teamCompanyUsersService: TeamCompanyUsersService
  ){
  }

  ngOnInit(): void {
    this.loadItems();
    this.clients.sort = this.sort;
  }

  ngOnChanges(): void {
    this.loadItems();
  }

  private loadItems(): void {
    this.teamCompaniesService.getCompanies(this.teamCompanyUsersService.roles.broker ? 'broker' : 'agency').then(companies => {
      this.allCompanies = companies;
      this.clients.data = companies.filter((company: { parentId: string; type: string; }) => company.parentId === this.companyId && company.type === 'employer');
      this.countEvent.emit(this.clients.data.length);
    });
  }

  addClient(): void {
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      data: new EditCompanyDialogModel({}, 'employer', 'Client')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.teamCompaniesService.createCompany(dialogResult.name, dialogResult.payrollConfig, dialogResult.domainName, dialogResult.type, this.companyId,
            dialogResult.EIN, dialogResult.SIC, dialogResult.address1, dialogResult.address2, dialogResult.city, dialogResult.state, dialogResult.postcode).then(company => {
          this.teamCompaniesService.uploadLogo(company.id, dialogResult.imageData).then(result => {
            this.teamCompaniesService.modifyCompany(company.id, {logoUrl: result.url}).then(() => {
              this.snackBar.openFromComponent(SnackBarComponent, {data: `New client ${dialogResult.name} added`});
            });
            this.loadItems();
          });
        });
      }
    });
  }

  editClient(agency: any): void {
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      data: new EditCompanyDialogModel(agency, undefined)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.teamCompaniesService.uploadLogo(agency.id, dialogResult.imageData).then(result => {
          dialogResult.logoUrl = result.url;
          delete dialogResult.imageData;
          this.teamCompaniesService.modifyCompany(agency.id, dialogResult).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Client ${agency.name} updated`});
            this.loadItems();
          });
        });
      }
    });
  }

  removeClient(agency: any): void {
    this.uiAlertService.presentAlertConfirm(`Do you really want to remove client ${agency.name}?`).then(ok => {
      if (ok) {
        this.teamCompaniesService.deleteCompany(agency.id).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Client ${agency.name} removed`});
          this.loadItems();
        });
      }
    });
  }

  setProducts(client: any): void {
    this.teamCompaniesService.getCompany(client.id).then(company => {
      const dialogRef = this.dialog.open(CompanyProductsDialogComponent, {
        data: new CompanyProductsDialogModel(company.payrollConfig.products, rememberedAgencyId)
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult !== undefined) {
          this.teamCompaniesService.modifyCompany(client.id, {payrollConfig: _.merge(company.payrollConfig, { products: dialogResult})}).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Products set for ${client.name}`});
          });
        }
      });
    });
  }

  clearEnrollment(client: any): void {
    this.uiAlertService.presentAlertConfirm(`This will clear ALL census, eligibility and enrollment data for this client. Be very sure that this is what you intend. Do you want to proceed?`).then(async (confirm: any) => {
      if (confirm) {
        this.usersInsuranceService.deleteAllInsuranceStates(client.id).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `All census, eligibility and enrollment data cleared`});
        });
      }
    });
  }

  }
