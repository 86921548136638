import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import YouTubePlayer from 'youtube-player';

@Component({
  selector: 'app-moviedialog',
  templateUrl: './moviedialog.component.html',
  styleUrls: ['./moviedialog.component.scss']
})
export class MovieDialogComponent implements AfterViewInit{

  public title;
  private videoId;

  constructor(
      public dialogRef: MatDialogRef<MovieDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: MovieDialogModel,
  ) {
    this.title = data.title;
    this.videoId = data.videoId;
  }

  ngAfterViewInit(): void {
    const player = YouTubePlayer('video-player');
    player.loadVideoById(this.videoId);
    player.stopVideo();
  }

  onConfirm(): void {
    this.dialogRef.close();
  }

}

export class MovieDialogModel {
  constructor(public title: string, public videoId: string) {
  }
}
