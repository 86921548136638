import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {Dictionary} from 'highcharts';

export interface VendorProduct {
    id: string;
    vendorName: string;
    vendorSlug: string;
    productName: string;
    productSlug: string;
    metadata: Dictionary<any>;
}

@Injectable({
    providedIn: 'root'
})

export class VendorProductsService {

    constructor(
        private restangular: Restangular
    ) {
    }

    getAllVendorProducts(): Promise<Array<VendorProduct>> {
        return this.restangular.one('vendors').one('products').one('all').getList().toPromise();
    }

    getUniqueVendorProducts(): Promise<Array<VendorProduct>> {
        return this.restangular.one('vendors').one('products').one('unique').getList().toPromise();
    }

}
