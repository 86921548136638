<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content>
    <form class="example-form" [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>{{label}}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="input">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
  <button class="okbutton" mat-raised-button color="primary" (click)="onConfirm()" [disabled]="!form.valid">OK</button>
  <button mat-button (click)="onDismiss()">Cancel</button>
</div>
