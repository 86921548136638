<h1 mat-dialog-title>
    Link customer to a client.
</h1>
<div mat-dialog-content>
    <p class="info">
        Choose which client the team {{team.name ?? ''}} will be linked to.
    </p>
    <form class="form">
        <mat-form-field>
            <mat-label>All Clients</mat-label>
            <mat-select [formControl]="clientFormValue" [disabled]="allClients.length === 0">
                <mat-option *ngFor="let client of allClients" [value]="client">{{client.name}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="clientFormValue.touched && clientFormValue.invalid">
                <span class="client-form-hint">!Required field</span>
            </mat-hint>
        </mat-form-field>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="clientFormValue && !clientFormValue.valid" class="done-button" color="primary" (click)="linkCustomerToClient()">OK</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
