<div class="topline">
    <mat-form-field>
        <mat-label>State Filter</mat-label>
        <mat-select (selectionChange)="setStateFilter($event)">
            <mat-option value="all">All</mat-option>
            <mat-option value="census">Census</mat-option>
            <mat-option value="eligible">Eligible</mat-option>
            <mat-option value="enrolled">Enrolled</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <input class="search" #search matInput placeholder="Search by name, SSN, Product...">
    </mat-form-field>
    <app-paginator [reload]="reload" [itemCount]="itemCount" (pageEvent)="pageEvent($event)"></app-paginator>
</div>
<app-member-manager [reload]="reload" [table]="table" [companyId]="clientId" state="census"></app-member-manager>
