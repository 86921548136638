<h1 mat-dialog-title>
    Set Team Invoicing for {{team.name}}
</h1>
<div mat-dialog-content>
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab  *ngFor="let label of labels" [label]="label">
            <ng-container *ngTemplateOutlet="tmpl"></ng-container>
        </mat-tab>
    </mat-tab-group>
    <ng-template #tmpl>
        <h2 class="creditbalance">Current credit balance {{-credit / 100 | currency}}</h2>
        <form class="form" *ngIf="form" [formGroup]="form" padding>
            <mat-form-field>
                <mat-slide-toggle [checked]="form.get('invoicing').value" (change)="invoicingChanged($event)">Send Invoices</mat-slide-toggle>
                <input matInput hidden>
            </mat-form-field>
            <mat-form-field class="example-full-width" >
                <mat-label>Credit Amount to Add</mat-label>
                <input matInput placeholder="" formControlName="credit" type="number">
            </mat-form-field>

        </form>
        <div *ngIf="items.data.length > 0">
            <div class="creditheader">
                <h3>Transactions</h3>
                <mat-slide-toggle labelPosition="before" (change)="showAllChanged($event)">Show All</mat-slide-toggle>
            </div>
            <table class="table" mat-table matSort matTableExporter [dataSource]="items">
                <ng-container matColumnDef="date" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Date</th>
                    <td class="cell" mat-cell *matCellDef="let item"> {{item.created * 1000 | date : 'medium'}} </td>
                </ng-container>
                <ng-container matColumnDef="amount" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Amount</th>
                    <td class="cell currency" mat-cell *matCellDef="let item">{{-item.amount / 100 | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="balance" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Balance</th>
                    <td class="cell currency" mat-cell *matCellDef="let item">{{-item.ending_balance / 100 | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Type</th>
                    <td class="cell" mat-cell *matCellDef="let item">{{item.type}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
            </table>
        </div>
    </ng-template>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Done</button>
    <button mat-raised-button [disabled]="form && !form.valid" class="donebutton" color="primary" (click)="addCredit()">Add Credit</button>
</div>
