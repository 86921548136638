import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UseCsvService {

  public importerKeys = {
    // core
    IMPORTER_COMPANIES: '7935ff7f-2fef-4a17-815c-2684396947f4',
    IMPORTER_USERS: '',
    // insurance
    IMPORTER_PRODUCTS: '',
    IMPORTER_POLICIES: '',
    // ad server
    IMPORTER_ADS: '',
    IMPORTER_ADVERTISERS: '',
    IMPORTER_CAMPAIGNS: '',
    IMPORTER_FLIGHTS: '',
    IMPORTER_CREATIVES: '',
  };

  constructor() { }
}
