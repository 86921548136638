import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SpinnerService {

    visibility: BehaviorSubject<boolean>;
    public message = '';

    constructor() {
        this.visibility = new BehaviorSubject(false as boolean);
    }

    show(message = ''): void {
        this.message = message;
        this.visibility.next(true);
    }

    hide(): void {
        this.visibility.next(false);
    }
}
