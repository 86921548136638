<ng-container *ngIf="!embedded">
<mat-toolbar [class]="toolbarClass" *ngIf="ready && !this.router.url.includes('/2fa')">
   <button mat-icon-button class="example-icon" aria-label="Menu" (click)="toggleNav()">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="largemediaonly topleft">
      <span>{{leftTitle()}}</span>
      <span class="verticaldivider">|</span>
      <button mat-icon-button (click)="navigate('')"><mat-icon class="home">home</mat-icon></button>
  </div>
  <span *ngIf="!isCustomerSupport">{{currentWellspace.role ? 'Admin for': ''}} {{currentWellspace.name}} {{currentWellspace.companyName ? 'in ' + currentWellspace.companyName : ''}}</span>
  <span *ngIf="isIntegrationAdmin">{{company?.company_type | titlecase }}{{company ? ':' : ''}} {{company?.name}}</span>
  <mat-spinner class="spinner" *ngIf="spinnerLevel > 0" [diameter]="36"></mat-spinner>
  <span class="spacer"></span>
  <div *ngIf="isIntegrationAdmin">
      <button class="topbutton" mat-icon-button (click)="actionsClicked()">
          <div class="buttonwrapper">
              <span [matBadge]="approvalCount" matBadgeColor="accent" [matBadgeHidden]="false"></span>
             <mat-icon svgIcon="bell"></mat-icon>
              <span class="iconlabel">Actions</span>
          </div>
      </button>
  </div>
  <div *ngIf="isCustomerSupport || isBrokerAdmin">
      <span>{{currentUserInfo}}</span>
  </div>
  <div *ngIf="!isIntegrationAdmin && !isCustomerSupport && !isBrokerAdmin">
      <button class="topbutton" mat-icon-button (click)="plansClicked()">
        <div class="buttonwrapper">
            <mat-icon svgIcon="plans"></mat-icon>
            <span class="iconlabel">Plans</span>
        </div>
      </button>
      <button class="topbutton" mat-icon-button (click)="notificationsClicked()">
        <div class="buttonwrapper">
            <mat-icon>notifications</mat-icon>
            <span class="iconlabel">Notifications</span>
        </div>
      </button>
        <mat-menu #wellspacesMenu="matMenu">
          <div class="wellspacesMenu">
              <button mat-menu-item (click)="addWellspace()"><mat-icon>add</mat-icon>Add a Wellspace</button>
              <div *ngIf="wellspaces.length > 0">
                  <mat-divider ></mat-divider>
                  <span class="wellspacesection">Wellspaces</span><br>
                  <input name="wellspacefilter" class="filterinput" matInput [(ngModel)]="wellspaceFilter" (click)="$event.stopPropagation()" (keyup)="applyFilter($event)" placeholder="Filter by...">
                  <button *ngIf="wellspaceFilter" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter($event)">
                    <mat-icon>close</mat-icon>
                  </button>
                  <div *ngFor="let wellspace of filteredWellspaces" class="wellspaceitem" (click)="gotoWellspace(wellspace)">
                      <button mat-menu-item>{{wellspace.name}}</button>
                  </div>
              </div>
          </div>
      </mat-menu>
      <button class="topbutton" mat-icon-button [matMenuTriggerFor]="wellspacesMenu" >
        <div class="buttonwrapper">
            <mat-icon>grid_view</mat-icon>
            <span class="iconlabel">Wellspaces</span>
        </div>
      </button>
      <button class="topbutton" mat-icon-button (click)="helpClicked()">
        <div class="buttonwrapper">
            <mat-icon>help_outline</mat-icon>
            <span class="iconlabel">Help</span>
        </div>
      </button>
      <button class="topbutton" mat-icon-button (click)="launchClicked()">
        <div class="buttonwrapper">
            <img class="walliticon" src="assets/icons/favicon.png">
            <span class="iconlabel">Launch</span>
        </div>
      </button>
  </div>
  <span *ngIf="user && user.contact">{{user.contact.name || user.contact.email}}</span>
</mat-toolbar>
<mat-sidenav-container hasBackdrop="false" autosize="true">
  <mat-sidenav  *ngIf="isLoggedIn && !this.router.url.includes('/2fa')" class="sidenav" #sidenav mode="side" opened="true">
    <div class="sidenavwrapper">
        <div class="topside">
            <mat-accordion multi>
                <div>
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <span class="section">ACCOUNT</span>
                        </mat-expansion-panel-header>
                      <button *ngIf="!isIntegrationAdmin && !isCustomerSupport && !isBrokerAdmin" class="leftbutton" mat-icon-button (click)="wallitClicked()">
                            <mat-icon>keyboard_return</mat-icon>
                            Back to Wallit
                      </button>
                      <button class="leftbutton" mat-icon-button (click)="navigate('')">
                            <mat-icon>home</mat-icon>
                            Home
                      </button>
                      <button *ngIf="!isCustomerSupport" class="leftbutton" mat-icon-button (click)="navigate('profile')">
                            <mat-icon>person</mat-icon>
                            Account Profile
                      </button>
                        <button *ngIf="!isCustomerSupport" class="leftbutton" mat-icon-button (click)="navigate('2fa/profile')">
                            <mat-icon>security</mat-icon>
                            2FA
                        </button>
                        <button *ngIf="!isCustomerSupport && !isBrokerAdmin" class="leftbutton" mat-icon-button (click)="navigate('insuranceprofile')">
                            <mat-icon>medical_services</mat-icon>
                            Insurance Profile
                        </button>
                      <button *ngIf="!isIntegrationAdmin && !isCustomerSupport && !isBrokerAdmin" class="leftbutton" [disabled]="!currentWellspace.name" mat-icon-button (click)="navigate('analytics')">
                             <mat-icon>insights</mat-icon>
                             Analytics
                       </button>
                      <button class="leftbutton" mat-icon-button (click)="logoutClicked(false)">
                            <mat-icon>logout</mat-icon>
                            Sign Out
                      </button>
                    </mat-expansion-panel>
                </div>
                <div *ngIf="isBrokerAdmin">
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <span class="section">BROKER & AGENCY</span>
                        </mat-expansion-panel-header>
                        <button *ngIf="roles?.broker || isSuperAdmin" class="leftbutton" mat-icon-button (click)="navigate('brokeragency/broker/broker-profile')">
                            <mat-icon>store</mat-icon>
                            Broker
                        </button>
                        <button *ngIf="roles?.broker || roles?.agency || isSuperAdmin" class="leftbutton" mat-icon-button (click)="navigate('brokeragency/agency/agency-profile')">
                            <mat-icon>badge</mat-icon>
                            Agency
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('brokeragency/clients/client-census')">
                            <mat-icon>business</mat-icon>
                            Clients
                        </button>
                    </mat-expansion-panel>
                </div>
                <div *ngIf="isCustomerSupport">
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <span class="section">ADMINISTRATION</span>
                        </mat-expansion-panel-header>
                        <button class="leftbutton" mat-icon-button (click)="navigate('customersupport/managecustomers')">
                            <mat-icon>person_search</mat-icon>
                            Manage Customers
                        </button>
                        <button *ngIf="roles?.supportAdmin || isSuperAdmin" class="leftbutton" mat-icon-button (click)="navigate('customersupport/managesupportagents')">
                            <mat-icon>supervisor_account</mat-icon>
                            Manage Agents
                        </button>
                        <!--
                        <button *ngIf="isSupportAdmin || isSuperAdmin" class="leftbutton" mat-icon-button (click)="navigate('customersupport/reports')">
                            <mat-icon>assignment</mat-icon>
                            Reports
                        </button>
                        -->
                    </mat-expansion-panel>
                </div>
                <div *ngIf="!isIntegrationAdmin && !isCustomerSupport && !isBrokerAdmin">
                  <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                      <span class="section">ADMINISTRATION</span>
                    </mat-expansion-panel-header>
                    <button class="leftbutton" [disabled]="!currentWellspace.name" mat-icon-button (click)="navigate('managewellspace/overview/current')">
                        <mat-icon>groups</mat-icon>
                        Manage Your Wellspace
                    </button>
                    <button class="leftbutton" [disabled]="!currentWellspace.name" mat-icon-button (click)="navigate('purchases')">
                         <mat-icon>receipt</mat-icon>
                         <span [matBadge]="approvalCount" matBadgeColor="accent" [matBadgeHidden]="approvalCount === 0">
                            Approvals
                         </span>
                    </button>
                    <button class="leftbutton" [disabled]="!currentWellspace.name" mat-icon-button (click)="navigate('billing/stripechargehistory')">
                        <mat-icon>credit_card</mat-icon>
                        <span [matBadge]="badgeCount" matBadgeColor="accent" [matBadgeHidden]="badgeCount === 0">Billing</span>
                    </button>
                  </mat-expansion-panel>
                </div>
                <div *ngIf="isIntegrationAdmin">
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <span class="section">INSURANCE</span>
                        </mat-expansion-panel-header>
                        <button class="leftbutton" mat-icon-button (click)="navigate('insurance/carriers')">
                            <mat-icon>business</mat-icon>
                            Carriers
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('insurance/brokers')">
                            <mat-icon>store</mat-icon>
                            Brokers
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('insurance/employers')">
                            <mat-icon>badge</mat-icon>
                            Employers
                        </button>
                        <mat-divider></mat-divider>
                        <button class="leftbutton" mat-icon-button (click)="navigate('insurance/products')">
                            <mat-icon>receipt_long</mat-icon>
                            Companies
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('insurance/policies')">
                            <mat-icon>policy</mat-icon>
                            Policies
                        </button>
                        <mat-divider></mat-divider>
                        <button class="leftbutton" mat-icon-button (click)="navigate('insurance/users')">
                            <mat-icon>people</mat-icon>
                            Users
                        </button>
                    </mat-expansion-panel>
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <span class="section">ADVERTISING</span>
                        </mat-expansion-panel-header>
                        <button class="leftbutton" mat-icon-button (click)="navigate('advertising/advertisers')">
                            <mat-icon>corporate_fare</mat-icon>
                            Advertisers
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('advertising/campaigns')">
                            <mat-icon>date_range</mat-icon>
                            Campaigns
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('advertising/ads')">
                            <mat-icon>ad_units</mat-icon>
                            Ads
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('advertising/creatives')">
                            <mat-icon>palette</mat-icon>
                            Creatives
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('advertising/flights')">
                            <mat-icon>flights</mat-icon>
                            Flights
                        </button>
                        <mat-divider></mat-divider>
                        <button class="leftbutton" mat-icon-button (click)="navigate('advertising/impressions')">
                            <mat-icon>visibility</mat-icon>
                            Impressions
                        </button>
                    </mat-expansion-panel>
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <span class="section">MEDIA</span>
                        </mat-expansion-panel-header>
                        <button class="leftbutton" mat-icon-button (click)="navigate('media/images')">
                            <mat-icon>photo_llibrary</mat-icon>
                            Images
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('media/documents')">
                            <mat-icon>description</mat-icon>
                            Documents
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('media/content')">
                            <mat-icon>web</mat-icon>
                            Content
                        </button>
                    </mat-expansion-panel>
                </div>
                <div *ngIf="roles?.manager && (!isCustomerSupport || isSuperAdmin)">
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <span class="section">MANAGERS</span>
                        </mat-expansion-panel-header>
                        <button class="leftbutton" mat-icon-button (click)="navigate('products')">
                            <mat-icon>receipt_long</mat-icon>
                            Companies
                        </button>
                    </mat-expansion-panel>
                </div>
                <div *ngIf="isSuperAdmin">
                    <mat-expansion-panel expanded="true">
                           <mat-expansion-panel-header>
                                <span class="section">SUPER ADMIN</span>
                           </mat-expansion-panel-header>
                            <button class="leftbutton" mat-icon-button (click)="navigate('teampayments')">
                                  <mat-icon>grid_view</mat-icon>
                                  All Wellspaces
                            </button>
                            <button class="leftbutton" mat-icon-button (click)="navigate('products')">
                                  <mat-icon>receipt_long</mat-icon>
                                  Companies
                            </button>
                        <button *ngIf="checkClientFeaturePermission()" class="leftbutton" mat-icon-button (click)="navigate('clients')">
                            <mat-icon>person</mat-icon>
                            Clients
                        </button>
                        <button class="leftbutton" mat-icon-button (click)="navigate('externalbrokeradmin')">
                            <mat-icon>ballot</mat-icon>
                            Broker Admin
                        </button>
                    </mat-expansion-panel>
                </div>
                <div *ngIf="isSuperAdmin">
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <span class="section">REPORTS</span>
                        </mat-expansion-panel-header>
                        <button class="leftbutton" mat-icon-button (click)="navigate('reports/allocation-overview')">
                            <mat-icon>assignment</mat-icon>
                            Allocation Overview
                        </button>
                    </mat-expansion-panel>
                </div>
                <span class="version">Version {{info}}</span>
            </mat-accordion>
        </div>
        <!--<span class="sidebottom">Made with &hearts; by Wallit</span>-->
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div [ngClass]="!this.router.url.includes('/2fa') ? 'content' : ''">
        <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
</ng-container>
<router-outlet *ngIf="embedded"></router-outlet>
<div class="loading-indicator" *ngIf="showLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
    <span class="loadingmessage">{{spinnerMessage ? spinnerMessage : 'Wait'}}...</span>
</div>
