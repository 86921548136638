import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

export interface Approval {
  id: string;
  email: string;
  username: string;
  sequence: number;
  amount: number;
}

@Injectable({
  providedIn: 'root'
})
export class TeamApprovalsService {

  constructor(
      private restangular: Restangular,
  ) { }

  getApprovals(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('approvals').getList().toPromise();
  }

  deleteApproval(teamId: string, approvalId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('approvals', approvalId).remove().toPromise();
  }

  deleteAllApprovals(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('approvals').remove().toPromise();
  }

  modifyApproval(teamId: string, approvalId: string, fields: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('approvals', approvalId).customPUT({
      ...fields
    }).toPromise();
  }

  createApproval(teamId: string, userId: string, amount: number, email: string, name: string, sequence: number): Promise<any> {
    return this.restangular.one('teams', teamId).one('approvals').customPOST({
      userId,
      teamId,
      amount,
      email,
      name,
      sequence
    }).toPromise();
  }


}
