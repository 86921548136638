import { Component, OnInit } from '@angular/core';
import { TeamsService } from '../../services/teams/teams.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { StripeService } from '../../services/stripe/stripe.service';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Invoice } from '../../interfaces/stripe/invoice.interface';

@Component({
    selector: 'app-stripe-chargehistory',
    templateUrl: './stripe-chargehistory.component.html',
    styleUrls: ['./stripe-chargehistory.component.scss'],
})
export class StripeChargehistoryComponent implements OnInit {
    public charges: any;
    public invoices: Invoice[] = [];
    public invoicesWithoutPaymentMethod: Invoice[] = [];
    public items: any;
    public form: any;

    constructor(
        private teamsService: TeamsService,
        private spinnerService: SpinnerService,
        private stripeService: StripeService,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            method: new UntypedFormControl(),
        });
        this.form.valueChanges.subscribe((): void => {
            const value = this.form.get('method').value;
            if (value) {
                this.loadCharges(value?.stripeCustomerId, value?.stripePaymentMethod);
            }
        });
        this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any) => {
            this.teamsService.getPaymentMethods(wellspace.id).then((result: any[]): void => {
                this.items = result.filter((paymentMethod: any) => paymentMethod && paymentMethod.stripePaymentMethod);
                if (this.items.length === 1) {
                    this.loadCharges(this.items[0].stripeCustomerId, this.items[0].stripePaymentMethod);
                } else {
                    this.selectDefaultPaymentMethod(wellspace);
                }
            });
        });
    }

    selectDefaultPaymentMethod(wellspace: any): any {
        if (!wellspace && this.items.length <= 0) {
            return (this.charges = []);
        }
        const defaultPaymentMethod = wellspace?.defaultPaymentMethod;
        for (const item of this.items) {
            if (item?.stripePaymentMethod === defaultPaymentMethod && item?.stripeCustomerId) {
                this.form.setValue({ method: item });
                this.loadCharges(item?.stripeCustomerId, item?.stripePaymentMethod);
                return;
            }
        }
        this.charges = [];
    }

    loadCharges(customerId: string, paymentMethod: string): void {
        this.spinnerService.show('Loading charges...');
        this.stripeService
            .getInvoicesV2(customerId, paymentMethod, true)
            .then((invoices) => {
                this.spinnerService.hide();
                const treatedInvoices = invoices
                    .filter((invoice: Invoice) => invoice.amount > 0)
                    .map((invoice: Invoice) => {
                        invoice.date = new Date((invoice.date as number) * 1000);
                        return invoice;
                    });
                this.invoices = treatedInvoices.filter((invoice: Invoice) => invoice.withoutPaymentMethod === false);
                this.invoicesWithoutPaymentMethod = treatedInvoices.filter(
                    (invoice: Invoice) => invoice.withoutPaymentMethod === true
                );
            })
            .catch((err) => {
                this.chargersLoadErrorHandler(err);
            });
    }

    chargersLoadErrorHandler(err: any): void {
        this.charges = [];
        this.spinnerService.hide();
        console.error('Error in load charges ', err);
    }

    chargeStatus(charge: any): string {
        if (charge.status === 'pending') {
            return 'Pending';
        }
        if (charge.outcome.network_status === 'declined_by_network') {
            return 'Declined';
        }
        if (charge.amount_refunded > 0 || charge.description.includes('No Charge')) {
            return charge.amount === charge.amount_refunded
                ? 'Refunded'
                : `Partially Refunded $${(charge.amount_refunded === 0
                      ? charge.amount_refunded / 100
                      : charge.amount / 100
                  ).toFixed(2)}`;
        }
        return 'Approved';
    }
}
