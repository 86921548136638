<div class="step">
    <div [class]="'top ' + headerClass">
        <span></span>
        <span>{{title}}</span>
        <button mat-icon-button (click)="toggleExpand()">
            <mat-icon>{{ isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
    </div>
    <div [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
        <div class="middle" >
            <ng-content select="[middle]"></ng-content>
        </div>
        <div class="buttons">
            <ng-content select="[buttons]"></ng-content>
        </div>
    </div>
</div>
