import {AfterViewInit, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import * as moment from 'moment';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SessionService} from '../../../services/session/session.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {Dictionary} from 'highcharts/highcharts.src';
import {UsersService} from '../../../services/users/users.service';

@Component({
  selector: 'app-adduserdialog',
  templateUrl: './adduserdialog.component.html',
  styleUrls: ['./adduserdialog.component.scss', '../../../directives/mat-basic-spinner.directive.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdduserdialogComponent implements OnInit, AfterViewInit {

  public maxDate = moment().add(-18, 'year').toDate();
  public form;

  private oldTel = '';
  private currentEmail = '';
  public phoneVerified = false;
  public emailVerified = false;
  public partners = Array<Dictionary<any>>();
  public user;
  public editMode = false;
  public saving = false;
  private oldPhone;
  public hideSsn = true;

  constructor(
      private formBuilder: UntypedFormBuilder,
      public dialogRef: MatDialogRef<AdduserdialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: AdduserDialogModel,
      private sessionService: SessionService,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private usersService: UsersService
  ) {
    this.user = data.user;
    this.editMode = !!data.user;
    this.emailVerified = this.user?.isVerifiedEmail;
    this.phoneVerified = this.user?.isConfirmed;
    this.oldPhone = this.user?.phoneNumber?.substr(1);
    this.form = this.formBuilder.group({
      firstname: new UntypedFormControl(this.user?.firstName, Validators.required),
      lastname: new UntypedFormControl(this.user?.lastName, Validators.required),
      dob: new UntypedFormControl(this.user?.birthdate),
      tel: new UntypedFormControl(this.oldPhone, Validators.pattern('^[0-9]{10}$')),
      email: new UntypedFormControl(this.user?.email, [Validators.email, Validators.required]),
      address1: new UntypedFormControl(this.user?.address1),
      address2: new UntypedFormControl(this.user?.address2),
      city: new UntypedFormControl(this.user?.city),
      state: new UntypedFormControl(this.user?.state),
      postalCode: new UntypedFormControl(this.user?.postalCode),
      ssn: new UntypedFormControl(this.user?.ssn, Validators.pattern('^[0-9]{9}$')),
      partner: new UntypedFormControl(this.user?.partnerId, Validators.required),
    });
  }

  ngOnInit(): void {
    this.usersService.getVisibleBrands().then(partners => {
      this.partners = partners;
    });
  }

  ngAfterViewInit(): void {
    this.form.get('email')?.valueChanges.subscribe(value => {
      this.emailVerified = false;
    });
    this.form.get('tel')?.valueChanges.subscribe(value => {
      this.phoneVerified = false;
    });
    this.form.get('tel')?.setValue(this.user?.phoneNumber?.substr(1))
  }

  private fixNumber(thisPhone: string): string {
    return thisPhone ? '1' + thisPhone : '';
  }


  saveClicked(event: any): void {
    event.preventDefault();
    if (this.form.errors) {
      console.log('SAVECLICKED', this.form.errors);
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Missing or invalid field(s)'});
      return;
    }
    const data = {
      firstName: this.form.get('firstname')?.value,
      lastName: this.form.get('lastname')?.value,
      nickname: this.form.get('nickname')?.value,
      birthdate: this.form.get('dob')?.value,
      email: undefined,
      password: '',
      username: this.form.get('email')?.value,
      address1: this.form.get('address1')?.value,
      address2: this.form.get('address2')?.value,
      city: this.form.get('city')?.value,
      state: this.form.get('state')?.value,
      postalCode: this.form.get('postalCode')?.value,
      ssn: this.form.get('ssn')?.value,
      partnerId: this.form.get('partner')?.value,
      signupUserId: this.usersService.getCurrentUserId(),
    };
    if (this.user?.email !== this.form.get('email')?.value) {
      data.email = this.form.get('email')?.value;
    }
    this.saving = true;
    const phone = this.form.get('tel')?.value;
    if (this.user) {
      this.usersService.updateUser(this.user.id, data).then(() => {
        // @ts-ignore
        data.partnerId = this.form.get('partner')?.value;
        this.usersService.updateUserBrand(this.user.id, data.partnerId).then(() => {
          const allDone = () => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `User updated`});
            this.saving = false;
            this.dialogRef.close(data);
          };
          if (this.oldPhone === phone) {
            allDone();
          } else {
            const setPhoneNumber = () => {
              if (phone) {
                this.usersService.setPhoneNumberByUserId(this.user.id, this.fixNumber(phone)).then(() => {
                  allDone();
                }).catch(error => {
                  this.snackBar.openFromComponent(SnackBarComponent, {data: 'Phone number is already in use'});
                  this.saving = false;
                });
              } else {
                allDone();
              }
            };
            if (this.oldPhone) {
              this.usersService.removePhoneNumber(this.fixNumber(this.oldPhone)).then(() => {
                setPhoneNumber();
              });
            } else {
              setPhoneNumber();
            }
          }
        });
      }).catch((error: any) => {
        console.log('UPDATE ERROR', error);
        this.saving = false;
        this.snackBar.openFromComponent(SnackBarComponent, {data: 'Error updating user' });
      });
    } else {
      this.sessionService.getRandomPassword().then(password => {
        data.password = password;
        this.sessionService.createAccount(data).then((result: any) => {
          if (phone) {
            this.usersService.setPhoneNumberByUserId(result.userId, this.fixNumber(phone));
          }
          this.usersService.updateUserBrand(result.userId, this.form.get('partner')?.value).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `User created. Temporary password is ${data.password}`});
            this.saving = false;
            this.uiAlertService.presentAlertConfirm('Now that you have added the user, do you want to verify either their phone number or email now?').then(ok => {
              if (ok) {
                this.user = data;
                this.user.id = result.userId;
              } else {
                this.dialogRef.close(data);
              }
            });
         });
        }).catch((error: any) => {
          console.log('CREATE ERROR', error);
          this.saving = false;
          this.snackBar.openFromComponent(SnackBarComponent, {data: error.status === 409 ? 'Email address already exists. Email addresses must be unique.' : error});
        });
      });
    }
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }

  verifyPhoneNumber(): void {
    const phone = this.form.get('tel')?.value;
    this.usersService.resendPhoneValidation(this.user.id, this.fixNumber(phone)).then(() => {
      this.uiAlertService.presentFieldInput(`Enter phone verification code sent to customer's phone`, 'Enter Code').then(value => {
        if (value) {
          this.usersService.validatePhoneCode(this.user.id, this.fixNumber(phone), value).then(result => {
            console.log('VALIDATION RETURN', result);
            this.snackBar.openFromComponent(SnackBarComponent, {data: result ? 'Phone number verified' : 'Incorrect validation code'});
            this.phoneVerified = !!result;
          }).catch (error => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'Incorrect validation code'});
            this.usersService.removePhoneNumber(phone);
          });
        }
      });
    }).catch(error => {
      console.log('VERIFY ERROR', error)
      this.snackBar.openFromComponent(SnackBarComponent, {data: error.error.error});
    });
  }

  verifyEmail(): void {
    this.usersService.resendEmailValidation(this.user.id).then(() => {
      this.uiAlertService.presentFieldInput(`Enter verification code sent to customer's email`, 'Enter Code').then(value => {
        if (value) {
          this.usersService.validateEmailCode(this.user.id, value).then(result => {
            console.log('VALIDATION RETURN', result);
            this.snackBar.openFromComponent(SnackBarComponent, {data: result ? 'Email address verified' : 'Incorrect validation code'});
            this.emailVerified = !!result;
          });
        }
      });
    });
  }

  forceVerifyPhoneNumber(): void {
    this.uiAlertService.presentAlertConfirm('Are you sure you want to force verify this customer\'s phone number. Only do this if you are sure that the number you are supplying is associated with the customer.').then(ok => {
      if (ok) {
          this.usersService.confirmPhoneNumber(this.user.id, this.fixNumber(this.form.get('tel')?.value)).then(() => {
            this.phoneVerified = true;
            this.snackBar.openFromComponent(SnackBarComponent, {data: 'Phone number has been force verified.'});
          });
      }
    });
  }

  forceVerifyEmail(): void {
    this.uiAlertService.presentAlertConfirm('Are you sure you want to force verify this customer\'s email address. Only do this if you are sure that the email address you are supplying is associated with the customer.').then(ok => {
      if (ok) {
        this.usersService.confirmEmail(this.user.id).then(() => {
          this.emailVerified = true;
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Email address has been force verified.'});
        });
      }
    });
  }

  playHelpMovie(): void {
    this.uiAlertService.presentMovie('Modify/Add Customers' , UiAlertService.content.MODIFY_ADD_CUSTOMERS);
  }

}

export class AdduserDialogModel {
  constructor(public user: any) {
  }
}
