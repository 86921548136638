import {Component, Input, OnChanges} from '@angular/core';
import {TeamsService} from '../../../../services/teams/teams.service';

@Component({
  selector: 'app-members-overview',
  templateUrl: './members-overview.component.html',
  styleUrls: ['../analytics-common.scss']
})
export class MembersOverviewComponent implements OnChanges {
  @Input() analytics: any;
  public members: any;
  public activeCount = 0;
  public totalReimbursements = 0;

  constructor(private teamsService: TeamsService) {
    this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any): void => {
      if (wellspace) {
        this.teamsService.getTeamMembers(wellspace.id).then(members => {
          this.teamsService.buildMemberList(members, wellspace);
          this.members = members;
        }).catch(err => {
          console.error('Error in get team agency-members', err);
        });
      }
    });
  }

  ngOnChanges(): void {
    this.fillTotals();
  }

  private getActiveUsers(): number {
    const sinceDate = '2021-01-01';
    const foundUsers: Array<string> = [];
    let count = 0;
    this.analytics.transactions.forEach((transaction: any) => {
      if (transaction.date >= sinceDate && foundUsers.indexOf(transaction.userId) < 0) {
        foundUsers.push(transaction.userId);
        count++;
      }
    });
    return count;
  }

  private fillTotals(): void {
    if (this.analytics) {
      this.totalReimbursements = this.analytics.transactions.reduce((a: any, b: any) => {
        return a + b.teamAmountReimbursed;
      }, 0).toFixed(2);
      this.activeCount = this.getActiveUsers();
    }
  }

}
