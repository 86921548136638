import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';

@Component({
  selector: 'app-companyproductsdialog',
  templateUrl: './companyproductsdialog.component.html',
  styleUrls: ['./companyproductsdialog.component.scss']
})
export class CompanyProductsDialogComponent implements OnInit, AfterViewInit {

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public selection = new SelectionModel<any>(true, []);
  public items = new MatTableDataSource<any>();
  public allColumnLabels = ['select', 'vendorName', 'productName', 'vendorSlug'];
  private products;
  private companyId;
  public company: any;

  constructor(
      private vendorProductsService: VendorProductsService,
      private teamCompaniesService: TeamCompaniesService,
      public dialogRef: MatDialogRef<CompanyProductsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: CompanyProductsDialogModel
  ) {
    this.products = data.products;
    this.companyId = data.companyId;
  }

  ngOnInit(
  ): void {
    this.vendorProductsService.getAllVendorProducts().then(products => {
      this.items.sort = this.sort;
      const setProducts = () => {
        this.items.data = products;
        this.items.data.forEach(item => {
          if (this.products?.find((product: { id: any; }) => product === item.id)) {
            this.selection.select(item);
          }
        });
      };
      if (this.companyId) {
        this.teamCompaniesService.getCompany(this.companyId).then(company => {
          this.company = company;
          products = products.filter((product: { id: string; }) => company.payrollConfig.products?.find((productId: string) => productId === product.id));
          setProducts();
        });
      } else {
        setProducts();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.sort) {
      this.items.sort = this.sort;
      this.sort.active = 'vendorName';
      this.sort.direction = 'asc';
    }
  }

  okClicked(): void {
    this.dialogRef.close(this.selection.selected.map(product => product.id));
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.data.length;
    return numSelected === numRows;
  }

  isSomeSelected(): boolean {
    return this.selection.selected.length > 0;
  }

  /* Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(ref: { checked: boolean; }): void {
    if (this.isSomeSelected()) {
      this.selection.clear();
      ref.checked = false;
    } else {
      ref.checked = true;
      this.items.data.forEach((row: any) => this.selection.select(row));
    }
  }

}

export class CompanyProductsDialogModel {

  constructor(public products: any, public companyId: string = '') {
  }

}
