import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-provisioning-tile',
  templateUrl: './provisioning-tile.component.html',
  styleUrls: ['./provisioning-tile.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ height: '*', opacity: 1 })),
      state('collapsed', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
      transition('expanded <=> collapsed', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class ProvisioningTileComponent {

  @Input() headerClass = '';
  @Input() title = '';

  protected isExpanded = false;

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

}
