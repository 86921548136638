import {Component, Inject, OnInit} from '@angular/core';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-setteamcompanydialog',
  templateUrl: './setteamcompanydialog.component.html',
  styleUrls: ['./setteamcompanydialog.component.scss']
})
export class SetTeamCompanyDialogComponent implements OnInit {

  public companies: any;
  public form: any;
  public dialogData;

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private formBuilder: UntypedFormBuilder,
      public dialogRef: MatDialogRef<SetTeamCompanyDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: SetTeamCompanyComponentModel
  ) {
    this.dialogData = data;
  }

  ngOnInit(): void {
    this.teamCompaniesService.getCompanies().then(companies => {
      this.companies = companies;
      if (this.dialogData.teamMode) {
        this.companies = companies.filter((company: { type: string; }) => company.type === 'employer');
      }
      this.form = this.formBuilder.group({
        company: new UntypedFormControl(this.dialogData.companyId, [Validators.required]),
      });
    });
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }

  removeCompany(): void {
    this.dialogRef.close('');
  }

  setCompany(): void {
    this.dialogRef.close(this.form.get('company')?.value);
  }

}

export class SetTeamCompanyComponentModel {
  constructor(public companyId: string, public title: string, public info: string, public setButton: string, public removeButton: string, public teamMode: boolean) {
  }
}
