import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-media-images',
  templateUrl: './media-images.component.html',
  styleUrls: ['./media-images.component.scss']
})
export class MediaImagesComponent implements OnInit {

  public config: TableConfig = {
    title: 'Media - Images',
    dbTableName: 'media',
    filter: {type: 'image'},
    expand: [],
    columns: [
      {name: 'media_id', title: '', isImage: true, imageType: 'image'},
      {name: 'name', title: 'Image Name'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ],
    excludedFields: ['type', 'mimetype'],
    preWrite: (fields) => { fields.type = 'image'; delete fields.media_id; return true; },
  };

  constructor() { }

  ngOnInit(): void {
  }

}
