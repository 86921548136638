import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Dictionary} from 'highcharts';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-reconciliation-teamlog',
  templateUrl: './reconciliation-teamlog.component.html',
  styleUrls: ['./reconciliation-teamlog.component.scss']
})
export class ReconciliationTeamlogComponent implements OnInit, OnChanges {

  @Input() info: any;
  columnsToDisplay = ['date', 'email', 'team', 'sender', 'message', 'amount', 'taxable', 'status'];
  public items = new MatTableDataSource<Dictionary<any>>();
  public form: UntypedFormGroup;

  constructor(
      private formBuilder: UntypedFormBuilder,
  ) {
    this.form = this.formBuilder.group({
    });
  }

  ngOnInit(): void {
    this.loadItems();
  }

  ngOnChanges(): void {
    this.loadItems();
  }

  private loadItems(): void {
    this.items.data = this.info.teamLogTransactions;
    this.items.data?.forEach(item => {
      const thisMember = this.info.members.find((member: any) => member.id === item.senderUserId);
      item.sender = (thisMember?.firstName || '') + ' ' + (thisMember?.lastName || '');
    });
  }

}
