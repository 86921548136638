
<div *ngIf="showHeader" class="item header">
    <span class="icon"></span>
    <span class="itemwrapper"></span>
    <span class="message">Status</span>
    <span class="accountinfo">Accounts</span>
    <span class="employee-id">Employee ID</span>
    <span class="balance" *ngIf="wallitBalance">Wallit Balance</span>
    <span class="balance">Allowance Balance</span>
    <span class="balance">Peer Recognition Balance</span>
    <span class="employmentdate">Employment Date</span>
    <span class="teamjoindate">Team Join Date</span>
    <span class="role">Role</span>
    <span class="monthly-allowence">Monthly Allowance</span>
    <button mat-button></button>
</div>
<div class="item" #item>
    <img #avatar class="icon">
    <div class="itemwrapper">
        <span class="name">{{getMemberName(member)}}</span>
        <span class="email">{{getEmail(member)}}</span>
        <span *ngIf="!member.isQr" class="email">{{getLoginName(member)}}</span>
    </div>
    <span *ngIf="member.message" class="message">{{member.message || '---'}}</span>
    <span class="accountinfo">{{member.accountInfo || '---'}}</span>
    <span class="employee-id">{{member.employeeNumber || '---'}}</span>
    <span class="balance" *ngIf="wallitBalance">{{(member.wallitBalance | currency) || '---'}}</span>
    <span class="balance">{{(member.balance | currency) || '---'}}</span>
    <span class="balance">{{(member.p2pBalance | currency) || '---'}}</span>
    <span class="employmentdate">{{(member.employmentDate | date: 'mediumDate') || '---'}}</span>
    <span class="teamjoindate">{{(member.memberCreatedAt | date: 'mediumDate') || '---'}}</span>
    <span class="role">{{member.role || '---'}}</span>
    <span class="monthly-allowence">{{member.canReceiveAllowance === 1 ? 'Enabled' : 'Disabled'}}</span>
    <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="memberMenu" [matMenuTriggerData]="{member: member}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
</div>
<mat-menu #memberMenu="matMenu">
    <ng-template matMenuContent let-item="member">
        <button mat-menu-item (click)="deleteMember(member)">Remove</button>
        <button mat-menu-item (click)="refundMember(member)">Refund Allowance</button>
        <button mat-menu-item (click)="resendMember(member)">{{member.message === 'Active' ? 'Send Login Email' : 'Send Invitation Email'}}</button>
        <button mat-menu-item (click)="changeEmploymentDate(member)">Change Employment Date</button>
        <button mat-menu-item (click)="changeEmployeeNumber(member)">Change Employee Number</button>
        <button mat-menu-item (click)="toggleAdmin(member)" [disabled]="member.role === 'team admin'">{{member.role === 'admin' ? 'Remove as Admin' : 'Add as Admin'}}</button>
        <button [disabled]="team.enabled===0 || member.message !== 'Active'" mat-menu-item [matMenuTriggerFor]="memberSendMenu" [matMenuTriggerData]="{member: member}">Send</button>
        <button mat-menu-item (click)="changeUserMonthlyAllowance(member)">{{member.canReceiveAllowance === 1 ? 'Disable' : 'Enable'}} monthly allowance</button>
    </ng-template>
</mat-menu>
<mat-menu #memberSendMenu="matMenu">
    <ng-template matMenuContent let-item="member">
        <button mat-menu-item (click)="rewardMember(member)">One Time Reward</button>
        <button mat-menu-item (click)="p2pTopupMember(member)">Member-to-Member Top Up</button>
    </ng-template>
</mat-menu>
