<h1 mat-dialog-title>
  {{dialogData.title}}
</h1>
<div mat-dialog-content>
    <span class="info">{{dialogData.info}}</span>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
            <mat-form-field>
                <mat-label>{{dialogData.teamMode ? 'Employers' : 'Companies'}}</mat-label>
                <mat-select formControlName="company">
                    <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
                </mat-select>
            </mat-form-field>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="form && !form.valid" class="donebutton" color="primary" (click)="setCompany()">{{dialogData.setButton}}</button>
    <button mat-raised-button class="donebutton" (click)="removeCompany()">{{dialogData.removeButton}}</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
