import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-imageupload',
  templateUrl: './imageupload.component.html',
  styleUrls: ['./imageupload.component.scss']
})
export class ImageuploadComponent implements AfterViewInit {

  @ViewChild('camerainput') cameraInput?: ElementRef;
  @ViewChild('avatar') avatar?: ElementRef;
  @Input() imageUrl = '';
  @Output() uploadedEvent: EventEmitter<any> = new EventEmitter<any>();

  public gotImage = false;

  constructor(
      private snackBar: MatSnackBar,
    ) {
  }

  ngAfterViewInit(): void {
    if (this.avatar && this.imageUrl) {
      this.avatar.nativeElement.src = this.imageUrl;
    }
  }

  takePicture(): void {
    this.cameraInput?.nativeElement.click();
  }

  changePhoto($event: any): void {
    if ($event && $event.target.files[0] && $event.target.files[0].type.indexOf('image') < 0) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'You did not select a valid image file'});
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      this.uploadImage(reader.result);
    };
    reader.readAsArrayBuffer($event.target.files[0]);
  }

  private uploadImage(img: any): void {
    if (this.avatar) {
      this.avatar.nativeElement.src = 'data:image/jpeg;base64,' + btoa(String.fromCharCode(...new Uint8Array(img)));
      this.gotImage = true;
    }
    this.uploadedEvent.emit(img);
  }

  clearAvatar(): void {
    if (this.avatar) {
      this.gotImage = false;
      this.avatar.nativeElement.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    }
    this.uploadedEvent.emit(null);
  }

}
