import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TeamsService} from '../../../services/teams/teams.service';
import {Router} from '@angular/router';

interface UserInfo {
  userId: string;
  name: string;
  transactionCount: number;
  totalAmount: number;
  balance: number;
  disabled: boolean;
}

@Component({
  selector: 'app-analytics-members',
  templateUrl: './analytics-members.component.html',
  styleUrls: ['./analytics-members.component.scss']
})
export class AnalyticsMembersComponent implements OnInit, OnChanges {

  @Input() analytics: any;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public fileName = '';
  public items = new MatTableDataSource<UserInfo>();
  public columnsToDisplay = ['name', 'transactionCount', 'totalAmount', 'balance', 'disabled', 'action'];
  private members: any;
  private currentWellspace: any;

  constructor(
      private teamsService: TeamsService,
      private router: Router
  ) {
  }

  ngOnInit(): void {
    this.loadItems();
  }

  ngOnChanges(): void {
    this.loadItems();
  }

  private loadItems(): void {
    this.items.data = [];
    if (this.analytics) {
      this.analytics.transactions.forEach((transaction: any) => {
        const foundItem = this.items.data.find(item => item.userId === transaction.userId);
        if (!foundItem) {
          this.items.data.push({disabled: false, balance: 0, userId: transaction.userId, name: `${transaction.firstName} ${transaction.lastName}`, transactionCount: 1, totalAmount: transaction.amount});
        } else {
          if (transaction) {
            foundItem.transactionCount++;
            foundItem.totalAmount += transaction.teamAmountReimbursed;
          }
        }
      });
      this.items.sort = this.sort;
      this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
        this.currentWellspace = wellspace;
        this.fileName = `${this.currentWellspace.name} members`;
        this.teamsService.getTeamMembers(this.currentWellspace.id).then(members => {
          this.members = members;
          this.items.data.forEach(item => {
            const matchingMember = this.members.find((member: any) => member.userId === item.userId);
            if (matchingMember) {
              item.balance = matchingMember.balance;
              item.disabled = matchingMember.disabled;
            }
          });
          this.items.data = JSON.parse(JSON.stringify(this.items.data));
        });
      });

    }
  }

  showReimbursements(item: any): void {
    this.router.navigate(['analytics/reimbursements', item.userId]);
  }
}
