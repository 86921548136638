import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TableConfig} from '../table/table.component';
import {MetaService} from '../../../services/meta/meta.service';
import {Dictionary} from 'highcharts';
import {UtilsService} from '../../../services/utils/utils.service';
import {flatten} from 'flat';
import {ImageService, MediaVersionInfo} from '../../../services/meta/meta.image.service';
import {DomSanitizer} from '@angular/platform-browser';
import _ from 'lodash';
import {MatSelectChange} from '@angular/material/select';

export interface Field {
  name: string;
  label: string;
  fieldType: string;
  required?: boolean;
  level: number;
  values?: Array<string>;
  foreignKeyTable?: string;
}

@Component({
  selector: 'app-addedit',
  templateUrl: './addedit.component.html',
  styleUrls: ['./addedit.component.scss']
})
export class AddEditDialogComponent implements OnInit {

  public LEVEL_OFFSET = 40;
  public FIELD_SECTION = 'section';
  public config;
  public form;
  public item;
  public fields: Array<Field> = [];
  public files: File[] = [];
  public versions: Array<MediaVersionInfo> = [];
  private controls: Dictionary<any> = {};
  private actionThis;
  private actionFunc;
  private imageChanged = false;

  constructor(
      private formBuilder: UntypedFormBuilder,
      public dialogRef: MatDialogRef<AddEditDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: AddEditDialogModel,
      private metaService: MetaService,
      private utilsService: UtilsService,
      private imageService: ImageService,
  ) {
    let savedFileFieldValue = '';
    const buildFileField = (field: string) => this.imageService.get(field).then(file => {
      savedFileFieldValue = field;
      this.files = [
        new File([file.blob], '', {type: file.blob.type, lastModified: new Date(0).getSeconds()})
      ];
    });
    this.config = data.config;
    this.item = data.item;
    this.actionFunc = data.actionFunc;
    this.actionThis = data.actionThis;
    const flattenedItems = this.item ? flatten(this.item, {safe: true}) as Dictionary<any> : {};
    const buildFields = (schema: Dictionary<any>, keys: Array<string> = [], level = 0) => {
      for (const [key, value] of Object.entries(schema)) {
        if (![...this.config.excludedFields ?? [], 'metadata'].find(fieldName => fieldName === key)) {
          const item = schema[key];
          const fullKey = [...keys, key];
          const fullKeyAsName = fullKey.join('.');
          if (item.children) {
            this.fields.push({fieldType: this.FIELD_SECTION, label: key, level, name: ''});
            buildFields(item.children, fullKey, level + 1);
          } else {
            const foreignKeyTableName = this.metaService.tableSchema.keys[this.config.dbTableName].find((foreignKey: any) => foreignKey.fieldName === key)?.tableName;
            const imageColumn = this.config.columns.find(column => column.name === fullKeyAsName && column.isImage);
            if (imageColumn) {
              this.fields.push({fieldType: 'File', label: key, required: item.required, level, name: fullKeyAsName});
              const fileFieldValue = _.get(this.item, fullKeyAsName);
              if (fileFieldValue) {
                this.imageService.getVersions(fileFieldValue).then(versions => {
                  this.versions = versions;
                });
                buildFileField(fileFieldValue);
              }
            } else {
              this.fields.push({fieldType: item.fieldType, label: key, required: item.required, level, name: fullKeyAsName, values: item.values, foreignKeyTable: foreignKeyTableName});
            }
            const initialValue = this.config.expand?.includes(fullKeyAsName) ? flattenedItems[`${fullKeyAsName}.metadata.id`] : flattenedItems[fullKeyAsName];
            this.controls[fullKeyAsName] = new UntypedFormControl(item.fieldType === 'Date' ? initialValue.slice(0, -1) : initialValue, item.required ? [Validators.required] : []);
          }
        }
      }
    };
    buildFields(this.metaService.tableSchema.schema[this.config.dbTableName]);
    this.controls._versionselect =  new UntypedFormControl();
    this.controls._versionselect.valueChanges.subscribe((value: MediaVersionInfo) => {
      this.imageService.setVersion(savedFileFieldValue, value.version_id).then(() => {
        buildFileField(savedFileFieldValue);
      });
    });
    this.form = this.formBuilder.group(this.controls);
  }

  ngOnInit(): void {
  }

  okClicked(): void {
    const createFields = this.utilsService.getFormFields(this.form, this.fields.filter(field => !field.name.startsWith('_')));
    this.fields.forEach(field => {
      if (field.fieldType === 'File') {
        if (this.imageChanged) {
          createFields.file = this.files[0] as Blob; // TODO: only works for one file in the dialog
        } else {
          delete createFields.file;
        }
      }
    });
    this.actionFunc.bind(this.actionThis)(createFields, this.item).then(ok => {
      if (ok) {
        this.dialogRef.close(true);
      }
    });
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  onSelect(event: any, field: Field): void {
    this.files = [event.addedFiles[0]];
    this.form.get(field.name)?.setValue(field.name);
    this.imageChanged = true;
  }

  onRemove(event: any, field: Field): void {
    this.files.splice(this.files.indexOf(event), 1);
    this.form.get(field.name)?.setValue(undefined);
  }

  versionChanged(event: MatSelectChange): void {
    console.log('VERSION CHANGED', event);
  }

}

export class AddEditDialogModel {
  constructor(public config: TableConfig, public actionThis: any, public actionFunc: (data: Dictionary<any>, item?: Dictionary<any>) => Promise<boolean>, public item?: Dictionary<any>) {
  }
}

@Pipe({
  name: 'desnake',
  pure: true
})
export class SnakeCasePipe implements PipeTransform {

  transform(input: string): string {
    const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);
    const words = input.split('_').map(word => capitalize(word));
    if (words[words.length - 1] === 'Id') {
      words.pop();
    }
    return words.join(' ');
  }

}
