<div class="actionwrapper">
    <div class="actions">
        <button mat-raised-button color="primary" (click)="addItem()">
            <mat-icon>add</mat-icon>
            Add new group question
        </button>
    </div>
</div>
<table class="table" mat-table matSort [dataSource]="items">
    <ng-container matColumnDef="groupPlanId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group Plan</th>
        <td class="cell" mat-cell *matCellDef="let item">{{getGroupPlanName(item)}}</td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.category}}</td>
    </ng-container>
    <ng-container matColumnDef="sequence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sequence #</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.sequence}}</td>
    </ng-container>
    <ng-container matColumnDef="questionText">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Question Text</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.questionText}}</td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Response Type</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.type}}</td>
    </ng-container>
    <ng-container matColumnDef="metadata">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Metadata</th>
        <td class="cell" mat-cell *matCellDef="let item">{{truncateJSONString(item.metadata, 500)}}</td>
    </ng-container>
    <ng-container matColumnDef="actionmenu" stickyEnd>
        <th mat-header-cell matTooltip="Actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item"><button mat-button class="cell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="allColumnNames"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: allColumnNames"></tr>
    <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="editItem(item)">Edit</button>
            <button mat-menu-item (click)="duplicateItem(item)">Duplicate</button>
            <button mat-menu-item (click)="removeItem(item)">Remove</button>
        </ng-template>
    </mat-menu>
</table>
