import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class TeamprogramsService {

  constructor(
      private restangular: Restangular,
  ) { }

  getPrograms(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('programs').getList().toPromise();
  }

  createProgram(teamId: string, type: string, amount: number, message: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('programs').customPOST({
      type,
      teamId,
      amount,
      message,
    }).toPromise();
  }

  modifyProgram(teamId: string, programId: string, fields: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('programs', programId).customPUT({
      ...fields
    }).toPromise();
  }

  deleteProgram(teamId: string, programId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('programs', programId).remove().toPromise();
  }

  getProgramLogs(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('programlogs').getList().toPromise();
  }

  createProgramLog(teamId: string, userId: string, amount: number, message: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('programlogs').customPOST({
      userId,
      teamId,
      amount,
      message,
    }).toPromise();
  }

}
