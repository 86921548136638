import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-admin-actions',
  templateUrl: './admin-actions.component.html',
  styleUrls: ['./admin-actions.component.scss']
})
export class AdminActionsComponent implements OnInit {

  public config: TableConfig = {
    title: 'Actions',
    dbTableName: 'actions',
    readOnly: true,
    expand: ['user_id', 'company_id'],
    columns: [
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'},
      {name: 'user_id.name', title: 'User'},
      {name: 'company_id.name', title: 'Company'},
      {name: 'source', title: 'Source'},
      {name: 'action', title: 'Action'},
      {name: 'message', title: 'Message'},
      {name: 'status', title: 'Status'},
    ],
    menuItems: [
      { icon: '', label: 'Initiate Action', click: (item) => true}
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
