import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {StripeService} from '../../../services/stripe/stripe.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {TeamsService} from '../../../services/teams/teams.service';
import {Location} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TeamRewardSchedulesService} from '../../../services/teamrewardschedules/teamrewardschedules.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-uploadrewardsdialog',
  templateUrl: './uploadrewardsdialog.html',
  styleUrls: ['./uploadrewardsdialog.scss'],
})

export class UploadRewardsComponent implements OnInit {

  public sendButtonLabel = '';
  public successMessage = '';
  public team: any;
  public uploading = false;
  public result: any;
  constructor(
      private route: ActivatedRoute,
      private formBuilder: UntypedFormBuilder,
      private stripeService: StripeService,
      private uiAlertService: UiAlertService,
      private teamsService: TeamsService,
      private teamRewardScheduleService: TeamRewardSchedulesService,
      private location: Location,
      private snackBar: MatSnackBar,
      public dialogRef: MatDialogRef<UploadRewardsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: UploadRewardsComponentModel
  ) {
    this.teamsService.getTeamInfo(data.teamId).then(team => {
      this.team = team;
    });
  }

  ngOnInit(): void {
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }

  gotFileContents(event: any): void {
    this.uploading = true;
    this.result = undefined;
    this.teamRewardScheduleService.uploadRewardList(this.team.id, event).then(result => {
      this.uploading = false;
      if (result.errorMessage) {
        this.snackBar.openFromComponent(SnackBarComponent, {data: result.errorMessage});
        this.result = result;
      } else {
        this.successMessage = `${result.count} immediate, ${result.deferredCount} delayed rewards processed successfully`;
      }
    });
  }
}

export class UploadRewardsComponentModel {
  constructor(public teamId: string) {
  }
}
