import {AfterViewInit, Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-member-enroll',
  templateUrl: './member-enroll.component.html',
  styleUrls: ['./member-enroll.component.scss']
})
export class MemberEnrollComponent implements AfterViewInit {

  protected policies = {
    categories: [
      {
        title: 'Supplemental Coverage',
        details: this.domSanitizer.bypassSecurityTrustHtml(`<p style="font-size: 18px">Select from the following supplemental plans below. Please note that:</p><ul style="text-align: left"><li>Selecting a plan below does not guarantee issuance as all Aflac supplemental lines require a minimum of two employees from your group to enroll. Please contact your group manager for more details.</li><li>The Critical Illness and Life plans have different rates based on tobacco use. If you select these plans, you will be asked to provide information about your tobacco use.</li></ul>`),
        plans: [
          {
            title: 'Lump Sum Critical Illness',
            logo: 'https://1000logos.net/wp-content/uploads/2022/12/Aflac-logo.png',
            detailsUrl: 'https://s3.amazonaws.com/documents-pub.candor.insurance/sbc/ancillary/aflac/criticalIllness-CO.pdf',
            tiles: [
              {
                header: 'Employee/Child Tier',
                price: '$12/mo',
                priceFooter: 'AFTER EMPLOYER CONTRIBUTION',
                content: 'Your employer contributes <b>$0/mo</b> to this plan'
              },
              {
                line1: 'Major Critical Illness Event',
                line2: '$10,000',
                line3: '*PAYABLE ONCE PER LIFETIME'
              },
              {
                line1: 'Subsequent Critical Illness Event',
                line2: '$7,500',
              },
              {
                line1: 'Sudden Cardiac Arrest Benefit',
                line2: '$10,000',
                line3: '*PAYABLE ONCE PER LIFETIME'
              }
            ]
          },
          {
            title: 'Accident Indemnity Advantage',
            logo: 'https://1000logos.net/wp-content/uploads/2022/12/Aflac-logo.png',
            detailsUrl: '',
            tiles: [
              {
                header: 'Employee/Child Tier',
                price: '$27/mo',
                priceFooter: 'AFTER EMPLOYER CONTRIBUTION',
                content: 'Your employer contributes <b>$0/mo</b> to this plan'
              },
              {
                line1: 'Accidental Death Benefit',
                line2: '$80,000',
              },
              {
                line1: 'Initial Accident Hospitalization Benefit',
                line2: '$625 once per period hospital confinement',
              },
              {
                line1: 'Accident Hospital Confinement Benefit',
                line2: '$150 per day',
              }
            ]
          },
          {
            title: 'Voluntary Term Life Plans',
            logo: 'https://app.myhealthily.com/carrier-logos/one-america-logo.png',
            detailsUrl: '',
            tiles: [
              {
                header: 'Individual',
                price: '$36/mo',
                priceFooter: 'AFTER EMPLOYER CONTRIBUTION',
                content: 'Your employer contributes <b>$0/mo</b> to this plan'
              },
              {
                line1: 'Benefit Period',
                line2: '13 Weeks'
              },
              {
                line1: 'Eliminationn Period Accident',
                line2: '30 Days',
              },
              {
                line1: 'Weekly Benefit',
                line2: '$25 to $1,000',
              },
              {
                line1: 'Elimination Period Sickness',
                line2: '30 Days'
              }
            ]
          }
        ]
      },
      {
        title: 'Small Vegetable Coverage',
        details: this.domSanitizer.bypassSecurityTrustHtml(`<p style="font-size: 18px">Select from the following hilarious below. Please note that:</p><ul style="text-align: left"><li>Selecting a plan below does not guarantee issuance as all Aflac supplemental lines require a minimum of two employees from your group to enroll. Please contact your group manager for more details.</li><li>The Critical Illness and Life plans have different rates based on tobacco use. If you select these plans, you will be asked to provide information about your tobacco use.</li></ul>`),
        plans: [
          {
            title: 'Lump Sum Critical Illness',
            logo: 'https://1000logos.net/wp-content/uploads/2022/12/Aflac-logo.png',
            detailsUrl: 'https://s3.amazonaws.com/documents-pub.candor.insurance/sbc/ancillary/aflac/criticalIllness-CO.pdf',
            tiles: [
              {
                header: 'Employee/Child Tier',
                price: '$12/mo',
                priceFooter: 'AFTER EMPLOYER CONTRIBUTION',
                content: 'Your employer contributes <b>$0/mo</b> to this plan'
              },
              {
                line1: 'Major Critical Illness Event',
                line2: '$10,000',
                line3: '*PAYABLE ONCE PER LIFETIME'
              },
              {
                line1: 'Subsequent Critical Illness Event',
                line2: '$7,500',
              },
              {
                line1: 'Sudden Cardiac Arrest Benefit',
                line2: '$10,000',
                line3: '*PAYABLE ONCE PER LIFETIME'
              }
            ]
          },
          {
            title: 'Accident Indemnity Advantage',
            logo: 'https://1000logos.net/wp-content/uploads/2022/12/Aflac-logo.png',
            detailsUrl: '',
            tiles: [
              {
                header: 'Employee/Child Tier',
                price: '$27/mo',
                priceFooter: 'AFTER EMPLOYER CONTRIBUTION',
                content: 'Your employer contributes <b>$0/mo</b> to this plan'
              },
              {
                line1: 'Accidental Death Benefit',
                line2: '$80,000',
              },
              {
                line1: 'Initial Accident Hospitalization Benefit',
                line2: '$625 once per period hospital confinement',
              },
              {
                line1: 'Accident Hospital Confinement Benefit',
                line2: '$150 per day',
              }
            ]
          },
          {
            title: 'Voluntary Term Life Plans',
            logo: 'https://app.myhealthily.com/carrier-logos/one-america-logo.png',
            detailsUrl: '',
            tiles: [
              {
                header: 'Individual',
                price: '$36/mo',
                priceFooter: 'AFTER EMPLOYER CONTRIBUTION',
                content: 'Your employer contributes <b>$0/mo</b> to this plan'
              },
              {
                line1: 'Benefit Period',
                line2: '13 Weeks'
              },
              {
                line1: 'Eliminationn Period Accident',
                line2: '30 Days',
              },
              {
                line1: 'Weekly Benefit',
                line2: '$25 to $1,000',
              },
              {
                line1: 'Elimination Period Sickness',
                line2: '30 Days'
              }
            ]
          }
        ]
      }
    ]
  };

  protected navItems: any;
  protected contentSections: any;
  protected progressBar: any;

  private spouseIndex = 0;
  private childIndex = 0;

  constructor(
      private domSanitizer: DomSanitizer
  ) {
  }

  ngAfterViewInit(): void {
    this.navItems = document.querySelectorAll('.nav-item');
    this.contentSections = document.querySelectorAll('.content-section');
    this.progressBar = document.querySelector('.progress-bar');
    this.initialize();
  }


  updateView(sectionId: any, step: number): void {
    this.contentSections.forEach((section: any) => {
      section.classList.toggle('active', section.id === sectionId);

      if (section.id === sectionId) {
        const activeSubSection = section.querySelector('.section-subsection.active');
        if (activeSubSection) {
          activeSubSection.classList.remove('active');
        }
        const firstSubSection = section.querySelector('.section-subsection');
        if (firstSubSection) {
          firstSubSection.classList.add('active');
        }
      }
    });
    this.navItems.forEach((nav: any) => {
      nav.classList.toggle('active', nav.getAttribute('data-section') === sectionId);
    });
    const progress = (step / this.navItems.length) * 100;
    this.progressBar.style.width = `${progress}%`;
  }

  initialize(): void {
    this.updateView('about-you', 1);
    document.addEventListener('click', event => {
      // @ts-ignore
      if (event.target?.closest('.dropdown-item')) {
        // @ts-ignore
        const targetId = event.target?.closest('.dropdown-item').getAttribute('data-target');
        // @ts-ignore
        const parentNavItem = event.target?.closest('.nav-item');
        const sectionId = parentNavItem.getAttribute('data-section');
        this.updateView(sectionId, parseInt(parentNavItem.getAttribute('data-step'), 10));

        const currentSection = document.querySelector(`#${sectionId}`);
        if (currentSection) {
          currentSection.querySelectorAll('.section-subsection').forEach(subsection => {
            subsection.classList.toggle('active', subsection.id === targetId);
          });
        }
      }
    });
  }

  nextSubSection(nextSubSectionId: any): void {
    const currentSection = document.querySelector('.content-section.active');
    if (!currentSection) {
      return;
    }
    const currentSubSection = currentSection.querySelector('.section-subsection.active');
    if (!currentSubSection) {
      return;
    }
    const nextSubSection = currentSection.querySelector(`#${nextSubSectionId}`);
    if (nextSubSection) {
      currentSubSection.classList.remove('active');
      nextSubSection.classList.add('active');
    }
  }

  nextSection(nextSectionId: any): void {
    const currentSection = document.querySelector('.content-section.active');
    if (!currentSection) {
      return;
    }
    const nextSection = document.querySelector(`#${nextSectionId}`);
    if (nextSection) {
      currentSection.classList.remove('active');
      nextSection.classList.add('active');
      const navItem = document.querySelector(`.nav-item[data-section="${nextSectionId}"]`);
      if (navItem) {
        // @ts-ignore
        const step = parseInt(navItem.getAttribute('data-step'), 10);
        this.updateView(nextSectionId, step);
      }
    }
  }

  addForm(containerId: string, formHTML: (arg0: any) => string, indexVar: number, index: any): void {
    const container = document.getElementById(containerId);
    container?.insertAdjacentHTML('beforeend', formHTML(index));
    indexVar++;
  }

  addSpouseForm = () => {
    this.addForm('spouse-forms', index => `
        <div class="form-container">
            <h3>Spouse Information<span class="trashcan-icon" (click)="removeForm(this)">delete</span></h3>
            <div class="form-field">
                <div class="form-row">
                    <input type="text" name="spouse-name-${index}" placeholder="Spouse Name" required>
                    <input type="text" name="spouse-ssn-${index}" placeholder="SSN" required>
                    <select name="spouse-gender-${index}">
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div class="checkbox-row">
                    <label><input type="checkbox" name="spouse-tobacco-${index}"> Tobacco Use</label>
                    <label><input type="checkbox" name="spouse-household-${index}"> Same Household</label>
                </div>
            </div>
            <button type="button" (click)="saveSpouse(${index}, this)">Save Spouse</button>
        </div>
    `, this.spouseIndex, this.spouseIndex);
  }

  addChildForm = () => {
    this.addForm('child-forms', index => `
        <div class="form-container">
            <h3>Child Information<span class="trashcan-icon" (click)="removeForm(this)">delete</span></h3>
            <div class="form-field">
                <div class="form-row">
                    <input type="text" name="child-first-name-${index}" placeholder="First Name" required>
                    <input type="text" name="child-last-name-${index}" placeholder="Last Name" required>
                    <input type="text" name="child-ssn-${index}" placeholder="SSN" required>
                    <input type="date" name="child-birthdate-${index}" required>
                    <select name="child-gender-${index}">
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div class="checkbox-row">
                    <label><input type="checkbox" name="child-tobacco-${index}"> Tobacco Use</label>
                    <label><input type="checkbox" name="child-household-${index}"> Same Household</label>
                </div>
            </div>
            <button type="button" (click)="saveChild(${index}, this)">Save Child</button>
        </div>
    `, this.childIndex, this.childIndex);
  }

  removeForm = (element: { closest: (arg0: string) => any; }) => {
    const form = element.closest('.form-container');
    form.remove();
  }

  showToast(): void {
    const toast = document.getElementById('toast');
    toast?.classList.add('show');
    setTimeout(() => {
      toast?.classList.remove('show');
    }, 3000); // Time to stay visible
  }


  saveSpouse = (index: any, buttonElement: { remove: () => void; }) => {
    this.showToast();
    buttonElement.remove();
  }

  saveChild = (index: any, buttonElement: { remove: () => void; }) => {
    this.showToast();
    buttonElement.remove();
  }

  goToCoverage = () => {
    window.location.href = 'your-coverage.html';
  }

  openFile(fileName: string): void {
    window.open(fileName, 'pdfPopup', 'width=800,height=600,scrollbars=yes');
  }

}
