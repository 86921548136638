import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UsersService} from '../../services/users/users.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-externalbrokeradmin',
  templateUrl: './brokeradmin.component.html',
  styleUrls: ['./brokeradmin.component.scss']
})
export class BrokeradminComponent implements OnInit {

  private iframeWindow: any;

  public launchUrl: SafeUrl | undefined;

  @ViewChild('iframeObj', {static: false}) iframeObj: ElementRef | undefined;

  constructor(
      private usersService: UsersService,
      private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.usersService.getBrands().then(brands => {
      this.launchUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(brands.find((brand: any) => brand.name === 'Wallit').configjson.integration.myHealthily.adminLaunchUrl);
    });
  }

}
