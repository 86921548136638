<div class="topline">
    <h1>Agency</h1>
    <mat-form-field *ngIf="agencies.length > 0">
        <mat-label>Current Agency</mat-label>
        <mat-select (selectionChange)="setAgency($event)" [(value)]="selectedAgencyId">
            <mat-option *ngFor="let agency of agencies" [value]="agency.id">{{agency.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <h1 *ngIf="agencies.length === 0">{{agencyName}}</h1>
</div>
<app-infoband [items]="info"></app-infoband>
<nav mat-tab-nav-bar #tabs>
    <a mat-tab-link *ngFor="let link of links" (click)="gotoTab(link.link)"
       [active]="activeLink == link">{{link.title}}</a>
</nav>
<ng-container *ngIf="!selectedAgencyId">
    <app-inlinemessage level="info" message="Please select an agency so that you can update their profile, members, and producers"></app-inlinemessage>
</ng-container>
<ng-container *ngIf="selectedAgencyId">
    <app-agency-profile [companyId]="selectedAgencyId" *ngIf="activeLink?.title === 'Profile'"></app-agency-profile>
    <app-agency-clients (countEvent)="setCount('Clients', $event)" [companyId]="selectedAgencyId" [enabled]="activeLink?.title === 'Clients'"></app-agency-clients>
    <app-agency-members (countEvent)="setCount('Members', $event)" [companyId]="selectedAgencyId" [enabled]="activeLink?.title === 'Members'"></app-agency-members>
    <app-agency-producers (countEvent)="setCount('Producers', $event)" [companyId]="selectedAgencyId" [enabled]="activeLink?.title === 'Producers'"></app-agency-producers>
</ng-container>
