<table *ngIf="team && team.approved" class="table" mat-table [dataSource]="items">

    <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef>Account</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.account}}</td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="cell" mat-cell *matCellDef="let item">
            <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="memberMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
        </td>
    </ng-container>
    <mat-menu #memberMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="attachPaymentMethod(item)">Attach Payment Method</button>
            <button mat-menu-item (click)="removePaymentMethod(item)">Remove Payment Method</button>

        </ng-template>

    </mat-menu>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" ></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
<span *ngIf="team && team.approved && items.data.length === 0" class="emptymessage">You do not have any available payment methods to attach into your account.</span>

<div *ngIf="team && !team.approved">
    <br/>
    <br/>
    <span class="mandatetext">
        Your team has not yet been approved by Wallit. Once it has been approved, you will be able to return here and set up your payment method.
    </span>
</div>
