import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Dictionary} from 'highcharts';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-reconciliation-apilog',
  templateUrl: './reconciliation-apilog.component.html',
  styleUrls: ['./reconciliation-apilog.component.scss']
})
export class ReconciliationApilogComponent implements OnInit, OnChanges {

  @Input() info: any;
  columnsToDisplay = ['date', 'method', 'endpoint', 'member', 'body', 'responsecode'];
  public items = new MatTableDataSource<Dictionary<any>>();
  public form: UntypedFormGroup;

  constructor(
      private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
    });
  }

  ngOnInit(): void {
    this.loadItems();
  }

  ngOnChanges(): void {
    this.loadItems();
  }

  private loadItems(): void {
    this.items.data = this.info.apiLogTransactions;
    this.items.data?.forEach(item => {
      item.request.body = JSON.stringify(item.request.body);
      const thisMember = this.info.members.find((member: any) => member.id === item.userId);
      item.sender = (thisMember?.firstName || '') + ' ' + (thisMember?.lastName || '');
    });
  }

}
