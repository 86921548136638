import { Component, OnInit } from '@angular/core';
import {TableConfig} from '../metaview/table/table.component';

@Component({
  selector: 'app-advertising-impressions',
  templateUrl: './advertising-impressions.component.html',
  styleUrls: ['./advertising-impressions.component.scss']
})
export class AdvertisingImpressionsComponent implements OnInit {

  public config: TableConfig = {
    title: 'Impressions',
    dbTableName: 'impressions',
    expand: [],
    columns: [
      {name: 'name', title: 'Impression Name'},
      {name: 'metadata.created_at', title: 'Created', dateFormat: 'medium'}
    ],
    menuItems: [
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
