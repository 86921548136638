<h1>Manage Your Wellspace</h1>
<nav mat-tab-nav-bar #tabs>
  <a mat-tab-link *ngFor="let link of links" 
     (click)="onLinkClick(link)"
     [disabled]="link.title === 'Plan' && !isApproved"
     [active]="activeLink == link">{{ link.title }}</a>
</nav>

<app-teamedit *ngIf="activeLink?.title === 'Overview'"></app-teamedit>
<app-team-set-benefits *ngIf="activeLink?.title === 'Benefits'" 
  (emitHasUnsavedChanges)="setHasUnsavedChanges($event)" 
  (emitChangesWereSaved)="setHasUnsavedChanges($event)">
</app-team-set-benefits>
<app-team-add-members *ngIf="activeLink?.title === 'Members'"></app-team-add-members>
<app-program *ngIf="activeLink?.title === 'Scheduled Rewards'"></app-program>
<app-plan *ngIf="activeLink?.title === 'Plan'"></app-plan>
