import { Component, Input } from '@angular/core';
import { Invoice } from 'src/app/interfaces/stripe/invoice.interface';

@Component({
  selector: 'app-stripe-chargehistory-table',
  templateUrl: './stripe-chargehistory-table.component.html',
  styleUrls: ['./stripe-chargehistory-table.component.scss']
})
export class StripeChargehistoryTableComponent {
    @Input() invoices: Invoice[] = [];

  expandCollapse(charge: any): void {
    charge.expanded = !charge.expanded;
  }

  openInvoice(url: any): void {
    window.open(url);
  }

}
