import {Inject, Injectable} from '@angular/core';
import {Dictionary} from 'highcharts';
import {RESTANGULAR_INTEGRATION_SERVER} from '../../app.module';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  public tableSchema: Dictionary<any> = {};

  constructor(
      @Inject(RESTANGULAR_INTEGRATION_SERVER) private restangular: Restangular
  ) { }

  init(): Promise<any> {
    return this.restangular.one('meta/tables').get().toPromise().then((result: Dictionary<any>) => {
      this.tableSchema = result;
      return result;
    });
  }

  read(tableName: string, params?: Dictionary<string>, expand?: Array<string>): Promise<any> {
    return this.restangular.one(tableName).get(Object.assign(params ? params : {}, {expand: expand?.join(',')})).toPromise();
  }

  create(tableName: string, data: Dictionary<any>): Promise<any> {
    return this.restangular.one(tableName).customPOST(data).toPromise();
  }

  update(tableName: string, id: string, data: Dictionary<any>): Promise<any> {
    return this.restangular.one(tableName).one(id).customPUT(data).toPromise();
  }

  patch(tableName: string, id: string, data: Dictionary<any>): Promise<any> {
    return this.restangular.one(tableName).one(id).customPATCH(data).toPromise();
  }

  delete(tableName: string, id: string): Promise<any> {
    return this.restangular.one(tableName).one(id).remove().toPromise();
  }

}
