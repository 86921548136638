import { Component, OnInit, ViewChild } from '@angular/core';
import { TransactionsService } from '../../../../services/transactions/transactions.service';
import { TeamsService } from '../../../../services/teams/teams.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SpendingReportInterface } from './spending-report.interface';
import { Chart } from 'chart.js';

@Component({
    selector: 'app-analytics-spending-report',
    templateUrl: './analytics-spending-report.component.html',
    styleUrls: ['./analytics-spending-report.component.scss']
})
export class AnalyticsSpendingReportComponent implements OnInit {
    @ViewChild(MatSort) sort!: MatSort;
    private teamId = '';
    public spendingList: MatTableDataSource<SpendingReportInterface> = new MatTableDataSource<SpendingReportInterface>([]);
    public columnsToDisplay: string[] = ['categoryName', 'allowanceSpent', 'reimbursements'];
    public chart: any = [];

    constructor(
        private transactionService: TransactionsService,
        private teamService: TeamsService
    ) { }

    ngOnInit(): void {
        this.teamService.wellspaceChangedObservable.subscribe((team: any): void => {
            if (team && team.id) {
                this.teamId = team.id;
                this.getSpendingList();
            }
        });
    }

    getSpendingList(): void {
        if (this.teamId) {
            this.spendingList.data = [];
            this.transactionService.getTeamSpendingReport(this.teamId).then((spendingReportData: SpendingReportInterface[]): void => {
                this.spendingList.data = spendingReportData;
                this.spendingList.sort = this.sort;
                this.setChart(spendingReportData);
            }).catch(err => {
                this.spendingList.data = [];
                console.error('Error in get spending report', err);
            });
        }
    }

    getTotalSpent(): number {
        return this.spendingList.data.reduce((acc: number, curr: SpendingReportInterface): number => acc + curr.allowanceSpent, 0);
    }

    getTotalReimbursements(): number {
        return this.spendingList.data.reduce((acc: number, curr: SpendingReportInterface): number => acc + curr.reimbursements, 0);
    }

    setChart(spendingReportData: SpendingReportInterface[]): void {
        if (this.teamId) {
            this.chart = new Chart('chart', {
                type: 'bar',
                data: {
                    labels: spendingReportData.map(report => report.categoryName),
                    datasets: [
                        {
                            label: 'Allowance Balance Spent ($)',
                            data: spendingReportData.map(report => report.allowanceSpent),
                            backgroundColor: 'rgb(255, 99, 132)'
                        },
                    ],
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    return new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(tooltipItem.raw as number);
                                }
                            }
                        }
                    }
                }
            });
        }
    }

}
