import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-team-companies',
  templateUrl: './team-companies.component.html',
  styleUrls: ['./team-companies.component.scss']
})
export class TeamCompaniesComponent implements OnInit {

  public links = [
    { title: 'Companies', link: '/products/companies'},
    { title: 'Permissions', link: '/products/permissions'},
    { title: 'Subscriptions', link: '/products/subscriptions'},
  ];

  public activeLink: any;

  constructor(
      private router: Router
  ) { }

  ngOnInit(): void {
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 2;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
  }

  gotoTab(link: string): void {
    this.router.navigate([link]);
    this.activeLink = link;
  }
}
