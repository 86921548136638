<div *ngIf="team">
    <span class="teamname">{{team.name}}</span>
    <div class="planwrapper">
        <div class="plans currentplan" *ngIf="companyPlusPlan">
            <span class="planname">Plus plan</span>
            <span class="rate">Included</span>
        </div>
        <div [class]="team.plan === plan.plan ? 'plans currentplan' : 'plans'" *ngFor="let plan of companyPlusPlan ? [] : plans">
            <span class="planname">{{plan.name}}</span>
            <span class="rate">{{plan.rate}}</span>
            <button mat-raised-button [disabled]="team.plan === plan.plan || (!team.defaultPaymentMethod && plan !== plans[0]) || !isTeamApproved" color="primary" class="editbutton" (click)="selectPlan(plan)">
                {{team.plan === plan.plan ? 'Current Plan' : buttonMessage(plan)}}
            </button>
        </div>
    </div>
    <span *ngIf="!team.defaultPaymentMethod && !companyPlusPlan">After you have selected a payment method, you may then choose a different paid plan.</span>
</div>
