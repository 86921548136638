import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {TeamprogramsService} from '../../../services/teamprograms/teamprograms.service';
import {TeamsService} from '../../../services/teams/teams.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-editprogramdialog',
  templateUrl: './editprogramdialog.component.html',
  styleUrls: ['./editprogramdialog.component.scss']
})
export class EditProgramDialogComponent implements OnInit {

  public form;
  public program;
  private team: any;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private teamsService: TeamsService,
      private teamProgramsService: TeamprogramsService,
      private snackBar: MatSnackBar,
      public dialogRef: MatDialogRef<EditProgramDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: EditProgramDialogModel
  ) {
    this.program = data.program;
    this.form = this.formBuilder.group({
      amount: new UntypedFormControl(this.program.amount),
      message: new UntypedFormControl(this.program.message)
    });
  }

  ngOnInit(): void {
    this.teamsService.wellspaceChangedObservable.subscribe(wellspace => {
      this.team = wellspace;
    });
  }

  private getFormField(name: string): any {
    const field = this.form.get(name);
    return field ? field.value : undefined;
  }

  okClicked(): void {
    const newAmount = this.getFormField('amount');
    const newMessage = this.getFormField('message');
    this.program.amount = newAmount;
    this.program.message = newMessage;
    this.teamProgramsService.modifyProgram(this.team.id, this.program.id, {amount: newAmount, message: newMessage}).then(_ => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: this.program.updatedMessage});
      this.dialogRef.close(0);
    });
  }

  onDismiss(): void {
    this.dialogRef.close(0);
  }

}

export class EditProgramDialogModel {
  constructor(public program: any) {
  }
}

