import { Component, OnInit } from '@angular/core';
import {TeamsService} from '../../services/teams/teams.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SpinnerService} from '../../services/spinner/spinner.service';

@Component({
  selector: 'app-reconciliation',
  templateUrl: './reconciliation.component.html',
  styleUrls: ['./reconciliation.component.scss']
})
export class ReconciliationComponent implements OnInit {

  public links = [
    { title: 'Stripe Transactions', link: '/reconciliation/stripe'},
    { title: 'Team Log', link: '/reconciliation/teamlog'},
    { title: 'API Log', link: '/reconciliation/apilog'},
  ];
  public activeLink: any;

  public info: any;

  constructor(
      private teamsService: TeamsService,
      private route: ActivatedRoute,
      private router: Router,
      private spinnerService: SpinnerService
  ) {
  }

  ngOnInit(): void {
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 2;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
    this.route.params.subscribe(params => {
      this.links.forEach(link => link.link = `${link.link}/${params.customerId}`);
      this.spinnerService.show();
      // TODO: Seems to be sending the wrong parameter, should be teamId
      this.teamsService.getTeamReconciliation(params.customerId).then(info => {
        this.teamsService.getTeamInfo(params.customerId).then(wellspace => {
          info.team = wellspace;
          this.teamsService.getTeamMembers(params.customerId).then(members => {
            info.members = members;
            this.info = info;
            this.spinnerService.hide();
          });
        });
      });
    });
  }

}
