import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {ApiService} from '../../services/api/api.service';
import {SessionService} from '../../services/session/session.service';
import {AppSettings} from "../../app.settings";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
      public apiService: ApiService,
      private sessionService: SessionService,
      public router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const idTokenKey: string | undefined = Object.keys(localStorage).find(key => key.startsWith('CognitoIdentityServiceProvider') && key.endsWith('idToken'));
    const isApiAuthenticated: boolean = this.apiService.isAuthenticated();
    if (!isApiAuthenticated || !idTokenKey) {
      this.clearLocalStorageAndRedirectUser(state);
      return false;
    } else {
      return true;
    }
  }

  clearLocalStorageAndRedirectUser(state: RouterStateSnapshot): void {
    this.sessionService.loginUrl = state.url;
    localStorage.clear();
    void this.router.navigate(['/login']).then((): void => window.location.reload());
  }

}
