import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MetaService} from '../../services/meta/meta.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  @Input() tags: Array<string> = [];
  @Output() changeEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  public filteredTags: Array<string> = [];
  private allTags: Array<string> = [];

  constructor(
      private metaService: MetaService
  ) {
    this.filteredTags = this.allTags;
  }

  ngOnInit(): void {
    this.metaService.read('tags').then(tags => {
      this.allTags = tags.items;
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push(value.trim());
      this.changeEvent.emit(this.tags);
    }
    if (input) {
      input.value = '';
    }
  }

  remove(tag: any): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.changeEvent.emit(this.tags);
    }
  }

  change(event: any): void {
    this.filteredTags = this._filter(event.target.value);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().includes(filterValue));
  }

}
