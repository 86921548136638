import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Transaction} from '../../services/transactions/transactions.service';
import {Dictionary} from 'highcharts';
import {UntypedFormBuilder, FormControl, UntypedFormGroup} from '@angular/forms';
import {TeamsService} from '../../services/teams/teams.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-reconciliation-stripe',
  templateUrl: './reconciliation-stripe.component.html',
  styleUrls: ['./reconciliation-stripe.component.scss']
})
export class ReconciliationStripeComponent implements OnInit, OnChanges {

  @Input() info: any;
  columnsToDisplay = ['date', 'user', 'message', 'sender', 'amount', 'type', 'status'];
  public items = new MatTableDataSource<Dictionary<any>>();
  public form: UntypedFormGroup;
  public total = 0;

  constructor(
      private formBuilder: UntypedFormBuilder,
  ) {
    this.form = this.formBuilder.group({
    });
  }

  ngOnChanges(): void {
    this.loadItems();
  }

  ngOnInit(): void {
    this.loadItems();
  }

  loadItems(): void {
    this.items.data = this.info.stripeTransactions;
    this.items.data?.forEach(item => {
      const thisMember = this.info.members.find((member: any) => member.id === item.charges.data[0].invoice?.metadata?.fromUserId);
      item.sender = (thisMember?.firstName || '') + ' ' + (thisMember?.lastName || '');
    });
    this.total = this.items.data?.reduce((acc, obj) => acc + obj.amount_received, 0);
  }

  getMembers(item: any): string {
    try {
      return JSON.parse(item.charges.data[0].invoice.metadata.memberEmailorEmails).join(', ');
    } catch {
    }
    return '';
  }

  getStatus(item: any): string {
    if (item.last_payment_error) {
      return `${item.last_payment_error.code}/${item.last_payment_error.decline_code}`;
    }
    return '';
  }

  getType(item: any): string {
    return item.charges.data[0].invoice.metadata.isP2p === 'true' ? 'P2P' : '';
  }

}
