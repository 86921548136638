import {Component, Input} from '@angular/core';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../../snackbar/snackbar.component';

@Component({
  selector: 'app-broker-profile',
  templateUrl: './broker-profile.component.html',
  styleUrls: ['./broker-profile.component.scss']
})
export class BrokerProfileComponent {

  @Input() companyId = '';

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private snackBar: MatSnackBar
  ) {
  }

  saveBrokerProfile(data: any): void {
    this.teamCompaniesService.modifyCompany(this.companyId, data).then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Broker profile saved'});
    });
  }

}
