<table class="table" mat-table [dataSource]="items">
    <ng-container matColumnDef="default">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="cell" mat-cell *matCellDef="let item"><mat-chip *ngIf="item.default && item?.bankInfo?.type !== 'invoice'">Default</mat-chip></td>
    </ng-container>
    <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="cell" mat-cell *matCellDef="let item"><img class="logo" [src]="item.bankInfo.logo"></td>
    </ng-container>
    <ng-container matColumnDef="info1">
        <th mat-header-cell *matHeaderCellDef>Account</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.info1}}</td>
    </ng-container>
    <ng-container matColumnDef="status-info">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td class="cell" mat-cell *matCellDef="let item">{{ item.bankInfo && item.bankInfo.isPending ? 'Pending' : 'Verified' }}</td>
    </ng-container>
    <ng-container matColumnDef="info2">
        <th mat-header-cell *matHeaderCellDef>Expiration</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.info2}}</td>
    </ng-container>
    <ng-container matColumnDef="info3">
        <th mat-header-cell *matHeaderCellDef>Pays For</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.info3}}</td>
    </ng-container>
    <ng-container matColumnDef="verify-info">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="cell" mat-cell *matCellDef="let item" style="text-align: center">
            <button *ngIf="item.bankInfo.isPending" mat-button (click)="openStripeVerifyAccountModal(item.microdepositInfo ?? '')">Verify</button>
        </td>
    </ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="cell" mat-cell *matCellDef="let item">
            <button [disabled]="item?.bankInfo?.type === 'invoice'" (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="memberMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
        </td>
    </ng-container>
    <mat-menu #memberMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="removePaymentMethod(item)">Remove</button>
            <button mat-menu-item (click)="setDefaultPaymentMethod(item)" [disabled]="item.default">Make Default</button>
        </ng-template>
    </mat-menu>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" ></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
<span *ngIf="items.data.length === 0" class="emptymessage">You do not have any payment methods set up.</span>
