import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import {ApiService} from '../../services/api/api.service';
import {SessionService} from '../../services/session/session.service';

@Injectable({
    providedIn: 'root'
})
export class EmbeddedGuard implements CanActivate {

    constructor(
        public apiService: ApiService,
        private sessionService: SessionService,
        public router: Router,
        public route: ActivatedRoute
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise((resolve, reject) => {
                console.log('EMBEDDEDGUARD', route.paramMap);
                return this.sessionService.login(route.paramMap.get('userId') || '').then(_ => {
                    resolve(true);
                }).catch(error => {
                    resolve(false);
                });
        });
    }

}
