<div class="actions">
    <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: fileName})"><mat-icon>file_download</mat-icon>Export to CSV</button>
</div>
<form [formGroup]="form">
    <div class="controls">
        <mat-form-field>
            <mat-label>Company</mat-label>
            <mat-select formControlName="company" name=company>
                <mat-option *ngFor="let company of companies" [value]="company.value">{{company.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" name="status">
                <mat-option *ngFor="let status of statuses" [value]="status.value">{{status.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field >
            <mat-label>Start Date</mat-label>
            <input matInput formControlName="startDate" type="datetime-local">
        </mat-form-field>
        <mat-form-field >
            <mat-label>End Date</mat-label>
            <input matInput formControlName="endDate" type="datetime-local">
        </mat-form-field>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: fileName})"><mat-icon>file_download</mat-icon>Export to CSV</button>
    </div>
</form>
<table class="table" mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="subscriptions">
    <span *ngIf="subscriptions.length === 0">No subscriptions match your search criteria.</span>
    <ng-container matColumnDef="image" >
        <th mat-header-cell *matHeaderCellDef>Company</th>
        <td class="cell" mat-cell *matCellDef="let item">
            <div class="imagecontainer">
                <img class="productimage" [src]="item.plan.product.images[0]">
                <span>{{item.plan.product.metadata.company}}</span>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Product</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.plan.product.name}}</td>
    </ng-container>
    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">User</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.customer.name ? item.customer.name + ' ('+ item.customer.email + ')' : item.customer.email}}</td>
    </ng-container>
    <ng-container matColumnDef="pricing" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Pricing</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.plan.amount / 100 | currency}}</td>
    </ng-container>
    <ng-container matColumnDef="status" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Status</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.status| titlecase}}<span *ngIf="item.cancel_at">, Cancels {{item.cancel_at * 1000 | date: 'mediumDate'}}</span></td>
    </ng-container>
    <ng-container matColumnDef="created" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header  matTooltip="Sort Ascending/Descending/None">Created</th>
        <td class="cell" mat-cell *matCellDef="let item">{{item.created * 1000 | date: 'mediumDate'}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>

