
<div class="spending-report-container">

    <div class="spending-report-title">
        <h1>Spending Report</h1>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'spending-report'})">
            <mat-icon>file_download</mat-icon>Export to CSV
        </button>
    </div>

    <div class="spending-report-table-container">
        <div class="spending-report-chart">
            <canvas id='chart'>{{chart}}</canvas>
        </div>

        <table mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="spendingList" class="mat-elevation-z4">

            <ng-container matColumnDef="categoryName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Category Name</th>
                <td mat-cell *matCellDef="let element"> {{(element.categoryName.join(', ')) ?? '---'}} </td>
                <td mat-footer-cell *matFooterCellDef>Total:</td>
            </ng-container>

            <ng-container matColumnDef="allowanceSpent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Allowance Balance Spent ($)</th>
                <td mat-cell *matCellDef="let element"> {{(element.allowanceSpent | currency) ?? 0 }} </td>
                <td mat-footer-cell *matFooterCellDef> {{ getTotalSpent() | currency }} </td>
            </ng-container>

            <ng-container matColumnDef="reimbursements">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None"># of Reimbursements</th>
                <td mat-cell *matCellDef="let element"> {{element.reimbursements ?? 0}} </td>
                <td mat-footer-cell *matFooterCellDef> {{ getTotalReimbursements() }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
            <tr [hidden]="spendingList.data.length <= 0" class="spending-footer-row" mat-footer-row *matFooterRowDef="columnsToDisplay, sticky: true"></tr>

        </table>

        <p class="no-payment-text" *ngIf="spendingList.data.length <= 0">
            No Spending Report found!
        </p>

    </div>

</div>
