<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{item ? 'Edit' : 'Add New'}} {{config.title.slice(0, -1)}}
</h1>
<div mat-dialog-content>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
        <div *ngFor="let field of fields">
            <div [ngStyle]="{'margin-left': field.level * LEVEL_OFFSET + 'px'}" class="section" *ngIf="field.fieldType === FIELD_SECTION">
                <span [ngStyle]="{'font-size': 'calc(16pt - ' + field.level * 2 + 'pt)'}" class="sectionlabel">{{field.label | desnake}}</span>
            </div>
            <app-addeditfield *ngIf="field.fieldType === 'Boolean'" [formGroup]="form" [field]="field" [inputType]="'boolean'"></app-addeditfield>
            <app-addeditfield *ngIf="field.fieldType === 'Number'" [formGroup]="form" [field]="field" [inputType]="'number'"></app-addeditfield>
            <app-addeditfield *ngIf="field.fieldType === 'String' || field.fieldType === 'UUID'" [formGroup]="form" [field]="field" [inputType]="'text'"></app-addeditfield>
            <app-addeditfield *ngIf="field.fieldType === 'Date' || field.fieldType === 'DateNow'" [formGroup]="form" [field]="field" [inputType]="'datetime-local'"></app-addeditfield>
            <app-addeditfield *ngIf="field.fieldType === 'Color'" [formGroup]="form" [field]="field" [inputType]="'color'"></app-addeditfield>
            <app-addeditfield *ngIf="field.fieldType === 'HTML'" [formGroup]="form" [field]="field" [inputType]="'html'"></app-addeditfield>
            <app-addeditfield *ngIf="field.fieldType === 'ARRAY'" [formGroup]="form" [field]="field" [inputType]="'array'"></app-addeditfield>
            <div class="filetitle" *ngIf="field.fieldType === 'File'">
                <mat-label  class="fileformlabel" [ngStyle]="{'margin-bottom': '5px', 'margin-left': field.level * LEVEL_OFFSET + 'px'}">{{field.label | desnake}}</mat-label>
                <mat-form-field *ngIf="versions.length > 1" >
                    <mat-select formControlName="_versionselect" placeholder="Version...">
                        <mat-option *ngFor="let version of versions; let i = index;" [value]="version">{{version.last_modified | date: 'medium'}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <ngx-dropzone [ngStyle]="{'background-color': '#f8f8f8', 'margin-bottom': '20px', 'margin-left': field.level * LEVEL_OFFSET + 'px',  width: 'calc(100% - ' + field.level * LEVEL_OFFSET + 'px)'}" *ngIf="field.fieldType === 'File'" (change)="onSelect($event, field)" [multiple]="false">
                <ngx-dropzone-label>Drag a file or click to browse<span *ngIf="field.required" class="required">*</span></ngx-dropzone-label>
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f, field)">
                    <ngx-dropzone-label class="filelabel">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-image-preview>
            </ngx-dropzone>
        </div>
    </form>
</div>
<span class="required" >* Required Field</span>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!form.valid" class="donebutton" color="primary" (click)="okClicked()">OK</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
