<div class="header">
    <span class="title">{{config.title}}</span>
    <div >
        <button class="fab" mat-fab color="primary" [matMenuTriggerFor]="tablemenu" ><mat-icon>more_vert</mat-icon></button>
        <button mat-fab (click)="add()"><mat-icon>add</mat-icon></button>
    </div>
    <mat-menu #tablemenu="matMenu">
        <ng-template matMenuContent>
            <button mat-menu-item (click)="export()">
                <mat-icon>file_download</mat-icon>
                <span>Export...</span>
            </button>
            <button mat-menu-item (click)="import()">
                <mat-icon>file_upload</mat-icon>
                <span>Import...</span>
            </button>
        </ng-template>
    </mat-menu></div>
<div class="controls">
    <app-paginator [itemCount]="itemCount"></app-paginator>
</div>
<table class="table" mat-table matSort matTableExporter #exporter="matTableExporter" [hiddenColumns]="config.hiddenExportColumnNumbers" [dataSource]="items">
    <ng-container *ngFor="let column of config.columns" [matColumnDef]="column.name" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">{{column.title}}</th>
        <td mat-cell *matCellDef="let item">
            <span *ngIf="!column.isImage">{{column.dateFormat ? (_.get(item, column.name) | date : column.dateFormat) : (_.get(item, column.name) || (column.name2 && _.get(item, column.name2)))}}</span>
            <ng-template #tipContent>
                <img class="tipImage" [src]="column.imageUrl">
            </ng-template>
            <span *ngIf="column.isImage" matTooltip="$any(tipContent)" contentType="template"><img *ngIf="item.imageUrl" class="imagethumbnail" [src]="item.imageUrl"></span>
        </td>
    </ng-container>
    <ng-container matColumnDef="actionmenu" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let item"><button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button></td>
    </ng-container>
    <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button *ngFor="let menuItem of config.menuItems" mat-menu-item (click)="menuItem.click(item)">
                <mat-icon>{{menuItem.icon}}</mat-icon>
                <span>{{menuItem.label}}</span>
            </button>
            <mat-divider *ngIf="config.menuItems.length > 0"></mat-divider>
            <ng-container *ngIf="!config.readOnly">
                <button mat-menu-item (click)="edit(item)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit...</span>
                </button>
                <button mat-menu-item (click)="duplicate(item)">
                    <mat-icon>content_copy</mat-icon>
                    <span>Duplicate...</span>
                </button>
                <button mat-menu-item (click)="delete(item)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </ng-container>
        </ng-template>
    </mat-menu>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
</table>
