import {Component, Input, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {Transaction, TransactionsService} from '../../../services/transactions/transactions.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TeamsService} from '../../../services/teams/teams.service';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-analytics-reimbursements',
  templateUrl: './analytics-reimbursements.component.html',
  styleUrls: ['./analytics-reimbursements.component.scss']
})
export class AnalyticsReimbursementsComponent implements OnInit {
  @Input() analytics: any;
  @ViewChild(MatSort) sort!: MatSort;
  SCROLL_CHUNK = 1000;

  public columnsToDisplay = [
      'transactionDate', 'user', 'employeeNumber',
      'sender', 'details', 'amount',
      'category', 'taxable', 'status', 'action'
  ];
  public statuses = [
    {number: 0, message: 'All' },
    {number: 1, message: 'Paid' },
    {number: 2, message: 'Onetime' }
  ];
  public members: any;
  public team: any;
  public items = new MatTableDataSource<Transaction>();
  private page = 0;
  private teamId = '';
  private searchValue = '';
  public userId = 'all';
  public form: UntypedFormGroup;
  public fileName = '';

  constructor(
      private transactionsService: TransactionsService,
      private teamsService: TeamsService,
      private route: ActivatedRoute,
      private formBuilder: UntypedFormBuilder,
  ) {
    this.form = this.formBuilder.group({
          member: new UntypedFormControl(0),
          status: new UntypedFormControl(0)
        }
    );
    this.form.valueChanges.subscribe(() => {
      const memberIndex = this.form.get('member')?.value;
      this.userId = memberIndex ? this.members[memberIndex].userId : 'all';
      this.loadData().then(results => {
        this.items.data = results;
      });
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.userId) {
        this.userId = params.userId;
      }
      this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any) => {
        if (wellspace) {
          this.teamId = wellspace.id;
          this.team = wellspace;
          this.teamsService.getTeamMembers(this.teamId).then(members => {
            this.members = members.filter((thisMember: any) => thisMember.userId);
            this.members.unshift({firstName: 'All', lastName: 'members'});
            const index = members.findIndex((thisMember: any) => thisMember.userId === this.userId);
            this.form.get('member')?.setValue(index < 0 ? 0 : index, {emitEvent: false});
            const member = this.members[index < 0 ? 0 : index];
            this.fileName = `${wellspace.name} reimbursements ${member.firstName} ${member.lastName}`;
            this.loadData().then(results => {
              this.items.data = results;
              this.items.sort = this.sort;
            });
          });
        }
      });
    });
  }

  private async loadData(): Promise<any> {
    let results: any[] = [];
    // MM-3876: It was requested that the list include all transaction types,
    // with the exception of "Topup".
    const excludeStatus = 'Topup';
    await this.transactionsService.listReimbursements(this.teamId, this.page, this.SCROLL_CHUNK, this.searchValue, '', excludeStatus).then(res => {
      results = res?.teams[0]?.items.filter((obj: any, index: number, self: any) =>
        index === self.findIndex((t: any) => (
            JSON.stringify(t) === JSON.stringify(obj)
        )));
    }).catch(err => {
      console.error('Error in list Reimbursements', err);
      results = [];
    });
    if (this.userId !== 'all') {
      results = results.filter((item: any) => this.userId === item.userId);
    }
    const statusFilter = this.form.get('status')?.value;
    if (statusFilter !== 0) {
      results = results.filter((item: any) => item.status === this.statuses[statusFilter].message);
    }
    if (this.searchValue) {
      const search = this.searchValue.toLowerCase();
      results = results.filter((item: any) => {
        const member = this.members.find((thisMember: any) => thisMember.userId === item.userId);
        return item.employeeNumber?.toLowerCase().includes(search) ||
            item.status?.toLowerCase().includes(search) ||
            item.amount?.toString().includes(search) ||
            member.firstName?.toLowerCase().includes(search) ||
            member.lastName?.toLowerCase().includes(search) ||
            (!this.team.transactionPrivacy && item.details?.toLowerCase().includes(search));
      });
    }
    results.forEach((result: any) => {
      result.employeeNumber = this.members.find((member: any) => member.userId === result.userId)?.employeeNumber;
    });
    return results.filter((item: any) => !(item.status === 'Payment failed' || item.status === 'Low or zero balance' || item.status === 'Topup' || item.status === null));
  }

  filterItems(event: any): void {
    this.searchValue = event.target.value;
    this.loadData().then(results => {
      this.items.data = results;
    });
  }

}

@Pipe({
  name: 'getUserNameReimbursements',
  pure: true
})
export class GetUserNameReimbursements implements PipeTransform {

  transform(item: any, thisArg: AnalyticsReimbursementsComponent): string {
    const member = thisArg.members.find((thisMember: any) => thisMember.userId === item.userId);
    return !member ? '---' : `${member.firstName ?? ''} ${member?.lastName ?? ''}`;
  }

}

@Pipe({
  name: 'getSenderNameReimbursements',
  pure: true
})
export class GetSenderNameReimbursements implements PipeTransform {

  transform(item: any, thisArg: AnalyticsReimbursementsComponent): string {
    const member = thisArg.members.find((thisMember: any) => thisMember.userId === item.sender);
    return !member ? '---' : `${member.firstName ?? ''} ${member?.lastName ?? ''}`;
  }

}

